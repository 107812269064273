export default {
  'opsQuestion.title': 'OPS Question',

  'opsQuestion.add': 'Add',
  'opsQuestion.addQuesion': 'Add',
  'opsQuestion.searchKey': 'Keywords...',
  'opsQuestion.delete': 'Delete',
  'opsQuestion.operation': 'Operation',
  'opsQuestion.edit': 'Edit',
  'opsQuestion.query': 'Search',
  'opsQuestion.details': 'Details',

  'opsQuestion.save': 'Save',
  'opsQuestion.cancel': 'Cancel',

  'opsQuestion.lableManage': 'Lable Manage',
  'opsQuestion.name': 'Name',
  'opsQuestion.definitionDescription': 'Definition Description',
  'opsQuestion.createTime': 'Create Time',

  'opsQuestion.questionList': 'Question List',
  'opsQuestion.flightIATASearch': 'Flight IATA...',
  'opsQuestion.problemKeySearch': 'Problem Key...',
  'opsQuestion.orgInChargeSearch': 'Org In Charge...',
  'opsQuestion.tailNoSearch': 'Tail No...',
  'opsQuestion.deptSearch': 'Dept...',
  'opsQuestion.destSearch': 'Dest...',
  'opsQuestion.exportData': 'Export',
  'opsQuestion.customColumns': 'Display',
  'opsQuestion.selectColumns': 'Select Columns',
  'opsQuestion.allLabel': 'All Label',
  'opsQuestion.allState': 'All State',
  'opsQuestion.tailNoExp': 'Tail No...',
  'opsQuestion.allLevel': 'All Level',

  'opsQuestion.FATAL': 'FATAL',
  'opsQuestion.SERIOUS': 'SERIOUS',
  'opsQuestion.WARN': 'WARN',
  'opsQuestion.CRITICAL': 'CRITICAL',
  'opsQuestion.ERROR': 'ERROR',
  'opsQuestion.ALARM': 'ALARM',
  'opsQuestion.ATTENTION': 'ATTENTION',
  'opsQuestion.NOTIFICATION': 'NOTIFICATION',
  'opsQuestion.DEBUG': 'DEBUG',

  'opsQuestion.PENDING': 'PENDING',
  'opsQuestion.PROCESSING': 'PROCESSING',
  'opsQuestion.UNVERIFIED': 'UNVERIFIED',
  'opsQuestion.SOLVED': 'SOLVED',
  'opsQuestion.CLOSED': 'CLOSED',

  'opsQuestion.planeFlight': 'Plane Flight',

  'opsQuestion.serialNo': 'Serial No',
  'opsQuestion.questionName': 'Question name',
  'opsQuestion.happenTime': 'Happen time',
  'opsQuestion.orgInCharge': 'Org in charge',
  'opsQuestion.problemLevel': 'Problem level',
  'opsQuestion.tailNo': 'Tail No',
  'opsQuestion.flightIATA': 'Flight IATA',
  'opsQuestion.flightDate': 'Flight date',
  'opsQuestion.deptCity': 'Dept city',
  'opsQuestion.deptCode': 'Dept code',
  'opsQuestion.destCity': 'Dest city',
  'opsQuestion.destCode': 'Dest code',
  'opsQuestion.description': 'Description',
  'opsQuestion.reason': 'Reason',
  'opsQuestion.resolve': 'Resolve',
  'opsQuestion.labels': 'Labels',
  'opsQuestion.state': 'State',
  'opsQuestion.personInCharge': 'Person in charge',
  'opsQuestion.recordTime': 'Record time',
  'opsQuestion.updateTime': 'Update time',

  'opsQuestion.questionDetails': 'Question Details',
  'opsQuestion.flight': 'Flight',
  'opsQuestion.flightInfo': 'Flight Info',
  'opsQuestion.joinTime': 'Join Time',
  'opsQuestion.leaveTime': 'Leave Time',
  'opsQuestion.takeOffTime': 'Take Off Time',
  'opsQuestion.arriveTime': 'Arrive Time',
  'opsQuestion.actualTakeOffTime': 'Actual Take Off Time',
  'opsQuestion.actualArriveTime': 'Actual Arrive Time',
  'opsQuestion.predictTakeOffTime': 'Predict Take Off Time',
  'opsQuestion.predictArriveTime': 'Predict Arrive Time',
  'opsQuestion.updateUser': 'Update User',

  'opsQuestion.exp1': "(The problem name for the same aircraft at the same time cannot be duplicated)",
  'opsQuestion.exp2': "(The name of the organization responsible for the current issue)",
  'opsQuestion.exp3': "(If left blank, it will be considered unassigned)",
  'opsQuestion.exp4': "(Once submitted, it cannot be modified)",
  'opsQuestion.exp5': "(Evaluate from multiple perspectives and dimensions, multiple choices are supported)",
}