export default {
  'nmsDeviceAircrafts.title': '飞机列表',
  'nmsDeviceAircrafts.pageDescription': '机载网络设备运行管理',

  'nmsDeviceAircrafts.search': '请输入机尾号搜索',
  'nmsDeviceAircrafts.tailNumber': '机尾号',
  'nmsDeviceAircrafts.airlines': '所属公司',
  'nmsDeviceAircrafts.modificationScheme': '改装方案',
  'nmsDeviceAircrafts.networkType': '网络类型',
  'nmsDeviceAircrafts.installationTime': '装机时间',
  'nmsDeviceAircrafts.edit': '操作',
  'nmsDeviceAircrafts.planeAge': '机龄(年)',
  'nmsDeviceAircrafts.filterSelectAll': '全部',
  'nmsDeviceAircrafts.netTypesLocal': '局域网',
  'nmsDeviceAircrafts.netTypesSat':'卫星',
  'nmsDeviceAircrafts.dataReport':'数据报告',
  'nmsDeviceAircrafts.runningLog':'设备数据',
  'nmsDeviceAircrafts.flightHistory':'飞行历史',
  'nmsDeviceAircrafts.refittingDetails':'改装详情',
  'nmsDeviceAircrafts.updateRefittingDetails':'更新改装方案',
  'nmsDeviceAircrafts.netTypesNoNet':'无网络',
  'nmsDeviceAircrafts.noImage':'暂无图片',
  'nmsDeviceAircrafts.countsPlaneNumber':'累计装机数量',
  'nmsDeviceAircrafts.countsFlightsNumber':'本周执飞次数',
  'nmsDeviceAircrafts.countsHealthNumber':'本周运行健康度',
  'nmsDeviceAircrafts.countsPlaneNumberUnit':'架',
  'nmsDeviceAircrafts.countsFlightsNumberUnit':'次',
  'nmsDeviceAircrafts.more':'更多',
  'nmsDeviceAircrafts.record':'实时信息',
  'nmsDeviceAircrafts.detailed':'飞行记录',
  'nmsDeviceAircrafts.serviceTest':'服务检测',

  'nmsDeviceAircrafts.bitInfo':'Bit Info配置',
  'nmsDeviceAircrafts.pointStation':'小站设置',
  'nmsDeviceAircrafts.gnsRight':'机载服务器鉴权',
  'nmsDeviceAircrafts.test':'装机测试',
  'nmsDeviceAircrafts.bwlist':'黑白名单检测',
  'nmsDeviceAircrafts.deviceChange':'设备拆换',
  'nmsDeviceAircrafts.softwareUpdate':'软件更新',
  'nmsDeviceAircrafts.deviceChangeLog':'拆换记录',
  'nmsDeviceAircrafts.detailedLog':'飞行记录',
  'nmsDeviceAircrafts.refresh':'刷新',
  'nmsDeviceAircrafts.airborne':'飞行状态',
  'nmsDeviceAircrafts.PSstatus':'小站状态',
  'nmsDeviceAircrafts.unknown':'未知',
  'nmsDeviceAircrafts.flying':'飞行中',
  'nmsDeviceAircrafts.landing':'已停飞',
  'nmsDeviceAircrafts.online':'在线',
  'nmsDeviceAircrafts.offline':'离线',
};