import React, {
  PureComponent
} from "react"
import {
  Menu,
} from "antd"
import "antd/dist/antd.less"
import "./index.less"
import {
  AppContextEnhancer
} from "shared/libs/app-context"
import AppHeader from "./header"
import AppBottom from "./bottom"

import menuCompose from './menu-compose'

import {
  createMenus,
  menuSettings
} from './menu-creator'

const RootMenu = Menu;

@AppContextEnhancer
@menuCompose
class AppMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuChildren: createMenus(props['menus'] || []),
    }
  }

  render() {
    const {context, selectedKeys, openKeys} = this.props;
    return (
      <div className="zr-menu-container">
        <AppHeader/>
        <div className="menu-layout">
          <RootMenu {...menuSettings}
                    inlineIndent={24}
                    defaultSelectedKeys={selectedKeys}
                    defaultOpenKeys={openKeys}
                    inlineCollapsed={context.menuCollapsed}>
            {this.state.menuChildren}
          </RootMenu>
        </div>
        <AppBottom/>
      </div>
    )
  }
}


export default AppMenu