export default {
  'commodityInfo.title': 'Details',
  'commodityInfo.definition': 'Definition',
  'commodityInfo.setting': 'Setting',
  'commodityInfo.operatorID': 'operatorID',
  'commodityInfo.dataFlowType': 'dataFlowType',
  'commodityInfo.dataFlowValue': 'dataFlowValue',
  'commodityInfo.endTime': 'endTime',
  'commodityInfo.upload': 'upload',
  'commodityInfo.download': 'download',
  'commodityInfo.urls': 'urls',
  'commodityInfo.apps': 'apps',
  'commodityInfo.notOpenedYet': '(not opened yet, please wait)',
  'commodityInfo.mainImage': 'mainImage',
  'commodityInfo.name': 'name',
  'commodityInfo.price': 'price',
  'commodityInfo.points': 'points',
  'commodityInfo.type': 'type',
  'commodityInfo.createTime': 'creatTime',
  'commodityInfo.offShelfTime': 'offShelfTime',
  'commodityInfo.statusValue': 'status',
  'commodityInfo.plusDescription': 'plusDescription',
  'commodityInfo.description': 'description',
  'commodityInfo.status1': 'status1',
  'commodityInfo.status2': 'status2',
  'commodityInfo.status3': 'status3',
  'commodityInfo.status4': 'status4',
  'commodityInfo.operatorTrafficName': 'Operator traffic name',
  'commodityInfo.fixedTimeLong': 'Fixed time is long',
  'commodityInfo.maxAvailableTrafficSize': 'Maximum available traffic size',
  'commodityInfo.ispName': 'ISP',
  'commodityInfo.vspName': 'VSP',

  'commodityInfo.FIXED_LEG': '固定航段',
  'commodityInfo.FIXED_TIME_RANGE': '固定时段',
  'commodityInfo.FIXED_TIME': '固定时长',
  'commodityInfo.FIXED_DATA_FLOW': '固定流量',
  'commodityInfo.TIME_LIMIT': '限定时间',
  'commodityInfo.THIRD_PART': '第三方',

  'commodityInfo.NonMember': '非会员',
  'commodityInfo.Member': '会员',
  'commodityInfo.Unlimited': '不限',

  'commodityInfo.commodity_status0': '下架',
  'commodityInfo.commodity_status1': '在售',
  'commodityInfo.commodity_status2': '等待上架',
  'commodityInfo.commodity_status3': '超过有效期，自动下架中',
};