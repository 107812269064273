export default {
  'commodityInfo.title': '套餐详情',
  'commodityInfo.definition': '流量定义',
  'commodityInfo.setting': '运营设置',
  'commodityInfo.operatorID': '运营商ID',
  'commodityInfo.dataFlowType': '流量类型',
  'commodityInfo.dataFlowValue': '固定流量大小',
  'commodityInfo.endTime': '流量套餐有效截止时间',
  'commodityInfo.upload': '上行速率',
  'commodityInfo.download': '下行速率',
  'commodityInfo.urls': '指定访问地址',
  'commodityInfo.apps': '指定访问应用',
  'commodityInfo.notOpenedYet': '(暂未开通，敬请期待)',
  'commodityInfo.mainImage': '运营主图',
  'commodityInfo.name': '运营标题',
  'commodityInfo.price': '销售价格',
  'commodityInfo.points': '所需积分',
  'commodityInfo.type': '购买用户类型',
  'commodityInfo.createTime': '创建时间',
  'commodityInfo.onShelfTime': '上架下架时间',
  'commodityInfo.statusValue': '运营状态',
  'commodityInfo.plusDescription': '备注说明',
  'commodityInfo.description': '套餐介绍',
  'commodityInfo.status1': '销售中',
  'commodityInfo.status2': '已上架',
  'commodityInfo.status3': '已下架',
  'commodityInfo.status4': '待上架',
  'commodityInfo.operatorTrafficName': '运营商流量包名称',
  'commodityInfo.fixedTimeLong': '固定的时间长',
  'commodityInfo.maxAvailableTrafficSize': '最多可用流量大小',
  'commodityInfo.ispName': '网络运营商',
  'commodityInfo.vspName': '增值服务商',

  'commodityInfo.FIXED_LEG': '固定航段',
  'commodityInfo.FIXED_TIME_RANGE': '固定时段',
  'commodityInfo.FIXED_TIME': '固定时长',
  'commodityInfo.FIXED_DATA_FLOW': '固定流量',
  'commodityInfo.TIME_LIMIT': '限定时间',
  'commodityInfo.THIRD_PART': '第三方',

  'commodityInfo.NonMember': '非会员',
  'commodityInfo.Member': '会员',
  'commodityInfo.Unlimited': '不限',

  'commodityInfo.commodity_status0': '下架',
  'commodityInfo.commodity_status1': '在售',
  'commodityInfo.commodity_status2': '等待上架',
  'commodityInfo.commodity_status3': '超过有效期，自动下架中',
};