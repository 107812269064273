export default {
  'dataEventStatistics.title': 'Event Statistics',

  'dataEventStatistics.tailNumber': 'Tail No',
  'dataEventStatistics.flightNumber': 'Flight number',
  'dataEventStatistics.timeInterval': 'Time interval',
  'dataEventStatistics.query': 'Search',
  'dataEventStatistics.tailNumberSearch': 'Tail No...',
  'dataEventStatistics.flightNumberSearch': 'Flight number...',
  'dataEventStatistics.eventNameSearch': 'Event name...',
  'dataEventStatistics.eventLevelIdsSearch': 'Event level...',
  'dataEventStatistics.deptSearch': 'Dept...',
  'dataEventStatistics.destSearch': 'Dest...',
  'dataEventStatistics.exportData': 'Export',

  'dataEventStatistics.tailNo': 'Tail No',
  'dataEventStatistics.flightNo': 'Flight Number',
  'dataEventStatistics.flightDate': 'Flight Date',
  'dataEventStatistics.deptCity': 'Dept City',
  'dataEventStatistics.destCity': 'Dest City',
  'dataEventStatistics.body': 'Event name',
  'dataEventStatistics.eventLevelName': 'Event Level',
  'dataEventStatistics.occurrenceCount': 'Count',

  'dataEventStatistics.eventName': 'Event',
  'dataEventStatistics.eventLevel': 'Level',

  'dataEventStatistics.time': 'Time',
  'dataEventStatistics.totalFlight': 'Total Flight',
  'dataEventStatistics.totalDay': 'Total Day',
  'dataEventStatistics.totalWeek': 'Total Week',
  'dataEventStatistics.totalMonth': 'Total Month',
  'dataEventStatistics.totalQuarter': 'Total Quarter',
  'dataEventStatistics.totalYear': 'Total Year',

  'dataEventStatistics.last1d': 'Last day',
  'dataEventStatistics.last2d': 'Last 2 days',
  'dataEventStatistics.last3d': 'Last 3 days',
  'dataEventStatistics.last5d': 'Last 5 days',
  'dataEventStatistics.last7d': 'Last 7 days',
  'dataEventStatistics.last30d': 'Last 30 days',
  'dataEventStatistics.custom': 'Custom',
};
