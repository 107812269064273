export default {
  'iasAirport.title': 'Airport',
  'iasAirport.createAirport': 'Add Airport',
  'iasAirport.editAirport': 'Edit Airport',

  'iasAirport.searchKey': 'Name/IATA/ICAO...',
  'iasAirport.search': 'Location or Address...',

  'iasAirport.confirm': 'Confirm',
  'iasAirport.operation': 'Operation',
  'iasAirport.save': 'Save',
  'iasAirport.cancel': 'Cancel',
  'iasAirport.create': 'Add',
  'iasAirport.edit': 'Edit',
  'iasAirport.delete': 'Delete',

  'iasAirport.name': 'Name',
  'iasAirport.iata': 'IATA',
  'iasAirport.icao': 'ICAO',
  'iasAirport.country': 'Country',
  'iasAirport.city': 'City',
  'iasAirport.updateTime': 'Update Time',

  'iasAirport.nameEdit': 'Airport Name (name)',
  'iasAirport.iataEdit': 'IATA Code (iata)',
  'iasAirport.icaoEdit': 'ICAO Code (icao)',
  'iasAirport.countryEdit': 'Country (country)',
  'iasAirport.cityEdit': 'City (city)',
  'iasAirport.longitude': 'Longitude (longitude)',
  'iasAirport.latitude': 'Latitude (latitude)',
  'iasAirport.altitude': 'Altitude (feet)',
  'iasAirport.dst': 'Daylight Saving Time (dst)',
  'iasAirport.timezone': 'Timezone (timezone)',
  'iasAirport.tz': 'TZ',
  'iasAirport.source': 'Source (source)',
  'iasAirport.type': 'Type (type)',
  'iasAirport.priority': 'Priority',
};