export default {
  'nmsMonitorEvent.title': '监控事件',

  'nmsMonitorEvent.levelSet': '级别设置',
  'nmsMonitorEvent.addEvent': '新增事件',
  'nmsMonitorEvent.editEvent': '编辑事件',

  'nmsMonitorEvent.nameSearch': '输入事件名称搜索...',
  'nmsMonitorEvent.all': '全部',

  'nmsMonitorEvent.name': '事件名称',
  'nmsMonitorEvent.levelName': '级别',
  'nmsMonitorEvent.conditionDescription': '触发条件',
  'nmsMonitorEvent.operate': '操作',

  'nmsMonitorEvent.edit': '编辑',
  'nmsMonitorEvent.delete': '删除',
  'nmsMonitorEvent.deleteConfirm': '可能在其他地方被引用，删除后将不能正常使用，请再次确认是否需要删除？',
  'nmsMonitorEvent.thinkAgain': '再想一想',
  'nmsMonitorEvent.confirm': '我意已决',

  'nmsMonitorEvent.cancel': '取消',
  'nmsMonitorEvent.submit': '提交',
  'nmsMonitorEvent.inputName': '请输入名称',
  'nmsMonitorEvent.inputDescription': '请输入条件描述...',
  'nmsMonitorEvent.inputKey': '请输入设备参数关键字检索...',
  'nmsMonitorEvent.recent': '最近',
  'nmsMonitorEvent.minutes': '分钟',
  'nmsMonitorEvent.is': '属于',
  'nmsMonitorEvent.isNot': '不属于',
  'nmsMonitorEvent.contain': '包含',
  'nmsMonitorEvent.noContain': '不包含',
  'nmsMonitorEvent.isNull': '为空',
  'nmsMonitorEvent.notNull': '非空',
  'nmsMonitorEvent.changed': '有变化',
  'nmsMonitorEvent.noChange': '未变化',
  'nmsMonitorEvent.startWith': '开始是',
  'nmsMonitorEvent.endWith': '结束是',
  'nmsMonitorEvent.notStartWith': '开始不是',
  'nmsMonitorEvent.notEndWith': '结束不是',
  'nmsMonitorEvent.equal': '等于',
  'nmsMonitorEvent.notEqual': '不等于',
  'nmsMonitorEvent.greatThan': '大于',
  'nmsMonitorEvent.lessThan': '小于',
  'nmsMonitorEvent.greatThanEqual': '大于等于',
  'nmsMonitorEvent.lessThanEqual': '小于等于',
  'nmsMonitorEvent.current': '当前值',
  'nmsMonitorEvent.min': '最小值',
  'nmsMonitorEvent.max': '最大值',
  'nmsMonitorEvent.avg': '平均值',
  'nmsMonitorEvent.sum': '累计值',
  'nmsMonitorEvent.pre': '上一个值',
  'nmsMonitorEvent.and': '且',
  'nmsMonitorEvent.or': '或',
  'nmsMonitorEvent.AND': '且条件',
  'nmsMonitorEvent.OR': '或条件',

};
