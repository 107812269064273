export default {
  'satcomStationWarning.title': 'Station Warning',

  'satcomStationWarning.tailNoColon': 'Tail No:',
  'satcomStationWarning.levelColon': 'Level:',
  'satcomStationWarning.statusColon': 'Status:',
  'satcomStationWarning.timeColon': 'Time:',

  'satcomStationWarning.all': 'Select all',
  'satcomStationWarning.minor': 'Minor',
  'satcomStationWarning.major': 'Major',
  'satcomStationWarning.open': 'Open',
  'satcomStationWarning.close': 'Close',

  'satcomStationWarning.stow': 'Stow',
  'satcomStationWarning.more': 'More',
  'satcomStationWarning.enterTailNumber': 'Tail No...',
  'satcomStationWarning.today': 'Today',
  'satcomStationWarning.thisWeek': 'Last week',
  'satcomStationWarning.thisMonth': 'Last month',

  'satcomStationWarning.tailNo': 'Tail No',
  'satcomStationWarning.warningTime': 'Warning Time',
  'satcomStationWarning.pointStationCode': 'Point Station Code',
  'satcomStationWarning.warningHeader': 'Warning Header',
  'satcomStationWarning.warningLevel': 'Warning Level',
  'satcomStationWarning.warningStatus': 'Warning Status',
  'satcomStationWarning.warningCount': 'Warning Count',
  'satcomStationWarning.warningType': 'Warning Type',
  'satcomStationWarning.warningDetail': 'Warning Detail',
  'satcomStationWarning.warningID': 'Warning ID',
};