import React from 'react'
import {
  connect
} from 'react-redux'
import {
  compose,
  onlyUpdateForKeys,
  branch,
  renderComponent
} from 'recompose'
import Exception from '../Exception'
// 校验路由权限组件
const isNotAuth = props => {
  // route 本路由表，user 全局登录信息、menu 服务端返回的菜单列表
  const {
    match: {
      path = null
    },
    user = {},
    permissions = []
  } = props
  const {
    id = null
  } = user
  if (id) {
    const result = permissions.find(i => i === path)
    if (result) {
      return false
    }
    return true
  }

  return true
}

const NotFound = () => (
  <Exception type='403' errorMessage="您没有权限查看此页面" action={()=>window.history.back()} />
)

const renderWhileIsNotAuth = branch(
  isNotAuth,
  renderComponent(NotFound)
)

const mapState = ({
  authorization
}) => ({
  user: authorization.userData,
  permissions: authorization.permissions,
})

const getUserAndMenu = compose(
  connect(mapState),
  onlyUpdateForKeys(['user', 'permissions'])
)

export default compose(
  getUserAndMenu,
  renderWhileIsNotAuth
)