export default {
  'dataPackage.title': 'Package',

  'dataPackage.tailNumber': 'Tail No',
  'dataPackage.flightNumber': 'Flight number',
  'dataPackage.timeInterval': 'Time interval',
  'dataPackage.query': 'Search',
  'dataPackage.tailNumberSearch': 'Tail No search...',
  'dataPackage.flightNumberSearch': 'Flight number search...',
  'dataPackage.commodityNameSearch': 'Name search...',
  'dataPackage.deptSearch': 'Dept...',
  'dataPackage.destSearch': 'Dest...',
  'dataPackage.exportData': 'Export',

  'dataPackage.flightDate': 'Flight date',
  'dataPackage.dept': 'Dept',
  'dataPackage.dest': 'Dest',
  'dataPackage.flightDuration': 'Flight duration',

  'dataPackage.commodityName': 'Commodity name',
  'dataPackage.satcomName': 'Satcom name',
  'dataPackage.total': 'Total',
  'dataPackage.inactive': 'Inactive',
  'dataPackage.activated': 'Activated',
  'dataPackage.failed': 'Failed',
  'dataPackage.orderPassenger': 'Order passenger',
  'dataPackage.activatedPassenger': 'Activated passenger',

  'dataPackage.time': 'Time',
  'dataPackage.totalFlight': 'Total Flight',
  'dataPackage.totalDay': 'Total Day',
  'dataPackage.totalWeek': 'Total Week',
  'dataPackage.totalMonth': 'Total Month',
  'dataPackage.totalQuarter': 'Total Quarter',
  'dataPackage.totalYear': 'Total Year',

  'dataPackage.last1d': 'Last day',
  'dataPackage.last2d': 'Last 2 days',
  'dataPackage.last3d': 'Last 3 days',
  'dataPackage.last5d': 'Last 5 days',
  'dataPackage.last7d': 'Last 7 days',
  'dataPackage.last30d': 'Last 30 days',
  'dataPackage.custom': 'Custom',
};
