import React from 'react'

const empty = () => () => "empty function"

export const AppContext = React.createContext({
  menuCollapsed: true,
  _setMenuCollapsed: empty(),
  userInfo: {}
})

export const AppContextEnhancer = Component => props => (
  <AppContext.Consumer>
    {context => <Component {...props} context={context}/>}
  </AppContext.Consumer>
)

export const AppContextKeyEnhancer = (key) => (Component) => props => {
  const crateCOn = (k, c) => {
    return {
      [k]: c
    }
  }
  return (
    <AppContext.Consumer>
      {context => <Component {...props} {...crateCOn(key,context)}/>}
    </AppContext.Consumer>
  )
}

export const AppKeyEnhancer = (Consumer, key) => (Component) => props => {
  const crateCOn = (k, c) => {
    return {
      [k]: c
    }
  }
  return (
    <Consumer>
      {context => <Component {...props} {...crateCOn(key,context)}/>}
    </Consumer>
  )
}