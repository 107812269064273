export default {
  'aircraftManage.title': 'Aircraft',

  'aircraftManage.tailNumberEg': 'Tail No eg: b573',
  'aircraftManage.searchOrganization': 'Name...',
  'aircraftManage.search': 'Search',
  'aircraftManage.newAircraft': 'Add',
  'aircraftManage.tailNumber': 'Tail No',
  'aircraftManage.orgName': 'Org name',
  'aircraftManage.model': 'Model',
  'aircraftManage.standardSeats': 'Standard seats',
  'aircraftManage.productionCompany': 'Production company',
  'aircraftManage.createTime': 'Create time',
  'aircraftManage.firstFightDate': 'First flight date',
  'aircraftManage.operation': 'Operation',
  'aircraftManage.edit': 'Edit',
  'aircraftManage.inputSeatNum': 'Please enter the standard seat capacity',

  'aircraftManage.exitDate': 'Exit date',
  'aircraftManage.remark': 'Comments',
  'aircraftManage.save': 'Save',
  'aircraftManage.cancel': 'Cancel',
  'aircraftManage.selectModel': 'Please select model',
  'aircraftManage.editAircraft': 'Edit aircraft',

  'aircraftManage.cabinCount': 'Cabin number',
  'aircraftManage.cabin': 'Cabin',
  'aircraftManage.cabinType': 'Cabin type',
  'aircraftManage.row': 'Row',
  'aircraftManage.column': 'Column',
  'aircraftManage.gangway': 'Gangway',
  'aircraftManage.first': 'First',
  'aircraftManage.business': 'Business',
  'aircraftManage.superEconomy': 'Super economy',
  'aircraftManage.economy': 'Economy',
  'aircraftManage.seatCount': 'Seat Count',
  'aircraftManage.AircraftPreview': 'Aircraft Preview',
  'aircraftManage.code': 'Code',
};