//import "./cryptohelper"
import update from "immutability-helper"
import {
  uniqueId
} from "lodash-es"
import i18n from "i18n-js"
import * as l from "shared/langs"
import UA from "ua-device"
import {DEBUG} from "./env"
import {message,} from 'antd';

let trackMap = {}
let settings = {}
let interval = "";

export function cls(...classes) {
  return (
    classes.filter(c => typeof c === "string" && c !== "").join(" ") || null
  )
}

export function logI(...args) {
  if (DEBUG) {
    console.log(...args);
  }
}

export function logE(...args) {
  console.error(...args);
}

export function checkNumber(data, unit) {
  if (data === '0' || data === 0) {
    return unit ? 0 + unit : 0;
  }
  if (!data) {
    return '--';
  }
  return unit ? data + unit : data;
}

export function displayName(Comp) {
  return Comp.displayName || Comp.name || "Component"
}

export function constrain(value, min, max) {
  if (value < min) {
    return min
  } else if (value > max) {
    return max
  } else {
    return value
  }
}

export function copysign(x, from) {
  return Math.sign(from) * x
}

export function interpolate(value, fromStart, fromEnd, toStart, toEnd) {
  return (
    (value - fromStart) / (fromEnd - fromStart) * (toEnd - toStart) + toStart
  )
}

export function limit(x, range) {
  return Math.abs(x) < range ? x : copysign(range, x)
}

export function comp(a, b) {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

export function hittest([px, py], [sx, sy, ex, ey]) {
  return px >= sx && px <= ex && py >= sy && py <= ey
}

export function randomStr(len) {
  let ret = ""
  let from = 48 //'0'
  let to = 122
  for (let i = 0; i < len; i++) {
    ret += String.fromCharCode(parseInt(Math.random() * (to - from) + from))
  }
  return ret
}

export function getRandom() {
  function randomIntDataFlowGenerator(min, max) {
    let arr = []
    for (let i = 0; i < 60; i++) {
      arr.push({speed: getRandomInt(min, max), timing: Date.now() + i})
    }
    return arr
  }

  let randomIntUploadDataFlow = randomIntDataFlowGenerator(100, 200);
  let randomIntDownloadDataFlow = randomIntDataFlowGenerator(100, 200);

  let randomIntAverageUploadDataFlow = getRandomInt(100, 200)
  let randomIntAverageDownloadDataFlow = getRandomInt(100, 200)

  let randomIntOnlineAccount = getRandomInt(10, 100)

  let randomIntOnlineAccountList = randomIntDataFlowGenerator(10, 100);

  let randomIntOnlineDeviceList = randomIntDataFlowGenerator(10, 100);

  const random = {
    randomIntUploadDataFlow,
    randomIntDownloadDataFlow,
    randomIntAverageUploadDataFlow,
    randomIntAverageDownloadDataFlow,
    randomIntOnlineAccount,
    randomIntOnlineAccountList,
    "randomIntOnlineDevice": randomIntOnlineAccount + getRandomInt(1, 10),
    randomIntOnlineDeviceList
  }
  return random
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //不含最大值，含最小值
}

export function encrypt(str) {
  //return CryptoAdapter.encryptRSA(str)
}

export function decrypt(str, key) {
  //return CryptoAdapter.decryptAES(str, ...key.split("|"))
}

export function setTransform(element, value) {
  element.style.WebkitTransform = value
  element.style.MozTransform = value
  element.style.msTransform = value
  element.style.transform = value
}

export function getTransform(value) {
  return {
    WebkitTransform: value,
    MozTransform: value,
    msTransform: value,
    transform: value,
  }
}

export function formatTime(seconds) {
  let minute = Math.floor(seconds / 60)
  minute = minute >= 10 ? minute : `0${minute}`
  let second = Math.floor(seconds % 60)
  second = second >= 10 ? second : `0${second}`
  return `${minute}:${second}`
}

export function arr(gen, n) {
  const result = new Array(n)
  for (let i = 0; i < n; i++) {
    result[i] = gen()
  }
  return result
}

export function completeList(list, n) {
  const remainingNum = n - list.length % n

  if (remainingNum === n) return list

  const empty = () => ({
    id: uniqueId(),
  })

  return update(list, {
    $push: arr(empty, remainingNum)
  })
}

export function obj2Str(obj) {
  return (
    obj &&
    Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join("&")
  )
}

export function tableList2Str(list) {
  return (
    list &&
    list.map(item => `${encodeURIComponent(item.dataName)},${encodeURIComponent(item.dataIndex)}`)
      .join(";")
  )
}

export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime()
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now() //use high-precision timer if available
  }
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = ((d + Math.random() * 16) % 16) | 0
    d = Math.floor(d / 16)
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const detectPlatform = (function () {
  let hasDetected = false

  let platformName = "Unknown"

  let isWindowsDesktop = false
  let isMacOs = false
  let isAndroid = false
  let isBlackBerry = false
  let isPalm = false
  let isSymbian = false
  let isWindowsMobile = false
  let isWindowsPhone = false
  let isIOS = false
  let isMeeGo = false
  let isMaemo = false
  let isJava = false
  let isWebOS = false
  let isBada = false
  let isBREW = false

  return function detectPlatformFromCacheIfPossible() {
    if (!hasDetected) {
      const ua = window.navigator.userAgent
      if (/Android/i.test(ua)) {
        isAndroid = true
        platformName = "Android"
      } else if (/blackberry|\bBB10\b|rim tablet os/i.test(ua)) {
        isBlackBerry = true
        platformName = "BlackBerry"
      } else if (
        /PalmOS|avantgo|blazer|elaine|hiptop|palm|plucker|xiino/i.test(ua)
      ) {
        isPalm = true
        platformName = "Palm"
      } else if (
        /Symbian|SymbOS|Series60|Series40|SYB-[0-9]+|\bS60\b/i.test(ua)
      ) {
        isSymbian = true
        platformName = "Symbian"
      } else if (
        /Windows CE.*(PPC|Smartphone|Mobile|[0-9]{3}x[0-9]{3})|Window Mobile|Windows Phone [0-9.]+|WCE;/i.test(
          ua,
        )
      ) {
        isWindowsMobile = true
        platformName = "WindowsMobile"
      } else if (
        /Windows Phone 10.0|Windows Phone 8.1|Windows Phone 8.0|Windows Phone OS|XBLWP7|ZuneWP7|Windows NT 6.[23]; ARM;/i.test(
          ua,
        )
      ) {
        isWindowsPhone = true
        platformName = "WindowsPhone"
      } else if (/\biPhone.*Mobile|\biPod|\biPad/i.test(ua)) {
        isIOS = true
        platformName = "iOS"
      } else if (/MeeGo/i.test(ua)) {
        isMeeGo = true
        platformName = "MeeGo"
      } else if (/Maemo/i.test(ua)) {
        isMaemo = true
        platformName = "Maemo"
      } else if (/J2ME\/|\bMIDP\b|\bCLDC\b/i.test(ua)) {
        isJava = true
        platformName = "Java"
      } else if (/webOS|hpwOS/i.test(ua)) {
        isWebOS = true
        platformName = "WebOS"
      } else if (/\bBada\b/i.test(ua)) {
        isBada = true
        platformName = "Bada"
      } else if (/BREW/i.test(ua)) {
        isBREW = true
        platformName = "BREW"
      } else if (/(Intel|PPC) Mac OS X/i.test(ua)) {
        isMacOs = true
        platformName = "macOS"
      } else if (/Windows NT/i.test(ua)) {
        isWindowsDesktop = true
        platformName = "Windows"
      } else if (/Linux/i.test(ua)) {
        platformName = "Linux"
      }
    }
    return {
      platformName,
      isWindowsDesktop,
      isMacOs,
      isAndroid,
      isBlackBerry,
      isPalm,
      isSymbian,
      isWindowsMobile,
      isWindowsPhone,
      isIOS,
      isMeeGo,
      isMaemo,
      isJava,
      isWebOS,
      isBada,
      isBREW,
    }
  }
})()

export const device = (async function (UA) {
  let output = new UA(navigator.userAgent)
  let deviceId = await localStorage.getItem("deviceId") || generateUUID()
  localStorage.setItem("deviceId", deviceId)

  return {
    browser: output.browser && output.browser.name,
    browserVersion: output.browser &&
      output.browser.version &&
      output.browser.version.original,
    os: output.os.name,
    osVersion: output.os.version && output.os.version.original,
    model: output.device.model,
    deviceId,
  }
})(UA)

export const tracker = {
  set: (key, value) => {
    settings[key] = value
  },
  delete: key => {
    delete settings[key]
  },
  start: async (name, func, options) => {
    if (!trackMap[name]) {
      let params = options || {}
      Object.assign(params, settings)
      params.contentName = name
      const res = await func.apply(this, [params])
      trackMap[name] = res.recordId
    }
  },
  send: async (name, func, options) => {
    let params = options || {}
    Object.assign(params, settings)
    params.contentName = name
    await func.apply(this, [params])
  },
  end: (name, end, options) => {
    if (trackMap[name]) {
      let params = options || {}
      Object.assign(params, settings)
      params["data"] = params["data"] || {}
      params.contentName = name
      params["data"].recordId = trackMap[name]
      end.apply(this, [params])
      delete trackMap[name]
    }
  },
  endAll: end => {
    Object.keys(trackMap).forEach(key => {
      let params = {}
      params.recordId = trackMap[key]
      params.contentName = key
      end.apply(this, params)
      delete trackMap[key]
    })
  },
}

export function formatScore(score) {
  return Number.isFinite(score) ? score.toFixed(1) : ""
}

export function sortOnlineUsers(selfId, listOfOnlineUsers) {
  if (listOfOnlineUsers == null) {
    return []
  }
  return listOfOnlineUsers.sort((a, b) => {
    if (a.id === selfId) {
      return -1
    } else if (b.id === selfId) {
      return 1
    } else {
      return a.name.localeCompare(b.name)
    }
  })
}

export function unitTranslate(value, unit) {
  switch (unit) {
    case 'GB':
      value = value * 1024;
      break;
    case 'TB':
      value = value * 1024 * 1024;
      break;
    default:
      value = value * 1;
      break;
  }
  return value;
}

export function unitChange(fromUnit, toUnit, value) {
  switch (fromUnit) {
    case 'MB':
      switch (toUnit) {
        case 'Kb':
          value = value * 8 * 1024;
          break;
        case 'KB':
          value = value * 1024;
          break;
        case 'Mb':
          value = value * 8 || 0;
          break;
        case 'GB':
          value = value / 1024 || 0;
          break;
        case 'TB':
          value = value / (1024 * 1024) || 0;
          break;
        default:
          break;
      }
      break;
    case 'GB':
      switch (toUnit) {
        case 'Kb':
          value = value * 8 * 1024 * 1024;
          break;
        case 'KB':
          value = value * 1024 * 1024;
          break;
        case 'Mb':
          value = value * 1024 * 8 || 0;
          break;
        case 'MB':
          value = value * 1024;
          break;
        case 'TB':
          value = value / 1024 || 0;
          break;
        default:
          break;
      }
      break;
    case 'TB':
      switch (toUnit) {
        case 'Kb':
          value = value * 8 * 1024 * 1024 * 1024;
          break;
        case 'KB':
          value = value * 1024 * 1024 * 1024;
          break;
        case 'MB':
          value = value * 1024 * 1024;
          break;
        case 'Mb':
          value = value * 1024 * 1024 * 8 || 0;
          break;
        case 'GB':
          value = value * 1024;
          break;
        default:
          break;
      }
      break;
    case 'KB':
      switch (toUnit) {
        case 'Kb':
          value = value * 8;
          break;
        case 'MB':
          value = value / 1024 || 0;
          break;
        case 'GB':
          value = value / 1024 / 1024 || 0;
          break;
        case 'TB':
          value = value / 1024 / 1024 / 1024 || 0;
          break;
        case 'Mb':
          value = value / 1024 * 8 || 0;
          break;
        default:
          break;
      }
      break;
    case 'Mb':
      switch (toUnit) {
        case 'Kb':
          value = value * 1024;
          break;
        case 'KB':
          value = value / 8 * 1024 || 0;
          break;
        case 'MB':
          value = value / 8 || 0;
          break;
        case 'GB':
          value = value / 8 / 1024 || 0;
          break;
        case 'TB':
          value = value / 8 / 1024 / 1024 || 0;
          break;
        default:
          break;
      }
      break;
    case 'Kb':
      switch (toUnit) {
        case 'Mb':
          value = value / 1024 || 0;
          break;
        case 'KB':
          value = value / 8 || 0;
          break;
        case 'MB':
          value = value / 1024 / 8 || 0;
          break;
        case 'GB':
          value = value / 8 / 1024 / 1024 || 0;
          break;
        case 'TB':
          value = value / 8 / 1024 / 1024 / 1024 || 0;
          break;
        default:
          break;
      }
      break;

    case 'minute':
      switch (toUnit) {
        case 'hour':
          value = Math.floor(value / 60 || 0);
          break;
        case 'day':
          value = Math.floor(Math.floor(value / 60 || 0) / 24);
          break;
        case 'month':
          value = Math.floor(Math.floor(Math.floor(value / 60 || 0) / 24) / 30);
          break;
        case 'year':
          value = Math.floor(Math.floor(Math.floor(Math.floor(value / 60 || 0) / 24) / 30) / 12);
          break;
        default:
          break;
      }
      break;
    case 'hour':
      switch (toUnit) {
        case 'minute':
          value = value * 60;
          break;
        case 'day':
          value = Math.floor(value / 24 || 0);
          break;
        case 'month':
          value = Math.floor(Math.floor(value / 24 || 0) / 30);
          break;
        case 'year':
          value = Math.floor(Math.floor(Math.floor(value / 24 || 0) / 30) / 12);
          break;
        default:
          break;
      }
      break;
    case 'day':
      switch (toUnit) {
        case 'minute':
          value = value * 24 * 60;
          break;
        case 'hour':
          value = value * 24;
          break;
        case 'month':
          value = Math.floor(value / 30 || 0);
          break;
        case 'year':
          value = Math.floor(Math.floor(value / 30 || 0) / 12);
          break;
        default:
          break;
      }
      break;
    case 'month':
      switch (toUnit) {
        case 'minute':
          value = value * 30 * 24 * 60;
          break;
        case 'hour':
          value = value * 30 * 24;
          break;
        case 'day':
          value = value * 30;
          break;
        case 'year':
          value = Math.floor(value / 12 || 0);
          break;
        default:
          break;
      }
      break;
    case 'year':
      switch (toUnit) {
        case 'minute':
          value = value * 12 * 30 * 24 * 60;
          break;
        case 'hour':
          value = value * 12 * 30 * 24;
          break;
        case 'day':
          value = value * 12 * 30;
          break;
        case 'month':
          value = value * 12;
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return value;
}
