export default {
  'personalCenter.personalCenterTitle': '个人中心',
  'personalCenter.admin': '企业管理员',
  'personalCenter.account': '登录账户',
  'personalCenter.phone': '手机号码',
  'personalCenter.mail': '常用邮箱',
  'personalCenter.orgName': '所属机构',
  'personalCenter.menuBasicInfo': '基本信息',
  'personalCenter.menuMessageSetting': '提醒设置',
  'personalCenter.messageSetting': '接收方式设置',
  'personalCenter.menuEditPassword': '修改密码',
  'personalCenter.menuQuit': '安全退出',
  'personalCenter.basicInfoAvatar': '头像',
  'personalCenter.basicInfoAccount': '登录账号',
  'personalCenter.basicInfoName': '姓名',
  'personalCenter.basicInfoAuth': '授权角色',
  'personalCenter.basicInfoPhone': '手机号码',
  'personalCenter.basicInfoMail': '常用邮箱',
  'personalCenter.basicInfoStatus': '状态设置',
  'personalCenter.basicInfoStatusOn': '启用',
  'personalCenter.basicInfoStatusOff': '停用',
  'personalCenter.basicInfoMoreDescription': '备注说明',
  'personalCenter.basicInfoMoreDescriptionPlaceHolder': '请输入备注信息',
  'personalCenter.basicInfoCancel': '取消',
  'personalCenter.basicInfoSubmit': '提交',
  'personalCenter.passwordEditOld': '旧密码',
  'personalCenter.passwordEditNew': '新密码',
  'personalCenter.passwordEditRealize': '确认密码',
  'personalCenter.messageSettingTitle1': '飞机起飞提醒',
  'personalCenter.messageSettingTitle2': '飞机降落提醒',
  'personalCenter.messageSettingTitle3': '软件升级提醒',
  'personalCenter.messageSettingContent1': '系统消息',
  'personalCenter.messageSettingContent2': '邮箱',
  'personalCenter.messageSettingContent3': '钉钉消息',
  'personalCenter.messageSettingContent4': '短信（暂不支持）',
  'personalCenter.messageSettingContent1exp': 'XOP平台灯泡消息',
  'personalCenter.messageSettingContent2exp': '需要提供邮箱地址',
  'personalCenter.messageSettingContent3exp': '通过钉钉webhook接入',
  'personalCenter.messageSettingEdit3': '编辑',
  'personalCenter.messageSettingSave3': '保存',
  'personalCenter.correctPhoneNumber': '请输入正确的手机号',
  'personalCenter.correctEmail': '请输入正确的邮箱地址',
  'personalCenter.submitSuccess': '提交成功',
  'personalCenter.imageUploadTips1': '上传图片比例为1:1',
  'personalCenter.passwordEditOldMessage1': '请输入旧密码',
  'personalCenter.passwordEditOldMessage2': '请输入6-16位密码',
  'personalCenter.dingTalkKey': '安全设置',
  'personalCenter.mailTipsTitle': '友情提示',
  'personalCenter.mailTipsContent': '此功能需要提供你的邮箱地址方可使用',
  'personalCenter.mailTipsStep': '去提供',
  'personalCenter.passwordsNotMatch': '两次输入的密码不一致',
};
