export default {
  'rolesManage.title': '角色管理',
  'rolesManage.addRole': '添加角色',
  'rolesManage.roleName': '角色名称',
  'rolesManage.menuAuth': '菜单授权',
  'rolesManage.pageAuth': '页面授权',
  'rolesManage.notificationAuth': '通知授权',
  'rolesManage.description': '备注说明',
  'rolesManage.operation': '操作',
  'rolesManage.delete': '删除',
  'rolesManage.roleNameMessage': '请输入160字以内的名字',
  'rolesManage.roleAddPlaceholder1': '请输入角色名称',
  'rolesManage.roleAddPlaceholder2': '请输入备注信息',
  'rolesManage.noSpace': '不能为空',
  'rolesManage.cancel': '取消',
  'rolesManage.save': '保存',
  'rolesManage.success': '保存成功',
  'rolesManage.deleteConfirm': '确定删除该角色信息吗？',
  'rolesManage.deleteConfirm2': '删除后将不能恢复，是否确认删除？',
  'rolesManage.yes': '确定',
  'rolesManage.no': '取消',
  'rolesManage.cantDelete': '无法删除该角色',
  'rolesManage.add': '新增',
  'rolesManage.edit': '编辑',
};