export default {
  'nmsException.troubleshooting': '故障排查',
  'nmsException.tailNumber': '机尾号',
  'nmsException.model': '机型',
  'nmsException.manufacturer': '生产公司',
  'nmsException.factoryDate': '出厂日期',
  'nmsException.firstFlightDate': '首飞日期',
  'nmsException.machineAge': '机龄',
  'nmsException.numberSeats': '标准座位数',
  'nmsException.totalLength': '机身长度(m)',
  'nmsException.wingspan': '翼展(m)',
  'nmsException.machineHeight': '机高(m)',
  'nmsException.open': '展开',
  'nmsException.retract': '收起',
  'nmsException.deviceInstalled': '设备故障排查清单',
  'nmsException.faultDetection': '故障检测项',
  'nmsException.solution': '解决方案',
  'nmsException.unknownUpdateTime': '更新时间未知',
  'nmsException.aircraftNameZh': '中文名称',
  'nmsException.aircraftNameEn': '英文名称',
  'nmsException.pilotNum': '飞行员数量',
  'nmsException.IATA': 'IATA三字码',
  'nmsException.ICAO': '四字码',

  'nmsException.normal': '正常',
  'nmsException.fullLoad': '满载',
  'nmsException.exception': '异常',
  'nmsException.notConnectedInvalid': '未连接/失效',
  'nmsException.unknown': '未知',
  'nmsException.notMatch': '无法识别',
  'nmsException.noData': '无数据',

  'nmsException.faultWarningTime': '数据告警时间',
  'nmsException.faultName': '故障名称',
  'nmsException.recommendedSolution': '建议解决方案',
  'nmsException.recoveryTime': '恢复时间',
  'nmsException.untreated': '未处理',

  'nmsException.refreshAfter': '秒后刷新',
  'nmsException.lostConnection': '已失联',
  'nmsException.normalOperation': '运行正常',
  'nmsException.runException': '运行异常',
  'nmsException.fullLoadState': '满载运行',
  'nmsException.unknownState': '未知设备',
  'nmsException.notMatchState': '发现无法识别的指标',
  'nmsException.unknownChinese': '中文全称未知',
  'nmsException.unknownEnglish': '英文全称未知',

  'nmsException.basicInfo': '基本构型信息',
  'nmsException.checkItem': '检查项目',
  'nmsException.checkStatus': '检测状态',
  'nmsException.firstTime': '更新时间',
  'nmsException.duration': '持续时间',
  'nmsException.edit': '操作',

};