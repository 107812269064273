export default {
  'nmsDictionary.title': '设备字典',
  'nmsDictionary.pageDescription': '机载网络设备定义，设备基础数据管理',

  'nmsDictionary.rename': '重命名',
  'nmsDictionary.viewDetails': '数据字典',
  'nmsDictionary.valueConversion': '值转换表',
  'nmsDictionary.deleteDevice': '删除设备',
  'nmsDictionary.parameterClass': '参数分类',
  'nmsDictionary.valueTransfer': '值转换',
  'nmsDictionary.transferTable': '转换对照表',

  'nmsDictionary.deviceName': '设备简称',
  'nmsDictionary.ChineseName': '中文全称',
  'nmsDictionary.EnglishName': '英文全称',
  'nmsDictionary.numberInstallableUnits': '可装机数量',
  'nmsDictionary.singleDevice': '单设备',
  'nmsDictionary.multipleDevices': '多设备',
  'nmsDictionary.addDevice': '添加设备',
  'nmsDictionary.Front-enddisplay': '用于前端显示',

  'nmsDictionary.IFEChardwareDeviceDefinition': '设备字典: IFEC 硬件设备定义及数据字典管理',
  'nmsDictionary.dataClass': '数据分类',
  'nmsDictionary.addDataClass': '+数据分类',
  'nmsDictionary.keywordQuery': '关键字查询',
  'nmsDictionary.enterKeyValue': '编号代码或备注',
  'nmsDictionary.all': '全部',
  'nmsDictionary.query': '查询',
  'nmsDictionary.new': '新增',
  'nmsDictionary.delete': '删除',
  'nmsDictionary.edit': '编辑',
  'nmsDictionary.manage': '管理',
  'nmsDictionary.multipleDelete': '批量删除',
  'nmsDictionary.newValue': '新增值',
  'nmsDictionary.pleaseSelectItem': '请选择字典项，才能做删除操作',
  'nmsDictionary.wantDeleteThem': '您是否真的想删除它们？',
  'nmsDictionary.wantDeleteIt': '您是否真的想删除它？',
  'nmsDictionary.cannotBeRecovered': '删除后不可恢复',
  'nmsDictionary.numberCode': '编号代码',
  'nmsDictionary.dataType': '数据类型',
  'nmsDictionary.displayName': '显示名称',
  'nmsDictionary.doesDeviceSupport': '该设备是否支持',
  'nmsDictionary.noteDescription': '备注说明',
  'nmsDictionary.operation': '操作',
  'nmsDictionary.originalValue': '原始值',
  'nmsDictionary.transferValue': '转换值',
  'nmsDictionary.displayName2': '规范名称',
  'nmsDictionary.bitStatusSetting': 'Bit 状态设置',
  'nmsDictionary.TestValueConfig': '检测值设置',
  'nmsDictionary.originalValue2': '状态值（监测到的原始值）',
  'nmsDictionary.transferValue2': '状态名（值转换中预设的转换值）',
  'nmsDictionary.DeviceEncoding': '设备参数编码选择',
  'nmsDictionary.inputNumberCode': '请输入编号代码搜索…',
  'nmsDictionary.BestSize': '(最佳大小300 x 300px)',
  'nmsDictionary.uploadImage': '请上传图片文件',
  'nmsDictionary.replaceImage': '上传文件超过100Kb，请替换图片',
  'nmsDictionary.FileUploading': '文件正在上传中...请稍后再试',
  'nmsDictionary.Submitting': '正在提交...',
  'nmsDictionary.PNGAndJPEG': '仅支持PNG和JPEG格式图片!',

  'nmsDictionary.statusValue': '状态值',
  'nmsDictionary.statusName': '状态名',
  'nmsDictionary.lightStatus': '状态灯',
  'nmsDictionary.statusCode': '状态编码',
  'nmsDictionary.priority': '监测优先级',
  'nmsDictionary.handle': '处理办法',

  'nmsDictionary.text': '文本',
  'nmsDictionary.numeric': '数值',
  'nmsDictionary.percent': '百分比',

  'nmsDictionary.NORMAL': '正常',
  'nmsDictionary.FAIL': '异常',
  'nmsDictionary.FULL_LOAD': '满载',

  'nmsDictionary.addChildClass': '添加子类',
  'nmsDictionary.addClass': '新增分类',
  'nmsDictionary.editClass': '编辑分类',
  'nmsDictionary.className': '分类名称',
  'nmsDictionary.groupCode': '识别编码',
  'nmsDictionary.groupCodeExp': '保存后不能修改，为空时即系统编码',
  'nmsDictionary.classNameExp': '用于底层业务逻辑',
  'nmsDictionary.remark': '备注',
  'nmsDictionary.addDictionary': '添加字典',
  'nmsDictionary.classification': '所属分类',
  'nmsDictionary.supported': '支持',
  'nmsDictionary.notSupported': '不支持',
  'nmsDictionary.yes': '是',
  'nmsDictionary.no': '否',

  'nmsDictionary.add': ' 添加设备',
  'nmsDictionary.coding': '编码代码',
};