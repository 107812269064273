export default {
  'personalCenter.personalCenterTitle': 'Me',
  'personalCenter.admin': 'Admin',
  'personalCenter.account': 'Account',
  'personalCenter.phone': 'Phone',
  'personalCenter.mail': 'Mail',
  'personalCenter.orgName': 'Org name',
  'personalCenter.menuBasicInfo': 'Basic Info',
  'personalCenter.menuMessageSetting': 'Message Setting',
  'personalCenter.messageSetting': 'Message Setting',
  'personalCenter.menuEditPassword': 'Edit Password',
  'personalCenter.menuQuit': 'Logout',
  'personalCenter.basicInfoAvatar': 'Avatar',
  'personalCenter.basicInfoAccount': 'Account',
  'personalCenter.basicInfoName': 'Name',
  'personalCenter.basicInfoAuth': 'Auth',
  'personalCenter.basicInfoPhone': 'Phone',
  'personalCenter.basicInfoMail': 'Mail',
  'personalCenter.basicInfoStatus': 'Status',
  'personalCenter.basicInfoStatusOn': 'On',
  'personalCenter.basicInfoStatusOff': 'Off',
  'personalCenter.basicInfoMoreDescription': 'Description',
  'personalCenter.basicInfoMoreDescriptionPlaceHolder': 'please input the description',
  'personalCenter.basicInfoCancel': 'Cancel',
  'personalCenter.basicInfoSubmit': 'Submit',
  'personalCenter.passwordEditOld': 'Old Password',
  'personalCenter.passwordEditNew': 'New Password',
  'personalCenter.passwordEditRealize': 'Confirm Password',
  'personalCenter.messageSettingTitle1': 'Take Off Notification',
  'personalCenter.messageSettingTitle2': 'Device Problem Notification',
  'personalCenter.messageSettingTitle3': 'Software Update Notification',
  'personalCenter.messageSettingContent1': 'System Notification',
  'personalCenter.messageSettingContent2': 'Mail',
  'personalCenter.messageSettingContent3': 'Ding Talk',
  'personalCenter.messageSettingContent4': 'Phone Message（under construction...）',
  'personalCenter.messageSettingEdit3': 'Edit',
  'personalCenter.messageSettingSave3': 'Save',
  'personalCenter.correctPhoneNumber': 'Wrong phone number format.',
  'personalCenter.correctEmail': 'Wrong mail number format.',
  'personalCenter.submitSuccess': 'Submit Success',
  'personalCenter.imageUploadTips1': 'Please upload 1:1 image.',
  'personalCenter.passwordEditOldMessage1': 'Input the old password',
  'personalCenter.passwordEditOldMessage2': 'Please enter a 6-16 digit password',
  'personalCenter.dingTalkKey': 'Key',
  'personalCenter.mailTipsTitle': 'Tip',
  'personalCenter.mailTipsContent': 'Please offer your email first.',
  'personalCenter.mailTipsStep': 'Offer',
  'personalCenter.passwordsNotMatch': 'Passwords entered twice do not match',
};
