export default {
  'mockTakeoffLanding.title': 'Takeoff & Landing',
  'mockTakeoffLanding.flightInfo': 'Simulated flight information',

  'mockTakeoffLanding.on': 'On',
  'mockTakeoffLanding.off': 'Off',
  'mockTakeoffLanding.edit': 'Edit',
  'mockTakeoffLanding.onOffExp':'When the master switch is turned off before landing, the aircraft will be forced back to landing state',

  'mockTakeoffLanding.planeTailNo': 'Tail No',
  'mockTakeoffLanding.planeIP': 'Aircraft IP / Onboard server IP',
  'mockTakeoffLanding.takeOffDate': 'Departure date',
  'mockTakeoffLanding.flightNo': 'Flight number',
  'mockTakeoffLanding.actualTakeOffTime': 'Actual takeoff time',
  'mockTakeoffLanding.actualLandTime': 'Actual landing time',
  'mockTakeoffLanding.takeOffAirportName': 'Name of departure airport',
  'mockTakeoffLanding.takeOffAirportTripleCode': 'Departure airport 3',
  'mockTakeoffLanding.planningTakeOffTime': 'Planned departure time',
  'mockTakeoffLanding.landingAirportName': 'Name of landing airport',
  'mockTakeoffLanding.landingAirportTripleCode': 'Landing airport 3',
  'mockTakeoffLanding.planningLandingTime': 'Planned arrival time',

  'mockTakeoffLanding.takeoff': 'Simulated takeoff',
  'mockTakeoffLanding.landing': 'Simulated landing',
  'mockTakeoffLanding.takeoffExplain': 'When simulating takeoff, call the takeoff notification interface of Weitong aircraft, and open the access network',
  'mockTakeoffLanding.landingExplain': 'When simulated landing, call the landing notification interface of Satcom aircraft and close the access network',

  'mockTakeoffLanding.passengerList': 'Online passenger list',
  'mockTakeoffLanding.weiTongList': 'Wei Tong',
  'mockTakeoffLanding.refresh': 'Refresh',
  'mockTakeoffLanding.internalIp': 'IP address',
  'mockTakeoffLanding.seatNo': 'Seat number',
  'mockTakeoffLanding.idCard': 'Id card',
  'mockTakeoffLanding.gnsStatus': 'GNS status',
  'mockTakeoffLanding.externalIp': 'External Ip',
  'mockTakeoffLanding.satcomStatus': 'Satcom status',

  'mockTakeoffLanding.editTitle': 'Simulated takeoff and landing parameter setting',
  'mockTakeoffLanding.save': 'Save',

  'mockTakeoffLanding.syncing': 'Syncing',
  'mockTakeoffLanding.syncSuccess': 'Sync Success',
  'mockTakeoffLanding.syncFail': 'Sync Fail',
};
