import React from "react";
import App from "./app";
import Login from "./app/pages/login";
import {BrowserRouter, Route, Redirect, Switch} from "react-router-dom";
import en_US from "antd/lib/locale-provider/en_US";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import {ConfigProvider} from "antd";
import "fonts/iconfont/iconfont.css";
import AuthorizedRoute from "./components/WithAuthorizedRouter";
import {connect} from "react-redux";
import {compose,} from "recompose"
import {addLocaleData, IntlProvider} from 'react-intl';
import zh from 'react-intl/locale-data/zh';
import en from 'react-intl/locale-data/en';
import my_zh from 'shared/langs/zh_CN';
import my_en from 'shared/langs/en_US';
import {logI} from "shared/utils";
import {chanageThemes} from "styles/themes"

const locales = {
  en: my_en,
  zh: my_zh,
};

addLocaleData([...zh, ...en]);

const supportsHistory = "pushState" in window.history;
const localesAnt = {
  zh: zh_CN,
  en: en_US,
};

class RootLay extends React.Component {
  getFirstPath = () => {
    if (!this.props.menus) {
      return '/app/error'
    }
    let firstItem = this.props.menus[0] ? this.props.menus[0] : {};
    if (firstItem.path) {
      return firstItem.path;
    }
    if (firstItem.children && firstItem.children.length > 0) {
      return firstItem.children[0].path
    }
    return '/app/dashboard-ifec';
  };

  render() {
    let firstPath = this.getFirstPath();
    logI("--RootLay--firstItem", firstPath);
    const {locale} = this.props;
    return (
      <ConfigProvider locale={localesAnt[locale]} key={locale}>
        <IntlProvider locale={locale} messages={locales[locale]} key={locale}>
          <BrowserRouter basename={"/"} forceRefresh={!supportsHistory}>
            <Switch>
              <Route path="/login" component={Login}/>
              <Redirect from="/" exact to={firstPath}/>
              <AuthorizedRoute path="/app" component={App}/>
              <Redirect to={firstPath}/>
            </Switch>
          </BrowserRouter>
        </IntlProvider>
      </ConfigProvider>
    );
  }

  componentDidMount() {
    chanageThemes();
  }
}

const Comp = compose(
  connect(
    ({
       authorization,
       config
     }) => ({
      locale: config.locale,
      menus: authorization.menus
    }), null)
)(RootLay);

export default Comp;
