export default {
  'nmsDetailedLog.title': 'Detailed',

  'nmsDetailedLog.altitude': 'Altitude and network status',
  'nmsDetailedLog.alarmStatistics': 'Alarm statistics',
  'nmsDetailedLog.networkStatus': 'Network status',
  'nmsDetailedLog.numberPeople': 'Number of people online',
  'nmsDetailedLog.stationStatus': 'Station status',
  'nmsDetailedLog.detailedRecordSwitchedTo': 'Detailed operation record the status of the following parameters has been switched to',
  'nmsDetailedLog.alarmStatisticsExp': 'From aircraft modification to now, check the number of abnormal items of bit information of each equipment at continuous time point',
  'nmsDetailedLog.networkStatusExp': 'Network status',
  'nmsDetailedLog.Beam': 'Beam',
  'nmsDetailedLog.SatelliteConnectionStatus': 'Satellite Connection Status',
  'nmsDetailedLog.CPUUsage': 'CPU Usage',
  'nmsDetailedLog.RAMUsage': 'RAM Usage',

  'nmsDetailedLog.last5m': 'Last 5 minutes',
  'nmsDetailedLog.last10m': 'Last 10 minutes',
  'nmsDetailedLog.last30m': 'Last 30 minutes',
  'nmsDetailedLog.last1h': 'Last hour',
  'nmsDetailedLog.last2h': 'Last 2 hours',
  'nmsDetailedLog.last4h': 'Last 4 hours',
  'nmsDetailedLog.last6h': 'Last 6 hours',
  'nmsDetailedLog.last24h': 'Last 24 hours',
  'nmsDetailedLog.last7d': 'Last 7 days',
  'nmsDetailedLog.custom': 'Custom',
};