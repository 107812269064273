export default {
  'passengerOrgConfig.title': '外部服务',

  'passengerOrgConfig.openClient': '密钥配置',
  'passengerOrgConfig.serverConfig': '服务配置',

  'passengerOrgConfig.add': '新增',
  'passengerOrgConfig.searchKey': '机构名称搜索...',
  'passengerOrgConfig.edit': '编辑',
  'passengerOrgConfig.delete': '删除',
  'passengerOrgConfig.enable': '启用',
  'passengerOrgConfig.disabled': '停用',
  'passengerOrgConfig.isEnable': '是否启用',
  'passengerOrgConfig.copy':'复制',

  'passengerOrgConfig.clientName': '机构名称',
  'passengerOrgConfig.openClientId': 'OpenClientId',
  'passengerOrgConfig.publicKey': '公钥',
  'passengerOrgConfig.privateKey': '私钥',
  'passengerOrgConfig.remarks': '备注说明',
  'passengerOrgConfig.updateTime': '更新时间',

  'passengerOrgConfig.airlineName': '机构名称',
  'passengerOrgConfig.airlineCode': '机构识别码',
  'passengerOrgConfig.authenticationPath': '旅客身份验证服务',
  'passengerOrgConfig.registerPath': '旅客注册服务',
  'passengerOrgConfig.organizationIsUnauthorized': '该机构未鉴权',

  'passengerOrgConfig.save': '保存',
  'passengerOrgConfig.cancel': '取消',
  'passengerOrgConfig.oneTouch': '一键生成',
}