export default {
  'nmsFlightList.title': 'Device Report',

  'nmsFlightList.tailNumber': 'Tail No',
  'nmsFlightList.flightNumber': 'Flight number',
  'nmsFlightList.timeInterval': 'Time interval',
  'nmsFlightList.query': 'Search',
  'nmsFlightList.tailNumberSearch': 'Tail No...',
  'nmsFlightList.flightNumberSearch': 'Flight number...',
  'nmsFlightList.deptSearch': 'Dept ...',
  'nmsFlightList.destSearch': 'Dest ...',
  'nmsFlightList.refresh': 'Refresh',

  'nmsFlightList.time': 'Time',
  'nmsFlightList.model': 'Model',
  'nmsFlightList.departureCity': 'Departure city',
  'nmsFlightList.toCity': 'To city',
  'nmsFlightList.takeOffArrive': 'Take off -- Arrive',
  'nmsFlightList.actualLandingTime': 'Actual landing time',
  'nmsFlightList.actualTakeoffTime': 'Actual take off time',
  'nmsFlightList.landingTime': 'Plan landing time',
  'nmsFlightList.takeoffTime': 'Plan take off time',
  'nmsFlightList.flightTime': 'Flight time',
  'nmsFlightList.edit': 'Operation',
  'nmsFlightList.list': 'List',
  'nmsFlightList.fees': 'Fees',

  'nmsFlightList.flightReport': 'Hardware report',
  'nmsFlightList.operationReport': 'Operation report',
  'nmsFlightList.flightRun': 'Run report',
  'nmsFlightList.last5m': 'Last 5 minutes',
  'nmsFlightList.last10m': 'Last 10 minutes',
  'nmsFlightList.last30m': 'Last 30 minutes',
  'nmsFlightList.last1h': 'Last hour',
  'nmsFlightList.last2h': 'Last 2 hours',
  'nmsFlightList.last4h': 'Last 4 hours',
  'nmsFlightList.last6h': 'Last 6 hours',
  'nmsFlightList.last24h': 'Last 24 hours',
  'nmsFlightList.last7d': 'Last 7 days',
  'nmsFlightList.custom': 'Custom',
};
