export default {
  "nmsDeviceMaintain.title": "装机维护",
  "nmsDeviceMaintain.pageDescription":
    "IFEC硬件设备装机及日常维护，所有与配置相关的都以配置升级的方式操作",

  "nmsDeviceMaintain.search": "搜 索",
  "nmsDeviceMaintain.newInstallation": "+ 新装机",
  "nmsDeviceMaintain.tailNumberEg": "机尾号 eg：B573",
  "nmsDeviceMaintain.tailNumber": "机尾号",
  "nmsDeviceMaintain.modificationScheme": "改装方案",
  "nmsDeviceMaintain.networkType": "网络类型",
  "nmsDeviceMaintain.installedTime": "装机时间",
  "nmsDeviceMaintain.WMTcheck": "WMT检查项配置",
  "nmsDeviceMaintain.edit": "操作",

  "nmsDeviceMaintain.lan": "局域网",
  "nmsDeviceMaintain.satellite": "卫星",
  "nmsDeviceMaintain.noNetwork": "无网络"
};
