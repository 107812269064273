import {fetchJSON, getAxios,} from "shared/fetch"
import {BASE_API_URL, DEBUG} from "shared/env"

import {obj2Str} from "shared/utils"
import {func} from "prop-types"


export const FLIGHT_LIST_PATH = `${BASE_API_URL}/nms/info429/lastTracks`

export const VERSION_PATH = `${BASE_API_URL}/user/dashboard/project/versions`

export const LOGOUT_PATH = `${BASE_API_URL}/user/admin/logout`

export const REFRESH_TOKEN_PATH = `${BASE_API_URL}/user/admin/refresh`

export const FLIGHT_LIST_URL = `${BASE_API_URL}/ias/flight/getFlightList`


export const CREATE_CLIENT_URL = `${BASE_API_URL}/user/client/addClient`;

export const GET_CLIENTS_URL = `${BASE_API_URL}/user/client/getClientList`;

export const DELETE_CLIENT_URL = `${BASE_API_URL}/user/client/deleteClientById`;

export const GET_AIRCRAFT_TYPE_URL = `${BASE_API_URL}/user/aircraft/getAircraftTypeList`;

export const ADD_AIRCRAFT_TYPE_URL = `${BASE_API_URL}/user/aircraft/addAircraftType`;

export const DELETE_AIRCRAFT_TYPE_URL = `${BASE_API_URL}/user/aircraft/deleteAircraftType`;

export const GET_AIRCRAFTTYPE_DETAILS = `${BASE_API_URL}/user/aircraft/getAircraftTypeInfo`;
export const GET_PLANE_DEVICE_LIST = `${BASE_API_URL}/user/setting/getPlaneDeviceList`;
export const GET_DEVICE_INFO_DETAILS = `${BASE_API_URL}/user/deviceinfo/getDeviceInfoDetails`;

export const POST_NEW_MODIFY_PLANE = `${BASE_API_URL}/user/airplane/refitting`;
export const GET_DEVICES_LIST = `${BASE_API_URL}/user/airplane/deviceNames`;
export const GET_MODIFY_PLANE_INFO = `${BASE_API_URL}/user/airplane/refitting/detail`;
export const GET_MODIFY_PLANE_LIST = `${BASE_API_URL}/user/airplane/refitting`;
export const GET_MODIFY_PLANE_WMT_SETTING_FILE = `${BASE_API_URL}/nms/airplane/wmtConfigFile`;
export const POST_MODIFY_PLANE_WMT_SETTING = `${BASE_API_URL}/nms/airplane/wmtConfig`;
export const GET_TAILNO_CHECK = `${BASE_API_URL}/user/airplane/check`;

export const GET_AIRCRAFT_TYPE_LIST = `${BASE_API_URL}/user/aircraft`;

export const GET_TAIL_NUM_LIST = `${BASE_API_URL}/user/airplane/tailNumList`;
export const ADD_AUTHORITY = `${BASE_API_URL}/auth/management`;

export const GET_AIRPLANE = `${BASE_API_URL}/user/airplane`;
export const GET_AIRCRAFT = `${BASE_API_URL}/user/aircraft`;

export const GET_REFITTING_TAILS = `${BASE_API_URL}/user/airplane/refittingTailNos`;

export const POINT_STATION_SETTING = `${BASE_API_URL}/user/airplane/pointStation`;
export const FLIGHT_PLAN = `${BASE_API_URL}/ias/flight/planTable`;
export const FLIGHT_DETAIL = `${BASE_API_URL}/ias/flight/detail`;
export const flightExport = `${BASE_API_URL}/ias/flight/flightList/export`;
export const fillingSoftwareUrl = `${BASE_API_URL}/airlines/filling-software`;
export const schemeUrl = `${BASE_API_URL}/user/scheme`;
export const preinstallUrl = `${BASE_API_URL}/user/preInstall`;
export const unRefittingUrl = `${BASE_API_URL}/user/airplane/un-refitting/tailNumList`;
export const refittingUrl = `${BASE_API_URL}/user/airplane/v2.13/refitting`;
export const planeUrl = `${BASE_API_URL}/user/user/plane`;
export const refittingPlansUrl = `${BASE_API_URL}/user/airplane/plane/refitting/refittingPlans`;
export const forProblemUrl = `${BASE_API_URL}/ias/flight/list/forProblem`;
export const flightStatusGroupUrl = `${BASE_API_URL}/ias/flight/planTable/statusGroup`;
export const updateTimeInfoUrl = `${BASE_API_URL}/ias/flight/updateTimeInfo`;
export const historyUpdateTimeInfoUrl = `${BASE_API_URL}/ias/flight/historyUpdateTimeInfo`;

export function editHistoryFlight(data) {
  return getAxios(`${historyUpdateTimeInfoUrl}`, "PUT", data);
}
export function editFlight(data) {
  return getAxios(`${updateTimeInfoUrl}`, "PUT", data);
}

export function getFlightStatusGroup() {
  return getAxios(`${flightStatusGroupUrl}`, "GET");
}

export function getFlightListForProblem(data) {
  let params = obj2Str(data);
  return getAxios(`${forProblemUrl}/?${params}`, "GET");
}

export function getEditPlaneList(data) {
  let params = obj2Str(data);
  return getAxios(`${planeUrl}/edit/page?${params}`, "GET");
}

export function getSeletedPlaneList(data) {
  let params = obj2Str(data);
  return getAxios(`${planeUrl}/list?${params}`, "GET");
}

export function getPlaneList(data) {
  let params = obj2Str(data);
  return getAxios(`${planeUrl}/page?${params}`, "GET");
}

export function editPlane(data) {
  return getAxios(`${planeUrl}/batchSave`, "PUT", data);
}

export function addPlane(data) {
  return getAxios(`${planeUrl}`, "POST", data);
}

export function deletePlane(id) {
  return getAxios(`${planeUrl}/${id}`, "DELETE");
}

export async function airplaneRefitting(id, data) {
  return await getAxios(`${refittingUrl}/${id}`, "PUT", data);
}

export async function getUnRefittingTailNumList(data) {
  let params = obj2Str(data)
  return await getAxios(`${unRefittingUrl}?${params}`, "GET");
}

export async function addPreinstall(data) {
  return await getAxios(`${preinstallUrl}`, "POST", data);
}

export async function getSchemelansList() {
  return await getAxios(`${refittingPlansUrl}`, "GET");
}

export async function getSchemeList() {
  return await getAxios(`${schemeUrl}/list`, "GET");
}

export async function addScheme(data) {
  return await getAxios(`${schemeUrl}`, "PUT", data);
}

export async function deleteScheme(id) {
  return await getAxios(`${schemeUrl}/${id}`, "DELETE");
}

export async function getFillingSoftList() {
  return await getAxios(`${fillingSoftwareUrl}/list`, "GET");
}

export async function addFillingSoft(data) {
  return await getAxios(`${fillingSoftwareUrl}`, "POST", data);
}

export async function editFillingSoft(id, data) {
  return await getAxios(`${fillingSoftwareUrl}/${id}`, "PUT", data);
}

export async function getFillingSoftVersionList(data) {
  let params = obj2Str(data);
  return await getAxios(`${fillingSoftwareUrl}/version/page?${params}`, "GET");
}

export async function addFillingSoftVersion(data) {
  return await getAxios(`${fillingSoftwareUrl}/version`, "POST", data);
}

export async function editFillingSoftVersion(id, data) {
  return await getAxios(`${fillingSoftwareUrl}/version/${id}`, "PUT", data);
}

export async function getFillingSoftVersionSubList(versionId) {
  return await getAxios(`${fillingSoftwareUrl}/version/sub/list?versionId=${versionId}`, "GET");
}

export async function downloadFillingSoft(fileId) {
  window.open(`${fillingSoftwareUrl}/version/download/${fileId}`);
}

export async function getFlightPlan(data) {
  let params = obj2Str(data);
  return await getAxios(`${FLIGHT_PLAN}?${params}`, "GET");
}

export async function getFlightDetail(tailNo, day, status) {
  let param = status ? `status=${status}` : ''
  const info = await getAxios(`${FLIGHT_DETAIL}/${tailNo}/${day}?${param}`, "GET");
  return info;
}

export async function flightListExport(data) {
  let params = obj2Str(data);
  if (params) {
    window.open(`${flightExport}?${params}`);
  }
}

export async function searchFlightRecord({pageSize, current}, data) {
  let params = obj2Str(data)
  if (params) {
    return await getAxios(`${FLIGHT_LIST_URL}/${current}/${pageSize}?${params}`, "GET");
  } else {
    return await getAxios(`${FLIGHT_LIST_URL}/${current}/${pageSize}`, "GET");
  }
}


export async function getFlightList(tailNo,size) {
  const info = await getAxios(`${FLIGHT_LIST_PATH}?tailNo=${tailNo}&size=${size}`, "GET");
  return info
}

export async function getVersion() {
  const info = await getAxios(`${VERSION_PATH}`, "GET");
  return info
}

export async function getClientList({current, pageSize}) {
  return await getAxios(`${GET_CLIENTS_URL}/${current}/${pageSize}`, "GET")
}

export async function addClient(data) {
  let params = obj2Str(data);
  return await getAxios(`${CREATE_CLIENT_URL}`, "POST", params);
}

export async function deleteClient(clientId) {
  return await getAxios(`${DELETE_CLIENT_URL}?clientId=${clientId}`, "DELETE");
}

export async function deleteAircraftType() {
  return await getAxios(`${DELETE_AIRCRAFT_TYPE_URL}`, "DELETE")
}

export async function getPlaneDeviceList({pageSize, current}) {
  return await getAxios(`${GET_PLANE_DEVICE_LIST}/${current}/${pageSize}`, "GET");
}

export async function getDeviceInfoDetails(data) {
  let params = obj2Str(data)
  if (params) {
    return await getAxios(`${GET_DEVICE_INFO_DETAILS}?${params}`, "GET");
  } else {
    return await getAxios(`${GET_DEVICE_INFO_DETAILS}`, "GET");
  }
}

export async function addModifyPlane(data) {
  return await getAxios(`${POST_NEW_MODIFY_PLANE}`, "POST", data)
}

export async function editModifyPlane(data, id) {
  return await getAxios(`${POST_NEW_MODIFY_PLANE}/${id}`, "PUT", data)
}

export async function getDevicesList() {
  return await getAxios(`${GET_DEVICES_LIST}`, "GET")
}

export async function getModifyPlaneInfo(tailNo) {
  return await getAxios(`${GET_MODIFY_PLANE_INFO}/${tailNo}`, "GET")
}

export async function getModifyPlaneList(pagination, keyword, resolution) {
  keyword = keyword ? `tailNoLike=${encodeURIComponent(keyword)}&` : ''
  resolution = resolution ? `refittingPlan=${encodeURIComponent(resolution)}` : ''
  return await getAxios(`${GET_MODIFY_PLANE_LIST}/${pagination.current}/${pagination.pageSize}?${keyword}${resolution}`, "GET")
}

export async function getModifiedPlaneWMTSetting(tailNo) {
  return await getAxios(`${POST_MODIFY_PLANE_WMT_SETTING}?tailNo=${tailNo}`, "GET")
}

export async function getModifiedPlaneCurrentWMTSetting(tailNo) {
  return await getAxios(`${POST_MODIFY_PLANE_WMT_SETTING}/${tailNo}`, "GET")
}

export async function postModifiedPlaneWMTSetting(data) {
  return await getAxios(`${POST_MODIFY_PLANE_WMT_SETTING}`, "POST", data)
}

export async function getModifiedPlaneWMTSettingFile(tailNo, token) {
  window.open(`${GET_MODIFY_PLANE_WMT_SETTING_FILE}/${tailNo}?token=${token}`)
}

export async function checkTailNo(tailNo) {
  return await getAxios(`${GET_TAILNO_CHECK}/${tailNo}`, "GET")
}

export async function getTailNumList(data) {
  let params = obj2Str(data)
  if (params) {
    return await getAxios(`${GET_TAIL_NUM_LIST}?${params}`, "GET");
  } else {
    return await getAxios(`${GET_TAIL_NUM_LIST}`, "GET");
  }
}

export async function addDeviceAuthority(data) {
  return await getAxios(`${ADD_AUTHORITY}`, "POST", data)
}

export async function deleteDeviceAuthority(clientId) {
  return await getAxios(`${ADD_AUTHORITY}/${clientId}`, "DELETE")
}

export async function disableDeviceAuthority(clientId) {
  return await getAxios(`${ADD_AUTHORITY}/disable/${clientId}`, "PUT")
}

export async function enableDeviceAuthority(clientId) {
  return await getAxios(`${ADD_AUTHORITY}/enable/${clientId}`, "PUT")
}

export async function getAuthorityList(pageSize, current, data) {
  let params = obj2Str(data)
  return await getAxios(`${ADD_AUTHORITY}/${pageSize}/${current}?${params}`, "GET");
}

export async function getAirplaneList(pageSize, current, data) {
  let params = obj2Str(data)
  return await getAxios(`${GET_AIRPLANE}/${current}/${pageSize}?${params}`, "GET");
}

export async function addAirplane(data) {
  return await getAxios(`${GET_AIRPLANE}`, "POST", data);
}

export async function editAirplane(id, data) {
  return await getAxios(`${GET_AIRPLANE}/${id}`, "PUT", data);
}

export async function getAircraftList() {
  return await getAxios(`${GET_AIRCRAFT}`, "GET");
}

export async function getAircraftTypeList(pagination, keywords) {
  return await getAxios(`${GET_AIRCRAFT_TYPE_LIST}/${pagination.pageSize}/${pagination.current}?aircraftTypeLike=${keywords}`, "GET")
}

export async function getAircraftTypeDetails(id) {
  return await getAxios(`${GET_AIRCRAFT_TYPE_LIST}/${id}`, "GET")
}

export async function addAircraftType(data) {
  return await getAxios(`${GET_AIRCRAFT_TYPE_LIST}`, "POST", data)
}

export async function editAircraftType(id, data) {
  return await getAxios(`${GET_AIRCRAFT_TYPE_LIST}/${id}`, "PUT", data)
}

export async function getRefittingTails() {
  return getAxios(`${GET_REFITTING_TAILS}`, "GET")
}

export async function getPointStationSettings(tailNo) {
  return getAxios(`${POINT_STATION_SETTING}/${tailNo}`, "GET")
}

export async function setPointStationSettings(data) {
  return await getAxios(`${POINT_STATION_SETTING}`, "POST", data);
}
