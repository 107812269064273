import {
  createStore,
  combineReducers,
  compose,
  applyMiddleware
} from "redux"
import thunkMiddleware from "redux-thunk"
import {
  reducer as formReducer
} from "redux-form"
import {
  routerReducer,
  routerMiddleware
} from "react-router-redux"

import authorization from "./authorization/reducers"
import config from "./config/reducers"
import flightsStatus from "./flights-status/reducers"
const store = createStore(
  combineReducers({
    flightsStatus,
    authorization,
    config,
    form: formReducer,
    routerReducer,
  }),
  compose(
    applyMiddleware(thunkMiddleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
)

export const dispatch = store.dispatch.bind(store)
export const getState = store.getState.bind(store)

export default store
