export default {
  'aircraftType.title': 'Aircraft Type',

  'aircraftType.search': 'Search...',
  'aircraftType.newModel': 'Add',
  'aircraftType.model': 'Model',
  'aircraftType.ChineseName': 'Chinese name',
  'aircraftType.EnglishName': 'English name',
  'aircraftType.productionCompany': 'Production company',
  'aircraftType.firstFlightDate': 'First flight date',
  'aircraftType.operation': 'Operation',
  'aircraftType.edit':'Edit',
  'aircraftType.editModel': 'Edit model',
  'aircraftType.numberOfPilots': 'Number of pilots',
  'aircraftType.fuselageLength': 'Fuselage length',
  'aircraftType.save': 'Save',
  'aircraftType.cancel': 'Cancel',
  'aircraftType.IATA3': 'IATA3',
  'aircraftType.CODE4': 'CODE4',
  'aircraftType.People': 'People',
  'aircraftType.twoPlaces': 'Please enter two decimal places',
  'aircraftType.m': 'M',
};