export default {
  'iasUserList.title': '上网旅客',
  'iasUserList.pageDescription': '实时在线旅客列表，上下线控制',

  'iasUserList.keyword': '关键字',
  'iasUserList.timeInterval': '上线时间',
  'iasUserList.flightNumber': '航班号',
  'iasUserList.seatNumber': '座位号',
  'iasUserList.travellerID': '常旅客ID',
  'iasUserList.lastUpdate': '上一次更新',
  'iasUserList.operation': '操作',
  'iasUserList.details': '详情',
};