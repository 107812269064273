export default {
  'dashboardMonitor.title': 'Data monitoring',

  'dashboardMonitor.tailNumberSearch': 'Tail No search...',
  'dashboardMonitor.paramSelection': 'Monitoring parameter selection',
  'dashboardMonitor.pleaseEnterKey': 'Please enter parameter key or name search',
  'dashboardMonitor.last5m': 'Last 5 minutes',
  'dashboardMonitor.last10m': 'Last 10 minutes',
  'dashboardMonitor.last30m': 'Last 30 minutes',
  'dashboardMonitor.last1h': 'Last hour',
  'dashboardMonitor.last2h': 'Last 2 hours',
  'dashboardMonitor.last4h': 'Last 4 hours',
  'dashboardMonitor.last6h': 'Last 6 hours',
  'dashboardMonitor.last24h': 'Last 24 hours',
  'dashboardMonitor.last7d': 'Last 7 days',
  'dashboardMonitor.custom': 'Custom',

  'dashboardMonitor.1column': '1 column',
  'dashboardMonitor.2column': '2 column',
  'dashboardMonitor.3column': '3 column',
  'dashboardMonitor.4column': '4 column',

  'dashboardMonitor.intervalCannotExceed24': 'Time interval cannot exceed 24 hours',
};