export default {
  'rbacMenuForm.pageFunctionDescription': '系统软件、目录、菜单配置和定义',
  'rbacMenuForm.add': '新增',
  'rbacMenuForm.edit': '修改',

  'rbacMenuForm.type': '类型',
  'rbacMenuForm.contents': '目录',
  'rbacMenuForm.menus': '菜单',
  'rbacMenuForm.displayName': '显示名称',
  'rbacMenuForm.icon': '图标',
  'rbacMenuForm.higherLevel': '上级',
  'rbacMenuForm.sortNum': '排序号',
  'rbacMenuForm.connectionAddress': '连接地址',
  'rbacMenuForm.authorizationSign': '授权标示',
  'rbacMenuForm.save': '保存',
  'rbacMenuForm.cancel': '取消',

  'rbacMenuForm.success': '操作成功',
  'rbacMenuForm.pleaseEnterName': '请选择名称',
  'rbacMenuForm.pleaseSelectParentDirectory': '请选择上级目录',

  'rbacMenuForm.operation': '操作',
  'rbacMenuForm.delete': '删除',
  'rbacMenuForm.baseInfo': '基本信息',
  'rbacMenuForm.btn': '按钮/功能',
  'rbacMenuForm.searchKey': '输入关键字搜索...',
  'rbacMenuForm.add2': '添加',
  'rbacMenuForm.addFeatures': '添加功能',
  'rbacMenuForm.name': '名称',
  'rbacMenuForm.remark': '定义说明',
  'rbacMenuForm.path': '地址路径',
  'rbacMenuForm.service': '服务',
  'rbacMenuForm.method': '方法',
};
