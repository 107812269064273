export default {
  'passengerRegister.title': 'Register Log',
  'passengerRegister.pageDescription': 'Ordinary passengers register as frequent passenger log records of the airline',

  'passengerRegister.keyword': 'Keywords',
  'passengerRegister.timeInterval': 'Time interval',
  'passengerRegister.flightNumber': 'Flight number',
  'passengerRegister.certificateNo': 'Certificate No.',
  'passengerRegister.seatNumber': 'Seat number',
  'passengerRegister.tailNumber': 'Tail No',
  'passengerRegister.phoneNumber': 'Phone number',
  'passengerRegister.status': 'Status',
  'passengerRegister.creationTime': 'Created Time',

  'passengerRegister.success': 'Success',
  'passengerRegister.failed': 'Failed',
};