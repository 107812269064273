export default {
  'opsDashboard.title': '运维总览',

  'opsDashboard.query': '查询',
  'opsDashboard.orgInChargeSearch': '问题定位搜索...',
  'opsDashboard.tailNoSearch': '机尾号搜索...',

  'opsDashboard.FATAL': '致命',
  'opsDashboard.SERIOUS': '严重',
  'opsDashboard.WARN': '警戒',
  'opsDashboard.CRITICAL': '临界',
  'opsDashboard.ERROR': '错误',
  'opsDashboard.ALARM': '警告',
  'opsDashboard.ATTENTION': '注意',
  'opsDashboard.NOTIFICATION': '通知',
  'opsDashboard.DEBUG': '调试',

  'opsDashboard.PENDING': '待解决',
  'opsDashboard.PROCESSING': '处理中',
  'opsDashboard.UNVERIFIED': '待验证',
  'opsDashboard.SOLVED': '已解决',
  'opsDashboard.CLOSED': '已关闭',

  'opsDashboard.count': '问题记录数',
  'opsDashboard.planeCount': '出现问题的飞机数',
  'opsDashboard.flightCount': '出现问题的航班数',
  'opsDashboard.unsolvedCount': '未解决的问题数',

  'opsDashboard.levelDistribution': '问题级别分布',
  'opsDashboard.internetPercent': '影响上网的问题占比',
  'opsDashboard.orgInChargePercent': '问题定位占比',
  'opsDashboard.timeDistribution': '问题时序分布',
  'opsDashboard.top': '问题统计TOP10',

  'opsDashboard.DAY': '天',
  'opsDashboard.WEEK': '周',
  'opsDashboard.MONTH': '月',
  'opsDashboard.QUARTER': '季',
  'opsDashboard.YEAR': '年',
}