import {
  fetchJSON,
  getAxios,
} from "shared/fetch"
import fetch from "isomorphic-fetch"
import {
  BASE_API_URL,
  DEBUG
} from "shared/env"

import {
  obj2Str
} from "shared/utils"
//通知列表
const LIST_PATH = `${BASE_API_URL}/notice/sys/page`;
//设置已读
const READ_NOTICE_PATH = `${BASE_API_URL}/notice/sys/read`;
//设置全部已读
const READ_ALL_PATH = `${BASE_API_URL}/notice/sys/readAll`;
//保存邮件配置 启用禁用邮件
const SAVE_MAIL = `${BASE_API_URL}/notice/setting/server/mail`;
//测试发送邮件
const TEST_MAIL = `${BASE_API_URL}/notice/setting/server/mail/test`;
//钉钉
const SAVE_DINDIN = `${BASE_API_URL}/notice/setting/server/dingding`;
//测试发送钉钉
const TEST_DINDIN = `${BASE_API_URL}/notice/setting/server/dingding/test`;
//获取邮件 订单配置信息
const EMAIL_DINDIN = `${BASE_API_URL}/notice/setting/server/settings`;
export async function setRead(id) {
  return await getAxios(`${READ_NOTICE_PATH}/${id}`, "PUT")
}

export async function setReadAll() {
  return await getAxios(`${READ_ALL_PATH}`, "PUT")
}

export async function getNotReadList() {
  let pageSize = 100;
  let pageNum = 1;
  return await getAxios(`${LIST_PATH}/${pageSize}/${pageNum}?status=not_read`, "GET")
}

export async function getMailDindinConfig() {
  return await getAxios(`${EMAIL_DINDIN}`, "GET",)
}

export async function setMailEnabled(enabled) {
  return await getAxios(`${SAVE_MAIL}?enabled=${enabled}`, "PUT")
}

export async function setMailConfig(data) {
  return await getAxios(`${SAVE_MAIL}`, "POST", data)
}

export async function testMail(data) {
  return await getAxios(`${TEST_MAIL}`, "POST", data)
}

export async function setDindinEnabled(enabled) {
  return await getAxios(`${SAVE_DINDIN}?enabled=${enabled}`, "PUT")
}

export async function setDindinConfig(data) {
  return await getAxios(`${SAVE_DINDIN}`, "POST", data)
}

export async function testDindin(data) {
  return await getAxios(`${TEST_DINDIN}`, "POST", data)
}