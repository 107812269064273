export default {
  'nmsDeviceAircrafts.title': 'Aircraft List',
  'nmsDeviceAircrafts.pageDescription': 'Airborne equipments management',

  'nmsDeviceAircrafts.search': 'Tail No',
  'nmsDeviceAircrafts.tailNumber': 'Tail No',
  'nmsDeviceAircrafts.airlines': 'Airline',
  'nmsDeviceAircrafts.modificationScheme': 'Modification',
  'nmsDeviceAircrafts.networkType': 'Network',
  'nmsDeviceAircrafts.installationTime': 'Installation Date',
  'nmsDeviceAircrafts.edit': 'Operations',
  'nmsDeviceAircrafts.planeAge': 'Age',
  'nmsDeviceAircrafts.filterSelectAll': 'All',
  'nmsDeviceAircrafts.netTypesLocal': 'Local',
  'nmsDeviceAircrafts.netTypesSat': 'Satellite',
  'nmsDeviceAircrafts.dataReport': 'Report',
  'nmsDeviceAircrafts.runningLog': 'Operations Log',
  'nmsDeviceAircrafts.flightHistory': 'History',
  'nmsDeviceAircrafts.updateRefittingDetails': 'Update Settings',
  'nmsDeviceAircrafts.refittingDetails': 'Details',
  'nmsDeviceAircrafts.netTypesNoNet': 'disconnect',
  'nmsDeviceAircrafts.noImage': 'NO IMAGE',
  'nmsDeviceAircrafts.countsPlaneNumber': 'Planes',
  'nmsDeviceAircrafts.countsFlightsNumber': 'Flights',
  'nmsDeviceAircrafts.countsHealthNumber': 'Health',
  'nmsDeviceAircrafts.countsPlaneNumberUnit': ' ',
  'nmsDeviceAircrafts.countsFlightsNumberUnit': ' ',
  'nmsDeviceAircrafts.more': 'More',
  'nmsDeviceAircrafts.record': 'Record',
  'nmsDeviceAircrafts.detailed': 'Detailed',
  'nmsDeviceAircrafts.serviceTest': 'Service Test',

  'nmsDeviceAircrafts.bitInfo': 'Bit Info Config',
  'nmsDeviceAircrafts.pointStation': 'Station Config',
  'nmsDeviceAircrafts.gnsRight': 'Airborne Auth',
  'nmsDeviceAircrafts.bwlist': 'B/W List Det',
  'nmsDeviceAircrafts.test': 'Inst Test',
  'nmsDeviceAircrafts.deviceChange': 'Dev Repl',
  'nmsDeviceAircrafts.softwareUpdate': 'SW Update',
  'nmsDeviceAircrafts.deviceChangeLog': 'Repl Records',
  'nmsDeviceAircrafts.detailedLog': 'Detailed log',
  'nmsDeviceAircrafts.refresh': 'Refresh',
  'nmsDeviceAircrafts.airborne': 'Airborne',
  'nmsDeviceAircrafts.PSstatus': 'Point Station',
  'nmsDeviceAircrafts.unknown': 'Unknown',
  'nmsDeviceAircrafts.flying': 'Flying',
  'nmsDeviceAircrafts.landing': 'Landing',
  'nmsDeviceAircrafts.online': 'Online',
  'nmsDeviceAircrafts.offline': 'Offline',
};
