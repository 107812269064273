export default {
  'aircraftManage.title': '飞机管理',

  'aircraftManage.tailNumberEg': '机尾号 eg：B573',
  'aircraftManage.searchOrganization': '请输入机构名称搜索...',
  'aircraftManage.search': '搜索',
  'aircraftManage.newAircraft': '新增飞机',
  'aircraftManage.tailNumber': '机尾号',
  'aircraftManage.orgName': '所属公司',
  'aircraftManage.model': '机型',
  'aircraftManage.standardSeats': '标准座位数',
  'aircraftManage.productionCompany': '生产公司',
  'aircraftManage.createTime': '添加时间',
  'aircraftManage.firstFightDate': '首飞日期',
  'aircraftManage.operation': '操作',
  'aircraftManage.edit': '编辑',
  'aircraftManage.inputSeatNum': '请输入标准座位数',

  'aircraftManage.exitDate': '出厂日期',
  'aircraftManage.remark': '备注说明',
  'aircraftManage.save': '保存',
  'aircraftManage.cancel': '取消',
  'aircraftManage.selectModel': '请选择机型',
  'aircraftManage.editAircraft': '编辑飞机',

  'aircraftManage.cabinCount': '舱位数',
  'aircraftManage.cabin': '舱位',
  'aircraftManage.cabinType': '舱位类型',
  'aircraftManage.row': '排',
  'aircraftManage.column': '列',
  'aircraftManage.gangway': '过道',
  'aircraftManage.first': '头等',
  'aircraftManage.business': '公务',
  'aircraftManage.superEconomy': '超级经济',
  'aircraftManage.economy': '经济',
  'aircraftManage.seatCount': '座位数',
  'aircraftManage.AircraftPreview': '飞机预览图',
  'aircraftManage.code': '编码',

};