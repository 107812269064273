export default {
  'opsConfig.title': 'OPS Config',

  'opsConfig.add': 'Add',
  'opsConfig.searchKey': 'Keywords...',
  'opsConfig.delete': 'Delete',
  'opsConfig.operation': 'Operation',
  'opsConfig.edit': 'Edit',

  'opsConfig.save': 'Save',
  'opsConfig.cancel': 'Cancel',

  'opsConfig.lableManage': 'Lable Manage',
  'opsConfig.name': 'Name',
  'opsConfig.definitionDescription': 'Definition Description',
  'opsConfig.createTime': 'Create Time',
}