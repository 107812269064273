export default {
  'rbacOrganization.title': '机构管理',

  'rbacOrganization.search': '请输入机构名称搜索...',
  'rbacOrganization.add': '新增机构',
  'rbacOrganization.editOrganization': '编辑机构',
  'rbacOrganization.delete': '删除',
  'rbacOrganization.edit': '编辑',

  'rbacOrganization.name': '机构名称',
  'rbacOrganization.industry': '所属行业',
  'rbacOrganization.address': '公司地址',
  'rbacOrganization.updateTime': '更新时间',
  'rbacOrganization.operation': '操作',
  'rbacOrganization.orgType': '机构类型',
  'rbacOrganization.code': '机构识别码',

  'rbacOrganization.wantDelete': '】一旦被删除将无法恢复，请确认是否要删除？',

  'rbacOrganization.cancel': '取消',
  'rbacOrganization.save': '保存',
};
