export default {
  'airlinesFilling.title': '机载软件',

  'airlinesFilling.version': '版本号',
  'airlinesFilling.version2': '版本',
  'airlinesFilling.edit': '编辑',
  'airlinesFilling.list': '列表',
  'airlinesFilling.createSoftware': '新建软件',
  'airlinesFilling.softwareName': '软件名称',
  'airlinesFilling.supplier': '供应商',
  'airlinesFilling.softwareType': '所属分类',
  'airlinesFilling.UPPER': '应用软件',
  'airlinesFilling.UNDERLYING': '系统软件',
  'airlinesFilling.OTHER': '其他',

  'airlinesFilling.softwareVersion': '软件版本',
  'airlinesFilling.refresh': '刷新',
  'airlinesFilling.versionSearch': '版本搜索...',
  'airlinesFilling.projectSearch': '所属项目搜索...',

  'airlinesFilling.project': '所属项目',
  'airlinesFilling.filllingSoftwareName': '软件名称',
  'airlinesFilling.createUser': '创建者',
  'airlinesFilling.createTime': '添加时间',
  'airlinesFilling.operate': '操作',
  'airlinesFilling.details': '详情',
  'airlinesFilling.share': '分享',
  'airlinesFilling.download': '下载',
  'airlinesFilling.DOWNLOADING': '同步中...',
  'airlinesFilling.FAIL': '同步失败',
  'airlinesFilling.CHECK_FAIL': '效验失败',
  'airlinesFilling.pleasePasteIt': '下载地址已复制成功，去粘贴分享吧',

  'airlinesFilling.addVersion': '新增版本',
  'airlinesFilling.editVersion': '编辑版本',
  'airlinesFilling.downloadUrl': '全量包下载地址',
  'airlinesFilling.md5': 'MD5效验码',
  'airlinesFilling.description': '版本说明',
  'airlinesFilling.ok': '确定',
  'airlinesFilling.cancel': '取消',

  'airlinesFilling.softwareVersionInfo': '软件版本详情',
  'airlinesFilling.productName': '产品名称',
  'airlinesFilling.pn': '产品PN',
  'airlinesFilling.productVersion': '产品版本',
  'airlinesFilling.publicVersion': '不填视为公用版本',
  'airlinesFilling.SoftwareName': '软件名称输入有误请重新入',
  'airlinesFilling.fullPackageAddress': '全量包的完整下载地址(下载格式必须为“.z”、“.zip”和“.tar,gz”)…',
  'airlinesFilling.VersionNumber': '版本号输入有误请重新入',
  'airlinesFilling.pixelJPGimage': '100Kb以内的200 x 200像素的jpg图片',
  'airlinesFilling.uploadImage': '请上传图片文件',
  'airlinesFilling.replaceImage': '上传文件超过100Kb，请替换图片',
  'airlinesFilling.FileUploading': '文件正在上传中...请稍后再试',
  'airlinesFilling.Submitting': '正在提交...',

};
