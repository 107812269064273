export default {
  'satcomStationWarning.title': '小站告警',

  'satcomStationWarning.tailNoColon': '改装飞机：',
  'satcomStationWarning.levelColon': '告警等级：',
  'satcomStationWarning.statusColon': '告警状态：',
  'satcomStationWarning.timeColon': '发生时间：',

  'satcomStationWarning.all': '全部',
  'satcomStationWarning.minor': '轻微',
  'satcomStationWarning.major': '严重',
  'satcomStationWarning.open': '未处理',
  'satcomStationWarning.close': '已处理',

  'satcomStationWarning.stow': '收起',
  'satcomStationWarning.more': '更多',
  'satcomStationWarning.enterTailNumber': '请输入机尾号查找...',
  'satcomStationWarning.today': '今天',
  'satcomStationWarning.thisWeek': '最近1周',
  'satcomStationWarning.thisMonth': '最近1月',

  'satcomStationWarning.tailNo': '机尾号',
  'satcomStationWarning.warningTime': '告警发生时间',
  'satcomStationWarning.pointStationCode': '小站编号',
  'satcomStationWarning.warningHeader': '告警抬头',
  'satcomStationWarning.warningLevel': '告警等级',
  'satcomStationWarning.warningStatus': '告警状态',
  'satcomStationWarning.warningCount': '告警次数',
  'satcomStationWarning.warningType': '告警类型',
  'satcomStationWarning.warningDetail': '告警详情',
  'satcomStationWarning.warningID': '告警ID',
};