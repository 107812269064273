export default {
  'passengerLogin.title': '登录记录',
  'passengerLogin.pageDescription': '旅客上网记录、网络接入请求状态查看',

  'passengerLogin.keyword': '航班号',
  'passengerLogin.timeInterval': '登录时间',
  'passengerLogin.flightNumber': '航班号',
  'passengerLogin.certificateNo': '证件号',
  'passengerLogin.seatNumber': '座位号',
  'passengerLogin.tailNumber': '机尾号',
  'passengerLogin.IpAddress': 'IP地址',
  'passengerLogin.status': '状态',
  'passengerLogin.creationTime': '登录时间',
  'passengerLogin.accountDuplication': '上网账号去重',
  'passengerLogin.all': '全部',

  'passengerLogin.inputTailNo': '机尾号搜索...',
  'passengerLogin.inputFlightNumber': '航班号搜索...',

  'passengerLogin.success': '成功',
  'passengerLogin.failed': '失败',

  'passengerLogin.internalIp': '内网-IP地址',
  'passengerLogin.externalIp': '外网-IP地址',
  'passengerLogin.accNumber': '上网账号',
};