export default {
  'settingFlight.title': 'Flight history',
  'settingFlight.pageDescription': 'Flight execution history',

  'settingFlight.tailNumber': 'Tail No',
  'settingFlight.flightNumber': 'Flight number',
  'settingFlight.timeInterval': 'Time interval',
  'settingFlight.query': 'Search',
  'settingFlight.tailNumberSearch': 'Tail No...',
  'settingFlight.flightNumberSearch': 'Flight number...',
  'settingFlight.deptSearch': 'Search dept...',
  'settingFlight.destSearch': 'Search dest...',
  'settingFlight.exportData': 'Export',

  'settingFlight.tailNo': 'Tail No',
  'settingFlight.flightIATA': 'Flight IATA',
  'settingFlight.flightDate': 'Flight Date',
  'settingFlight.flightStatus': 'Flight Status',
  'settingFlight.deptCode': 'Dept Code',
  'settingFlight.deptCity': 'Dept City',
  'settingFlight.destCode': 'Dest Code',
  'settingFlight.destCity': 'Dest City',
  'settingFlight.actualTakeOffTime': 'Actual Take Off Time',
  'settingFlight.actualArriveTime': 'Actual Arrive Time',
  'settingFlight.takeOffTime': 'Take Off Time',
  'settingFlight.predictTakeOffTime': 'Predict Take Off Time',
  'settingFlight.arriveTime': 'Arrive Time',
  'settingFlight.predictArriveTime': 'Predict Arrive Time',
  'settingFlight.passengerNum': 'Passenger Num',
  'settingFlight.operate': 'Operation',
  'settingFlight.correction': 'Correction',
  'settingFlight.correctionTitle': 'Correction flight info',
  'settingFlight.save': 'Save',

  'settingFlight.time': 'Time',
  'settingFlight.model': 'Model',
  'settingFlight.departureCity': 'Departure city',
  'settingFlight.toCity': 'To city',
  'settingFlight.takeOffArrive': 'Take off -- Arrive',
  'settingFlight.actualLandingTime': 'Plan landing time',
  'settingFlight.actualTakeoffTime': 'Plan takeoff time',
  'settingFlight.flightTime': 'Flight time',
  'settingFlight.edit': 'Edit',
  'settingFlight.list': 'List',
  'settingFlight.fees': 'Fees',

  'settingFlight.all': 'All',
  'settingFlight.normal': 'Normal',
  'settingFlight.delay': 'Delay',
  'settingFlight.alternate': 'Alternate',
  'settingFlight.forcedLanding': 'Forced Landing',
  'settingFlight.return': 'Return',
  'settingFlight.cancel': 'Cancel',
  'settingFlight.other': 'Other',

  'settingFlight.last1d': 'Last day',
  'settingFlight.last2d': 'Last 2 days',
  'settingFlight.last3d': 'Last 3 days',
  'settingFlight.last5d': 'Last 5 days',
  'settingFlight.last7d': 'Last 7 days',
  'settingFlight.last30d': 'Last 30 days',
  'settingFlight.custom': 'Custom',
};
