export default {
  'airlinesPreinstallForm.title': 'Pre-install',

  'airlinesPreinstallForm.step1': 'Step1',
  'airlinesPreinstallForm.step2': 'Step2',
  'airlinesPreinstallForm.step3': 'Step3',
  'airlinesPreinstallForm.step4': 'Step4',
  'airlinesPreinstallForm.step5': 'Step5',
  'airlinesPreinstallForm.step6': 'Step6',

  'airlinesPreinstallForm.printer': 'Printer',
  'airlinesPreinstallForm.lastStep': 'Last step',
  'airlinesPreinstallForm.nextStep': 'Next step',
  'airlinesPreinstallForm.nextSupplement': 'Next supplement',
  'airlinesPreinstallForm.ok': 'OK',
  'airlinesPreinstallForm.cancel': 'Cancel',

  'airlinesPreinstallForm.step1Title': 'Select the modification plan of the aircraft',
  'airlinesPreinstallForm.tailNo': 'Aircraft',
  'airlinesPreinstallForm.aircraftType':'Aircraft Type',
  'airlinesPreinstallForm.tailNo.': 'Tail No.',
  'airlinesPreinstallForm.refittingTime': 'Refitting time',
  'airlinesPreinstallForm.refittingPlan': 'Refitting plan',
  'airlinesPreinstallForm.netType': 'Net type',
  'airlinesPreinstallForm.installDevice': 'Install device',
  'airlinesPreinstallForm.refittingDiagram': 'Refitting diagram',
  'airlinesPreinstallForm.refittingDiagramBtn': 'Refitting diagram',
  'airlinesPreinstallForm.aircraftTypeExp': 'Aircraft type exp...',
  'airlinesPreinstallForm.tailNoExp': 'Tail No exp...',
  'airlinesPreinstallForm.refittingDiagramExp': '(for JPG and PNG images within 100kb, the best aspect ratio of 900:400 is recommended)',
  'airlinesPreinstallForm.ISP': 'ISP',
  'airlinesPreinstallForm.VSP': 'VSP',
  'airlinesPreinstallForm.aaaType': 'AAA Type',

  'airlinesPreinstallForm.NONE': 'NONE',
  'airlinesPreinstallForm.GROUND': 'GROUND',
  'airlinesPreinstallForm.ON_BOARD': 'ON BOARD',

  'airlinesPreinstallForm.inputProductNo': 'Please enter the product code',
  'airlinesPreinstallForm.productNo': 'Product No',
  'airlinesPreinstallForm.manufactureSerialNo': 'Manufacture serial No',
  'airlinesPreinstallForm.hardwareVersion': 'Hardware version',

  'airlinesPreinstallForm.ip': 'IP /Server IP',
  'airlinesPreinstallForm.modmanIp': 'MODMAN IP',
  'airlinesPreinstallForm.pointManagerGroup': 'Point Manager Group',
  'airlinesPreinstallForm.pointVnoCode': 'Point Vno Code',
  'airlinesPreinstallForm.pointStationCode': 'Point Station Code',

  'airlinesPreinstallForm.selectSoftware': 'Select Software',
  'airlinesPreinstallForm.fillingSoftwareName': 'Software Name',
  'airlinesPreinstallForm.supplier': 'Supplier',
  'airlinesPreinstallForm.project': 'Project',
  'airlinesPreinstallForm.version': 'Version',
  'airlinesPreinstallForm.name': 'name',
  'airlinesPreinstallForm.pn': 'PartNumber',
  'airlinesPreinstallForm.subsVersion': 'Subs Version',
  'airlinesPreinstallForm.allZip': 'All zip',

  'airlinesPreinstallForm.enabled': 'Enabled',
  'airlinesPreinstallForm.remark': 'Comments',
  'airlinesPreinstallForm.clickToGet':'Click to get',
  'airlinesPreinstallForm.inputNameKey':'Software name, supplier, or keywords search...',
  'airlinesPreinstallForm.Enable':'Enable',
  'airlinesPreinstallForm.Disable':'Disable',
  'airlinesPreinstallForm.Recommended':'(Recommended)',
};
