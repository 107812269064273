export default {
  'nmsDeviceDataReport.title': 'Data Report',
  'nmsDeviceDataReport.pageDescription': '',
  'nmsDeviceDataReport.historyListTitle': 'Report History',
  'nmsDeviceDataReport.step1Select':'Select Tail No',
  'nmsDeviceDataReport.tailNumber':'Tail No',
  'nmsDeviceDataReport.reportType':'Report Type',
  'nmsDeviceDataReport.step1SelectItem1':'Operation Report',
  'nmsDeviceDataReport.step1SelectItem2':'Warning Report',
  'nmsDeviceDataReport.step1Button':'Next',
  'nmsDeviceDataReport.time':'From-To',
  'nmsDeviceDataReport.today':'Today',
  'nmsDeviceDataReport.thisMonth':'This Month',
  'nmsDeviceDataReport.thisYear':'This Year',
  'nmsDeviceDataReport.devicesTitle':'Devices',
  'nmsDeviceDataReport.dataTypeTitle':'Data Type',
  'nmsDeviceDataReport.step2Button1':'Previous',
  'nmsDeviceDataReport.step2Button2':'Submit',
  'nmsDeviceDataReport.submitSuccess':'Success',
  'nmsDeviceDataReport.step3Button1':'Back',
  'nmsDeviceDataReport.step3Button2':'Download',
  'nmsDeviceDataReport.step3Button3':'Print',
  'nmsDeviceDataReport.step3Button4':'Cancel',
  'nmsDeviceDataReport.step3tips':'Click above name to overview',
  'nmsDeviceDataReport.reportTime':'Report Time',
  'nmsDeviceDataReport.reportName':'Report Name',
  'nmsDeviceDataReport.descriptionTitle':'Description',
  'nmsDeviceDataReport.description1':'1. Operation reports, that is the overall operation of the on-board hardware equipment in a unit time period, can be divided into operating daily, weekly, monthly and annual reports.',
  'nmsDeviceDataReport.description2':'2. Early warning report, which is the failure early warning report of the aircraft modification plan in a unit time period.',
  'nmsDeviceDataReport.description3':'3. The above data report types, formats, and data items are generated according to the needs of specific business scenarios, with report preview, print, and download functions.',
  'nmsDeviceDataReport.step1':'Select Report Type',
  'nmsDeviceDataReport.step2':'Define Data',
  'nmsDeviceDataReport.step3':'Report View/Download/Print',
  'nmsDeviceDataReport.to':"to",
  'nmsDeviceDataReport.step2radio1': "Device Config",
  'nmsDeviceDataReport.step2radio2': "Altitude",
  'nmsDeviceDataReport.step2radio3': "Online Passengers",
  'nmsDeviceDataReport.step2radio4': "Device running status",
};