export default {
  'iasAAA.title': 'AAA话单',

  'iasAAA.query': '查询',
  'iasAAA.exportData': '导出数据',
  'iasAAA.tailNoSearch': '机尾号搜索…',
  'iasAAA.flightNoSearch': '航班号搜索…',
  'iasAAA.passengerInfoSearch': '旅客证件或座位号搜索...',
  'iasAAA.deptSearch': '出发城市或机场三字码搜索...',
  'iasAAA.destSearch': '到达城市或机场三字码搜索...',
  'iasAAA.macSearch': 'MAC地址搜索…',
  'iasAAA.packageNameSearch': '流量包名称搜索…',

  'iasAAA.flightInfo': '航班信息',
  'iasAAA.deviceInfo': '设备信息',
  'iasAAA.packageInfo': '套餐信息',
  'iasAAA.netList': '上网明细',

  'iasAAA.dept': '出发',
  'iasAAA.dest': '到达',
  'iasAAA.tailNo': '机尾号',
  'iasAAA.flightNo': '航班号',
  'iasAAA.flightDate': '航班日期',
  'iasAAA.idCardNo': '证件号',
  'iasAAA.seatNo': '座位号',
  'iasAAA.startTime': '上网开始时间',
  'iasAAA.useTime': '上网时长(s)',
  'iasAAA.up': '上行流量(KB)',
  'iasAAA.down': '下行流量(KB)',
  'iasAAA.sumSize': '累计流量(KB)',
  'iasAAA.operation': '操作',
  'iasAAA.details': '详情',
  'iasAAA.refresh': '刷新',

  'iasAAA.actualTakeOffTime': '实际起飞时间',
  'iasAAA.actualArriveTime': '实际到达时间',
  'iasAAA.arriveTime': '计划到达时间',
  'iasAAA.takeOffTime': '计划起飞时间',
  'iasAAA.predictArriveTime': '预计到达时间',
  'iasAAA.predictTakeOffTime': '预计起飞时间',
  'iasAAA.deptCityDestCity': '飞行航段',

  'iasAAA.packageCount': '使用流量包',
  'iasAAA.deviceCount': '使用设备数',
  'iasAAA.usedTimeAll': '使用总时长',
  'iasAAA.usedValueAll': '使用总流量',

  'iasAAA.order': '序号',
  'iasAAA.mac': 'MAC地址',
  'iasAAA.internalIp': '内网IP',
  'iasAAA.externalIp': '外网IP',
  'iasAAA.usedTime': '使用时长',
  'iasAAA.usedValue': '使用流量',
  'iasAAA.info': '明细',

  'iasAAA.xopOrderNo': 'XOP订单号',
  'iasAAA.packageName': '流量包名称',
  'iasAAA.aTime': '可用时长',
  'iasAAA.aValue': '可用流量',
  'iasAAA.usedTimeS': '已用时长',
  'iasAAA.usedValueM': '已用流量',

  'iasAAA.operatorOrderNo': '运营商订单号',
  'iasAAA.channelOrderNo': '渠道订单号',
  'iasAAA.accNum': '上网帐号',
  'iasAAA.priority': '使用优先级',
  'iasAAA.allowMaxDown': '下行限速',
  'iasAAA.allowMaxUp': '上行限速',
  'iasAAA.consumptionMode': '计费方式',
  'iasAAA.from': '使用限制(开始时间)',
  'iasAAA.to': '使用限制(截止时间)',
  'iasAAA.lastReportTime': '最近一次上报记录时间',
  'iasAAA.surplusValue': '剩余流量',
  'iasAAA.surplusTime': '剩余时长',
  'iasAAA.orderTime': '购买时间',
  'iasAAA.token': '应用流量包识别Token',
  'iasAAA.applied': '是否应用过',
  'iasAAA.exceed30days': '时间跨度选择不能超过30天',

  'iasAAA.remainTime': '剩余时长',
  'iasAAA.usedValueBytes': '消耗流量',
  'iasAAA.remainValue': '剩余流量',
  'iasAAA.expireTime': '过期时间',
  'iasAAA.recordTime': '记录时间',

  'iasAAA.last1d': '最近1天',
  'iasAAA.last2d': '最近2天',
  'iasAAA.last3d': '最近3天',
  'iasAAA.last5d': '最近5天',
  'iasAAA.last7d': '最近7天',
  'iasAAA.last30d': '最近30天',
  'iasAAA.custom': '自定义',

};