export default {
  'iasAirport.title': '机场数据',
  'iasAirport.createAirport': '新增机场',
  'iasAirport.editAirport': '编辑机场',

  'iasAirport.searchKey': '请输入机场名称/三字码/四字码搜索',
  'iasAirport.search': '输入地点或地址搜索',

  'iasAirport.confirm':'确定',
  'iasAirport.operation': '操作',
  'iasAirport.save': '保存',
  'iasAirport.cancel': '取消',
  'iasAirport.create': '新增',
  'iasAirport.edit': '编辑',
  'iasAirport.delete': '删除',

  'iasAirport.name': '机场名称',
  'iasAirport.iata': '机场三字码',
  'iasAirport.icao': '机场四字码',
  'iasAirport.country': '国家',
  'iasAirport.city': '城市',
  'iasAirport.updateTime': '更新时间',

  'iasAirport.nameEdit': '机场名称name',
  'iasAirport.iataEdit': '机场三字码iata',
  'iasAirport.icaoEdit': '机场四字码icao',
  'iasAirport.countryEdit': '国家country',
  'iasAirport.cityEdit': '城市city',
  'iasAirport.longitude': '经度longitude',
  'iasAirport.latitude': '纬度latitude',
  'iasAirport.altitude': '海拔高度altitude(英尺)',
  'iasAirport.dst': '夏令时dst',
  'iasAirport.timezone': '时区timezone',
  'iasAirport.tz': 'TZ',
  'iasAirport.source': '来源source',
  'iasAirport.type': '类型type',
  'iasAirport.priority': '优先priority',
};