export default {
  'nmsMonitoringSettings.title': '监控设置',
  'nmsMonitoringSettings.pageDescription': '机载网络设备监控检查项自定义配置',

  'nmsMonitoringSettings.addCategory': '监控分类',
  'nmsMonitoringSettings.categoryName': '分类名称',
  'nmsMonitoringSettings.all': '全部',
  'nmsMonitoringSettings.keywordQuery': '检查项:',
  'nmsMonitoringSettings.enterKeyValue': '请输入关键字',
  'nmsMonitoringSettings.query': '查询',
  'nmsMonitoringSettings.new': '新增',
  'nmsMonitoringSettings.delete': '移除',
  'nmsMonitoringSettings.edit': '编辑',
  'nmsMonitoringSettings.pleaseSeleteItem': '请选择检查项',
  'nmsMonitoringSettings.wantDeleteThem': '您是否真的想删除它们？',
  'nmsMonitoringSettings.wantDeleteIt': '您是否真的想删除它？',
  'nmsMonitoringSettings.cannotBeRecovered': '删除后不可恢复',

  'nmsMonitoringSettings.checkItem': '检查项',
  'nmsMonitoringSettings.detectionValue': '检测值',
  'nmsMonitoringSettings.alarmOrNot': '是否告警',
  'nmsMonitoringSettings.alarmType': '告警类型',
  'nmsMonitoringSettings.faultName': '故障名称',
  'nmsMonitoringSettings.recommendedSolution': '建议解决方案',
  'nmsMonitoringSettings.newTestItem': '新增检测项',
  'nmsMonitoringSettings.normal': '正常',
  'nmsMonitoringSettings.fullyLoaded': '满载',
  'nmsMonitoringSettings.failed': '异常',
  'nmsMonitoringSettings.connectionFailed': '未连接或无效',
  'nmsMonitoringSettings.notMatch': '发现无法识别的指标',
  'nmsMonitoringSettings.unKnow': '未知设备',
  'nmsMonitoringSettings.on': '是',
  'nmsMonitoringSettings.off': '否',
  'nmsMonitoringSettings.newValueAdded': '新增值',
  'nmsMonitoringSettings.newTestItemValue': '新增检测项值',
  'nmsMonitoringSettings.AMMsuggestions': 'AMM',

  'nmsMonitoringSettings.numberCode': '编号代码',
  'nmsMonitoringSettings.displayName': '显示名称',
};