export default {
  'iasPassengerOnlineDetails.title': 'Details of online passengers',

  'iasPassengerOnlineDetails.cardId': 'ID Number',
  'iasPassengerOnlineDetails.seatNumber': 'Seat Number',
  'iasPassengerOnlineDetails.flightNumber': 'Flight Number',
  'iasPassengerOnlineDetails.numberOfDevicesUsed': 'Number of Devices Used',
  'iasPassengerOnlineDetails.passengerUsedTraffic': 'Used Traffic',
  'iasPassengerOnlineDetails.statusOfPassengerNetwork': 'Network Availability Status',
  'iasPassengerOnlineDetails.AccountStatus': 'Account Status',
  'iasPassengerOnlineDetails.temporaryOffline': 'Temporarily Offline',
  'iasPassengerOnlineDetails.normalUse': 'Normal Use',
  'iasPassengerOnlineDetails.permanentBlackout': 'Permanently Blocked',
  'iasPassengerOnlineDetails.flightIATA': 'Flight IATA',
  'iasPassengerOnlineDetails.flightICAO': 'Flight ICAO',
  'iasPassengerOnlineDetails.deptCodeIATA': 'Dept IATA',
  'iasPassengerOnlineDetails.deptCodeICAO': 'Dept ICAO',
  'iasPassengerOnlineDetails.destCodeIATA': 'Dest IATA',
  'iasPassengerOnlineDetails.destCodeICAO': 'Dest ICAO',
  'iasPassengerOnlineDetails.destCity': 'Dest Airport',
  'iasPassengerOnlineDetails.deptCity': 'Dept Airport',

  'iasPassengerOnlineDetails.tailNo': 'Tail No',
  'iasPassengerOnlineDetails.actualTakeOffTime': 'Actual take off time',
  'iasPassengerOnlineDetails.actualArriveTime': 'Actual arrive time',
  'iasPassengerOnlineDetails.arriveTime': 'Arrive time',
  'iasPassengerOnlineDetails.takeOffTime': 'Take off time',
  'iasPassengerOnlineDetails.predictArriveTime': 'Predict arrive time',
  'iasPassengerOnlineDetails.predictTakeOffTime': 'Predict take off time',
  'iasPassengerOnlineDetails.flightDate': 'Flight date',
  'iasPassengerOnlineDetails.deptCityDestCity': 'Dept dest city',

  'iasPassengerOnlineDetails.trafficConsumption': 'Traffic consumption',

  'iasPassengerOnlineDetails.internetDevice': 'Internet device',
  'iasPassengerOnlineDetails.listOfEquipmentUsed': 'List of equipment used in the current flight',
  'iasPassengerOnlineDetails.IPAddress': 'IP address',
  'iasPassengerOnlineDetails.MACAddress': 'MAC address',
  'iasPassengerOnlineDetails.externalIp': 'External Ip',
  'iasPassengerOnlineDetails.InternetOnlineStatus': 'Online status',
  'iasPassengerOnlineDetails.just': 'Just',
  'iasPassengerOnlineDetails.mAgo': 'm ago',
  'iasPassengerOnlineDetails.hAgo': 'h ago',

  'iasPassengerOnlineDetails.availablePackages': 'Available packages',
  'iasPassengerOnlineDetails.listOfAvailablePackages': 'List of available packages for the current flight',
  'iasPassengerOnlineDetails.thirdPartyOrder': 'Third party order number',
  'iasPassengerOnlineDetails.order': 'Order',
  'iasPassengerOnlineDetails.XOPOrderNumber': 'XOP order number',
  'iasPassengerOnlineDetails.usedTraffic': 'Purchased traffic（MB）',
  'iasPassengerOnlineDetails.effectiveTime': 'Effective time',
  'iasPassengerOnlineDetails.endTime': 'End time',
  'iasPassengerOnlineDetails.inUse': 'In use',
  'iasPassengerOnlineDetails.unlimited': 'Unlimited',
  'iasPassengerOnlineDetails.offset': 'Offset',
  'iasPassengerOnlineDetails.satcomOfferId': 'Satcom offer id',
  'iasPassengerOnlineDetails.status': 'Status',
  'iasPassengerOnlineDetails.packageName': 'Package name',
  'iasPassengerOnlineDetails.beginTime': 'Begin time',
}