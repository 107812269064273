import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import {Provider} from "react-redux";
import store from "store";
import {AppContainer as HMRContainer} from "react-hot-loader";
import "fonts/iconfont/iconfont.css";
import 'styles/index.less'
import quickClick from "quickclick";
import RootLay from './RootLay';
import ECharts from 'echarts';
import themeJson from './echart_theme'

const root = document.getElementById("root");
quickClick(document.body);

ECharts.registerTheme('macarons', themeJson);

ReactDOM.render(
  <HMRContainer>
    <Provider store={store}>
      <RootLay/>
    </Provider>
  </HMRContainer>, root);

serviceWorker.unregister();
