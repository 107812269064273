export default {
  'nmsAlarmTroubleshoot.title': 'Troubleshooting',

  'nmsAlarmTroubleshoot.deviceBitInfoList': 'Device bit information list',
  'nmsAlarmTroubleshoot.bitInfoList': 'Device bit information list',
  'nmsAlarmTroubleshoot.deviceStatusOfAll': 'All parameters',
  'nmsAlarmTroubleshoot.backToTop': 'Back to top',
  'nmsAlarmTroubleshoot.statusOfAll': 'Status of all parameters',
  'nmsAlarmTroubleshoot.checkItem': 'Check item',
  'nmsAlarmTroubleshoot.curStatus': 'Current status',
  'nmsAlarmTroubleshoot.refresh': 'Refresh',

  'nmsAlarmTroubleshoot.deviceBitInfoListExp1': 'No data: black light, the data is not reported or the defined parameter key is invalid. ',
  'nmsAlarmTroubleshoot.deviceBitInfoListExp2': 'Invalid indicator: yellow light. The detection parameter value is set incorrectly. For example, pass is set and the result is reported as 1.',
  'nmsAlarmTroubleshoot.deviceBitInfoListExp3': 'Abnormal operation: red light, abnormal detection parameters, general parameter value is 1 or fail.',
  'nmsAlarmTroubleshoot.deviceBitInfoListExp4': 'Full load operation: yellow light, full load appears in the detection parameters, for example, the parameter value is higher than a certain value. ',
  'nmsAlarmTroubleshoot.deviceBitInfoListExp5': 'Normal operation: green light, normal detection parameters, general parameter value is 0 or pass.',
  'nmsAlarmTroubleshoot.statusOfAllExp': 'Status of all parameters',

};