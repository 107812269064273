import * as nmsApi from 'apis/nms';
import * as airlinesApi from 'apis/airlines';

export const SET_PLANES_INFO = "FLIGHTSTATUS_SET_PLANES_INFO"
export const SET_TRACK_LIST = "FLIGHTSTATUS_SET_TRACK_LIST"
export const UPDATE_TRACK_LIST = "FLIGHTSTATUS_UPDATE_TRACK_LIST"
export const UPDATE_TRACK_INFO = "FLIGHTSTATUS_UPDATE_TRACK_INFO"


export function setPlanesInfo(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_PLANES_INFO,
      data,
    })
  }
}

export function updateTrackList(data) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_TRACK_LIST,
      data,
    })
  }
}

export function getRefittingTailNos() {
  return async (dispatch) => {
    try {
      const tailNos = await nmsApi.getRefittingTailNos()
      tailNos.map(it => {
        refetchFlightList2(dispatch, it.tailNo, 1440)
      })
      return dispatch({
        type: SET_TRACK_LIST,
        data: tailNos
      })
    } catch (e) {
      console.log("=============", e)
    }
  }
}

export function updateTrackInfo(data) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_TRACK_INFO,
      data,
    })
  }
}

export function refetchFlightList(tailNo, size) {
  return async (dispatch) => {
    try {
      if (!tailNo) return
      let res = await airlinesApi.getFlightList(tailNo, size);
      let trackInfos = (res || []);
      const trData = trackInfos.sort((a, b) => a.currentTime - b.currentTime).map(({lat, lng}) => ({lat, lng}))
      const lastItem = trackInfos.length > 0 ? trackInfos[trackInfos.length - 1] : {}
      const timestamp = new Date().getTime() - 30 * 1000
      if (trData.length > 0) {
        dispatch({
          type: UPDATE_TRACK_INFO,
          data: {
            tailNo,
            trackInfos: trData,
            trackAngle: lastItem.head || '0',
            altitude: lastItem.altitude || '0',
            flightNo: lastItem.fltNum || '',
            netStatus: timestamp > lastItem.createTime ? 'unlink' : lastItem.netStatus,
            netType: lastItem.netType || '',
            time: lastItem.createTime || '',
            deptCode: lastItem.deptCode || '',
            destCode: lastItem.destCode || '',
          }
        })
      }
    } catch (e) {
      console.log("=============", e)
    }
  }
}

async function refetchFlightList2(dispatch, tailNo, size) {
  try {
    if (!tailNo) return
    let res = await airlinesApi.getFlightList(tailNo, size);
    let trackInfos = (res || []);
    const trData = trackInfos.sort((a, b) => a.currentTime - b.currentTime).map(({lat, lng}) => ({lat, lng}))
    const lastItem = trackInfos.length > 0 ? trackInfos[trackInfos.length - 1] : {}
    const timestamp = new Date().getTime() - 30 * 1000
    if (trData.length > 0) {
      dispatch({
        type: UPDATE_TRACK_INFO,
        data: {
          tailNo,
          trackInfos: trData,
          trackAngle: lastItem.head || '0',
          altitude: lastItem.altitude || '0',
          flightNo: lastItem.fltNum || '',
          netStatus: timestamp > lastItem.createTime ? 'unlink' : lastItem.netStatus,
          netType: lastItem.netType || '',
          time: lastItem.createTime || '',
          deptCode: lastItem.deptCode || '',
          destCode: lastItem.destCode || '',
        }
      })
    }
  } catch (e) {
    console.log("=============", e)
  }
}
