export default {
  'iasOrderLogs.title': '流量订单',
  'iasOrderLogs.pageDescription': '流量的购买快照，即系统中已生效的流量包记录',
  'iasOrderLogs.exportData': '导出数据',
  'iasOrderLogs.customColumns': '自定义列',

  'iasOrderLogs.flowPackageOrderRecord': '流量包订单记录',
  'iasOrderLogs.keyword': '关键字',
  'iasOrderLogs.timeInterval': '下单时间',
  'iasOrderLogs.orderNumber': '订单号',
  'iasOrderLogs.flightNumber': '航班号',
  'iasOrderLogs.seatNumber': '座位号',
  'iasOrderLogs.source': '渠道名称',
  'iasOrderLogs.thirdPartyOrderNumber': '渠道订单号',
  'iasOrderLogs.packageName': '套餐名称',
  'iasOrderLogs.creationTime': '下单时间',
  'iasOrderLogs.operation': '操作',
  'iasOrderLogs.details': '详情',
  'iasOrderLogs.price': '价格',

  'iasOrderLogs.cumulativeOrderQuantity': '累计订单数量',
  'iasOrderLogs.trafficPackageCompleted': '待完结流量套餐',
  'iasOrderLogs.newOrdersWeek': '本周新增订单',
  'iasOrderLogs.search': '搜索',
  'iasOrderLogs.packageDataFlow': '套餐流量',
  'iasOrderLogs.restOfPackageDataFlow': '剩余流量',
  'iasOrderLogs.packageEndTime': '有效截止时间',
  'iasOrderLogs.orderNumberParty': '渠道订单号',
  'iasOrderLogs.orderNumberXOP': 'XOP订单号',
  'iasOrderLogs.dept': '出发城市',
  'iasOrderLogs.dest': '到达城市',
  'iasOrderLogs.deptDestExp': '城市或者机场三字码搜索...',
  'iasOrderLogs.snapshot': '快照',
  'iasOrderLogs.infinite': '无限',

  'iasOrderLogs.expand': '展开',
  'iasOrderLogs.packUp': '收起',
  'iasOrderLogs.reset': '重置',
  'iasOrderLogs.idCard': '旅客证件号',
  'iasOrderLogs.seatNo': '旅客座位号',
  'iasOrderLogs.flightNo': '所属航班号',
  'iasOrderLogs.status': '订单状态',
  'iasOrderLogs.satcomOfferId': '运营商订单号',
  'iasOrderLogs.trafficSize': '流量大小',
  'iasOrderLogs.satcomUpRate': '上行速率',
  'iasOrderLogs.satcomDownRate': '下行速率',
  'iasOrderLogs.packageType': '流量类型',
  'iasOrderLogs.offset': '有效时长',
  'iasOrderLogs.commodityName': '流量包名称',
  'iasOrderLogs.satcomName': '运营商流量包名称',
  'iasOrderLogs.deptCity': '出发城市',
  'iasOrderLogs.deptCode': '出发机场三字码',
  'iasOrderLogs.destCity': '到达城市',
  'iasOrderLogs.destCode': '到达机场三字码',
  'iasOrderLogs.inputSearch': '请输入搜索...',

  'iasOrderLogs.all': '全部',
  'iasOrderLogs.inactive': '未激活',
  'iasOrderLogs.failed': '激活失败',
  'iasOrderLogs.success': '成功',
  'iasOrderLogs.manual': '手动激活',
  'iasOrderLogs.retrying': '重试中',
  'iasOrderLogs.activating': '激活中',
  'iasOrderLogs.activated': '已激活',
  'iasOrderLogs.activate': '激活',

  'iasOrderLogs.popActivating': '手动激活中...',
  'iasOrderLogs.popFailed': '激活失败，请稍后再试！',
  'iasOrderLogs.popSuccess': '激活成功！此操作只针对还未降落的航班订单有效',
};
