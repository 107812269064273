export default {
  'nmsMaintainSetting.title': 'Installation Details',
  'nmsMaintainSetting.pageDescription': 'Associate the installed device with xstreamopsystem',

  'nmsMaintainSetting.basicInformation': 'Basic information',
  'nmsMaintainSetting.tailNumber': 'Tail No',
  'nmsMaintainSetting.model': 'Model',
  'nmsMaintainSetting.factoryDate': 'Factory date',
  'nmsMaintainSetting.machineAge': 'Machine age',
  'nmsMaintainSetting.installDevice': 'Install device',
  'nmsMaintainSetting.modificationScheme': 'Modification',
  'nmsMaintainSetting.installationTime': 'Installation time',
  'nmsMaintainSetting.networkType': 'Network type',
  'nmsMaintainSetting.orgName': 'Airline',

  'nmsMaintainSetting.lan': 'LAN',
  'nmsMaintainSetting.satellite': 'Satellite',
  'nmsMaintainSetting.noNetwork': 'No network',

  'nmsMaintainSetting.editInstallation': 'Edit Installation',
  'nmsMaintainSetting.installationDiagram': 'Installation diagram',
  'nmsMaintainSetting.newInstallation': 'New installation',
  'nmsMaintainSetting.associateInstalledDevice': 'Associate the installed device with XstreamOP system',
  'nmsMaintainSetting.uploadAircraftSchematic': 'Upload aircraft schematic diagram',
  'nmsMaintainSetting.save': 'Save',
  'nmsMaintainSetting.cancel': 'Cancel',
  'nmsMaintainSetting.areYouSure': 'Are you sure you want to leave this page?',
  'nmsMaintainSetting.print': 'Print',

  'nmsMaintainSetting.aircraftTypeNameZh': 'Name(zh)',
  'nmsMaintainSetting.aircraftTypeNameEn': 'Name(en)',
  'nmsMaintainSetting.iata3': 'IATA Code',
  'nmsMaintainSetting.productionCompany': 'Production',
  'nmsMaintainSetting.planeLength': 'Length',
  'nmsMaintainSetting.pilotsNumber': 'Pilots',
  'nmsMaintainSetting.firstFly': 'Birthday',
  'nmsMaintainSetting.seatsNumber': 'Seats',
  'nmsMaintainSetting.icao4': 'ICAO Code',
  'nmsMaintainSetting.imageUploadTips1': 'image should be 9:4',
  'nmsMaintainSetting.imageUploadTips2': 'image should be PNG or JPEG',
  'nmsMaintainSetting.imageUploadTips3': 'image should be below 10MB',

  'nmsMaintainSetting.ip': 'Plane IP',
  'nmsMaintainSetting.ipCheckWrong': 'invalid IP format',

  'nmsMaintainSetting.tailNoCheckWrong': 'Please input the tailNo.',
};