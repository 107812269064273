export default {
  'iasControl.title': 'Access Control',

  'iasControl.tailNoSearch': 'Tail No…',
  'iasControl.flightNoSearch': 'Flight Number…',
  'iasControl.addressSearch': 'Address…',
  'iasControl.all': 'All',
  'iasControl.SOURCE_ALLOW': 'SOURCE ALLOW',
  'iasControl.SOURCE_BLOCK': 'SOURCE BLOCK',
  'iasControl.TARGET_ALLOW': 'TARGET ALLOW',
  'iasControl.TARGET_BLOCK': 'TARGET BLOCK',
  'iasControl.create': 'Add',
  'iasControl.edit': 'Edit',
  'iasControl.delete': 'Delete',
  'iasControl.tailNo': 'Tail No',
  'iasControl.address': 'Address',
  'iasControl.enable': 'Enable',
  'iasControl.true': 'True',
  'iasControl.false': 'False',
  'iasControl.type': 'Type',
  'iasControl.remark': 'Comments',
  'iasControl.updateTime': 'Update Time',
  'iasControl.batchEnable': 'Batch Enable',
  'iasControl.batchOff': 'Batch Off',
  'iasControl.batchClone': 'Batch Clone',
  'iasControl.batchDelete': 'Batc Delete',
  'iasControl.duplicateRule': 'Duplicate Rule',
  'iasControl.COVER': 'COVER',
  'iasControl.SKIP': 'SKIP',

  'iasControl.status': 'Status',
  'iasControl.operation': 'Operation',
  'iasControl.confirm': 'Confirm',
  'iasControl.save': 'Save',
  'iasControl.cancel': 'Cancel',

};