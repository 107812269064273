export default {
  'rbacUsers.title': 'Account',
  'rbacUsers.pageDescription': 'XstreamOP system login account configuration',

  'rbacUsers.search': 'Search...',
  'rbacUsers.searchOrganization': 'Org Name...',
  'rbacUsers.add': 'Add',
  'rbacUsers.account': 'Account',
  'rbacUsers.name': 'Name',
  'rbacUsers.email': 'Email',
  'rbacUsers.orgName': 'Org Name',
  'rbacUsers.role': 'Role',
  'rbacUsers.userPlanCount': 'Plan Count',
  'rbacUsers.time': 'Time',
  'rbacUsers.state': 'State',
  'rbacUsers.authoriza': 'Authoriza',
  'rbacUsers.remark': 'Comments',
  'rbacUsers.operation': 'Edit',
  'rbacUsers.totalOf': 'A total of {num}',

  'rbacUsers.delete': 'Delete',
  'rbacUsers.details': 'Details',

  'rbacUsers.wantDelete': 'You will not be able to recover after deleting. Are you sure you want to delete?',

  'rbacUsers.authRole': 'Authorized role',
  'rbacUsers.unknownRole': 'Empty means unknown role',
  'rbacUsers.addAccount': 'Create account',
  'rbacUsers.editAccount': 'Edit account',
  'rbacUsers.affiliate': 'Affiliate',
  'rbacUsers.phoneNo': 'Phone number',
  'rbacUsers.password': 'Password',
  'rbacUsers.statusSetting': 'Status setting',

  'rbacUsers.on': 'ON',
  'rbacUsers.off': 'OFF',

  'rbacUsers.userInfo': 'User info',
  'rbacUsers.searchTailNo': 'Search tail no...',
  'rbacUsers.plane': 'Plan',
  'rbacUsers.editInfo': 'Edit info',
  'rbacUsers.selectPlan': 'Select plan',
  'rbacUsers.tailNo': 'Tail no',
  'rbacUsers.orgName2': 'Org name',
  'rbacUsers.aircraftType': 'Aircraft type',
  'rbacUsers.seatCount': 'Seat count',
  'rbacUsers.createTime': 'Create time',
};
