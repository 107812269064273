import {getAxios} from 'shared/fetch';
import fetch from 'isomorphic-fetch';
import {BASE_API_URL, DEBUG} from 'shared/env';

import {obj2Str} from 'shared/utils';

export const NMS_DEVICE_INFO_LIST = `${BASE_API_URL}/user/setting/getPlaneDeviceListByTailNum`;
export const NMS_GET_ALARM_DEVICES = `${BASE_API_URL}/nms/getAlarmDevices`;
export const NMS_GET_ALARM_MONITORING_BY_DEVICE_ID = `${BASE_API_URL}/nms/getAlarmMonitoringByDeviceId`;
export const NMS_UPDATE_ALARM_MONITORING_BY_ID = `${BASE_API_URL}/nms/updateAlarmMonitoringById`;

//@Deprecated
export const NMS_GET_ALARM_TYPE_STATISTICS = `${BASE_API_URL}/nms/getAlarmTypeStatistics`;
export const NMS_GET_ALARM_DEVICE_STATISTICS = `${BASE_API_URL}/nms/getAlarmDeviceStatistics`;
export const NMS_GET_ALARM_LEVEL_STATISTICS = `${BASE_API_URL}/nms/getAlarmLevelStatistics`;
export const NMS_GET_ALARM_RECORD = `${BASE_API_URL}/nms/getAlarmRecord`;
export const NMS_BATCH_CONFIRM_ALARM_RECORD = `${BASE_API_URL}/nms/batchConfirmAlarmRecord`;
export const NMS_GET_ALARM_OPERATING_RECORD = `${BASE_API_URL}/nms/getAlarmOperatingRecord`;

export const NMS_CONFIG_GET_DEVICE_INFO_CATEGORY_LIST = `${BASE_API_URL}/nms/device-config/getDeviceInfoCategoryList`;
export const NMS_DEVICE_DICTIONARY = `${BASE_API_URL}/nms/device-dictionary`;
export const NMS_DEVICE_DICTIONARY_GROUP = `${BASE_API_URL}/nms/device-dictionary/group`;

export const NMS_DEVICE_DICTIONARY_SEARCH_KEY = `${BASE_API_URL}/nms/device-dictionary/keys/page`;
export const NMS_DEVICE_DICTIONARY_GET_DEVICE_INDEX = `${BASE_API_URL}/nms/device-dictionary/deviceByTypeName`;

export const NMS_DEVICE_DICTIONARY_ADD_KEY = `${BASE_API_URL}/nms/device-dictionary/keys`;
export const NMS_DEVICE_DICTIONARY_ADD_SUB_DEVICE = `${BASE_API_URL}/nms/device-dictionary/addSubDevice`;
export const NMS_DEVICE_DICTIONARY_GET_ALL_DEVICE = `${BASE_API_URL}/nms/device-dictionary/all`;

export const NMS_MONITOR_CONFIG_GROUP = `${BASE_API_URL}/nms/monitor-config/group`;
export const NMS_MONITOR_CONFIG_GROUP_ITEM = `${BASE_API_URL}/nms/monitor-config/group/item`;
export const NMS_MONITOR_CONFIG_ITEMS = `${BASE_API_URL}/nms/monitor-config/items`;

export const NMS_MONITOR_CONFIG_KEY_VALUE = `${BASE_API_URL}/nms/monitor-config/value`;

export const NMS_MONITOR_ALARM_HISTORY = `${BASE_API_URL}/nms/monitor-alarm/history`;
export const NMS_MONITOR_PLANE_OVERVIEW = `${BASE_API_URL}/nms/monitor-alarm/plane/overview`;
export const NMS_MONITOR_PLANE_unionOVERVIEW = `${BASE_API_URL}/nms/monitor-alarm/plane/unionOverview`;

export const NMS_GET_PLANE_INFO = `${BASE_API_URL}/user/airplane/byTailNo`;
export const NMS_GET_DEVICES_OF_PLANE = `${BASE_API_URL}/user/setting/findDeviceTypeByTailNum`;
export const NMS_GET_DEVICES_INFO_OF_PLANE = `${BASE_API_URL}/nms/monitor-alarm/info`;
export const NMS_GET_DETECTION_OF_DEVICE = `${BASE_API_URL}/nms//monitor-alarm/monitorInfo`;
export const NMS_GET_RESOLUTION_OF_DEVICE = `${BASE_API_URL}/nms/monitor-alarm/historyByKey`;

export const SET_DEVICE_SORT = `${BASE_API_URL}/nms/device-dictionary/sortIndex`;
export const SET_DEVICE_KES_SORT = `${BASE_API_URL}/nms/device-dictionary/kes/sortIndex`;

export const NMS_GET_REFIT_TAIL_NOS = `${BASE_API_URL}/user/airplane/refittingPlanes`;
export const NMS_GET_REFIT_DEVICES = `${BASE_API_URL}/user/airplane/devices`;
export const NMS_GET_RUN_RECORD_LIST = `${BASE_API_URL}/nms/monitor-alarm/runRecordTable`;
export const NMS_GET_DEVICE_DICTIONARY_LIST = `${BASE_API_URL}/nms/device-dictionary/keys`;
export const NMS_RUN_RECORD_DOWNLOAD = `${BASE_API_URL}/nms/monitor-alarm/runRecord/download`;

export const NMS_ALTITUDE_NET = `${BASE_API_URL}/nms/report/altitudeAndNet`;
export const NMS_ALL_DEVICE_INFO = `${BASE_API_URL}/nms/report/allDeviceInfo`;

const NMS_NET_SPEED = `${BASE_API_URL}/nms/report/netSpeed`;
const NMS_MONITOR_ALARM = `${BASE_API_URL}/nms/monitor-alarm/query`;
const NMS_MONITOR_ALARM_DEVICES = `${BASE_API_URL}/nms/monitor-alarm/query/devices`;
const NMS_MONITOR_ALARM_DETAIL = `${BASE_API_URL}/nms/monitor-alarm/query/deviceDetail`;
const NMS_MONITOR_ALARM_INFO = `${BASE_API_URL}/nms/monitor-alarm/query/monitorInfo`;
const NMS_MONITOR_ALARM_CHART = `${BASE_API_URL}/nms/monitor-alarm/query/chart`;
const NMS_MONITOR_ALL_KEY = `${BASE_API_URL}/nms/monitor-alarm/query/allKeys`;
const NMS_ALARM_COUNT = `${BASE_API_URL}/nms/monitor-alarm/query/deviceCount`;
const NMS_ALARM_NETWORK = `${BASE_API_URL}/nms/monitor-alarm/query/networkStatus`;
const NMS_NET_SPEED_TIME = `${BASE_API_URL}/nms/report/netSpeedByTime`;

const beamSwitchDetails = `${BASE_API_URL}/nms/report/beamSwitchDetails`;
const devicePower = `${BASE_API_URL}/nms/report/devicePower`;
const hardware = `${BASE_API_URL}/nms/report/hardware`;
const software = `${BASE_API_URL}/nms/report/software`;


const diskUsageGns = `${BASE_API_URL}/nms/report/diskUsage/gns`;
const diskUsageCwap1 = `${BASE_API_URL}/nms/report/diskUsage/cwap1`;
const diskUsageCwap2 = `${BASE_API_URL}/nms/report/diskUsage/cwap2`;

const monitorLevel = `${BASE_API_URL}/nms/monitorSetting/monitorLevel`;
const monitorEvent = `${BASE_API_URL}/nms/monitorSetting/monitorEvent`;
const deviceKeySearch = `${BASE_API_URL}/nms/device-dictionary/keys/search`;
const eventHistory = `${BASE_API_URL}/nms/monitorSetting/event/history`;
const groupSort = `${BASE_API_URL}/nms/device-dictionary/group/sort`;

const transferKeyList = `${BASE_API_URL}/nms/device-dictionary/value-transfer/key`;
const transferList = `${BASE_API_URL}/nms/device-dictionary/value-transfer/transfer`;
const bitInfo = `${BASE_API_URL}/nms/device-dictionary/bit-info`;
const eventHistoryPage = `${BASE_API_URL}/nms/monitorSetting/event/history/page`;
const eventExportUrl = `${BASE_API_URL}/nms/monitorSetting/event/history/export`;
const reportEventUrl = `${BASE_API_URL}/nms/report/event`;
const physicalDeviceUrl = `${BASE_API_URL}/nms/device-history/physicalDevice`;
const joinDetailUrl = `${BASE_API_URL}/nms/report/joinDetail`;
export const fillingSoftwareUrl = `${BASE_API_URL}/nms/filling-software`;
const monitorItemsUrl = `${BASE_API_URL}/nms/monitor-alarm/monitorItems`;

const serviceDefinitionUrl = `${BASE_API_URL}/nms/serviceTesting/definition`;
const serviceDefinitionPageUrl = `${BASE_API_URL}/nms/serviceTesting/definition/page`;
const serviceSettingUrl = `${BASE_API_URL}/nms/serviceTesting/setting`;
const serviceSettingPageUrl = `${BASE_API_URL}/nms/serviceTesting/setting/page`;
const serviceSettingTailNosUrl = `${BASE_API_URL}/nms/serviceTesting/setting/tailNos`;
const serviceSingleTestUrl = `${BASE_API_URL}/nms/serviceTesting/singleTest`;
const serviceListUrl = `${BASE_API_URL}/nms/serviceTesting/service/list`;
const servicebyTailNoUrl = `${BASE_API_URL}/nms/serviceTesting/setting/byTailNo`;

const adapterConfigUrl = `${BASE_API_URL}/nms/adapter-config`;
const adapterConfigGroupsUrl = `${BASE_API_URL}/nms/adapter-config/groups`;
const adapterConfigListUrl = `${BASE_API_URL}/nms/adapter-config/list`;
const adapterConfigPageUrl = `${BASE_API_URL}/nms/adapter-config/page`;
const adapterConfigTailNosUrl = `${BASE_API_URL}/nms/adapter-config/tailNos`;
const adapterConfigAliasUrl = `${BASE_API_URL}/nms/adapter-config/alias/all`;
const adapterConfigMappingUrl = `${BASE_API_URL}/nms/adapter-config/mapping`;
const adapterConfigCloneUrl = `${BASE_API_URL}/nms/adapter-config/clone`;
const adapterConfigBatchCloneUrl = `${BASE_API_URL}/nms/adapter-config/batchClone`;
const adapterConfigExportUrl = `${BASE_API_URL}/nms/adapter-config/export`;
export async function cloneApplyConfig(data) {
  return await getAxios(`${adapterConfigCloneUrl}`, 'POST', data);
}
export async function batchCloneApplyConfig(data) {
  return await getAxios(`${adapterConfigBatchCloneUrl}`, 'POST', data);
}
export async function adapterConfigExport(data) {
  let params = obj2Str(data);
  if (params) {
    window.open(`${adapterConfigExportUrl}?${params}`);
  }
}
export async function getApplyConfigMappingList(tailNo, group, alias) {
  let data = {tailNo}
  if (group) data.group = group
  if (alias) data.alias = alias
  let params = obj2Str(data);
  return await getAxios(`${adapterConfigMappingUrl}?${params}`, 'GET');
}

export async function getApplyConfigAlias() {
  return await getAxios(`${adapterConfigAliasUrl}`, 'GET');
}

export async function getApplyConfigTailNos() {
  return await getAxios(`${adapterConfigTailNosUrl}`, 'GET');
}

export async function getApplyConfigGroups() {
  return await getAxios(`${adapterConfigGroupsUrl}`, 'GET');
}

export async function getApplyConfigList(data) {
  let params = obj2Str(data);
  return await getAxios(`${adapterConfigListUrl}?${params}`, 'GET');
}

export async function getApplyConfigPage(data) {
  let params = obj2Str(data);
  return await getAxios(`${adapterConfigPageUrl}?${params}`, 'GET');
}

export async function addApplyConfig(data) {
  return await getAxios(`${adapterConfigUrl}`, 'POST', data);
}

export async function deleteApplyConfig(id) {
  return await getAxios(`${adapterConfigUrl}/${id}`, 'DELETE');
}

export async function getApplyConfig(id) {
  return await getAxios(`${adapterConfigUrl}/${id}`, 'GET');
}

export async function editApplyConfig(data) {
  return await getAxios(`${adapterConfigUrl}`, 'PUT', data);
}

export async function getServiceSettingByTailNo(tailNo) {
  return await getAxios(`${servicebyTailNoUrl}?tailNo=${tailNo}`, 'GET');
}

export async function singleTestService(tailNo, definitionId) {
  return await getAxios(`${serviceSingleTestUrl}?tailNo=${tailNo}&definitionId=${definitionId}`, 'GET');
}

export async function getServiceList(tailNo) {
  return await getAxios(`${serviceListUrl}?tailNo=${tailNo}`, 'GET');
}

export async function getServiceSettingTailNos() {
  return await getAxios(`${serviceSettingTailNosUrl}`, 'GET');
}

export async function getServiceSettingList(data) {
  let params = obj2Str(data);
  return await getAxios(`${serviceSettingUrl}?${params}`, 'GET');
}

export async function getServiceSettingPage(data) {
  let params = obj2Str(data);
  return await getAxios(`${serviceSettingPageUrl}?${params}`, 'GET');
}

export async function addServiceSetting(data) {
  return await getAxios(`${serviceSettingUrl}`, 'POST', data);
}

export async function deleteServiceSetting(id) {
  return await getAxios(`${serviceSettingUrl}/${id}`, 'DELETE');
}

export async function getServiceSetting(id) {
  return await getAxios(`${serviceSettingUrl}/${id}`, 'GET');
}

export async function editServiceSetting(id, data) {
  return await getAxios(`${serviceSettingUrl}/${id}`, 'PUT', data);
}

export async function getServiceDefinitionList(data) {
  let params = obj2Str(data);
  return await getAxios(`${serviceDefinitionUrl}?${params}`, 'GET');
}

export async function getServiceDefinitionPage(data) {
  let params = obj2Str(data);
  return await getAxios(`${serviceDefinitionPageUrl}?${params}`, 'GET');
}

export async function addServiceDefinition(data) {
  return await getAxios(`${serviceDefinitionUrl}`, 'POST', data);
}

export async function deleteServiceDefinition(id) {
  return await getAxios(`${serviceDefinitionUrl}/${id}`, 'DELETE');
}

export async function getServiceDefinition(id) {
  return await getAxios(`${serviceDefinitionUrl}/${id}`, 'GET');
}

export async function editServiceDefinition(id, data) {
  return await getAxios(`${serviceDefinitionUrl}/${id}`, 'PUT', data);
}

export async function getMonitorItems(data) {
  let params = obj2Str(data);
  return await getAxios(`${monitorItemsUrl}?${params}`, 'GET');
}

export async function getSoftVersionList(searchKey) {
  return await getAxios(`${fillingSoftwareUrl}/version/list?searchKey=${searchKey}`, 'GET');
}

export async function getFillingSoftList() {
  return await getAxios(`${fillingSoftwareUrl}/list`, 'GET');
}

export async function addFillingSoft(data) {
  return await getAxios(`${fillingSoftwareUrl}`, 'POST', data);
}

export async function editFillingSoft(id, data) {
  return await getAxios(`${fillingSoftwareUrl}/${id}`, 'PUT', data);
}

export async function getFillingSoftVersionList(data) {
  let params = obj2Str(data);
  return await getAxios(`${fillingSoftwareUrl}/version/page?${params}`, 'GET');
}

export async function addFillingSoftVersion(data) {
  return await getAxios(`${fillingSoftwareUrl}/version`, 'POST', data);
}

export async function editFillingSoftVersion(id, data) {
  return await getAxios(`${fillingSoftwareUrl}/version/${id}`, 'PUT', data);
}

export async function getFillingSoftVersionSubList(versionId) {
  return await getAxios(`${fillingSoftwareUrl}/version/sub/list?versionId=${versionId}`, 'GET');
}

export async function downloadFillingSoft(fileId) {
  window.open(`${fillingSoftwareUrl}/version/download/${fileId}`);
}

export async function joinDetailExport(data) {
  let params = obj2Str(data);
  if (params) {
    window.open(`${joinDetailUrl}/export?${params}`);
  }
}

export async function getJoinDetailList(pageSize, current, data) {
  let params = obj2Str(data);
  return await getAxios(`${joinDetailUrl}/${pageSize}/${current}?${params}`, 'GET');
}

export async function getDeviceResumeDetail(id) {
  return await getAxios(`${physicalDeviceUrl}/${id}/history`, 'GET');
}

export async function getDeviceResumeList(data) {
  let params = obj2Str(data);
  return await getAxios(`${physicalDeviceUrl}/page?${params}`, 'GET');
}

export async function reportEventExport(data) {
  let params = obj2Str(data);
  if (params) {
    window.open(`${reportEventUrl}/export?${params}`);
  }
}

export async function getReportEventList({pageSize, current}, data) {
  let params = obj2Str(data);
  return await getAxios(`${reportEventUrl}/page/${pageSize}/${current}?${params}`, 'GET');
}

export async function eventHistoryExport(data) {
  let params = obj2Str(data);
  if (params) {
    window.open(`${eventExportUrl}?${params}`);
  }
}

export async function getEventHistoryList({pageSize, current}, data) {
  let params = obj2Str(data);
  return await getAxios(`${eventHistoryPage}/${pageSize}/${current}?${params}`, 'GET');
}

export async function getBitInfoKeyList(deviceName, searchKey) {
  return await getAxios(`${bitInfo}/key/list/${deviceName}?searchKey=${searchKey}`, 'GET');
}

export async function addBitInfoKey(data) {
  return await getAxios(`${bitInfo}/key`, 'POST', data);
}

export async function deleteBitInfoKey(keyId) {
  return await getAxios(`${bitInfo}/key/${keyId}`, 'DELETE');
}

export async function getBitInfo(keyId) {
  return await getAxios(`${bitInfo}/data/${keyId}`, 'GET');
}

export async function editBitInfo(data) {
  return await getAxios(`${bitInfo}/save`, 'put', data);
}

export async function getTransferKeyList(deviceId, searchKey) {
  return await getAxios(`${transferKeyList}/list/${deviceId}?searchKey=${searchKey}`, 'GET');
}

export async function addTransferKey(data) {
  return await getAxios(`${transferKeyList}`, 'POST', data);
}

export async function deleteTransferKey(keyId) {
  return await getAxios(`${transferKeyList}/${keyId}`, 'DELETE');
}

export async function getTransferList(deviceKeysId) {
  return await getAxios(`${transferList}/list/${deviceKeysId}`, 'GET');
}

export async function addTransfer(data) {
  return await getAxios(`${transferList}`, 'POST', data);
}

export async function deleteTransfer(valueTransferId) {
  return await getAxios(`${transferList}/${valueTransferId}`, 'DELETE');
}

export async function setGroupSort(data) {
  return await getAxios(`${groupSort}`, 'PUT', data);
}

export async function getEventHistory(tailNo) {
  return await getAxios(`${eventHistory}/${tailNo}/4`, 'GET');
}

export async function getDeviceKeyList(searchKey) {
  return await getAxios(`${deviceKeySearch}?searchKey=${searchKey}`, 'GET');
}

export async function getMonitorEventInfo(id) {
  return await getAxios(`${monitorEvent}/${id}`, 'GET');
}

export async function getMonitorEventList({pageSize, current}, data) {
  let params = obj2Str(data);
  return await getAxios(`${monitorEvent}/${pageSize}/${current}?${params}`, 'GET');
}

export async function addMonitorEvent(data) {
  return await getAxios(`${monitorEvent}`, 'POST', data);
}

export async function editMonitorEvent(id, data) {
  return await getAxios(`${monitorEvent}/${id}`, 'PUT', data);
}

export async function deleteMonitorEvent(id) {
  return await getAxios(`${monitorEvent}/${id}`, 'DELETE');
}

export async function getMonitorLevel() {
  return await getAxios(`${monitorLevel}`, 'GET');
}

export async function addMonitorLevel(data) {
  return await getAxios(`${monitorLevel}`, 'POST', data);
}

export async function editMonitorLevel(id, data) {
  return await getAxios(`${monitorLevel}/${id}`, 'PUT', data);
}

export async function deleteMonitorLevel(id) {
  return await getAxios(`${monitorLevel}/${id}`, 'DELETE');
}

export async function getDeviceInfoList(data) {
  let params = obj2Str(data);
  if (params) {
    return await getAxios(`${NMS_DEVICE_INFO_LIST}?${params}`, 'GET');
  } else {
    return await getAxios(`${NMS_DEVICE_INFO_LIST}`, 'GET');
  }
}

export async function getAlarmDevices() {
  return await getAxios(`${NMS_GET_ALARM_DEVICES}`, 'GET');
}

//@deprecated
export async function getAlarmMonitoringByDeviceId(deviceId) {
  return await getAxios(
    `${NMS_GET_ALARM_MONITORING_BY_DEVICE_ID}?deviceId=${deviceId}`,
    'GET'
  );
}

//@deprecated
export async function updateAlarmMonitoringById(data) {
  let params = obj2Str(data);
  return await getAxios(`${NMS_UPDATE_ALARM_MONITORING_BY_ID}`, 'POST', params);
}

//@deprecated
export async function getAlarmTypeStatistics(data) {
  let params = obj2Str(data);
  if (params) {
    return await getAxios(`${NMS_GET_ALARM_TYPE_STATISTICS}?${params}`, 'GET');
  } else {
    return await getAxios(`${NMS_GET_ALARM_TYPE_STATISTICS}`, 'GET');
  }
}

//@deprecated
export async function getAlarmDeviceStatistics(data) {
  let params = obj2Str(data);
  if (params) {
    return await getAxios(
      `${NMS_GET_ALARM_DEVICE_STATISTICS}?${params}`,
      'GET'
    );
  } else {
    return await getAxios(`${NMS_GET_ALARM_DEVICE_STATISTICS}`, 'GET');
  }
}

//@deprecated
export async function getAlarmLevelStatistics(data) {
  let params = obj2Str(data);
  if (params) {
    return await getAxios(`${NMS_GET_ALARM_LEVEL_STATISTICS}?${params}`, 'GET');
  } else {
    return await getAxios(`${NMS_GET_ALARM_LEVEL_STATISTICS}`, 'GET');
  }
}

//@deprecated
export async function getAlarmRecord({pageSize, current}, data) {
  let params = obj2Str(data);
  if (params) {
    return await getAxios(
      `${NMS_GET_ALARM_RECORD}/${current}/${pageSize}?${params}`,
      'GET'
    );
  } else {
    return await getAxios(
      `${NMS_GET_ALARM_RECORD}/${current}/${pageSize}`,
      'GET'
    );
  }
}

//@deprecated
export async function batchConfirmAlarmRecord(params) {
  // let params = obj2Str(data)
  return await getAxios(`${NMS_BATCH_CONFIRM_ALARM_RECORD}`, 'POST', params);
}

//@deprecated
export async function getAlarmOperatingRecord({pageSize, current}, data) {
  let params = obj2Str(data);
  if (params) {
    return await getAxios(
      `${NMS_GET_ALARM_OPERATING_RECORD}/${current}/${pageSize}?${params}`,
      'GET'
    );
  } else {
    return await getAxios(
      `${NMS_GET_ALARM_OPERATING_RECORD}/${current}/${pageSize}`,
      'GET'
    );
  }
}

export async function getDeviceInfoCategoryList(params) {
  return await getAxios(
    `${NMS_CONFIG_GET_DEVICE_INFO_CATEGORY_LIST}`,
    'GET',
    params
  );
}

export async function addDeviceDictionary(params) {
  return await getAxios(`${NMS_DEVICE_DICTIONARY}`, 'POST', params);
}

export async function editDeviceDictionary(id, params) {
  return await getAxios(`${NMS_DEVICE_DICTIONARY}/${id}`, 'PUT', params);
}

export async function getDeviceDictionary({pageSize, current}) {
  return await getAxios(
    `${NMS_DEVICE_DICTIONARY}/${pageSize}/${current}`,
    'GET'
  );
}

export async function getAllDevice() {
  return await getAxios(`${NMS_DEVICE_DICTIONARY_GET_ALL_DEVICE}`, 'GET');
}

export async function getDeviceDictionaryGroup(deviceTypeId) {
  return await getAxios(
    `${NMS_DEVICE_DICTIONARY_GROUP}?deviceId=${deviceTypeId}`,
    'GET'
  );
}

export async function addDeviceDictionaryGroup(params) {
  return await getAxios(`${NMS_DEVICE_DICTIONARY_GROUP}`, 'POST', params);
}

export async function deleteDeviceDictionaryGroup(groupId) {
  return await getAxios(`${NMS_DEVICE_DICTIONARY_GROUP}/${groupId}`, 'DELETE');
}

export async function editDeviceDictionaryGroup(groupId, params) {
  return await getAxios(`${NMS_DEVICE_DICTIONARY_GROUP}/${groupId}`, 'PUT', params);
}

export async function searchKeyForDictionary({pageSize, current},
                                             deviceId,
                                             data) {
  let params = obj2Str(data);
  if (params) {
    return await getAxios(
      `${NMS_DEVICE_DICTIONARY_SEARCH_KEY}/${deviceId}/${pageSize}/${current}?${params}`,
      'GET'
    );
  } else {
    return await getAxios(
      `${NMS_DEVICE_DICTIONARY_SEARCH_KEY}/${deviceId}/${pageSize}/${current}`,
      'GET'
    );
  }
}

export async function getDeviceIndexsDictionary(typeName) {
  return await getAxios(
    `${NMS_DEVICE_DICTIONARY_GET_DEVICE_INDEX}?typeName=${encodeURIComponent(
      typeName
    )}`,
    'GET'
  );
}

export async function addDeviceIndexDictionary(data) {
  let params = obj2Str(data);
  return await getAxios(
    `${NMS_DEVICE_DICTIONARY_ADD_SUB_DEVICE}`,
    'POST',
    params
  );
}

export async function addDeviceDictionaryKey(params) {
  return await getAxios(`${NMS_DEVICE_DICTIONARY_ADD_KEY}`, 'POST', params);
}

export async function editDeviceDictionaryKey(keyId, params) {
  return await getAxios(
    `${NMS_DEVICE_DICTIONARY_ADD_KEY}/${keyId}`,
    'PUT',
    params
  );
}

export async function deleteDeviceDictionaryKey(data) {
  let params = obj2Str(data);
  return await getAxios(`${NMS_DEVICE_DICTIONARY_ADD_KEY}?${params}`, 'DELETE');
}

export async function getMonitorConfigGroup(deviceTypeId) {
  return await getAxios(`${NMS_MONITOR_CONFIG_GROUP}/${deviceTypeId}`, 'GET');
}

export async function addMonitorConfigGroup(params) {
  return await getAxios(`${NMS_MONITOR_CONFIG_GROUP}`, 'POST', params);
}

export async function editMonitorConfigGroup(groupId, data) {
  let params = obj2Str(data);
  return await getAxios(
    `${NMS_MONITOR_CONFIG_GROUP}/${groupId}?${params}`,
    'PUT'
  );
}

export async function deleteMonitorConfigGroup(groupId) {
  return await getAxios(`${NMS_MONITOR_CONFIG_GROUP}/${groupId}`, 'DELETE');
}

export async function addKeyToMonitorGroup(groupId, data) {
  let params = obj2Str(data);
  return await getAxios(
    `${NMS_MONITOR_CONFIG_GROUP_ITEM}/${groupId}`,
    'POST',
    params
  );
}

export async function removeKeyToMonitorGroup(groupId, data) {
  let params = obj2Str(data);
  return await getAxios(
    `${NMS_MONITOR_CONFIG_GROUP_ITEM}/${groupId}?${params}`,
    'DELETE'
  );
}

//获取监控设置配置项
export async function getKeyItemsByMonitorGroup(deviceType, groupId, data) {
  let params = obj2Str(data);
  if (params) {
    return await getAxios(
      `${NMS_MONITOR_CONFIG_ITEMS}/${deviceType}/${groupId}?${params}`,
      'GET'
    );
  } else {
    return await getAxios(
      `${NMS_MONITOR_CONFIG_ITEMS}/${deviceType}/${groupId}`,
      'GET'
    );
  }
}

export async function addValueToKey(data) {
  return await getAxios(`${NMS_MONITOR_CONFIG_KEY_VALUE}`, 'POST', data);
}

export async function editValueToKey(valueId, data) {
  return await getAxios(
    `${NMS_MONITOR_CONFIG_KEY_VALUE}/${valueId}`,
    'PUT',
    data
  );
}

export async function removeValueToKey(valueId) {
  return await getAxios(`${NMS_MONITOR_CONFIG_KEY_VALUE}/${valueId}`, 'DELETE');
}

export async function getMonitorExceptionList({pageSize, current}, deviceType, params) {
  let moreParams = ''
  if (params) {
    moreParams = `&searchKey=${params.searchKey}&beginTime=${params.beginDate}&endTime=${params.endDate}&tailNo=${params.tailNo}`
  }
  return await getAxios(
    `${NMS_MONITOR_ALARM_HISTORY}/${pageSize}/${current}?deviceTypeName=${encodeURIComponent(
      deviceType
    )}${moreParams}`,
    'GET'
  );
}

export async function getPlaneUnionOverview(data) {
  let params = obj2Str(data);
  return await getAxios(`${NMS_MONITOR_PLANE_unionOVERVIEW}?${params}`, 'GET');
}

export async function getPlaneView(data) {
  let params = obj2Str(data);
  return await getAxios(`${NMS_MONITOR_PLANE_OVERVIEW}?${params}`, 'GET');
}

export async function getPlaneDeviceListByTailNo(tailNo) {
  return await getAxios(`${NMS_MONITOR_PLANE_OVERVIEW}/${tailNo}`, 'GET');
}

export async function getPlaneInfo(tailNum) {
  return await getAxios(`${NMS_GET_PLANE_INFO}/${tailNum}`, 'GET');
}

export async function getDevicesOfPlane(tailNum) {
  return await getAxios(
    `${NMS_GET_DEVICES_OF_PLANE}?tailNum=${tailNum}`,
    'GET'
  );
}

export async function getDevicesInfoOfPlane(tailNum, deviceTypeName) {
  return await getAxios(
    `${NMS_GET_DEVICES_INFO_OF_PLANE}/${tailNum}/${deviceTypeName}?groupType=SYSTEM_INFO`,
    'GET'
  );
}

export async function getDetectionOfDevice(tailNum, deviceTypeName) {
  return await getAxios(
    `${NMS_GET_DETECTION_OF_DEVICE}/${tailNum}/${deviceTypeName}`,
    'GET'
  );
}

export async function getResolutionOfDevice(tailNum, deviceName, monitorKey, monitorValue) {
  return await getAxios(
    `${NMS_GET_RESOLUTION_OF_DEVICE}/8/1?tailNo=${tailNum}&deviceName=${deviceName}&monitorKey=${monitorKey}&monitorValue=${monitorValue}`,
    'GET'
  );
}

export async function setDeviceSortIndex(params) {
  return await getAxios(`${SET_DEVICE_SORT}`, 'PUT', params);
}

export async function setDeviceKesSortIndex(params) {
  return await getAxios(`${SET_DEVICE_KES_SORT}`, 'PUT', params);
}

export async function getRefittingTailNos() {
  return await getAxios(`${NMS_GET_REFIT_TAIL_NOS}`, 'GET');
}

export async function getRefittingDevices(tailNo) {
  return await getAxios(`${NMS_GET_REFIT_DEVICES}/${tailNo}`, 'GET');
}

export async function getRunRecordList(pageNum, pageSize, data) {
  let params = obj2Str(data);
  return await getAxios(`${NMS_GET_RUN_RECORD_LIST}/${pageSize}/${pageNum ? pageNum : 1}?${params}`, 'GET');
}

export async function getDeviceDictionaryList(deviceId) {
  return await getAxios(`${NMS_GET_DEVICE_DICTIONARY_LIST}/${deviceId}`, 'GET');
}

export async function getAltitudeAndNet(data) {
  let params = obj2Str(data);
  return await getAxios(`${NMS_ALTITUDE_NET}?${params}`, 'GET');
}

export async function getAllDeviceInfo(data) {
  let params = obj2Str(data);
  return await getAxios(`${NMS_ALL_DEVICE_INFO}?${params}`, 'GET');
}

export async function getRunRecordFile(data) {
  let params = obj2Str(data);
  window.open(`${NMS_RUN_RECORD_DOWNLOAD}?${params}`)
}

export function getNetSpeedList(tailNo) {
  return getAxios(`${NMS_NET_SPEED}?tailNo=${tailNo}`, 'GET')
}

export function getTimeNetSpeedList(data) {
  let params = obj2Str(data);
  return getAxios(`${NMS_NET_SPEED_TIME}?${params}`, 'GET')
}

export function getMonitorAlarmList(data) {
  let params = obj2Str(data);
  return getAxios(`${NMS_MONITOR_ALARM}?${params}`, 'GET')
}

export function getMonitorAlarmDeviceList(tailNo, time) {
  return getAxios(`${NMS_MONITOR_ALARM_DEVICES}/${tailNo}/${time}`, 'GET')
}

export function getMonitorAlarmDeviceDetail(tailNo, deviceName, time) {
  return getAxios(`${NMS_MONITOR_ALARM_DETAIL}/${tailNo}/${deviceName}/${time}`, 'GET')
}

export function getMonitorAlarmDeviceInfo(tailNo, deviceName, time) {
  return getAxios(`${NMS_MONITOR_ALARM_INFO}/${tailNo}/${deviceName}/${time}`, 'GET')
}

export function getMonitorAlarmChart(tailNo, deviceName, key, time) {
  return getAxios(`${NMS_MONITOR_ALARM_CHART}/${tailNo}/${deviceName}/${key}/${time}?paramAlias=${key}`, 'GET')
}

export function getMonitorAlarmChartByKey(tailNo, deviceName, key, time) {
  return getAxios(`${NMS_MONITOR_ALARM_CHART}/${tailNo}/${deviceName}/${key}/${time}`, 'GET')
}

export function getMonitorKeyDevice(deviceName, searchKey) {
  return getAxios(`${NMS_MONITOR_ALL_KEY}?deviceName=${deviceName}&searchKey=${searchKey}`, 'GET')
}

export function getMonitorAllKey(searchKey) {
  return getAxios(`${NMS_MONITOR_ALL_KEY}?searchKey=${searchKey}`, 'GET')
}

export function getMonitorChart(deviceName, key, data) {
  data.paramAlias = key
  let params = obj2Str(data);
  return getAxios(`${NMS_MONITOR_ALARM_CHART}/${deviceName}/${key}?${params}`, 'GET')
}

export function getMonitorChartByKey(deviceName, key, data) {
  let params = obj2Str(data);
  return getAxios(`${NMS_MONITOR_ALARM_CHART}/${deviceName}/${key}?${params}`, 'GET')
}

export function getAlarmCount(tailNo, data) {
  let params = obj2Str(data);
  return getAxios(`${NMS_ALARM_COUNT}/${tailNo}?${params}`, 'GET')
}

export function getAlarmNetwork(tailNo, data) {
  let params = obj2Str(data);
  return getAxios(`${NMS_ALARM_NETWORK}/${tailNo}?${params}`, 'GET')
}

export function getBeamSwitchDetails(data) {
  let params = obj2Str(data);
  return getAxios(`${beamSwitchDetails}?${params}`, 'GET');
}

export function getDevicePower(data) {
  let params = obj2Str(data);
  return getAxios(`${devicePower}?${params}`, 'GET');
}

export function getHardware(data) {
  let params = obj2Str(data);
  return getAxios(`${hardware}?${params}`, 'GET');
}

export function getSoftware(data) {
  let params = obj2Str(data);
  return getAxios(`${software}?${params}`, 'GET');
}

export function getDiskUsageGns(data) {
  let params = obj2Str(data);
  return getAxios(`${diskUsageGns}?${params}`, 'GET');
}

export function getDiskUsageCwap1(data) {
  let params = obj2Str(data);
  return getAxios(`${diskUsageCwap1}?${params}`, 'GET');
}

export function getDiskUsageCwap2(data) {
  let params = obj2Str(data);
  return getAxios(`${diskUsageCwap2}?${params}`, 'GET');
}
