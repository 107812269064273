export default {
  'rbacOrganization.title': 'Organization',

  'rbacOrganization.search': 'Keywords...',
  'rbacOrganization.add': 'Add',
  'rbacOrganization.editOrganization': 'Edit',
  'rbacOrganization.delete': 'Delete',
  'rbacOrganization.edit': 'Edit',

  'rbacOrganization.name': 'Name',
  'rbacOrganization.industry': 'Industry',
  'rbacOrganization.address': 'Address',
  'rbacOrganization.updateTime': 'Update time',
  'rbacOrganization.operation': 'Operation',
  'rbacOrganization.orgType': 'Org type',
  'rbacOrganization.code': 'code',

  'rbacOrganization.wantDelete': 'You will not be able to recover after deleting. Are you sure you want to delete?',

  'rbacOrganization.cancel': 'Cancel',
  'rbacOrganization.save': 'Save',
};
