export default {
  'opsDashboard.title': 'Ops Dashboard',

  'opsDashboard.query': 'Search',
  'opsDashboard.orgInChargeSearch': 'Org In Charge...',
  'opsDashboard.tailNoSearch': 'Tail No...',

  'opsDashboard.FATAL': 'FATAL',
  'opsDashboard.SERIOUS': 'SERIOUS',
  'opsDashboard.WARN': 'WARN',
  'opsDashboard.CRITICAL': 'CRITICAL',
  'opsDashboard.ERROR': 'ERROR',
  'opsDashboard.ALARM': 'ALARM',
  'opsDashboard.ATTENTION': 'ATTENTION',
  'opsDashboard.NOTIFICATION': 'NOTIFICATION',
  'opsDashboard.DEBUG': 'DEBUG',

  'opsDashboard.PENDING': 'PENDING',
  'opsDashboard.PROCESSING': 'PROCESSING',
  'opsDashboard.UNVERIFIED': 'UNVERIFIED',
  'opsDashboard.SOLVED': 'SOLVED',
  'opsDashboard.CLOSED': 'CLOSED',

  'opsDashboard.count': 'Count',
  'opsDashboard.planeCount': 'Plane Count',
  'opsDashboard.flightCount': 'Flight Count',
  'opsDashboard.unsolvedCount': 'Unsolved Count',

  'opsDashboard.levelDistribution': 'Level Distribution',
  'opsDashboard.internetPercent': 'Internet Percent',
  'opsDashboard.orgInChargePercent': 'Org In Charge Percent',
  'opsDashboard.timeDistribution': 'Time Distribution',
  'opsDashboard.top': 'TOP 10',

  'opsDashboard.DAY': 'DAY',
  'opsDashboard.WEEK': 'WEEK',
  'opsDashboard.MONTH': 'MONTH',
  'opsDashboard.QUARTER': 'QUARTER',
  'opsDashboard.YEAR': 'YEAR',
}