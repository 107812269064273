export default {
  'rbacUsersForm.pageFunctionDescription': 'System software, catalog, menu configuration and definition',
  'rbacUsersForm.add': 'Add',
  'rbacUsersForm.edit': 'Edit',

  'rbacUsersForm.type': 'type',
  'rbacUsersForm.contents': 'content',
  'rbacUsersForm.menus': 'menu',
  'rbacUsersForm.displayName': 'display name',
  'rbacUsersForm.icon': 'Icon',
  'rbacUsersForm.higherLevel': 'higher level',
  'rbacUsersForm.sortNum': 'sort number',
  'rbacUsersForm.connectionAddress': 'connection address',
  'rbacUsersForm.authorizationSign': 'authorization sign',
  'rbacUsersForm.cancel': 'Cancel',
  'rbacUsersForm.save': 'Save',

  'rbacUsersForm.autoGet': 'Auto retrieve',

  'rbacUsersForm.success': 'Successful',
  'rbacUsersForm.pleaseEnterName': 'Please select name',
  'rbacUsersForm.pleaseSelectParentDirectory': 'Please select parent directory',
};
