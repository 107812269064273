export default {
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.monitor': 'Data Monitor',
  'menu.nms.manage': 'NMS',
  'menu.nms.aircrafts': 'Aircraft List',
  'menu.nms.monitoring': 'Monitoring',
  'menu.nms.maintain': 'Maintenance',
  'menu.nms.dataDownload': 'Data Download',
  'menu.nms.dictionarySettings': 'Monitoring Setting',
  'menu.nms.deviceDataReport': 'Device Report',
  'menu.nms.dictionary': 'Device Dictionary',
  'menu.nms.warnings': 'Device Alarm',
  'menu.ias.manage': 'IAS',
  'menu.ias.dataOrder': 'Order List',
  'menu.ias.userList': 'User List',
  'menu.ias.dataPackage': 'Data Package',
  'menu.ias.accessControl': 'Access Control',
  'menu.ias.addPackage': 'Create Package',
  'menu.ias.flightsList': 'Flights List',
  'menu.cms.manage': 'CMS',
  'menu.cms.video': 'Video',
  'menu.cms.video.category': 'Video Category',
  'menu.cms.video.version': 'Video Version',
  'menu.cms.video.resource': 'Video Resource',
  'menu.cms.aircraftConfig': 'Aircraft Config',
  'menu.cms.recommendedManage': 'Recommended Manage',
  'menu.cms.advertisingManage': 'Advertising Manage',
  'menu.cms.e-commerceManage': 'E-commerceManage',
  'menu.cms.operationReport': 'Operation Report',
  'menu.cms.userManage': 'UserManage',
  'menu.cms.download': 'Download',
  'menu.passenger.manage': 'Passenger',
  'menu.passenger.accessLog': 'Behavior log',
  'menu.passenger.loginLog': 'Auth Log',
  'menu.passenger.regisiterLog': 'Register Log',
  'menu.passenger.virtual': 'Virtual Passenger',
  'menu.passenger.callRecord': 'Call Record',
  'menu.data.accessAnalysis': 'Access Analysis',
  'menu.data.flowAnalysis': 'Flow Analysis',
  'menu.data.traceBack': 'Trace Back',
  'menu.flights.manage': 'Aircraft',
  'menu.flights.flight': 'Flight History',
  'menu.flights.aircraftManage': 'Aircraft',
  'menu.flights.aircraftType': 'Aircraft Type',
  'menu.data.analysis': 'Data Analysis',
  'menu.data.dashboardIfec': 'Flight Map',
  'menu.data.dashboardNms': 'DashboardNms',
  'menu.system.config': 'System Config',
  'menu.system.authorization': 'Authorization',
  'menu.system.dataSource': 'DataSource',
  'menu.system.notification': 'Notification',
  'menu.version': 'Version',
  'menu.admin.permission': 'Permissions',
  'menu.admin.account': 'Account',
  'menu.admin.function': 'Function',
  'menu.personalCenter': 'Personal Center',
  'menu.settings.messageDefinition': 'Notification Setting',
  'menu.admin.role': 'Roles Manages',
  'menu.softwareVersion': 'Software Version',
  'menu.mockTest': 'Mock Test',
  'menu.mockTest.takeoffLanding': 'Takeoff & Landing',
  'menu.satcom.stationWarning': 'Station Warning',
  'menu.airlines.flightPlan': 'Flight Plan',
  'menu.passenger.netList': 'Internet Passenger',
  'menu.data.package': 'Package',
  'menu.nms.monitorEvent': 'Monitor Event',
  'menu.airlines.filling': 'Filling Software',
  'menu.data.eventStatistics': 'Event Statistics',
  'menu.airlines.scheme': 'Scheme',
  'menu.airlines.preinstall': 'Pre-install',
  'menu.resume': 'Device Resume',
  'menu.nms.join': 'Join Detail',
  'menu.rbac.organization': 'Organization',
  'menu.passenger.orgConfig': 'Org Config',
  'menu.ops.manage': 'Mocha ITOM',
  'menu.ops.dashboard': 'OPS Dashboard',
  'menu.ops.question': 'OPS Question',
  'menu.ops.config': 'OPS Config',
  'menu.nms.alarm.troubleshoot': 'Troubleshoot',
  'menu.data.trace-back-details': 'Trace back details',
  'menu.log.manage': 'Log Manage',
  'menu.log.oplog': 'Op-log',
  'menu.ias.aaa': 'AAA List',
  'menu.toolkit.analysis': 'Analysis',
  'menu.toolkit.source': 'Data Source',
  'menu.toolkit.interface': 'Data Interface',
  'menu.ias.airport': 'Airport',
  'menu.toolkit.chart': 'Chart',
  'menu.toolkit.panel': 'Panel',
  'menu.nms.service.definition': 'Service Definition',
  'menu.nms.service.setting': 'Service Setting',
  'menu.nms.service.test': 'Service Test',
  'menu.nms.alarm.service': 'Service Alarm',
  'menu.nms.apply.config': 'Apply Config',
  'menu.nms.apply.config.form': 'Apply Config Form',
  'menu.nms.detailed.log': 'Detailed',
  'menu.nms.point.station': 'Station Config',
  'menu.nms.recordTime': 'Record Time',
  'menu.nms.device.maintain.view': 'Modification Details',
  'menu.rbac.page': 'Page Manage',
};
