export default {
  'nmsDetailedLog.title': '飞行记录',

  'nmsDetailedLog.altitude': '飞行高度与网络状态',
  'nmsDetailedLog.alarmStatistics': '告警统计',
  'nmsDetailedLog.networkStatus': '网络状态',
  'nmsDetailedLog.numberPeople': '在线人数',
  'nmsDetailedLog.stationStatus': '小站状态',
  'nmsDetailedLog.detailedRecordSwitchedTo': '以下参数状态状态已切换至',
  'nmsDetailedLog.alarmStatisticsExp': '从飞机改装到现在，查看连续时间点上各设备的 Bit信息非正常项数量',
  'nmsDetailedLog.networkStatusExp': '网络状态',
  'nmsDetailedLog.Beam': '波束变化曲线',
  'nmsDetailedLog.SatelliteConnectionStatus': '卫星连接状态',
  'nmsDetailedLog.CPUUsage': '每台设备的CPU使用率变化曲线',
  'nmsDetailedLog.RAMUsage': '每台设备的内存使用率变化曲线',


  'nmsDetailedLog.last5m': '最近5分钟',
  'nmsDetailedLog.last10m': '最近10分钟',
  'nmsDetailedLog.last30m': '最近30分钟',
  'nmsDetailedLog.last1h': '最近1小时',
  'nmsDetailedLog.last2h': '最近2小时',
  'nmsDetailedLog.last4h': '最近4小时',
  'nmsDetailedLog.last6h': '最近6小时',
  'nmsDetailedLog.last24h': '最近24小时',
  'nmsDetailedLog.last7d': '最近7天',
  'nmsDetailedLog.custom': '自定义',
};