import React from "react";
import withAuthRouter from "components/WithAuthRouter";
import {Route} from "react-router-dom";
import CacheRoute, {CacheSwitch} from 'react-router-cache-route'

import asyncComponent from "./asyncComponent";

const DashboardIfec = asyncComponent(() => import('app/pages/dashboard-ifec'));
const IASCommodityList = asyncComponent(() => import('app/pages/commodity-table'));
const IASControl = asyncComponent(() => import('app/pages/ias-access-control'));
const IASOrderLog = asyncComponent(() => import('app/pages/ias-order-logs'));
const IASNetList = asyncComponent(() => import('app/pages/ias-net-list'));
const IASNetRealTimeCost = asyncComponent(() => import('app/pages/ias-net-real-time-cost'));
const PassengerAccessLog = asyncComponent(() => import('app/pages/passenger-access-log'));
const PassengerRegisterLog = asyncComponent(() => import('app/pages/passenger-register-log'));
const PassengerLoginLog = asyncComponent(() => import('app/pages/passenger-login-log'));
const PassengerVirtual = asyncComponent(() => import('app/pages/passenger-virtual'));
const PassengerVirtualForm = asyncComponent(() => import('app/pages/passenger-virtual-form'));
const SettingsFlight = asyncComponent(() => import('app/pages/settings-flight'));
const ErrorMessage = asyncComponent(() => import('app/pages/error-message'));
const CommodityForm = asyncComponent(() => import('app/pages/commodity-form'));
const CommodityFormAdd = asyncComponent(() => import('app/pages/commodity-form-add'));
const CommodityInfo = asyncComponent(() => import('app/pages/commodity-info'));
const Version = asyncComponent(() => import('app/pages/version'));
const SettingsAddClient = asyncComponent(() => import('app/pages/settings-add-client'));
const SettingsAddAircraftType = asyncComponent(() => import('app/pages/settings-add-aircraft-type'));
const SettingsNotification = asyncComponent(() => import('app/pages/settings-notification'));
const SettingsClient = asyncComponent(() => import('app/pages/settings-client'));
const SettingsAircraftType = asyncComponent(() => import('app/pages/settings-aircraft-type'));
const IASOrderInfo = asyncComponent(() => import('app/pages/ias-order-info'));
const IASUserInfo = asyncComponent(() => import('app/pages/ias-user-info'));
const RbacMenus = asyncComponent(() => import('app/pages/rbac-menus'));
const RbacUsers = asyncComponent(() => import('app/pages/rbac-users'));
const RbacUsersAdd = asyncComponent(() => import('app/pages/rbac-users-form'));
const RbacMenuAdd = asyncComponent(() => import('app/pages/rbac-menu-form'));
const NmsDeviceDictionary = asyncComponent(() => import('app/pages/nms-device-dictionary'));
const NmsDeviceDictionaryInfo = asyncComponent(() => import('app/pages/nms-device-dictionary-info'));
const NmsDeviceMonltoring = asyncComponent(() => import('app/pages/nms-device-monitoring'));
const NmsRecord = asyncComponent(() => import('app/pages/nms-record'));
const NmsRecordRealTime = asyncComponent(() => import('app/pages/nms-record-real-time'));
const DeviceExceptionMonitor = asyncComponent(() => import('app/pages/nms-exception-monitor'));
const AircraftsList = asyncComponent(() => import('app/pages/nms-device-aircrafts'));
const DeviceMaintainList = asyncComponent(() => import('app/pages/nms-device-maintain'));
const DeviceMaintainWmt = asyncComponent(() => import('app/pages/nms-device-maintain-wmt'));
const DeviceMaintainSettingEdit = asyncComponent(() => import('app/pages/nms-device-maintain-setting-edit'));
const DeviceMaintainSetting = asyncComponent(() => import('app/pages/nms-device-maintain-setting'));
const aircraftManageTable = asyncComponent(() => import('app/pages/aircraft-manage-table'));
const aircraftManageForm = asyncComponent(() => import('app/pages/aircraft-manage-form'));
const DeviceDataReport = asyncComponent(() => import('app/pages/nms-device-data-report'));
const PersonalCenter = asyncComponent(() => import('app/pages/personal-center'));
const MockTakeOffLanding = asyncComponent(() => import('app/pages/mock-takeoff-landing'));
const SettingsMessageDefinition = asyncComponent(() => import('./app/pages/settings-message-definition'));
const TrafficSnapshot = asyncComponent(() => import('./app/pages/ias-traffic-snapshot'));
const RolesManage = asyncComponent(() => import('app/pages/ams-roles-manage'));
const RolesManageForm = asyncComponent(() => import('app/pages/ams-roles-manage-form'));
const iasFlightsList = asyncComponent(() => import('./app/pages/ias-flights-list'));
const PassengerOnlineList = asyncComponent(() => import('./app/pages/ias-passenger-online-list'));
const PassengerOnlineDetails = asyncComponent(() => import('./app/pages/ias-passenger-online-details'));
const NmsPointStation = asyncComponent(() => import('./app/pages/nms-point-station'));
const NmsDeviceWarningPage = asyncComponent(() => import('./app/pages/nms-device-warning-page'));
const NmsAlarmTroubleshooting = asyncComponent(() => import('./app/pages/nms-alarm-troubleshooting'));
const DashboardMonitor = asyncComponent(() => import('./app/pages/dashboard-monitor'));
const NMSDetailedLog = asyncComponent(() => import('./app/pages/nms-detailed-log'));
const passengerCallRecord = asyncComponent(() => import('./app/pages/passenger-call-record'));
const NmsFlightList = asyncComponent(() => import('./app/pages/nms-flight-list'));
const NmsReportOperate = asyncComponent(() => import('./app/pages/nms-report-operate'));
const NmsReportRun = asyncComponent(() => import('./app/pages/nms-report-run'));
const dataAccessAnalysis = asyncComponent(() => import('./app/pages/data-access-analysis'));
const dataFlowAnalysis = asyncComponent(() => import('./app/pages/data-flow-analysis'));
const dataTraceBack = asyncComponent(() => import('./app/pages/data-trace-back'));
const dataTraceBackDetails = asyncComponent(() => import('./app/pages/data-trace-back-details'));
const satcomStationWarning = asyncComponent(() => import('./app/pages/satcom-station-warning'));
const airlinesFlightPlan = asyncComponent(() => import('./app/pages/airlines-flight-plan'));
const passengerNetList = asyncComponent(() => import('./app/pages/passenger-net-list'));
const dataPackage = asyncComponent(() => import('./app/pages/data-package'));
const nmsMonitorEvent = asyncComponent(() => import('./app/pages/nms-monitor-event'));
const nmsMonitorEventForm = asyncComponent(() => import('./app/pages/nms-monitor-event-form'));
const nmsAlarmLevel = asyncComponent(() => import('./app/pages/nms-alarm-level'));
const nmsBitInfo = asyncComponent(() => import('./app/pages/nms-device-bit-info'));
const nmsValueTransfer = asyncComponent(() => import('./app/pages/nms-device-value-transfer'));
const nmsEventHistory = asyncComponent(() => import('./app/pages/nms-event-history'));
const airlinesFillingList = asyncComponent(() => import('./app/pages/airlines-filling-list'));
const airlinesFillingVersionList = asyncComponent(() => import('./app/pages/airlines-filling-version-list'));
const airlinesFillingVersionInfo = asyncComponent(() => import('./app/pages/airlines-filling-version-info'));
const airlinesFillingVersionForm = asyncComponent(() => import('./app/pages/airlines-filling-version-form'));
const dataEventStatistics = asyncComponent(() => import('./app/pages/data-event-statistics'));
const airlinesScheme = asyncComponent(() => import('./app/pages/airlines-scheme'));
const airlinesPreinstallForm = asyncComponent(() => import('./app/pages/airlines-preinstall-form'));//
const nmsResume = asyncComponent(() => import('./app/pages/nms-resume'));
const nmsResumeDetails = asyncComponent(() => import('./app/pages/nms-resume-details'));
const nmsJoin = asyncComponent(() => import('./app/pages/nms-join'));
const rbacOrganization = asyncComponent(() => import('./app/pages/rbac-organization'));
const rbacOrganizationForm = asyncComponent(() => import('./app/pages/rbac-organization-form'));
const rbacUsersDetails = asyncComponent(() => import('./app/pages/rbac-users-details'));
const passengerOrgConfig = asyncComponent(() => import('./app/pages/passenger-org-config'));
const passengerOrgConfigKey = asyncComponent(() => import('./app/pages/passenger-org-config-form-key'));
const passengerOrgConfigServer = asyncComponent(() => import('./app/pages/passenger-org-config-form-server'));
const opsConfig = asyncComponent(() => import('./app/pages/ops-config'));
const opsConfigFormTag = asyncComponent(() => import('./app/pages/ops-config-form-tag'));
const airLineMap = asyncComponent(() => import('./app/pages/air-line-map'));
const opsQuestion = asyncComponent(() => import('./app/pages/ops-question'));
const opsQuestionForm = asyncComponent(() => import('./app/pages/ops-question-form'));
const opsQuestionDetails = asyncComponent(() => import('./app/pages/ops-question-details'));
const opsDashboard = asyncComponent(() => import('./app/pages/ops-dashboard'));
const RbacPage = asyncComponent(() => import('app/pages/rbac-page'));
const RbacPageForm = asyncComponent(() => import('app/pages/rbac-page-form'));
const logOplog = asyncComponent(() => import('app/pages/log-oplog'));
const iasAaa = asyncComponent(() => import('app/pages/ias-aaa'));
const iasAaaDetails = asyncComponent(() => import('app/pages/ias-aaa-details'));
const toolkitSource = asyncComponent(() => import('app/pages/toolkit-source'));
const toolkitSourceForm = asyncComponent(() => import('app/pages/toolkit-source-form'));
const toolkitInterface = asyncComponent(() => import('app/pages/toolkit-interface'));
const toolkitInterfaceForm = asyncComponent(() => import('app/pages/toolkit-interface-form'));
const toolkitInterfaceTest = asyncComponent(() => import('app/pages/toolkit-interface-test'));
const iasAirport = asyncComponent(() => import('app/pages/ias-airport'));
const iasAirportForm = asyncComponent(() => import('app/pages/ias-airport-form'));
const toolkitChart = asyncComponent(() => import('app/pages/toolkit-chart'));
const toolkitChartForm = asyncComponent(() => import('app/pages/toolkit-chart-form'));
const toolkitChartPreview = asyncComponent(() => import('app/pages/toolkit-chart-preview'));
const toolkitPanel = asyncComponent(() => import('app/pages/toolkit-panel'));
const toolkitPanelForm = asyncComponent(() => import('app/pages/toolkit-panel-form'));
const toolkitPanelPreview = asyncComponent(() => import('app/pages/toolkit-panel-preview'));
const nmsServiceDefinition = asyncComponent(() => import('app/pages/nms-service-definition'));
const nmsServiceDefinitionForm = asyncComponent(() => import('app/pages/nms-service-definition-form'));
const nmsServiceSetting = asyncComponent(() => import('app/pages/nms-service-setting'));
const nmsServiceSettingForm = asyncComponent(() => import('app/pages/nms-service-setting-form'));
const nmsServiceTest = asyncComponent(() => import('app/pages/nms-service-test'));
const nmsAlarmService = asyncComponent(() => import('app/pages/nms-alarm-service'));//
const nmsApplyConfig = asyncComponent(() => import('app/pages/nms-apply-config'));
const nmsApplyConfigForm = asyncComponent(() => import('app/pages/nms-apply-config-form'));

const SettingsFlightWrapper = withAuthRouter(SettingsFlight);

class AppRouter extends React.Component {
  render() {
    return (
      <CacheSwitch style={{height: "100%"}}>
        <Route path={"/app/nms/apply-config-form/:id/:model"} exact component={nmsApplyConfigForm}/>
        <Route path={"/app/nms/apply-config"} exact component={nmsApplyConfig}/>
        <Route path={`/app/nms/alarm/service/:tailNo/:time`} exact component={nmsAlarmService}/>
        <Route path={"/app/nms/service/test/:tailNo"} exact component={nmsServiceTest}/>
        <Route path={"/app/nms/service/setting"} exact component={nmsServiceSetting}/>
        <Route path={"/app/nms/service/setting-form/:id"} exact component={nmsServiceSettingForm}/>
        <Route path={"/app/nms/service/definition"} exact component={nmsServiceDefinition}/>
        <Route path={"/app/nms/service/definition-form/:id"} exact component={nmsServiceDefinitionForm}/>
        <Route path={"/app/toolkit/panel"} exact component={toolkitPanel}/>
        <Route path={"/app/toolkit/panel-form/:id"} exact component={toolkitPanelForm}/>
        <Route path={"/app/toolkit/panel-preview/:id"} exact component={toolkitPanelPreview}/>
        <Route path={"/app/toolkit/chart"} exact component={toolkitChart}/>
        <Route path={"/app/toolkit/chart-form/:id"} exact component={toolkitChartForm}/>
        <Route path={"/app/toolkit/chart-preview/:id"} exact component={toolkitChartPreview}/>
        <Route path={"/app/ias/airport"} exact component={iasAirport}/>
        <Route path={"/app/ias/airport-form/:id"} exact component={iasAirportForm}/>
        <Route path={"/app/toolkit/interface"} exact component={toolkitInterface}/>
        <Route path={"/app/toolkit/interface-form/:id"} exact component={toolkitInterfaceForm}/>
        <Route path={"/app/toolkit/interface-test/:id"} exact component={toolkitInterfaceTest}/>
        <Route path={"/app/toolkit/source"} exact component={toolkitSource}/>
        <Route path={"/app/toolkit/source-form/:id"} exact component={toolkitSourceForm}/>
        <CacheRoute path={"/app/ias/aaa"} exact component={iasAaa}/>
        <Route path={"/app/ias/aaa-details/:accNum/:flightId"} exact component={iasAaaDetails}/>
        <Route path={"/app/log/oplog"} exact component={logOplog}/>
        <Route path={"/app/rbac/page"} exact component={RbacPage}/>
        <Route path={"/app/rbac/page-form/:model"} exact component={RbacPageForm}/>
        <CacheRoute path={"/app/ops/dashboard"} exact component={opsDashboard}/>
        <Route path={"/app/ops/question-details/:id"} exact component={opsQuestionDetails}/>
        <Route path={"/app/ops/question-form/:model"} exact component={opsQuestionForm}/>
        <Route path={"/app/ops/question"} exact component={opsQuestion}/>
        <Route path={"/app/ops/config-form-tag/:model"} exact component={opsConfigFormTag}/>
        <Route path={"/app/ops/config"} exact component={opsConfig}/>
        <Route path={"/app/airLineMap"} exact component={airLineMap}/>
        <Route path={"/app/passenger/org-config-form-key/:model"} exact component={passengerOrgConfigKey}/>
        <Route path={"/app/passenger/org-config-form-server/:model"} exact component={passengerOrgConfigServer}/>
        <Route path={"/app/passenger/org-config"} exact component={passengerOrgConfig}/>
        <Route path={"/app/rbac/usersDetails/:id"} exact component={rbacUsersDetails}/>
        <Route path={"/app/rbac/organization/:model"} exact component={rbacOrganizationForm}/>
        <Route path={"/app/rbac/organization"} exact component={rbacOrganization}/>
        <CacheRoute path={"/app/nms/join"} exact component={nmsJoin}/>
        <Route path={"/app/nms/nmsResume"} exact component={nmsResume}/>
        <Route path={"/app/nms/nmsResume/:id"} exact component={nmsResumeDetails}/>
        <Route path={"/app/airlines/preinstall"} exact component={airlinesPreinstallForm}/>
        <Route path={"/app/airlines/scheme"} exact component={airlinesScheme}/>
        <CacheRoute path={"/app/data/eventStatistics"} exact component={dataEventStatistics}/>
        <Route path={"/app/airlines/filling-list"} exact component={airlinesFillingList}/>
        <Route path={"/app/airlines/filling-version-list/:fillingSoftwareId"} exact
               component={airlinesFillingVersionList}/>
        <Route path={"/app/airlines/filling-version-info/:versionId"} exact component={airlinesFillingVersionInfo}/>
        <Route path={"/app/airlines/filling-version-form/:model"} exact component={airlinesFillingVersionForm}/>
        <Route path={"/app/nms/event-history"} exact component={nmsEventHistory}/>
        <Route path={"/app/nms/monitor-event"} exact component={nmsMonitorEvent}/>
        <Route path={"/app/nms/monitor-event/:model"} exact component={nmsMonitorEventForm}/>
        <Route path={"/app/nms/alarm-level"} exact component={nmsAlarmLevel}/>
        <Route path={"/app/airlines/flight-plan"} exact component={airlinesFlightPlan}/>
        <Route path={"/app/ams/roles-manage"} exact component={RolesManage}/>
        <Route path={"/app/ams/roles-manage/:model"} exact component={RolesManageForm}/>
        <Route path={"/app/dashboard-ifec"} exact component={DashboardIfec}/>
        <Route path={"/app/dashboard-monitor"} exact component={DashboardMonitor}/>
        <CacheRoute path={"/app/data/access-analysis"} exact component={dataAccessAnalysis}/>
        <CacheRoute path={"/app/data/flow-analysis"} exact component={dataFlowAnalysis}/>
        <CacheRoute path={"/app/data/package"} exact component={dataPackage}/>
        <CacheRoute path={"/app/data/trace-back"} exact component={dataTraceBack}/>
        <Route path={"/app/data/trace-back-details/:passengerId"} exact component={dataTraceBackDetails}/>
        <Route path={"/app/ias/data-package"} exact component={IASCommodityList}/>
        <CacheRoute path={"/app/ias/access-control"} exact component={IASControl}/>
        <CacheRoute path={"/app/ias/data-order"} exact component={IASOrderLog}/>
        <CacheRoute path={"/app/ias/data-order/:flightNo"} exact component={IASOrderLog}/>
        <CacheRoute path={"/app/Passenger/loginLog"} exact component={PassengerLoginLog}/>
        <CacheRoute path={"/app/Passenger/loginLog/:tailNo"} exact component={PassengerLoginLog}/>
        <CacheRoute path={"/app/ias/flights"} exact component={iasFlightsList}/>
        <Route path={"/app/ias/flights/:tailNo"} exact component={iasFlightsList}/>
        <Route path={"/app/ias/flights/online-passenger-list/:flightNo/:tailNo/:flightId"} exact
               component={PassengerOnlineList}/>
        <Route path={"/app/ias/flights/online-passenger-details/:flightNo/:passengerId/:flightId"} exact
               component={PassengerOnlineDetails}/>
        <Route path={"/app/satcom/station-warning"} exact component={satcomStationWarning}/>
        <Route path={"/app/satcom/station-warning/:tailNo"} exact component={satcomStationWarning}/>
        <CacheRoute path={"/app/passenger/netList"} exact component={passengerNetList}/>
        <Route
          path={"/app/Passenger/regisiterLog"}
          exact
          component={PassengerRegisterLog}
        />
        <CacheRoute
          path={"/app/passenger/callRecord"}
          exact
          component={passengerCallRecord}
        />
        <CacheRoute
          path={"/app/behaviorLog/AccessLog"}
          exact
          component={PassengerAccessLog}
        />
        <Route path={"/app/nms/device/maintain/point-station/:tailNo"} exact component={NmsPointStation}/>
        <CacheRoute
          path={`/app/nms/device/warnings`}
          exact
          component={NmsDeviceWarningPage}
        />
        <CacheRoute path={`/app/nms/flight/list`} exact component={NmsFlightList}/>
        <CacheRoute path={`/app/nms/flight/list/:tailNo`} exact component={NmsFlightList}/>
        <Route path={`/app/nms/report/operate/:id`} exact
               component={NmsReportOperate}/>
        <Route path={`/app/nms/report/run/:id`} exact
               component={NmsReportRun}/>
        <Route
          path={`/app/nms/detailed/log/:tailNo/:aircraftType`}
          exact
          component={NMSDetailedLog}
        />
        <Route
          path={`/app/nms/device/warnings/:tailNum`}
          exact
          component={NmsDeviceWarningPage}
        />
        <CacheRoute
          path={`/app/nms/device/dictionary`}
          exact
          component={NmsDeviceDictionary}
        />
        <Route
          path={`/app/nms/device/dictionary/info/:id`}
          exact
          component={NmsDeviceDictionaryInfo}
        />
        <Route
          path={`/app/nms/device/bit/info/:id`}
          exact
          component={nmsBitInfo}
        />
        <Route
          path={`/app/nms/device/value/Transfer/:id`}
          exact
          component={nmsValueTransfer}
        />
        <Route
          path={"/app/nms/device/monitoring"}
          exact
          component={NmsDeviceMonltoring}
        />
        <CacheRoute path={"/app/nms/record"} exact component={NmsRecord}/>
        <Route path={"/app/nms/record/:tailNo"} exact component={NmsRecord}/>
        <Route path={"/app/nms/recordTime/:tailNo"} exact component={NmsRecordRealTime}/>
        <Route path={"/app/nms/recordTime/:tailNo/:time"} exact component={NmsRecordRealTime}/>
        <CacheRoute
          path={"/app/flights/Flight"}
          exact
          component={SettingsFlightWrapper}
        />
        <Route
          path={"/app/flights/Flight/:id"}
          exact
          component={SettingsFlight}
        />
        <Route
          path={"/app/Setting/AircraftType"}
          exact
          component={SettingsAircraftType}
        />
        <Route path={`/app/error`} exact component={ErrorMessage}/>
        <Route
          path={`/app/ias/data-packages/query/:id`}
          exact
          component={CommodityInfo}
        />
        <Route
          path={`/app/ias/data-package-form/:model`}
          exact
          component={CommodityFormAdd}
        />
        <Route
          path={`/app/ias/data-package-form/:model/:id`}
          exact
          component={CommodityFormAdd}
        />
        <Route path={`/app/ias/data-package/:model`} exact component={CommodityForm}/>
        <Route path={`/app/ias/data-package/:model/:id`} exact component={CommodityForm}/>
        <CacheRoute path={`/app/version`} exact component={Version}/>
        <Route path={`/app/setting/client`} exact component={SettingsClient}/>
        <CacheRoute
          path={`/app/setting/add-client`}
          exact
          component={SettingsAddClient}
        />
        <CacheRoute
          path={`/app/setting/settings-aircraft-type`}
          exact
          component={SettingsAircraftType}
        />
        <CacheRoute
          path={`/app/setting/message-definition`}
          exact
          component={SettingsMessageDefinition}
        />
        <Route
          path={`/app/setting/aircraft-type/:model`}
          exact
          component={SettingsAddAircraftType}
        />
        <Route
          path={`/app/setting/aircraft-type/:model/:id`}
          exact
          component={SettingsAddAircraftType}
        />
        <Route
          path={`/app/ias/traffic-snapshot/:id`}
          exact
          component={TrafficSnapshot}
        />
        <Route
          path={`/app/ias/order/detail/:id`}
          exact
          component={IASOrderInfo}
        />
        <Route
          path={`/app/ias/user/detail/:flightNo/:id`}
          exact
          component={IASUserInfo}
        />
        <Route
          path={`/app/nms/device/monitoring/:tailNum`}
          exact
          component={DeviceExceptionMonitor}
        />
        <Route path={`/app/rbac/menus`} exact component={RbacMenus}/>
        <Route path={`/app/rbac/menu/:model`} exact component={RbacMenuAdd}/>
        <Route path={`/app/rbac/users`} exact component={RbacUsers}/>
        <Route path={`/app/rbac/users/:model`} exact component={RbacUsersAdd}/>
        <Route
          path={`/app/nms/device/aircrafts`}
          exact
          component={AircraftsList}
        />
        <Route
          path={`/app/nms/device/maintain`}
          exact
          component={DeviceMaintainList}
        />
        <Route
          path={`/app/nms/device/maintain/setting/:model/:id`}
          exact
          component={DeviceMaintainSettingEdit}
        />
        <Route
          path={`/app/nms/device/maintain/setting/:model`}
          exact
          component={DeviceMaintainSettingEdit}
        />
        <Route
          path={`/app/nms/device/maintain/view/:id`}
          exact
          component={DeviceMaintainSetting}
        />
        <Route
          path={`/app/nms/device/maintain/wmt/:id`}
          exact
          component={DeviceMaintainWmt}
        />
        <Route
          path={`/app/nms/alarm/troubleshoot/:tailNo/:time`}
          exact
          component={NmsAlarmTroubleshooting}
        />
        <Route
          path={`/app/flights/aircraft-manage-table`}
          exact
          component={aircraftManageTable}
        />
        <Route
          path={`/app/flights/aircraft-manage-form/:model`}
          exact
          component={aircraftManageForm}
        />
        <CacheRoute
          path={`/app/mock/takeoff-landing`}
          exact
          component={MockTakeOffLanding}
        />
        <CacheRoute
          path={`/app/nms/device/data-report`}
          exact
          component={DeviceDataReport}
        />
        <Route
          path={`/app/nms/device/data-report/:tailNo`}
          exact
          component={DeviceDataReport}
        />
        <CacheRoute
          path={`/app/ias/net-realtimecost`}
          exact
          component={IASNetRealTimeCost}
        />
        <CacheRoute
          path={`/app/ias/net-list`}
          exact
          component={IASNetList}
        />
        <Route
          path={`/app/setting/notification`}
          exact
          component={SettingsNotification}
        />
        <CacheRoute path={`/app/personal-center/:model`} exact component={PersonalCenter}/>
        <CacheRoute path={`/app/personal-center`} exact component={PersonalCenter}/>
        <Route
          path={`/app/passenger/virtual`}
          exact
          component={PassengerVirtual}
        />
        <Route
          path={`/app/passenger/virtual/form/:model`}
          exact
          component={PassengerVirtualForm}
        />
        <Route extra component={ErrorMessage}/>
      </CacheSwitch>
    );
  }
}

export default AppRouter;
