export default {
  'rbacUsersForm.pageFunctionDescription': '系统软件、目录、菜单配置和定义',
  'rbacUsersForm.add': '添加账号',
  'rbacUsersForm.edit': '修改账号',

  'rbacUsersForm.type': '类型',
  'rbacUsersForm.contents': '目录',
  'rbacUsersForm.menus': '菜单',
  'rbacUsersForm.displayName': '显示名称',
  'rbacUsersForm.icon': '图标',
  'rbacUsersForm.higherLevel': '上级',
  'rbacUsersForm.sortNum': '排序号',
  'rbacUsersForm.connectionAddress': '连接地址',
  'rbacUsersForm.authorizationSign': '授权标示',
  'rbacUsersForm.cancel': '取消',
  'rbacUsersForm.save': '保存',

  'rbacUsersForm.autoGet': '自动获取',

  'rbacUsersForm.success': '操作成功',
  'rbacUsersForm.pleaseEnterName': '请选择名称',
  'rbacUsersForm.pleaseSelectParentDirectory': '请选择上级目录',
};
