export default {
  'nmsAlarmLevel.title': '级别设置',

  'nmsAlarmLevel.addLevel': '新增级别',

  'nmsAlarmLevel.name': '级别名称',
  'nmsAlarmLevel.code': '级别编码',
  'nmsAlarmLevel.priority': '优先级',
  'nmsAlarmLevel.remindType': '提醒方式',
  'nmsAlarmLevel.operate': '操作',
  'nmsAlarmLevel.delete': '删除',

  'nmsAlarmLevel.nameInput': '请输入级别名称...',
  'nmsAlarmLevel.codeInput': '请输入级别编码...',
  'nmsAlarmLevel.priorityInput': '数字越大级别越低...',

  'nmsAlarmLevel.system': '系统',
  'nmsAlarmLevel.email': '邮件',
  'nmsAlarmLevel.dingDing': '钉钉',
  'nmsAlarmLevel.sms': '短信',

  'nmsAlarmLevel.deleteConfirm': '可能在其他地方被引用，删除后将不能正常使用，请再次确认是否需要删除',
  'nmsAlarmLevel.thinkAgain': '再想一想',
  'nmsAlarmLevel.confirm': '我意已决',
  'nmsAlarmLevel.cancel': '取消',
  'nmsAlarmLevel.save': '保存',
  'nmsAlarmLevel.ok': '确认',
};
