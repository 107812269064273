export default {
  'rbacMenus.homePage': 'Home',
  'rbacMenus.authManage': 'Auth manage',
  'rbacMenus.function': 'Function',
  'rbacMenus.pageFunctionDescription': 'System - catalog - menu - button function configuration and management',

  'rbacMenus.add': '+ Add',
  'rbacMenus.displayName': 'Display name',
  'rbacMenus.sortNum': 'Sort number',
  'rbacMenus.connectionAddress': 'Connection address',
  'rbacMenus.authorizationSign': 'Authorization sign',
  'rbacMenus.operation': 'Operation',
  'rbacMenus.edit': 'Edit',
  'rbacMenus.delete': 'Delete',
};
