export default {
  'iasOrderLogs.title': 'Order List',
  'iasOrderLogs.pageDescription': 'Purchase snapshot of traffic, that is, the effective traffic package record in the system',
  'iasOrderLogs.exportData': 'Export',
  'iasOrderLogs.customColumns': 'Display',

  'iasOrderLogs.flowPackageOrderRecord': 'Flow package order record',
  'iasOrderLogs.keyword': 'Keywords',
  'iasOrderLogs.timeInterval': 'Time interval',
  'iasOrderLogs.orderNumber': 'Order number',
  'iasOrderLogs.flightNumber': 'Flight number',
  'iasOrderLogs.seatNumber': 'Seat number',
  'iasOrderLogs.source': 'Source',
  'iasOrderLogs.thirdPartyOrderNumber': 'Third party order number',
  'iasOrderLogs.packageName': 'Package name',
  'iasOrderLogs.creationTime': 'Creation time',
  'iasOrderLogs.operation': 'Operation',
  'iasOrderLogs.details': 'Details',
  'iasOrderLogs.price': 'Price',

  'iasOrderLogs.cumulativeOrderQuantity': 'Cumulative order quantity',
  'iasOrderLogs.trafficPackageCompleted': 'Traffic package to be completed',
  'iasOrderLogs.newOrdersWeek': 'New orders this week',
  'iasOrderLogs.search': 'Search',
  'iasOrderLogs.packageDataFlow': 'dataflow',
  'iasOrderLogs.restOfPackageDataFlow': 'rest of dataflow',
  'iasOrderLogs.packageEndTime': 'end time',
  'iasOrderLogs.orderNumberParty': 'party order ID',
  'iasOrderLogs.orderNumberXOP': 'XOP order ID',
  'iasOrderLogs.dept': 'Dept city',
  'iasOrderLogs.dest': 'Dest city',
  'iasOrderLogs.deptDestExp': 'city search...',
  'iasOrderLogs.snapshot': 'Snapshot',
  'iasOrderLogs.infinite': 'Infinite',

  'iasOrderLogs.expand': 'Expand',
  'iasOrderLogs.packUp': 'Pack up',
  'iasOrderLogs.reset': 'Reset',
  'iasOrderLogs.idCard': 'Id card',
  'iasOrderLogs.seatNo': 'Seat number',
  'iasOrderLogs.flightNo': 'Flight number',
  'iasOrderLogs.status': 'Status',
  'iasOrderLogs.satcomOfferId': 'Order Id',
  'iasOrderLogs.trafficSize': 'Traffic size',
  'iasOrderLogs.satcomUpRate': 'Satcom Up Rate',
  'iasOrderLogs.satcomDownRate': 'Satcom Down Rate',
  'iasOrderLogs.packageType': 'Traffic type',
  'iasOrderLogs.offset': 'Offset',
  'iasOrderLogs.commodityName': 'Commodity name',
  'iasOrderLogs.satcomName': 'Satcom name',
  'iasOrderLogs.deptCity': 'Dept city',
  'iasOrderLogs.deptCode': 'Dept code',
  'iasOrderLogs.destCity': 'Dest city',
  'iasOrderLogs.destCode': 'Dest code',
  'iasOrderLogs.inputSearch': 'Input search...',

  'iasOrderLogs.all': 'All',
  'iasOrderLogs.inactive': 'Inactive',
  'iasOrderLogs.failed': 'Failed',
  'iasOrderLogs.success': 'Success',
  'iasOrderLogs.manual': 'Manual',
  'iasOrderLogs.retrying': 'Retrying',
  'iasOrderLogs.activating': 'Activating',
  'iasOrderLogs.activated': 'Activated',
  'iasOrderLogs.activate': 'Activate',

  'iasOrderLogs.popActivating': 'Activating...',
  'iasOrderLogs.popFailed': 'Activate failed！',
  'iasOrderLogs.popSuccess': 'Activate success,',
};