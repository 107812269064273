import i18n from "i18n-js"

const locales = {
  en: () => import("./en_US"),
  zh: () => import("./zh_CN"),
}

export async function changeLang(locale) {
  if (!i18n.translations[locale]) {
    i18n.translations[locale] = (await locales[locale]())
  }
  i18n.locale = locale
}
export function getString(intl, id) {
  return intl.messages[id] || id;
}
export default i18n
