import darkVars from "./dark.json";
import lightVars from "./light.json";
import variables from "./theme.json";
import xopDark from "./dark.js";
import xopLight from "./light.js";

export {darkVars, lightVars, variables, chanageThemes, getThemes};

function chanageThemes(theme) {
  if (!theme) {
    theme = localStorage.getItem('app-theme')
  }
  doChanage(theme, getTheme(theme))
}

function getTheme(name) {

  switch (name) {
    case "dark": {
      return {
        ...variables,
        ...darkVars,
        ...xopDark
      };
    }
    case "light": {
      return {
        ...variables,
        ...lightVars,
        ...xopLight
      };
    }
    default:
      return {
        ...variables,
        ...lightVars,
        ...xopLight
      };
  }
}

async function doChanage(theme, vars) {
  try {
    window.less.options.javascriptEnabled = true;
    await window.less.modifyVars(vars);
  } catch (e) {
    console.log(e);
  }
}

function getThemes() {
  return ["dark", "light"];
}
