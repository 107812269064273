export default {
  'codeShare.submitting': '正在提交...',
  'codeShare.operationSucceeded': '操作成功',
  'codeShare.submitSucceeded': '提交成功',
  'codeShare.saveSucceeded': '恭喜，保存成功！',
  'codeShare.operationFailed': '操作失败',
  'codeShare.CopySucceeded': '复制成功',
  'codeShare.mustInput': '必须输入',
  'codeShare.pleaseInput': '请输入',
  'codeShare.pleaseSelect': '请选择',
  'codeShare.exceedsMaximumLength': '最大字符长度：',
  'codeShare.onlyLettersNumbers': '只能输入字母、数字',
  'codeShare.onlyNumbers': '只能输入数字',
  'codeShare.onlyInteger': '只能输入整数',
  'codeShare.onlyLettersNumber2': '只允许输入字母、数字、短横线、下划线',
  'codeShare.onlyLettersNumber3': '只允许输入字母, 数字, - ()的组合',
  'codeShare.specialNotAllowed': '不允许输入特殊字符',
  'codeShare.spacesNotAllowed': '前后不允许输入空格',
  'codeShare.characterLengthLimit': '输入字符长度只能：',
  'codeShare.mobileNumberRules': '不符合手机号码规则',
  'codeShare.inputError': '不符合输入规则',
  'codeShare.emailRules': '不符合邮箱规则',
  'codeShare.sizeOver': '大小超长限制，请重新选择',
  'codeShare.chineseNotAllowed': '不可输入中文',
  'codeShare.enterAnIntegerGreater': '请输入大于"0"的整数',
  'codeShare.months': '月',
  'codeShare.years': '年',
  'codeShare.h': '小时',
  'codeShare.m': '分钟',
  'codeShare.individual': '个',
  'codeShare.infinite': '无限',
  'codeShare.ServerInternalError': '服务器内部错误',
  'codeShare.NeedSignAgain': '需要重新登录',
  'codeShare.404Error': '你请求的服务不存在，请与系统管理员联系',
  'codeShare.403Error': '没有访问该资源的权限',
  'codeShare.totalOf': '共 {num} 条',
  'codeShare.delete': '删除',
  'codeShare.add': '增加',
  'codeShare.edit': '编辑',
  'codeShare.day1': '星期一',
  'codeShare.day2': '星期二',
  'codeShare.day3': '星期三',
  'codeShare.day4': '星期四',
  'codeShare.day5': '星期五',
  'codeShare.day6': '星期六',
  'codeShare.day7': '星期天',
  'codeShare.wantDelete': '一旦被删除将无法恢复，请确认是否要删除？',
  'codeShare.wantDelete2': '一旦被删除将无法恢复，请确认是否要删除？',

  "AL0010001": "航空公司名或编码重复",
  "AL0010002": "航司三字码重复",
  "AL0010003": "航司二字码重复",
  "AL0010004": "航司三字码格式错误(A-Z 0-9)",
  "AL0010005": "航司二字码格式错误(A-Z 0-9)",
  "AL0010006": "设备类型为空",
  "AL0010007": "机尾号为空",
  "AL0010008": "不存在的机尾号",
  "AL0010009": "IP已经被绑定",
  "AL0010010": "共享IP设备已经被绑定",
  "AL0010011": "设备未绑定IP",
  "AL0010012": "错误的设备类型",
  "AL0010013": "操作影响记录",
  "AL0010014": "管理员登录名重复",
  "AL0010015": "机尾号重复",
  "AL0010016": "飞机已经改装",
  "AL0010017": "数据为空",
  "AL0010018": "必要参数为空",
  "AL0010019": "机尾号不能更换",
  "AL0010020": "记录不存在",
  "AL0010021": "机型不存在",
  "AL0010022": "机载设备服务授权码未配置,请先使用[ 系统配置 > 系统授权 ]配置授权码",
  "AL0010023": "机型重复",
  "AL0010024": "重复的机载软件名称",
  "AL0010025": "重复的版本号",
  "AL0010026": "重复的下载地址",
  "AL0010027": "重复的方案名称",
  "AL0010028": "方案使用中",

  "FMS0010001": "重复的机载软件名称",
  "FMS0010002": "重复的版本号",
  "FMS0010003": "重复的下载地址",

  "DC0010001": "重复的ip",
  "DC0010002": "重复的url",
  "DC0010003": "重复的mac",
  "DC0010004": "重复的地址",
  "DC0010005": "地址格式不正确",

  "NM0010001": "更新Id为空",
  "NM0010002": "记录不存在",
  "NM0010003": "设备不存在",
  "NM0010004": "设备名不能为空",
  "NM0010005": "重复的设备简称",
  "NM0010006": "重复的设备中文全称",
  "NM0010007": "重复的设备英文全称",
  "NM0010008": "必须指定设备",
  "NM0010009": "重复的分组名称",
  "NM0010010": "请将该分类下的数据项转移后，再做此操作！",
  "NM0010011": "分组不属于当前设备",
  "NM0010012": "重复的设备键",
  "NM0010013": "重复的显示名称",
  "NM0010014": "重复的键描述",
  "NM0010015": "创建分组数超过最大限制",
  "NM0010016": "重复的检测值",
  "NM0010017": "重复的告警级别",
  "NM0010018": "重复的故障名称",
  "NM0010019": "无可监控的飞机",
  "NM0010020": "飞机无可监控的设备",
  "NM0010021": "未发现配置的监控项",
  "NM0010022": "机尾号不能为空",
  "NM0010023": "监控键不能为空",
  "NM0010024": "不正确的页码",
  "NM0010025": "不正确的分页大小",
  "NM0010026": "记录不存在或已被移除",
  "NM0010027": "设备处理器未注册",
  "NM0010028": "不是多设备,不能添加小版本",
  "NM0010029": "机尾号必须指定",
  "NM0010030": "设备必须指定",
  "NM0010031": "记录数过多,请缩小检索范围",
  "NM0010032": "设备数据不存在,请检查配置的设备是否正确",
  "NM0010033": "添加检测项必须选择一个分组",
  "NM0010034": "获取设备信息出错",
  "NM0010035": "未发现指定的监控项",
  "NM0010036": "重复的等级名",
  "NM0010037": "重复的等级编码",
  "NM0010038": "重复的等级优先级",
  "NM0010039": "重复的等级配置(名称/编码/优化级重复)",
  "NM0010040": "重复的事件名称定义",
  "NM0010041": "告警等级正在被使用,不能删除",
  "NM0010044": "重复的分组编码",
  "NM0010045": "重复的原始值",
  "NM0010046": "重复新增bit info信息",
  "NM0010047": "不能添加子分组,目前仅允许添加3级分组",
  "NM0010053": "重复的服务定义名",
  "NM0010054": "重复的服务定义键值",
  "NM0010055": "重复的服务定义名或键",
  "NM0010056": "该飞机已经配置过",
  "NM0010057": "该服务已被引用，不能删除！",
  "NM0010058": "适用名称已存在，请不要重复添加！",
  "NM0010059": "参数别名已存在，请不要重复添加！",
  "NM0010060": "别名未配置",
  "NM0010061": "可能别名错误或系统中未定义此别名",
  "NM0010062": "航班未找到",
  "NM0010063": "命令错误或上下文参数不正确",
  "NM0010064": "分组未定义",
  "NM0010065": "请先选择飞机机号后重试!",

  "UR0010001": "用户不存在",
  "UR0010002": "用户名与密码不匹配",
  "UR0010003": "授权服务器未响应",
  "UR0010004": "Token缺失",
  "UR0010005": "不正确的TOKEN",
  "UR0010006": "未登录",
  "UR0010007": "不是管理员",
  "UR0010008": "密码加密错误",
  "UR0010009": "包含无效字符",
  "UR0010010": "重试次数太多",
  "UR0010011": "不能获取默认密码",
  "UR0010012": "密码长度超过限制",
  "UR0010013": "密码解密出错",
  "UR0010014": "航司不存在",
  "UR0010015": "用户名已经存在",
  "UR0010016": "资源名已经存在",
  "UR0010017": "资源编码已经存在",
  "UR0010018": "一个或多个资源记录未找到",
  "UR0010019": "不能删除内置权限",
  "UR0010020": "上级资源不能是当前资源",
  "UR0010021": "上级资源未找到",
  "UR0010022": "上级资源不是一个目录或菜单",
  "UR0010023": "不能更新内置资源",
  "UR0010024": "角色名已经存在",
  "UR0010025": "角色编码已经存在",
  "UR0010026": "角色已经分配给用户",
  "UR0010027": "方法已经废弃",
  "UR0010028": "不能删除内建角色",
  "UR0010029": "不存在的角色",
  "UR0010030": "帐号禁用中",
  "UR0010031": "重复的机构名称",
  "UR0010032": "该机构正在被使用，不允许删除",
  "UR0010036": "重复的机构识别码",
  "UR0010037": "机构使用中，不允许修改机构类型与机构识别码",
  "UR0010038": "超级机构不允许操作",
  "UR0010039": "仅菜单资源可以设置子功能",
  "UR0010040": "资源已分配给角色,不能删除",
  "UR0010041": "地址已经存在",

  "PS0010000": "ip和mac至少一个不能为空",
  "PS0010001": "不存在的流量套餐",
  "PS0010002": "乘客信息不匹配",
  "PS0010003": "乘客不存在",
  "PS0010004": "不存在的乘客设备",
  "PS0010005": "签名校验失败",
  "PS0010006": "不存在的机尾号",
  "PS0010007": "获取乘客信息失败",
  "PS0010008": "无效订单token",
  "PS0010009": "不能获取到OAuth-Token",
  "PS0010010": "过期token",
  "PS0010011": "乘客不在此航班",
  "PS0010012": "机尾号所属航司与授权客户端不匹配",
  "PS0010013": "当前为非授权客户端帐号",
  "PS0010014": "无法识别的客户端配置",
  "PS0010015": "乘客身份服务配置缺失",
  "PS0010016": "乘客身份服务调用失败",
  "PS0010017": "不匹配的航空公司",
  "PS0010018": "乘客没有在线设备",
  "PS0010019": "非法的时区ID",
  "PS0010020": "不能购买已未上架商品",
  "PS0010021": "非法的ClientId",
  "PS0010022": "重复的模拟乘客帐号",
  "PS0010044": "无效的导出标题设置",
  "PS0010047": "只有自动激活失败的订单才可以手动激活",
  "PS0010048": "解析应用流量包参数失败",
  "PS0010049": "航班信息获取失败",
  "PS0010050": "来不及了，飞机已降落！此操作只针对还未降落的航班订单有效",
  "PS0010051": "手动激活失败，请稍后再试",
  "PS0010054": "OAuth客户端或增值服务运营商已存在",
  "PS0010055": "相同的机构下最多只能一个配置启用",
  "PS0010056": "OpenClient客户端已经配置,请删除后添加",
  "PS0010057": "不匹配的网络运营商",
  "PS0010058": "不匹配的增值服务商",
  "PS0010059": "3A话单查询参数不满足规则",
  "PS0010060": "航班参数缺失",
  "PS0010061": "航班起飞时间(实际)缺失",
  "PS0010062": "航班降落时间(实际)缺失",

  "CO0010001": "商品已经下架中",
  "CO0010002": "商品已经上架中",
  "CO0010003": "流量包流量超过限制",
  "CO0010004": "下架时间应在上架时间之后",
  "CO0010005": "套餐使用截止时间应在套餐上架时间之后",
  "CO0010006": "商品不存在",
  "CO0010007": "运营标题已被使用",
  "CO0010008": "必要参数缺失",
  "CO0010009": "图片不存在",
  "CO0010010": "不允许修改上架中的流量包",
  "CO0010011": "账户机构非增值服务商",

  "QD0010001": "ip和mac至少一个不能为空",
  "QD0010002": "乘客信息不匹配",
  "QD0010003": "青岛webService调用失败",
  "QD0010004": "验证身份信息失败，未能获取完整信息",
  "QD0010005": "青岛常旅客注册拒绝",
  "QD0010006": "请求对象为空",
  "QD0010007": "接口调用无响应",
  "QD0010008": "青岛接口无返回内容",
  "QD0010009": "获取乘客信息失败",

  "AU0020001": "缺失token",
  "AU0020002": "错误的token",

  "AU0010001": "旧密码不正确",
  "AU0010002": "密码格式不正确",
  "AU0010003": "密文密码解密错误",
  "AU0010004": "ClientId已存在",
  "AU0010005": "未能获取到该飞机信息,请确认是否有该记录",
  "AU0010006": "该飞机已配置授权",
  "AU0010007": "配置不存在",
  "AU0010008": "已经是启用状态",
  "AU0010009": "已经是禁用状态",
  "AU0010010": "不能删除XOP授权",

  "SA0010001": "不能获取到客户信息",
  "SA0010002": "同步客户信息失败",
  "SA0010003": "同步用户信息失败",
  "SA0010004": "卫通接口无响应",
  "SA0010005": "卫通接口调用失败",
  "SA0010006": "没有对应的处理器配置",
  "SA0010007": "不合法的请求参数",
  "SA0010008": "重复的处理器配置",
  "SA0010009": "不能获取飞机信息,请检查系统中是否存在机尾号的飞机信息",
  "SA0010010": "卫通接口返回无内容",
  "SA0010011": "该飞机还未配置模拟航班信息,请先配置后再开启",
  "SA0020001": "必须先降落",
  "SA0020002": "必须先起飞",
  "SA0020003": "必要参数缺失",
  "SA0020004": "请先设置并保存模拟参数",
  "SA0020005": "模拟功能未开启",
  "SA0020006": "功能启用时或已调用起飞未降落不能编辑,请先降落并禁用",
  "SA0020007": "不合法的请求参数",
  "SA0020008": "重复的处理器配置",
  "SA0020009": "不能获取飞机信息,请检查系统中是否存在机尾号的飞机信息",

  "OPS0010001": "重复的标签名称",
  "OPS0010002": "重复的标签定义",
  "OPS0010003": "该标签正在被使用，不允许删除",
  "OPS0010004": "重复的问题名称",

  'TK0010001': '重复的数据库连接名称',
  'TK0010002': '数据连接在其他地方被引用，不能删除',
  'TK0010005': '重复的数据接口名称',
  'TK0010008': '重复的图表名称',
  'TK0010009': '接口已下线',

  'CHA0010001': '重复的图表名称',
  'CHA0010002': '重复的看板名称',

  'NT0010001': 'SMTP服务器未配置',
  'NT0010002': '邮箱服务用户名未配置',
  'NT0010003': '邮箱服务器授权码未配置',
  'NT0010004': '邮箱服务端口未配置',
  'NT0010005': '用户未设置邮箱',
  'NT0010006': 'SMTP邮箱服务器验证失败,请检查邮箱服务器配置',
  'NT0010007': '邮件解析错误,可能地址不正确或内容不正确',
  'NT0010008': '邮件发送失败',
};