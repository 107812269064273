export default {
  'login.login': '登 录',
  'login.userName': '请输入用户名',
  'login.userPass': '请输入密码',
  'login.loginSuccess': '登录成功',
  'login.loading': '加载中...',
  'login.userNameErr': '用户名不能为空',
  'login.userPassErr': '密码不能为空',
  'login.adminPermission': '请联系管理员设置功能权限，当前账号未开通功能',
  'login.welcome': '欢迎来到 XOP',
  'login.xopDetails': 'XstreamOP简称XOP，是飞天基于航空互联网运营提出的地面平台解决方案，由六大模块构成，旨在承载真实的商业应用场景，并打造成为航空互联网的通用运营平台，为航空互联网的分布式智能商业应用提供平台支撑。',
};