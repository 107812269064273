export default {
  'iasOrderInfo.title': '订单详情',

  'iasOrderInfo.passengerName': '旅客姓名',
  'iasOrderInfo.orderNo': '渠道订单号',
  'iasOrderInfo.xopOrderNo': 'XOP订单号',
  'iasOrderInfo.passengerMail': '旅客邮箱',
  'iasOrderInfo.orderAddress': '下单地址',
  'iasOrderInfo.idCard': '证件号',
  'iasOrderInfo.createTime': '下单时间',
  'iasOrderInfo.optimalDisplay': '流量大小',
  'iasOrderInfo.seatNo': '座位号',
  'iasOrderInfo.orderFrom': '渠道来源',
  'iasOrderInfo.offerInstId': '运营商订单号',
  'iasOrderInfo.endTime2': '到期时间',


  'iasOrderInfo.title2': '使用过的航班',

  'iasOrderInfo.flightNumber': '航班号',
  'iasOrderInfo.departureTime': '实际起飞时间',
  'iasOrderInfo.airportDepartureLanding': '起飞降落机场',
  'iasOrderInfo.tailNumber': '机尾号',
  'iasOrderInfo.flight': '航段',
  'iasOrderInfo.flightTime': '飞行时长',
  'iasOrderInfo.onNetTime': '累计上网时长',
  'iasOrderInfo.usedDataFlow': '累计使用流量',
  'iasOrderInfo.restDataFlow': '剩余流量',


  'iasOrderInfo.title3': '流量消耗明细',

  'iasOrderInfo.recordTime': '记录时间',
  'iasOrderInfo.MAC': 'MAC地址',
  'iasOrderInfo.uploadDataFlow': '上行流量',
  'iasOrderInfo.downloadDataFlow': '下行流量',
  'iasOrderInfo.shouldCost': '应该扣除',
  'iasOrderInfo.mustCost': '实际扣除',

  'iasOrderInfo.operatorTrafficName': '运营商流量包名称',
  'iasOrderInfo.effectiveTrafficDeadline': '流量套餐有效截止时间',
  'iasOrderInfo.trafficSnapshot': '流量快照',
  'iasOrderInfo.trafficParam': '流量参数',
  'iasOrderInfo.operationInfo': '运营信息',
  'iasOrderInfo.trafficSupplier': '流量供应商',
  'iasOrderInfo.satcomOfferId': '运营商流量ID',
  'iasOrderInfo.trafficType': '流量类型',
  'iasOrderInfo.trafficSize': '流量大小',
  'iasOrderInfo.uplinkBroadband': '上行宽带',
  'iasOrderInfo.downlinkBroadband': '下行宽带',
  'iasOrderInfo.creationTime': '创建时间',
  'iasOrderInfo.specifyURL': '指定访问网址',
  'iasOrderInfo.specifyApp': '指定访问应用',
  'iasOrderInfo.notOpenedYet': '(暂未开通，敬请期待)',
  'iasOrderInfo.fixedTimeLong': '固定的时间长',
  'iasOrderInfo.maxAvailableTrafficSize': '最多可用流量大小',
  'iasOrderInfo.activeTime': '激活时间',
  'iasOrderInfo.endTime': '结束时间',
  'iasOrderInfo.flightNoFrom': '航班号',
  'iasOrderInfo.tailNoFrom': '机尾号',

  'iasOrderInfo.packageName': '套餐名称',
  'iasOrderInfo.salesPrice': '销售价格',
  'iasOrderInfo.effectiveDeadline': '有效截止时间',
  'iasOrderInfo.note': '备注说明',

  'iasOrderInfo.usedDataflow': '使用流量',
  'iasOrderInfo.restDataflow': '剩余流量',
  'iasOrderInfo.day': '天',
};