export default {
  'iasControl.title': '黑白名单',

  'iasControl.tailNoSearch': '机尾号搜索…',
  'iasControl.addressSearch': '地址搜索…',
  'iasControl.all': '全部',
  'iasControl.SOURCE_ALLOW': '源地址白名单',
  'iasControl.SOURCE_BLOCK': '源地址黑名单',
  'iasControl.TARGET_ALLOW': '目的地址白名单',
  'iasControl.TARGET_BLOCK': '目的地址黑名单',
  'iasControl.create': '新增',
  'iasControl.edit': '编辑',
  'iasControl.delete': '删除',
  'iasControl.tailNo': '机尾号',
  'iasControl.address': '地址',
  'iasControl.enable': '状态',
  'iasControl.true': '开启',
  'iasControl.false': '关闭',
  'iasControl.type': '名单类型',
  'iasControl.remark': '备注',
  'iasControl.updateTime': '更新时间',
  'iasControl.batchEnable': '批量开启',
  'iasControl.batchOff': '批量关闭',
  'iasControl.batchClone': '批量克隆到其他飞机',
  'iasControl.batchDelete': '批量删除',
  'iasControl.duplicateRule': '替换规则',
  'iasControl.COVER': '全部替换',
  'iasControl.SKIP': '跳过已有地址',

  'iasControl.status': '状态',
  'iasControl.operation': '操作',
  'iasControl.confirm': '确定',
  'iasControl.save': '保存',
  'iasControl.cancel': '取消',
};