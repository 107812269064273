export default {
  'iasFlightsList.title': '航班旅客',
  'iasFlightsList.tailNo': '航班旅客',
  'iasFlightsList.all': '全部',
  'iasFlightsList.airborne': '飞行中',
  'iasFlightsList.landing': '停飞中',
  'iasFlightsList.statusTitle': '在线人数',
  'iasFlightsList.statusTitle2': '内网设备连接数',
  'iasFlightsList.statusTitle3': '互联网设备连接数',
  'iasFlightsList.statusTitle4': '航班旅客人数',
  'iasFlightsList.online': '人',
  'iasFlightsList.devices': '个',
  'iasFlightsList.depAirport': '起飞机场',
  'iasFlightsList.desAirport': '降落机场',
  'iasFlightsList.flightNo': '航班号',
  'iasFlightsList.notFlightNo': '没有获取到航班号',

  'iasFlightsList.NONE': '',
  'iasFlightsList.GROUND': '地面AAA',
  'iasFlightsList.ON_BOARD': '机上AAA',
}