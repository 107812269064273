export default {
  'airlinesScheme.title': 'Scheme',

  'airlinesScheme.createScheme': 'Add',
  'airlinesScheme.name': 'Scheme name',
  'airlinesScheme.netTypes': 'Net type',
  'airlinesScheme.schemeDevices': 'Scheme devices',
  'airlinesScheme.description': 'Description',
  'airlinesScheme.updateTime': 'Update time',
  'airlinesScheme.delete': 'Delete',

  'airlinesScheme.allowedRepeat': '(Not allowed to repeat)',
  'airlinesScheme.enterName': 'Please enter the modification scheme name',
  'airlinesScheme.enterDescription': 'Please provide a brief description of the scheme',

  'airlinesScheme.LOCAL': 'LOCAL',
  'airlinesScheme.SATELLITE': 'SATELLITE',
  'airlinesScheme.ok': 'OK',
  'airlinesScheme.cancel': 'Cancel',
};
