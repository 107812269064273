export default {
  'codeShare.submitting': 'Submitting...',
  'codeShare.operationSucceeded': 'Operation succeeded',
  'codeShare.submitSucceeded': 'Submit succeeded',
  'codeShare.saveSucceeded': 'Save succeeded',
  'codeShare.operationFailed': 'Operation failed',
  'codeShare.CopySucceeded': 'Copy succeeded',
  'codeShare.mustInput': 'Must input',
  'codeShare.pleaseInput': 'Please input',
  'codeShare.pleaseSelect': 'Please select',
  'codeShare.exceedsMaximumLength': 'Exceeds maximum length:',
  'codeShare.onlyLettersNumbers': 'Only letters and numbers are allowed',
  'codeShare.onlyNumbers': 'Only numbers are allowed',
  'codeShare.onlyInteger': 'Only integers are allowed',
  'codeShare.onlyLettersNumber2': 'Only letters, numbers, hyphens, and underscores are allowed',
  'codeShare.onlyLettersNumber3': 'Only allow input of a combination of letters, numbers, -, and ()',
  'codeShare.specialNotAllowed': 'Special characters are not allowed',
  'codeShare.spacesNotAllowed': 'Spaces are not allowed at the beginning or end',
  'codeShare.characterLengthLimit': 'Character length limit is:',
  'codeShare.mobileNumberRules': 'Does not match mobile number rules',
  'codeShare.inputError': 'Input does not meet the requirements',
  'codeShare.emailRules': 'Does not match email rules',
  'codeShare.sizeOver': 'Size exceeds the limit, please select again',
  'codeShare.chineseNotAllowed': 'Chinese characters are not allowed',
  'codeShare.enterAnIntegerGreater': 'Please enter an integer greater than "0"',
  'codeShare.months': 'Month(s)',
  'codeShare.years': 'Year(s)',
  'codeShare.h': 'Hour(s)',
  'codeShare.m': 'Minute(s)',
  'codeShare.individual': 'Item(s)',
  'codeShare.infinite': 'Infinite',
  'codeShare.ServerInternalError': 'Server Internal Error',
  'codeShare.NeedSignAgain': 'Need to Sign In Again',
  'codeShare.404Error': 'The requested service does not exist. Please contact the system administrator.',
  'codeShare.403Error': 'You do not have permission to access this resource.',
  'codeShare.totalOf': 'Total of {num} items',
  'codeShare.delete': 'Delete',
  'codeShare.add': 'Add',
  'codeShare.edit': 'Edit',
  "codeShare.day1": "Monday",
  "codeShare.day2": "Tuesday",
  "codeShare.day3": "Wednesday",
  "codeShare.day4": "Thursday",
  "codeShare.day5": "Friday",
  "codeShare.day6": "Saturday",
  "codeShare.day7": "Sunday",
  'codeShare.wantDelete': ' is deleted, it cannot be recovered. Please confirm if you wish to delete it?',
  'codeShare.wantDelete2': 'It cannot be recovered. Please confirm if you wish to delete it?',

  "AL0010001": "Duplicate airline name or code",
  "AL0010002": "Repeated carrier treble",
  "AL0010003": "Repeated carrier two character code",
  "AL0010004": "Wrong format of carrier's three character code (A-Z 0-9)",
  "AL0010005": "The format of the carrier's two character code is wrong (A-Z 0-9)",
  "AL0010006": "Device type is empty",
  "AL0010007": "Tail No. is empty",
  "AL0010008": "Non existent tail no",
  "AL0010009": "IP has been bound",
  "AL0010010": "Shared IP device has been bound",
  "AL0010011": "Device not bound to IP",
  "AL0010012": "Wrong device type",
  "AL0010013": "Operation impact record",
  "AL0010014": "Duplicate administrator login name",
  "AL0010015": "Duplicate tail no",
  "AL0010016": "The aircraft has been modified",
  "AL0010017": "Data is empty",
  "AL0010018": "Required parameters are empty",
  "AL0010019": "Tail No cannot be changed",
  "AL0010020": "Record does not exist",
  "AL0010021": "Model does not exist",
  "AL0010022": "The airborne equipment service authorization code is not configured, please use [system configuration > system authorization] to configure the authorization code first",
  "AL0010023": "Repeated model",
  "AL0010024": "Duplicate filling software name",
  "AL0010025": "Duplicate version number",
  "AL0010026": "Duplicate download address",
  "AL0010027": "Duplicate scenario name",
  "AL0010028": "Duplicate doing",

  "FMS0010001": "Duplicate filling software name",
  "FMS0010002": "Duplicate version number",
  "FMS0010003": "Duplicate download address",

  "DC0010001": "Duplicate IP",
  "DC0010002": "Duplicate URL",
  "DC0010003": "Duplicate MAC",
  "DC0010004": "Duplicate address",
  "DC0010005": "Incorrect address format",

  "NM0010001": "Update ID is empty",
  "NM0010002": "Record does not exist",
  "NM0010003": "Device does not exist",
  "NM0010004": "Device name cannot be empty",
  "NM0010005": "Duplicate device abbreviation",
  "NM0010006": "Duplicate Chinese full name of device",
  "NM0010007": "Duplicate English full name of device",
  "NM0010008": "Device must be specified",
  "NM0010009": "Duplicate group name",
  "NM0010010": "Please transfer the data items under this classification before doing this operation! ",
  "NM0010011": "Grouping does not belong to the current device",
  "NM0010012": "Duplicate device key",
  "NM0010013": "Duplicate display name",
  "NM0010014": "Duplicate key description",
  "NM0010015": "The number of created groups exceeds the maximum limit",
  "NM0010016": "Duplicate test values",
  "NM0010017": "Repeated alarm levels",
  "NM0010018": "Duplicate fault name",
  "NM0010019": "No aircraft to monitor",
  "NM0010020": "Aircraft has no equipment to monitor",
  "NM0010021": "No configured monitoring items found",
  "NM0010022": "Tail No cannot be empty",
  "NM0010023": "Monitoring key cannot be empty",
  "NM0010024": "Incorrect page number",
  "NM0010025": "Incorrect page size",
  "NM0010026": "Record does not exist or has been removed",
  "NM0010027": "Device processor not registered",
  "NM0010028": "Not multiple devices, cannot add small version",
  "NM0010029": "Tail No must be specified",
  "NM0010030": "Device must specify",
  "NM0010031": "Too many records, please narrow the retrieval range",
  "NM0010032": "Device data does not exist, please check whether the configured device is correct",
  "NM0010033": "To add a test item, you must select a group",
  "NM0010034": "Error in obtaining device information",
  "NM0010035": "Specified monitoring item not found",
  "NM0010036": "Duplicate level name",
  "NM0010037": "Duplicate level coding",
  "NM0010038": "Repeated level optimization level",
  "NM0010039": "Duplicate level configuration (name / code / optimization level repetition)",
  "NM0010040": "Duplicate event name definition",
  "NM0010041": "Alarm level is in use and cannot be deleted",
  "NM0010044": "Repeated block coding",
  "NM0010045": "Duplicate raw value",
  "NM0010046": "Repeatedly adding bit info information",
  "NM0010047": "Cannot add subgroups. Currently, only level 3 groups are allowed",
  "NM0010053": "Duplicate service definition names",
  "NM0010054": "Duplicate service definition keys",
  "NM0010055": "Duplicate service definition names or keys",
  "NM0010056": "The aircraft has been configured",
  "NM0010057": "This service has been referenced and cannot be deleted!",
  "NM0010058": "The applicable name already exists, please do not add duplicates!",
  "NM0010059": "The parameter alias already exists, please do not add duplicates!",
  "NM0010060": "Alias is not configured",
  "NM0010061": "Possible alias error or the alias is not defined in the system",
  "NM0010062": "Flight not found",
  "NM0010063": "Command error or incorrect context parameters",
  "NM0010064": "Group is not defined",
  "NM0010065": "Please select an aircraft registration number and try again!",

  "UR0010001": "User does not exist",
  "UR0010002": "User name and password do not match",
  "UR0010003": "Authorization server not responding",
  "UR0010004": "Token missing",
  "UR0010005": "Incorrect token",
  "UR0010006": "Not logged in",
  "UR0010007": "Not an administrator",
  "UR0010008": "Password encryption error",
  "UR0010009": "Contains invalid characters",
  "UR0010010": "Too many retries",
  "UR0010011": "Cannot get default password",
  "UR0010012": "Password length exceeds the limit",
  "UR0010013": "Password decryption error",
  "UR0010014": "The airline does not exist",
  "UR0010015": "User name already exists",
  "UR0010016": "Resource name already exists",
  "UR0010017": "Resource code already exists",
  "UR0010018": "One or more resource records not found",
  "UR0010019": "Cannot delete built-in permission",
  "UR0010020": "The parent resource cannot be the current resource",
  "UR0010021": "Parent resource not found",
  "UR0010022": "The parent resource is not a directory",
  "UR0010023": "Cannot update built-in resources",
  "UR0010024": "Role name already exists",
  "UR0010025": "Role code already exists",
  "UR0010026": "Role assigned to user",
  "UR0010027": "Method has been abandoned",
  "UR0010028": "Cannot delete built-in role",
  "UR0010029": "Nonexistent roles",
  "UR0010030": "Account disabling",
  "UR0010031": "Duplicate institution name",
  "UR0010032": "Organization is being used and cannot be deleted",
  "UR0010036": "Repeated airline three character code",
  "UR0010037": "It is not allowed to modify the mechanism type when the mechanism is in use",
  "UR0010038": "super mechanism is not allowed to operate",
  "UR0010039": "only menu resources can set sub functions",
  "UR0010040": "the resource has been assigned to a role and cannot be deleted",
  "UR0010041": "Address already exists",

  "PS0010000": "At least one IP and MAC cannot be empty",
  "PS0010001": "Nonexistent traffic package",
  "PS0010002": "passenger information mismatch",
  "PS0010003": "passenger does not exist",
  "PS0010004": "passenger equipment not present",
  "PS0010005": "Signature verification failed",
  "PS0010006": "Non existent tail no",
  "PS0010007": "Failed to get passenger information",
  "PS0010008": "Invalid order token",
  "PS0010009": "Cannot get OAuth token",
  "PS0010010": "Eexpired token",
  "PS0010011": "Passenger not on this flight",
  "PS0010012": "The aircraft tail no belongs to a company that does not match the authorized client",
  "PS0010013": "currently an unauthorized client account",
  "PS0010014": "Unrecognized client configuration",
  "PS0010015": "Passenger identity service configuration missing",
  "PS0010016": "Passenger identity service call failed",
  "PS0010017": "Mismatched Airlines",
  "PS0010018": "Passenger has no online device",
  "PS0010019": "Illegal time zone ID",
  "PS0010020": "Can't buy goods that have not been put on the market",
  "PS0010021": "Illegal ClientID",
  "PS0010022": "Duplicate simulated passenger account number",
  "PS0010044": "Invalid export header settings",
  "PS0010047": "Only orders that fail to be activated automatically can be activated manually",
  "PS0010048": "Failed to parse application traffic packet parameters",
  "PS0010049": "Flight information acquisition failure",
  "PS0010050": "It's too late, the plane has landed! This operation is only valid for flight orders that have not landed yet",
  "PS0010051": "Manual activation failed",
  "PS0010054": "OAuth client or value-added service operator already exists",
  "PS0010055": "Only one configuration can be enabled under the same mechanism",
  "PS0010056": "OpenClient has been configured, please delete and add",
  "PS0010057": "Network operator mismatch",
  "PS0010058": "Value-added service provider mismatch",
  "PS0010059": "The parameters for 3A call record inquiry do not meet the requirements",
  "PS0010060": "Missing flight parameters",
  "PS0010061": "Missing Actual Departure Time of Flight",
  "PS0010062": "Missing Actual Arrival Time of Flight",

  "CO0010001": "The goods have been removed from the shelves",
  "CO0010002": "The goods have been put on the shelves",
  "CO0010003": "Flow packet flow exceeds the limit",
  "CO0010004": "The off shelf time shall be after the on shelf time",
  "CO0010005": "The deadline for using the package shall be after the time when the package is put on the market",
  "CO0010006": "Commodity does not exist",
  "CO0010007": "The traffic packet name has been used",
  "CO0010008": "Missing required parameters",
  "CO0010009": "Picture does not exist",
  "CO0010010": "Modifying traffic packets on the shelf is not allowed",
  "CO0010011": "Non value added service providers of account institutions",

  "QD0010001": "At least one IP and MAC cannot be empty",
  "QD0010002": "Passenger information mismatch",
  "QD0010003": "Qingdao WebService call failed",
  "QD0010004": "Failed to verify identity information, failed to get complete information",
  "QD0010005": "Qingdao frequent flyer registration refused",
  "QD0010006": "Request object is empty",
  "QD0010007": "No response to interface call",
  "QD0010008": "No content returned from Qingdao interface",
  "QD0010009": "Failed to get passenger information",

  "AU0020001": "Missing token",
  "AU0020002": "Wrong token",

  "AU0010001": "Old password incorrect",
  "AU0010002": "Incorrect password format",
  "AU0010003": "Ciphertext password decryption error",
  "AU0010004": "ClientID already exists",
  "AU0010005": "Failed to obtain the aircraft information, please confirm whether there is such record",
  "AU0010006": "The aircraft has been configured with authorization",
  "AU0010007": "Configuration does not exist",
  "AU0010008": "Already enabled",
  "AU0010009": "Already disabled",
  "AU0010010": "XOP authorization cannot be deleted",

  "SA0010001": "Unable to get customer information",
  "SA0010002": "Failed to synchronize customer information",
  "SA0010003": "Failed to synchronize user information",
  "SA0010004": "Satcom interface not responding",
  "SA0010005": "Satcom interface call failed",
  "SA0010006": "No corresponding processor configuration",
  "SA0010007": "Illegal request parameter",
  "SA0010008": "Duplicate processor configuration",
  "SA0010009": "Unable to obtain aircraft information, please check whether there is aircraft information of tail no in the system",
  "SA0010010": "Satcom interface returns no content",
  "SA0010011": "Aircraft has not been configured with simulated flight information, please configure it before starting it",
  "SA0020001": "Must land first",
  "SA0020002": "Must take off first",
  "SA0020003": "Missing required parameters",
  "SA0020004": "Set and save the simulation parameters first",
  "SA0020005": "Simulation function is not enabled",
  "SA0020006": "When the function is enabled or the takeoff has been called but not landed, it cannot be edited. Please land and disable first",
  "SA0020007": "Illegal request parameter",
  "SA0020008": "Duplicate processor configuration",
  "SA0020009": "Unable to obtain aircraft information, please check whether there is aircraft information of tail no in the system",

  "OPS0010001": "Duplicate label name",
  "OPS0010002": "Duplicate label definition",
  "OPS0010003": "Label is in use and cannot be deleted",
  "OPS0010004": "Duplicate question name",

  'TK0010001': 'Duplicate database connection name',
  'TK0010002': 'Data connection is referenced elsewhere and cannot be deleted',
  'TK0010005': 'Duplicate data interface name',
  'TK0010008': 'Duplicate chart name',
  'TK0010009': 'Interface is offline',

  'CHA0010001': 'Duplicate chart name',
  'CHA0010002': 'Duplicate dashboard name',

  'NT0010001': 'SMTP server is not configured',
  'NT0010002': 'Email service username is not configured',
  'NT0010003': 'Email server authorization code is not configured',
  'NT0010004': 'Email service port is not configured',
  'NT0010005': 'User has not set up an email address',
  'NT0010006': 'SMTP email server authentication failed, please check email server configuration',
  'NT0010007': 'Email parsing error, possibly due to incorrect address or content',
  'NT0010008': 'Email sending failed',
};