import {
  fetchJSON,
  getAxios,
} from "shared/fetch"
import fetch from "isomorphic-fetch"
import {
  generateUUID
} from "shared/utils"
import {
  BASE_API_URL
} from "shared/env"

export const LOGIN_PATH = `${BASE_API_URL}/user/admin/login`
export const LOGOUT_PATH = `${BASE_API_URL}/user/admin/logout`
export const REFRESH_TOKEN_PATH = `${BASE_API_URL}/user/admin/refresh`

export async function login(username, password) {
  const info = await getAxios(`${LOGIN_PATH}?password=${password}&username=${username}&from=frontend`, "POST");
  return info
}

export async function logout() {
  try {
    let deviceSecretKey = await localStorage.getItem("deviceSecretKey") || generateUUID()
    await localStorage.setItem("deviceSecretKey", deviceSecretKey)
    const info = await fetchJSON(`${LOGOUT_PATH}`)
    return info
  } catch (e) {
    console.log(e)
  }
}

export async function refreshToken() {
  try {
    let deviceSecretKey = await localStorage.getItem("deviceSecretKey") || generateUUID()
    await localStorage.setItem("deviceSecretKey", deviceSecretKey)
    const info = await fetchJSON(`${REFRESH_TOKEN_PATH}`)
    return info
  } catch (e) {
    console.error(e)
  }
}

export async function checkToken() {
  try {
    let deviceSecretKey = await localStorage.getItem("deviceSecretKey") || generateUUID()
    await localStorage.setItem("deviceSecretKey", deviceSecretKey)
    const info = await fetchJSON(`${REFRESH_TOKEN_PATH}`)
    return info
  } catch (e) {
    console.error(e)
  }
}

export async function updateUserInfo(data, secretKey) {
  const resData = {
    decrypted: {
      userData: data,
    },
  }
  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  const result = await fetchJSON(`/rest/secure/user`, {
    headers,
    method: "PUT",
    body: JSON.stringify(resData),
  })
  return JSON.parse(result)
}
