export default {
  'nmsMonitoringSettings.title': 'Monitoring settings',
  'nmsMonitoringSettings.pageDescription': 'Custom configuration of airborne network device monitoring check item',

  'nmsMonitoringSettings.addCategory': 'Add Category',
  'nmsMonitoringSettings.categoryName': 'Category Name',
  'nmsMonitoringSettings.all': 'All',
  'nmsMonitoringSettings.keywordQuery': 'Keyword query:',
  'nmsMonitoringSettings.enterKeyValue': 'Please enter the key value',
  'nmsMonitoringSettings.query': 'Search',
  'nmsMonitoringSettings.new': 'Add',
  'nmsMonitoringSettings.delete': 'Delete',
  'nmsMonitoringSettings.edit': 'Edit',
  'nmsMonitoringSettings.pleaseSeleteItem': 'Please select check item',
  'nmsMonitoringSettings.wantDeleteThem': 'Do you really want to delete them?',
  'nmsMonitoringSettings.wantDeleteIt': 'Do you really want to delete it?',
  'nmsMonitoringSettings.cannotBeRecovered': 'Cannot be recovered after deletion',

  'nmsMonitoringSettings.checkItem': 'Check item',
  'nmsMonitoringSettings.detectionValue': 'Detection value',
  'nmsMonitoringSettings.alarmOrNot': 'Alarm or not',
  'nmsMonitoringSettings.alarmType': 'Alarm type',
  'nmsMonitoringSettings.faultName': 'Fault name',
  'nmsMonitoringSettings.recommendedSolution': 'Recommended solution',
  'nmsMonitoringSettings.newTestItem': 'New test item',
  'nmsMonitoringSettings.normal': 'Normal',
  'nmsMonitoringSettings.fullyLoaded': 'Fully loaded',
  'nmsMonitoringSettings.failed': 'Failed',
  'nmsMonitoringSettings.connectionFailed': 'Connection failed',
  'nmsMonitoringSettings.notMatch': 'Not match',
  'nmsMonitoringSettings.unKnow': 'Un know',
  'nmsMonitoringSettings.on': 'On',
  'nmsMonitoringSettings.off': 'Off',
  'nmsMonitoringSettings.newValueAdded': 'New value added',
  'nmsMonitoringSettings.newTestItemValue': 'New test item value',
  'nmsMonitoringSettings.AMMsuggestions': 'AMM',

  'nmsMonitoringSettings.numberCode': 'Number code',
  'nmsMonitoringSettings.displayName': 'Display name',
};