export default {
  'settingNotification.title': '通知服务',

  'settingNotification.mailSettings': '邮件通知设置',
  'settingNotification.enableMailNotification': '启用邮件通知服务',
  'settingNotification.testSendingMail': '测试发送邮件',
  'settingNotification.save': '保存',
  'settingNotification.edit': '编辑',
  'settingNotification.defaultHeader': '默认邮件标题',
  'settingNotification.mailAddress': '邮件源地址',
  'settingNotification.testMailAddress': '测试邮件目的地址',
  'settingNotification.SMTPmailServer': 'SMTP邮件服务器',
  'settingNotification.authenticationUserName': '服务器验证用户名',
  'settingNotification.doNotFill': '（不填既无需验证）',
  'settingNotification.authenticationPassword': '服务器验证密码',
  'settingNotification.port': '服务器端口',

  'settingNotification.ddPushSetting': '钉钉通知设置',
  'settingNotification.enableDDnotification': '启用钉钉通知服务',
  'settingNotification.testDDpush': '测试钉钉推送',
  'settingNotification.robotName': '机器人名称',
  'settingNotification.testReceivingGroup': '测试接收群组',
  'settingNotification.privateKey': '签名验证私钥',

  'settingNotification.inputTest': '请输入必填项，才可以测试',

  'settingNotification.title1': '消息编码',
  'settingNotification.title2': '关键词',
  'settingNotification.title3': '通知内容',
  'settingNotification.switchOn': '开启',
  'settingNotification.switchOff': '停用',

  'settingNotification.smsSetting': '短信通知设置',
  'settingNotification.contentSetting': '通知内容及格式设置',
  'settingNotification.content0': '此内容将在飞机起飞时发出，格式：【B-FTS2011 起飞通知】FTS Airlines 航班 FT6868, 2011-06-06 06:00:00 从 成都 飞往 罗马，计划飞行时长 11小时25分',
  'settingNotification.content1': '此内容将在飞机降落时发出，格式：【B-FTS2011 降落通知】FTS Airlines 航班 FT6868, 2011-06-06 06:00:00 从 成都 起飞，2011-06-06 17:25:00 在 罗马 降落，飞行时长  11小时25分',
  'settingNotification.content2': '此内容将在监控事件满足触发条件时发出，格式：【XOP】B-8442 在 2011-06-06 06:56 IFC被重启且卫星发射电门也被关闭',
};
