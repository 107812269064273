export default {
  'dashboardIfec.basicFlightInfo': 'Basic flight information',
  'dashboardIfec.tailNumber': 'Tail No',
  'dashboardIfec.flight': 'Flight',
  'dashboardIfec.departure': 'Departure',
  'dashboardIfec.arrival': 'Arrival',
  'dashboardIfec.planStart': 'Plan to start',
  'dashboardIfec.plannedArrival': 'Planned arrival',
  'dashboardIfec.actualDeparture': 'Actual departure',
  'dashboardIfec.expectedArrival': 'Eexpected arrival',
  'dashboardIfec.airline': 'Airline',
  'dashboardIfec.status': 'Status',
  'dashboardIfec.flying': 'Flying',
  'dashboardIfec.landed': 'Landed',
  'dashboardIfec.readyToTakeoff': 'Ready to takeoff',
  'dashboardIfec.cabinNetworkAccess': 'Cabin network access',
  'dashboardIfec.realTimeUplinkTraffic': 'Real time uplink traffic',
  'dashboardIfec.realTimeDownlinkTraffic': 'Real time downlink traffic',
  'dashboardIfec.numberPeopleOnline': 'Number of people online',
  'dashboardIfec.numberInternetDevices': 'Number of Internet Devices',
  'dashboardIfec.airborneNetworkInformation': 'Device Status',
  'dashboardIfec.currentNet': 'Current network：',
  'dashboardIfec.mobile': 'Moblile',
  'dashboardIfec.Satellite': 'Satellite',
  'dashboardIfec.noSignal': 'No signal',
  'dashboardIfec.flightFromAirline': 'The basic information of this flight is from the airline',
  'dashboardIfec.map': 'MAP',
  'dashboardIfec.SATCOM': 'ST',
  'dashboardIfec.APSTAR': 'PT',

  'dashboardIfec.plan': 'ST',
  'dashboardIfec.expect': 'ET',
  'dashboardIfec.actual': 'AT',
  'dashboardIfec.resource': 'Resource',
  'dashboardIfec.EventAlert': 'Event Alert',
  'dashboardIfec.InternetActivity': 'Internet Activity',
  'dashboardIfec.DeviceStatus': 'Device Status',
  'dashboardIfec.Beam': 'Beam',
  'dashboardIfec.Altitude': 'Altitude(*1000ft)',
  'dashboardIfec.AccessTerminals': 'Access Terminals',
  'dashboardIfec.UploadandDownloadSpeed': 'Upload and Download Speed',
  'dashboardIfec.Seats': 'Seats',
  'dashboardIfec.People': 'People',
  'dashboardIfec.Authentications': 'Authentications',
  'dashboardIfec.InternetUsers': 'Internet Users',
  'dashboardIfec.Terminals': 'Terminals',
  'dashboardIfec.Orders': 'Orders',

  'dashboardIfec.installedQuantity': 'Installed quantity',
  'dashboardIfec.cumulativeNumberFlights': 'Number of flights',
  'dashboardIfec.carryingPassengers': 'Carrying passengers',
  'dashboardIfec.cumulativeTransactions': 'On-board transactions',
  'dashboardIfec.timeFirstInstallation': 'Time from first installation',
  'dashboardIfec.trafficConsumption': 'Traffic consumption',
  'dashboardIfec.proportionOnlinePassengers': 'Proportion of online passengers',
  'dashboardIfec.onlineDurationCapita': 'Online duration per capita',
  'dashboardIfec.onlineFlightDurationCapita': 'Online flight duration ratio per capita',
  'dashboardIfec.cabinWiFiUsage': 'Cabin Wi-Fi usage',
  'dashboardIfec.numberTrafficActivation': 'Number of traffic activation',
  'dashboardIfec.governmentIP': 'Government IP',
  'dashboardIfec.businessIP': 'Business IP',
  'dashboardIfec.entertainmentIP': 'Entertainment IP',
  'dashboardIfec.other': 'Other',
  'dashboardIfec.allYearRound': 'All year round',
  'dashboardIfec.thisMonth': 'This month',
  'dashboardIfec.thisWeek': 'This week',
  'dashboardIfec.today': 'Today',

  'dashboardIfec.detailedRecord': 'Detailed Record',
  'dashboardIfec.trafficOrder': 'Traffic Order',
  'dashboardIfec.flightPassengers': 'Flight Passengers',
  'dashboardIfec.loginRecord': 'Auth Log',
  'dashboardIfec.flightHistory': 'Flight History',
  'dashboardIfec.modificationDetails': 'Modification Details',
  'dashboardIfec.details': 'Details',
  'dashboardIfec.updateTime': 'Update time',
};