export default {
  'airlinesFilling.title': 'Filling Software',

  'airlinesFilling.version': 'Version',
  'airlinesFilling.version2': 'Version',
  'airlinesFilling.edit': 'Edit',
  'airlinesFilling.list': 'List',
  'airlinesFilling.createSoftware': 'Create software',
  'airlinesFilling.softwareName': 'Software name',
  'airlinesFilling.supplier': 'Supplier',
  'airlinesFilling.softwareType': 'Software type',
  'airlinesFilling.UPPER': 'UPPER',
  'airlinesFilling.UNDERLYING': 'UNDERLYING',
  'airlinesFilling.OTHER': 'OTHER',

  'airlinesFilling.softwareVersion': 'Software version',
  'airlinesFilling.refresh': 'Refresh',
  'airlinesFilling.versionSearch': 'Version Search...',
  'airlinesFilling.projectSearch': 'Project Search...',

  'airlinesFilling.project': 'Project',
  'airlinesFilling.filllingSoftwareName': 'Software name',
  'airlinesFilling.createUser': 'Create User',
  'airlinesFilling.createTime': 'Create Time',
  'airlinesFilling.operate': 'Operation',
  'airlinesFilling.details': 'Details',
  'airlinesFilling.share': 'Share',
  'airlinesFilling.download': 'Download',
  'airlinesFilling.DOWNLOADING': 'DOWNLOADING...',
  'airlinesFilling.FAIL': 'FAIL',
  'airlinesFilling.CHECK_FAIL': 'CHECK FAIL',
  'airlinesFilling.pleasePasteIt': 'Download address has been copied successfully. Please paste and share it ',

  'airlinesFilling.addVersion': 'Add version',
  'airlinesFilling.editVersion': 'Edit version',
  'airlinesFilling.downloadUrl': 'Download Url',
  'airlinesFilling.md5': 'MD5',
  'airlinesFilling.description': 'Description',
  'airlinesFilling.ok': 'OK',
  'airlinesFilling.cancel': 'Cancel',

  'airlinesFilling.softwareVersionInfo': 'Software version info',
  'airlinesFilling.productName': 'Product name',
  'airlinesFilling.pn': 'Product PN',
  'airlinesFilling.productVersion': 'Product version',
  'airlinesFilling.publicVersion': 'Leave blank for public version',
  'airlinesFilling.SoftwareName': 'Software name entry is incorrect. Please re-enter',
  'airlinesFilling.fullPackageAddress': 'Complete download address for the full package (must be in ".z", ".zip", or ".tar.gz" format)',
  'airlinesFilling.VersionNumber': 'Version number entry is incorrect. Please re-enter',
  'airlinesFilling.pixelJPGimage': '200 x 200 pixel JPG image within 100Kb',
  'airlinesFilling.uploadImage': 'Please upload an image file',
  'airlinesFilling.replaceImage': 'The uploaded file exceeds 100Kb. Please replace the image.',
  'airlinesFilling.FileUploading': 'File is uploading... Please try again later',
  'airlinesFilling.Submitting': 'Submitting...',
};
