export default {
  'rbacMenus.homePage': '首页',
  'rbacMenus.authManage': '权限管理',
  'rbacMenus.function': '功能管理',
  'rbacMenus.pageFunctionDescription': '系统 - 目录 - 菜单 - 按钮功能配置及管理',

  'rbacMenus.add': '+ 新 增',
  'rbacMenus.displayName': '显示名称',
  'rbacMenus.sortNum': '排序号',
  'rbacMenus.connectionAddress': '连接地址',
  'rbacMenus.authorizationSign': '授权标示',
  'rbacMenus.operation': '操作',
  'rbacMenus.edit': '编辑',
  'rbacMenus.delete': '删除',
};
