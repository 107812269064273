export default {
  'rbacPage.title': 'Page Manage',

  'rbacPage.searchKey': 'Keywords...',
  'rbacPage.add': 'Add',
  'rbacPage.displayName': 'Name',
  'rbacPage.sortNum': 'Sort number',
  'rbacPage.connectionAddress': 'Address',
  'rbacPage.authorizationSign': 'Sign',
  'rbacPage.operation': 'Operation',
  'rbacPage.edit': 'Edit',
  'rbacPage.delete': 'Delete',
};
