import update from "immutability-helper"
import {
  SET_PLANES_INFO,
  SET_TRACK_LIST,
  UPDATE_TRACK_LIST,
  UPDATE_TRACK_INFO,
} from "store/flights-status/actions"


const initialState = {
  planeInfo: {},
  trackList: {},
  flightList: {},
};

const handlers = {
  [SET_PLANES_INFO](state, {data}) {
    let t = completeAssign({}, data)
    let c = update(state, {
      planeInfo: {
        $set: t
      },
    })
    return c;
  },
  [SET_TRACK_LIST](state, {data}) {
    let t = completeAssign({}, data)
    let c = update(state, {
      trackList: {
        $set: t,
      },
    })
    return c;
  },
  [UPDATE_TRACK_LIST](state, {data}) {
    let c = update(state, {
      trackList: {
        [data.tailNo]: {
          $set: data,
        }
      },
    })
    return c;
  },
  [UPDATE_TRACK_INFO](state, {data}) {
    let c = update(state, {
      flightList: {
        [data.tailNo]: {
          $set: data,
        }
      },
    })
    return c;
  },
}

export function completeAssign(target, sources) {
  sources && sources.forEach(source => {
    let descriptors = Object.keys(source).reduce((descriptors, key) => {
      descriptors[key] = Object.getOwnPropertyDescriptor(source, key)
      return descriptors
    }, {})
    let node = {}
    Object.defineProperties(node, descriptors)
    target[source.tailNo] = node
  })
  return target
}

export default function app(state = initialState, action) {
  const type = action.type
  if (!handlers[type]) return state
  return handlers[type](state, action)
}
