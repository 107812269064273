export default {
  'iasUserList.title': 'User list',
  'iasUserList.pageDescription': 'Real time online passenger list, up / down line control',

  'iasUserList.keyword': 'Keywords',
  'iasUserList.timeInterval': 'Time interval',
  'iasUserList.flightNumber': 'Flight number',
  'iasUserList.seatNumber': 'Seat number',
  'iasUserList.travellerID': 'Traveller ID',
  'iasUserList.lastUpdate': 'Last update',
  'iasUserList.operation': 'Edit',
  'iasUserList.details': 'Details',

};