export default {
  'settingNotification.title': 'Notification',

  'settingNotification.mailSettings': 'Mail service settings',
  'settingNotification.enableMailNotification': 'Enable mail notification',
  'settingNotification.testSendingMail': 'Test sending mail',
  'settingNotification.save': 'Save',
  'settingNotification.edit': 'Edit',
  'settingNotification.defaultHeader': 'Default message header',
  'settingNotification.mailAddress': 'Mail source address',
  'settingNotification.testMailAddress': 'Test mail destination address',
  'settingNotification.SMTPmailServer': 'SMTP mail server',
  'settingNotification.authenticationUserName': 'Server authentication user name',
  'settingNotification.doNotFill': '(do not fill in and do not need to verify)',
  'settingNotification.authenticationPassword': 'Server authentication password',
  'settingNotification.port': 'Server port',

  'settingNotification.ddPushSetting': 'Dingding push setting',
  'settingNotification.enableDDnotification': 'Enable dingding notification service',
  'settingNotification.testDDpush': 'Test dingding push',
  'settingNotification.robotName': 'Robot name',
  'settingNotification.testReceivingGroup': 'Test receiving group',
  'settingNotification.privateKey': 'Private Key',

  'settingNotification.inputTest': 'Please enter the necessary data',

  'settingNotification.title1': 'Notification Code',
  'settingNotification.title2': 'Keywords',
  'settingNotification.title3': 'Notification Content',
  'settingNotification.switchOn': 'ON',
  'settingNotification.switchOff': 'OFF',

  'settingNotification.smsSetting': 'SMS notification settings',
  'settingNotification.contentSetting': 'Notification content and format setting',
  'settingNotification.content0': 'This content will be sent out when the aircraft takes off, format: [b-fts2011 departure notice] FTS Airlines flight ft6868, 2011-06-06 06 06:00:00 from Chengdu to Rome, planned flight time is 11 hours and 25 minutes',
  'settingNotification.content1': 'This content will be sent out when the aircraft lands, format: [b-fts2011 landing notice] FTS Airlines flight ft6868, takes off from Chengdu at 06:00, 06-06-2011, lands in Rome at 17:25, 06-06-2011, with a flight duration of 11 hours and 25 minutes',
  'settingNotification.content2': 'This content will be sent out when the operation status of the device changes. Format: [b-fts 2011 device alarm notification] time: 06-06-06-2011 06:06:06 it is found that the GNS of the device changes from normal operation to abnormal operation. Click http://xop.fts.aero/xxx for details',

};
