export const SET_LOCALE = "SET_LOCALE";
export const SET_THEME = "SET_THEME";
export const SET_MENU_HIDE = "SET_MENU_HIDE";

export function setLocale(locale) {
  return async (dispatch) => {
    await localStorage.setItem("locale", locale);
    dispatch({
      type: SET_LOCALE,
      locale,
    })
  }
}

export function setTheme(theme) {
  return async (dispatch) => {
    await localStorage.setItem("app-theme", theme);
    dispatch({
      type: SET_THEME,
      theme,
    })
  }
}

export function setMenuHide(menuHide) {
  return async (dispatch) => {
    await localStorage.setItem("app-menu-hide", menuHide);
    dispatch({
      type: SET_MENU_HIDE,
      menuHide,
    })
  }
}
