export default {
  'passengerNetList.title': 'Internet Passenger',

  'passengerNetList.searchTailNo': 'Tail No...',
  'passengerNetList.searchFlightNo': 'Flight number...',
  'passengerNetList.searchKey': 'Keywords...',
  'passengerNetList.deptSearch': 'Dept...',
  'passengerNetList.destSearch': 'Dest...',

  'passengerNetList.search': 'Search',
  'passengerNetList.exportData': 'Export',

  'passengerNetList.tailNo': 'Tail No',
  'passengerNetList.flightNo': 'Flight Number',
  'passengerNetList.flightDate': 'Flight Date',
  'passengerNetList.deptCity': 'Dept City',
  'passengerNetList.destCity': 'Dest City',
  'passengerNetList.duration': 'Duration',
  'passengerNetList.idCard': 'Id Card',
  'passengerNetList.seatNo': 'Seat No',
  'passengerNetList.beginTime': 'Begin Time',
  'passengerNetList.useTime': 'Use Time（s）',
  'passengerNetList.upFlow': 'Up Flow（KB）',
  'passengerNetList.downFlow': 'Down Flow（KB）',
  'passengerNetList.sumFlow': 'Sum Flow（KB）',

  'passengerNetList.last1d': 'Last day',
  'passengerNetList.last2d': 'Last 2 days',
  'passengerNetList.last3d': 'Last 3 days',
  'passengerNetList.last5d': 'Last 5 days',
  'passengerNetList.last7d': 'Last 7 days',
  'passengerNetList.last30d': 'Last 30 days',
  'passengerNetList.custom': 'Custom',
}