export default {
  'nmsRecord.title': 'Data Download',
  'nmsRecord.pageDescription': 'View and download of airborne equipment operation log',

  'nmsRecord.onboardDevice': 'Device:',
  'nmsRecord.recordItem': 'Record item',
  'nmsRecord.all': 'Select all',
  'nmsRecord.stow': 'Stow',
  'nmsRecord.more': 'More',
  'nmsRecord.modifiedAircraft': 'Aircraft:',
  'nmsRecord.modifiedTime': 'Modified time:',
  'nmsRecord.enterTailNumber': 'Tail No',
  'nmsRecord.runDate': 'Date:',
  'nmsRecord.today': 'Today',
  'nmsRecord.thisWeek': 'Last week',
  'nmsRecord.thisMonth': 'Last month',
  'nmsRecord.query': 'Search',
  'nmsRecord.runLog': 'Run log',
  'nmsRecord.downloadLog': 'Export',
  'nmsRecord.recordTime': 'Record time',
  'nmsRecord.recordValue': 'Record value',
  'nmsRecord.recordIndicatorSelection': 'Record indicator selection',
  'nmsRecord.customColumns': 'Display',
  'nmsRecord.indicatorIsChecked': 'Only when the record indicator is checked can the allowed log be queried',
  'nmsRecord.selectAirborne': 'Please select airborne equipment',
  'nmsRecord.selectStartTime': 'Please select log start time',
  'nmsRecord.selectEndTime ': 'Please select the end time of the log',
  'nmsRecord.selectCustom': 'Please select a custom column',

  'nmsRecord.parameterSwitchedTo': 'The following parameter status has been switched to',

  'nmsRecord.record': 'Record',
  'nmsRecord.backLatest': 'Back to the latest',
  'nmsRecord.realTimeData': 'Real-time data',
  'nmsRecord.seconds': 'Seconds',
  'nmsRecord.minutes': 'Minutes',
  'nmsRecord.hours': 'Hours',
  'nmsRecord.timeInterval': 'Time interval',
  'nmsRecord.altitude': 'Altitude',
  'nmsRecord.cellular': 'Cellular',
  'nmsRecord.satellite': 'Satellite',
  'nmsRecord.flightNo': 'FlightNo',
  'nmsRecord.link': 'Link',
  'nmsRecord.unlink': 'Unlink',
  'nmsRecord.unknow': 'Unknow',

  'nmsRecord.second': '30s',
  'nmsRecord.second2': '60s',
  'nmsRecord.hour': '1h',

  'nmsRecord.keywords': 'Keywords：',
  'nmsRecord.keywordsPlaceHolder': 'Keywords...',
};
