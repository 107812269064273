export default {
  'login.login': 'Login',
  'login.userName': 'Please enter user name',
  'login.userPass': 'Please enter your password',
  'login.loginSuccess': 'Login successful',
  'login.loading': 'loading...',
  'login.userNameErr': 'Username can not be empty',
  'login.userPassErr': 'Password can not be empty',
  'login.adminPermission': 'Please contact the administrator to set the function permissions. The current account is not enabled',
  'login.welcome': 'Welcome XOP',
  'login.xopDetails': 'XstreampOP, referred to as XOP, is a ground platform solution based on aviation Internet operation proposed by Feitian. It is composed of six modules. It aims to carry real business application scenarios, build a general operation platform of aviation Internet, and provide platform support for distributed intelligent business applications of aviation Internet.',
};