export default {
  'nmsMonitorEvent.title': 'Monitor Event',

  'nmsMonitorEvent.levelSet': 'Level Set',
  'nmsMonitorEvent.addEvent': 'Add',
  'nmsMonitorEvent.editEvent': 'Edit',

  'nmsMonitorEvent.nameSearch': 'Name search...',
  'nmsMonitorEvent.all': 'All',

  'nmsMonitorEvent.name': 'Name',
  'nmsMonitorEvent.levelName': 'Level name',
  'nmsMonitorEvent.conditionDescription': 'Condition description',
  'nmsMonitorEvent.operate': 'Operation',

  'nmsMonitorEvent.edit': 'Edit',
  'nmsMonitorEvent.delete': 'Delete',
  'nmsMonitorEvent.deleteConfirm': 'Delete Confirm',
  'nmsMonitorEvent.thinkAgain': 'Think Again',
  'nmsMonitorEvent.confirm': 'Confirm',

  'nmsMonitorEvent.cancel': 'Cancel',
  'nmsMonitorEvent.submit': 'Submit',
  'nmsMonitorEvent.inputName': 'Input Name',
  'nmsMonitorEvent.inputDescription': 'Input Description...',
  'nmsMonitorEvent.inputKey': 'Input Key...',
  'nmsMonitorEvent.recent': 'Recent',
  'nmsMonitorEvent.minutes': 'Minutes',
  'nmsMonitorEvent.is': 'Is',
  'nmsMonitorEvent.isNot': 'Is Not',
  'nmsMonitorEvent.contain': 'Contain',
  'nmsMonitorEvent.noContain': 'NoContain',
  'nmsMonitorEvent.isNull': 'Is Null',
  'nmsMonitorEvent.notNull': 'NotNull',
  'nmsMonitorEvent.changed': 'Changed',
  'nmsMonitorEvent.noChange': 'No Change',
  'nmsMonitorEvent.startWith': 'Start With',
  'nmsMonitorEvent.endWith': 'End With',
  'nmsMonitorEvent.notStartWith': 'Not Start With',
  'nmsMonitorEvent.notEndWith': 'Not End With',
  'nmsMonitorEvent.equal': 'Equal',
  'nmsMonitorEvent.notEqual': 'Not Equal',
  'nmsMonitorEvent.greatThan': 'Great Than',
  'nmsMonitorEvent.lessThan': 'Less Than',
  'nmsMonitorEvent.greatThanEqual': 'Ggreat Than Equal',
  'nmsMonitorEvent.lessThanEqual': 'Less Than Equal',
  'nmsMonitorEvent.current': 'Current',
  'nmsMonitorEvent.min': 'min',
  'nmsMonitorEvent.max': 'max',
  'nmsMonitorEvent.avg': 'avg',
  'nmsMonitorEvent.sum': 'sum',
  'nmsMonitorEvent.pre': 'pre',
  'nmsMonitorEvent.and': 'and',
  'nmsMonitorEvent.or': 'or',
  'nmsMonitorEvent.AND': 'AND',
  'nmsMonitorEvent.OR': 'OR',
};
