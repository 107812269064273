export default {
  'airlinesFlightPlan.title': '航班计划',

  'airlinesFlightPlan.tailNoType': '机尾号/机型',
  'airlinesFlightPlan.xinqi1': '星期一',
  'airlinesFlightPlan.xinqi2': '星期二',
  'airlinesFlightPlan.xinqi3': '星期三',
  'airlinesFlightPlan.xinqi4': '星期四',
  'airlinesFlightPlan.xinqi5': '星期五',
  'airlinesFlightPlan.xinqi6': '星期六',
  'airlinesFlightPlan.xinqi7': '星期天',

  'airlinesFlightPlan.inputTailNo': '请输入机尾号、航班号、机场、城市搜索',

  'airlinesFlightPlan.all': '全部',
  'airlinesFlightPlan.normal': '计划',
  'airlinesFlightPlan.delay': '延误',
  'airlinesFlightPlan.cancel': '取消',
  'airlinesFlightPlan.other': '其它',
};