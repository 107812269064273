export default {
  'logOplog.title': '操作日志',

  'logOplog.operationTime': '操作时间',
  'logOplog.username': '登录账号',
  'logOplog.realName': '用户姓名',
  'logOplog.service': '操作模块',
  'logOplog.params': '操作参数',

  'logOplog.inputUser': '用户搜索...',
  'logOplog.inputKeyword': '操作关键字搜索...',
};