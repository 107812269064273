export default {
  'nmsException.troubleshooting': 'Troubleshooting',
  'nmsException.tailNumber': 'Tail No',
  'nmsException.model': 'Model',
  'nmsException.manufacturer': 'Manufacturer',
  'nmsException.factoryDate': 'Factory date',
  'nmsException.firstFlightDate': 'First flight date',
  'nmsException.machineAge': 'Machine age',
  'nmsException.numberSeats': 'number of seats',
  'nmsException.totalLength': 'Total length (m)',
  'nmsException.wingspan': 'Wingspan (m)',
  'nmsException.machineHeight': 'Machine height (m)',
  'nmsException.open': 'Open',
  'nmsException.retract': 'Retract',
  'nmsException.deviceInstalled': 'Device installed',
  'nmsException.faultDetection': 'Fault detection item',
  'nmsException.solution': 'Solution',
  'nmsException.unknownUpdateTime': 'Unknown update time',
  'nmsException.aircraftNameZh': 'Chinese name',
  'nmsException.aircraftNameEn': 'English name',
  'nmsException.pilotNum': 'Pilot Number',
  'nmsException.IATA': 'IATA',
  'nmsException.ICAO': 'ICAO',


  'nmsException.normal': 'Normal',
  'nmsException.fullLoad': 'Full load',
  'nmsException.exception': 'Exception',
  'nmsException.notConnectedInvalid': 'Not connected / Invalid',
  'nmsException.unknown': 'Unknown',
  'nmsException.notMatch': 'Not match',
  'nmsException.noData': 'No data',

  'nmsException.faultWarningTime': 'Fault warning time',
  'nmsException.faultName': 'Fault name',
  'nmsException.recommendedSolution': 'Recommended solution',
  'nmsException.recoveryTime': 'Recovery time',
  'nmsException.untreated': 'Untreated',

  'nmsException.refreshAfter': 's Refresh',
  'nmsException.lostConnection': 'Lost connection',
  'nmsException.normalOperation': 'Normal operation',
  'nmsException.runException': 'Run exception',
  'nmsException.fullLoadState': 'Full load',
  'nmsException.unknownState': 'Not connected or invalid',
  'nmsException.unknownChinese': 'Unknown Chinese name',
  'nmsException.unknownEnglish': 'Unknown English name',

  'nmsException.basicInfo': 'Basic information',
  'nmsException.checkItem': 'Check item',
  'nmsException.checkStatus': 'check status',
  'nmsException.firstTime': 'First registration time',
  'nmsException.duration': 'Duration',
  'nmsException.edit': 'Operation',
};