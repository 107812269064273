export default {
  'iasNetList.title': 'Net list',

  'iasNetList.search': 'Keywords：',
  'iasNetList.pleaseInput': 'ID number or seat number',

  'iasNetList.idCard': 'Id card',
  'iasNetList.seatNo': 'Seat No',
  'iasNetList.accNum': 'Acc number',
  'iasNetList.beginTime': 'Begin time',
  'iasNetList.useTime': 'Use time（s）',
  'iasNetList.upFlow': 'Up flow（KB）',
  'iasNetList.downFlow': 'Down flow（KB）',
  'iasNetList.fee': 'Fee（cent）',
}