export default {
  'rbacPageForm.pageFunctionDescription': 'System software, catalog, menu configuration and definition',
  'rbacPageForm.add': 'Add',
  'rbacPageForm.edit': 'Edit',

  'rbacPageForm.type': 'Type',
  'rbacPageForm.contents': 'Content',
  'rbacPageForm.menus': 'Menu',
  'rbacPageForm.displayName': 'Display name',
  'rbacPageForm.icon': 'Icon',
  'rbacPageForm.higherLevel': 'Higher level',
  'rbacPageForm.sortNum': 'Sort number',
  'rbacPageForm.connectionAddress': 'Connection address',
  'rbacPageForm.authorizationSign': 'Authorization sign',
  'rbacPageForm.save': 'Save',
  'rbacPageForm.cancel': 'Cancel',
  'rbacPageForm.search': 'Search',

  'rbacPageForm.success': 'Successful',
  'rbacPageForm.pleaseEnterName': 'Please select name',
  'rbacPageForm.pleaseSelectParentDirectory': 'Please select parent directory',

  'rbacPageForm.operation': 'Operation',
  'rbacPageForm.delete': 'Delete',
  'rbacPageForm.baseInfo': 'Base Info',
  'rbacPageForm.btn': 'Button/',
  'rbacPageForm.searchKey': 'Keywords...',
  'rbacPageForm.add2': 'Add',
  'rbacPageForm.addFeatures': 'Add Features',
  'rbacPageForm.name': 'Name',
  'rbacPageForm.remark': 'Comments',
  'rbacPageForm.code': 'Code',
  'rbacPageForm.path': 'Path',
  'rbacPageForm.service': 'Service',
  'rbacPageForm.method': 'Method',
};
