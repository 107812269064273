export default {
  'nmsJoin.title': '接入明细',

  'nmsJoin.searchMac': 'MAC地址搜索...',
  'nmsJoin.searchTailNo': '机尾号搜索...',
  'nmsJoin.searchFlightNo': '航班号搜索...',
  'nmsJoin.searchGnsIp': '内网IP地址搜索...',
  'nmsJoin.deptSearch': '出发城市、机场三字码搜索...',
  'nmsJoin.destSearch': '到达城市、机场三字码搜索...',
  'nmsJoin.onlyShowInternet': '上网用户',

  'nmsJoin.search': '查询',
  'nmsJoin.exportData': '导出数据',

  'nmsJoin.tailNo': '机尾号',
  'nmsJoin.flightNo': '航班号',
  'nmsJoin.flightDate': '航班日期',
  'nmsJoin.deptCity': '出发',
  'nmsJoin.destCity': '到达',
  'nmsJoin.mac': '终端MAC地址',
  'nmsJoin.deviceName': '终端名称',
  'nmsJoin.gnsIp': '内网分配IP',
  'nmsJoin.externalIp': '外网分配IP',
  'nmsJoin.timestamp': '记录时间',

  'nmsJoin.last5m': '最近5分钟',
  'nmsJoin.last10m': '最近10分钟',
  'nmsJoin.last30m': '最近30分钟',
  'nmsJoin.last1h': '最近1小时',
  'nmsJoin.last24h': '最近24小时',
  'nmsJoin.custom': '自定义',
}