export default {
  'dataTraceBackDetails.title': 'Trace Back',

  'dataTraceBackDetails.refresh': 'Refresh',
  'dataTraceBackDetails.callXOPInterface': 'Call XOP interface',
  'dataTraceBackDetails.callThirdInterface': 'call third party interface',

  'dataTraceBackDetails.operationSuccessful': 'Operation successful',
  'dataTraceBackDetails.operationFailed': 'Operation failed',
  'dataTraceBackDetails.requestInput': 'Request input',
  'dataTraceBackDetails.responseResult': 'Response result',
};
