export default {
  'nmsMaintainWmt.title': 'Bit Info Configuration',
  'nmsMaintainWmt.pageDescription': 'Initialize the content to be displayed on WMT according to the logical relationship of daily maintenance and inspection of aircraft, installed equipment and equipment',

  'nmsMaintainWmt.basicInformation': 'Basic information',
  'nmsMaintainWmt.versionName': 'Version name',
  'nmsMaintainWmt.modificationScheme': 'Modification scheme',
  'nmsMaintainWmt.installationTime': 'Installation time',
  'nmsMaintainWmt.networkType': 'Network type',
  'nmsMaintainWmt.installationDiagram': 'Installation diagram',

  'nmsMaintainSetting.lan': 'LAN',
  'nmsMaintainSetting.satellite': 'Satellite',
  'nmsMaintainSetting.noNetwork': 'No network',

  'nmsMaintainWmt.equipmentAndInspectionItems': 'Equipment and inspection items',
  'nmsMaintainWmt.cancel': 'Cancel',
  'nmsMaintainWmt.packing': 'Packing',
  'nmsMaintainWmt.areYouSure': 'Are you sure you want to leave this page?',
};