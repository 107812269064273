export default {
  'settingsClient.title': 'Authorization',
  'settingsClient.pageDescription': 'Management of airborne network equipment or system authorization code of the airline',

  'settingsClient.all': 'ALL',
  'settingsClient.serviceProvider': 'Service provider',
  'settingsClient.device': 'Device',
  'settingsClient.addAuthorization': 'Add',

  'settingsClient.tailNumber': 'Tail No',
  'settingsClient.type': 'type',
  'settingsClient.lastUsedTime': 'Last used time',
  'settingsClient.expirationTime': 'Expiration time',
  'settingsClient.usageStatus': 'Usage status',
  'settingsClient.noteDescription': 'Note description',
  'settingsClient.enable': 'Enable',
  'settingsClient.disable': 'Disable',
  'settingsClient.warning': 'Warning',
  'settingsClient.authorizationCodeInUse': 'The authorization code is in use and cannot be deleted!',
  'settingsClient.AreYouSureYouWantToDeleteIt': 'This item may be referenced in other places. It will not work normally after deletion. Are you sure you want to delete it again?',
  'settingsClient.SuccessfullyEnabled': 'Successfully enabled',
  'settingsClient.SuccessfullyDisabled': 'Successfully disabled',

  'settingsClient.authorizationObject': 'Authorization object',
  'settingsClient.clickToGet': 'Click to get',
  'settingsClient.save': 'Save',
  'settingsClient.deviceAuthorization': 'Device authorization',
  'settingsClient.serviceProviderAuthorization': 'Service provider authorization (please wait)',
  'settingsClient.selectTailNumber': 'Tail No...',
  'settingsClient.autoGenerate': 'Auto generate',
  'settingsClient.newAuthorization': 'New authorization',
  'settingsClient.addAirborneNetworkDevice': 'Add airborne network device or service provider authorization',
};