export default {
  'nmsEventHistory.title': '历史事件',

  'nmsEventHistory.tailNumber': '机尾号',
  'nmsEventHistory.flightNumber': '航班号',
  'nmsEventHistory.timeInterval': '时间区间',
  'nmsEventHistory.query': '查询',
  'nmsEventHistory.tailNumberSearch': '机尾号搜索...',
  'nmsEventHistory.flightNumberSearch': '航班号搜索...',
  'nmsEventHistory.deptSearch': '出发城市、机场三字码搜索...',
  'nmsEventHistory.destSearch': '到达城市、机场三字码搜索...',
  'nmsEventHistory.eventNameSearch': '事件名称搜索...',
  'nmsEventHistory.eventLevelSelect': '选择事件级别',
  'nmsEventHistory.exportData': '导出数据',

  'nmsEventHistory.tailNo': '机尾号',
  'nmsEventHistory.flightNo': '航班号',
  'nmsEventHistory.deptCity': '出发',
  'nmsEventHistory.destCity': '到达',
  'nmsEventHistory.eventLevelName': '事件名称',
  'nmsEventHistory.eventPriority': '级别',
  'nmsEventHistory.eventTime': '触发时间',

  'nmsEventHistory.last5m': '最近5分钟',
  'nmsEventHistory.last10m': '最近10分钟',
  'nmsEventHistory.last30m': '最近30分钟',
  'nmsEventHistory.last1h': '最近1小时',
  'nmsEventHistory.last1d': '最近1天',
  'nmsEventHistory.last2d': '最近2天',
  'nmsEventHistory.last3d': '最近3天',
  'nmsEventHistory.custom': '自定义',
};
