export default {
  'iasNetList.title': '上网清单',

  'iasNetList.search': '关键字搜索：',
  'iasNetList.pleaseInput': '请输入证件号或座位号',

  'iasNetList.idCard': '证件号',
  'iasNetList.seatNo': '座位号',
  'iasNetList.accNum': '上网账号',
  'iasNetList.beginTime': '开始时间',
  'iasNetList.useTime': '上网时长（秒）',
  'iasNetList.upFlow': '上行流量（KB）',
  'iasNetList.downFlow': '下行流量（KB）',
  'iasNetList.fee': '费用（分）',
}