import React, {PureComponent} from "react";

import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {rootPath} from "shared/const";
import "./index.less";
import {AppContextEnhancer} from "shared/libs/app-context";

import {
  BellOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UpOutlined
} from "@ant-design/icons";

import {Menu, Dropdown, Badge, message, Popover, Button} from "antd";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {connect} from "react-redux";
import {setLocale, setTheme} from "store/config/actions";
import * as notice from "apis/notice";
import {FormattedMessage} from "react-intl";
import * as users from "apis/users";
import * as f from "apis/files";
import {logI} from "shared/utils";
import {chanageThemes, getThemes} from "styles/themes";

@AppContextEnhancer
@withRouter
@connect(
  ({authorization, config}) => ({
    userInfo: authorization.userData,
    locale: config.locale,
    theme: config.theme,
  }),
  {
    setLocale,
    setTheme,
  }
)
class AppBottom extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userMenuVisible: false,
      langsMenuVisible: false,
      themesMenuVisible: false,
      route: "",
      noticeList: [],
      noticeCount: 0,
      avatar: ""
    };
  }

  getUserInfo = async () => {
    let res = await users.getUserInfo();
    this.setState({
      avatar: res.avatar
    });
  };

  getUserMenu = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => this.props.history.push(`/app/personal-center/basicInfo`)}>
          <div className={"header-menu-item"}>
            <FormattedMessage id="personalCenter.menuBasicInfo"/>
          </div>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item onClick={() => this.props.history.push(`/app/personal-center/notification`)}>
          <div className={"header-menu-item"}>
            <FormattedMessage id="personalCenter.menuMessageSetting"/>
          </div>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item onClick={() => this.props.history.push(this.state.route)}>
          <div className={"header-menu-item"}>
            <FormattedMessage id="personalCenter.menuEditPassword"/>
          </div>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item onClick={this.logout}>
          <div className={"header-menu-item"}>
            <FormattedMessage id="personalCenter.menuQuit"/>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  getLangsMenu = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => this.changeLang("zh")}>
          <div className="header-menu-item">
            <img className="icon" src={require("chinese.png")} alt=""/>
            <span className="txt">简体中文</span>
          </div>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item onClick={() => this.changeLang("en")}>
          <div className="header-menu-item">
            <img className="icon" src={require("ic_english.png")} alt=""/>
            <span className="txt">English</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  renderNoticeList = () => {
    let isShowList = !!(
      this.state.noticeList && this.state.noticeList.length > 0
    );
    return (
      <div className={"notice-list-lay"}>
        <div className={"notice-list"}>
          {this.state.noticeList.map((item, index) => {
            let itemClass =
              index === this.state.noticeList.length - 1
                ? "notice-item-lay"
                : "notice-item-lay xop-border-bottom";
            return (
              <div className={itemClass} key={index}>
                <div className={"notice-item-title"}>{item.title}</div>
                <div
                  className={"notice-item-body"}
                  dangerouslySetInnerHTML={{__html: item.body}}
                />
                <div className={"notice-item-btn"}>
                  <a
                    onClick={() => {
                      this.setNoticeRead(item.id);
                    }}
                  >
                    <FormattedMessage id="FTPageHeader.gotIt"/>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className={"notice-item-bottom"}>
          {isShowList ? (
            <a className={"notice-item-text xop-border-top"}
               onClick={() => {
                 this.setNoticeReadAll();
               }}
            >
              <FormattedMessage id="FTPageHeader.readAll"/>
              {'(' + this.state.noticeList.length + ')'}
            </a>
          ) : (
            <FormattedMessage id="FTPageHeader.noNotice"/>
          )}
        </div>
      </div>
    );
  };

  handleClick = async isDark => {
    this.props.setTheme(isDark ? "dark" : "light");
    chanageThemes(isDark ? "dark" : "light");
    this.onThemesMenuVisible(false);
  };

  renderThemes = () => {
    return (
      <Dropdown
        className="themesLayout"
        overlay={
          <Menu>
            <Menu.Item onClick={() => this.handleClick(true)}>
              <div className="header-menu-item">
                <span className="txt">
                  <FormattedMessage id="FTPageHeader.dark"/>
                </span>
              </div>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item onClick={() => this.handleClick(false)}>
              <div className="header-menu-item">
                <span className="txt">
                  <FormattedMessage id="FTPageHeader.light"/>
                </span>
              </div>
            </Menu.Item>
          </Menu>
        }
        placement="topLeft"
        onVisibleChange={this.onThemesMenuVisible}
      >
        <div className="header-user-box top-margin-10">
          <div className="header-user-name">
            <FormattedMessage id="FTPageHeader.themes"/>
            {this.state.themesMenuVisible ? (
              <UpOutlined className="up-down-icon"/>
            ) : (
              <DownOutlined className="up-down-icon"/>
            )}
          </div>
        </div>
      </Dropdown>
    );
  };

  renderLangsDropdown = () => {
    const {locale} = this.props;
    return (
      <Dropdown
        className="langsLayout"
        overlay={this.getLangsMenu()}
        placement="topLeft"
        onVisibleChange={this.onLangVisibleChange}
      >
        <div className="header-user-box top-margin-10">
          <img
            className="icon"
            src={
              locale === "zh"
                ? require("chinese.png")
                : require("ic_english.png")
            }
            alt=""
          />
          <div className="header-user-name">
            {this.state.langsMenuVisible ? (
              <UpOutlined className="up-down-icon"/>
            ) : (
              <DownOutlined className="up-down-icon"/>
            )}
          </div>
        </div>
      </Dropdown>
    );
  };

  renderCollapsedBtn = () => {
    const {context,} = this.props;
    const {menuCollapsed, setMenuCollapsed} = context;
    return (
      <div
        className={"header-menu-fold"}
        onClick={() => setMenuCollapsed(!menuCollapsed)}
      >
        {menuCollapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
      </div>
    );
  };

  renderNotice = () => {
    return (
      <div>
        <Popover
          content={this.renderNoticeList()}
          placement="rightTop"
          getPopupContainer={() => document.getElementById('appHeaderLay')}
        >
          <div>
            <Badge dot={this.state.noticeCount > 0}>
              <BellOutlined style={{fontSize: "14px", color: '#ffffff'}}/>
            </Badge>
          </div>
        </Popover>
      </div>
    );
  };

  renderUserInfo = (menuCollapsed) => {
    const {userInfo} = this.props;
    return (
      <Dropdown
        overlay={this.getUserMenu()}
        placement="topLeft"
        onVisibleChange={this.onVisibleChange}
      >
        <div className="header-user-box">
          <div className="header-user-avatar">
            <img
              className="header-user-avatar-img"
              src={
                this.state.avatar
                  ? `${f.UPLOADED_PATH}/${this.state.avatar}`
                  : require("../../../../images/default_avatar.png")
              }
              alt=""
            />
          </div>
          {menuCollapsed ? <div style={{height: '20px'}}>&nbsp; </div> :
            <div className="header-user-name">
              <span>{userInfo.displayName}</span>
              {this.state.userMenuVisible ? (
                <UpOutlined className="up-down-icon"/>
              ) : (
                <DownOutlined className="up-down-icon"/>
              )}
            </div>
          }
        </div>
      </Dropdown>
    );
  };

  render() {
    this.getLinkUrl();
    const {context, theme} = this.props;
    const {menuCollapsed,} = context;
    let className = theme !== 'dark' ? 'menu-border-bottom' : 'xop-border-bottom';
    let upClassName = menuCollapsed ? "upCollapsedLayout " + className : "upLayout " + className;
    let bottomClassName = menuCollapsed ? "appBottomCollapsed" : "appBottom";
    return (
      <div id="appHeaderLay" className={bottomClassName}>
        <div className={upClassName}>
          {this.renderNotice()}
          {this.renderUserInfo(menuCollapsed)}
        </div>
        <div className={"lowerLayout"}>
          {this.renderCollapsedBtn()}
          {menuCollapsed ? '' : this.renderThemes()}
          {menuCollapsed ? '' : this.renderLangsDropdown()}
        </div>
      </div>
    );
  }

  logout = async e => {
    await sessionStorage.setItem("authinfo", "");
    this.props.history.replace("/login");
  };

  changeLang = locale => {
    this.props.setLocale(locale);
    this.onLangVisibleChange(false);
  };

  onThemesMenuVisible = visible => {
    this.setState({
      themesMenuVisible: visible
    });
  };

  onLangVisibleChange = visible => {
    this.setState({
      langsMenuVisible: visible
    });
  };

  onVisibleChange = visible => {
    this.setState({
      userMenuVisible: visible
    });
  };

  getLinkUrl = () => {
    this.setState({
      route: `/${rootPath}/personal-center/passwordEdit`
    });
  };

  getNoticeList = async () => {
    try {
      let res = await notice.getNotReadList();
      this.setState({
        noticeList: res.pageData,
        noticeCount: res.total ? res.total : 0
      });
    } catch (e) {
      message.error(e.message);
    }
  };

  setNoticeRead = async id => {
    try {
      await notice.setRead(id);
      this.getNoticeList();
    } catch (e) {
      message.error(e.message);
    }
  };

  setNoticeReadAll = async () => {
    try {
      await notice.setReadAll();
      this.getNoticeList();
    } catch (e) {
      message.error(e.message);
    }
  };

  componentDidMount() {
    this.getUserInfo();
    this.getNoticeList();
    this.timer = setInterval(() => {
      this.getUserInfo();
      this.getNoticeList();
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }
}

export default AppBottom;
