import React, {
  PureComponent
} from "react"
import cs from "classnames"
import AppMenu from "./pages/menu"
import {
  connect
} from "react-redux"
import AppContainer from "./pages/container"
import {
  AppContext
} from "shared/libs/app-context"
import {
  setUserInfo,
} from "store/authorization/actions"
import AppRouter from 'router'

import i18n from 'i18n-js'
import * as l from "shared/langs"

import {
  withState,
  compose,
} from "recompose"
import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {setPlanesInfo} from "store/flights-status/actions"
import "shared/fetch";

class App extends PureComponent {
  planeInfoHandler = (data) => {
    this.props.setPlanesInfo(data)
  };

  render() {
    const {
      menuCollapsed,
      setMenuCollapsed,
      userInfo,
      setUserInfo,
      location,
      menuHide,
    } = this.props

    const {
      pathname
    } = location

    const contextValue = {
      menuCollapsed,
      setMenuCollapsed,
      userInfo,
      setUserInfo,
      pathname,
    };

    const clazz = cs({
      "zr-app-home": true,
      //菜单是否收起
      "collapsed": menuHide !== 'true' && menuCollapsed,
      //菜单是否隐藏
      'menuHided': menuHide === 'true'
    });
    return (
      <AppContext.Provider value={contextValue}>
        <div className={clazz}>
          <div className='left'>
            <AppMenu menus={this.props.menus}/>
          </div>
          <div className='right'>
            <DndProvider backend={HTML5Backend}>
              <AppContainer>
                <AppRouter/>
              </AppContainer>
            </DndProvider>
          </div>
        </div>
      </AppContext.Provider>
    )
  }

  async componentDidMount() {
    global.socket.emit('planeInfo', {xopToken: this.props.auth.access_token});
    global.socket.on('planeInfo', this.planeInfoHandler);
    const {locale,} = this.props;
    if (locale && !i18n.translations[locale]) {
      await l.changeLang(locale)
      this.forceUpdate()
    }
  }

  componentWillUnmount() {
    global.socket.off('planeInfo');
  }
}

const appCompose = compose(
  connect(
    ({
       authorization,
       config,
     }) => ({
      userInfo: authorization.userData,
      locale: config.locale,
      menuHide: config.menuHide,
      menus: authorization.menus,
      auth: authorization.auth,
    }), {
      setUserInfo,
      setPlanesInfo,
    }),
  withState('menuCollapsed', 'setMenuCollapsed', false),
  withState('socketPlanesInfo', 'setSocketPlanesInfo', [])
)(App)

export default appCompose
