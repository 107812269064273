import update from "immutability-helper"
import {
  SET_LOCALE,
  SET_THEME,
  SET_MENU_HIDE,
} from "store/config/actions"

let locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : 'zh';
let theme = localStorage.getItem("app-theme") ? localStorage.getItem("app-theme") : 'light';
let menuHide = localStorage.getItem("app-menu-hide") ? localStorage.getItem("app-menu-hide") : 'false';

const initialState = {
  locale,
  theme,
  menuHide,
};

const handlers = {
  [SET_LOCALE](state, {
    locale
  }) {
    return update(state, {
      locale: {
        $set: locale
      },
    })
  },
  [SET_THEME](state, {
    theme
  }) {
    return update(state, {
      theme: {
        $set: theme
      },
    })
  },
  [SET_MENU_HIDE](state, {
    menuHide
  }) {
    return update(state, {
      menuHide: {
        $set: menuHide
      },
    })
  },
};

export default function app(state = initialState, action) {
  const type = action.type;
  if (!handlers[type]) return state;
  return handlers[type](state, action)
}
