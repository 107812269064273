export default {
  'rbacMenuForm.pageFunctionDescription': 'System software, catalog, menu configuration and definition',
  'rbacMenuForm.add': 'Add',
  'rbacMenuForm.edit': 'Edit',

  'rbacMenuForm.type': 'Type',
  'rbacMenuForm.contents': 'Content',
  'rbacMenuForm.menus': 'Menu',
  'rbacMenuForm.displayName': 'Display name',
  'rbacMenuForm.icon': 'Icon',
  'rbacMenuForm.higherLevel': 'Higher level',
  'rbacMenuForm.sortNum': 'Sort number',
  'rbacMenuForm.connectionAddress': 'Connection address',
  'rbacMenuForm.authorizationSign': 'Authorization sign',
  'rbacMenuForm.save': 'Save',
  'rbacMenuForm.cancel': 'Cancel',

  'rbacMenuForm.success': 'Successful',
  'rbacMenuForm.pleaseEnterName': 'Please select name',
  'rbacMenuForm.pleaseSelectParentDirectory': 'Please select parent directory',

  'rbacMenuForm.operation': 'Operation',
  'rbacMenuForm.delete': 'Delete',
  'rbacMenuForm.baseInfo': 'Base Info',
  'rbacMenuForm.btn': 'Button/',
  'rbacMenuForm.searchKey': 'Search Key...',
  'rbacMenuForm.add2': 'Add',
  'rbacMenuForm.addFeatures': 'Add Features',
  'rbacMenuForm.name': 'Name',
  'rbacMenuForm.remark': 'Comments',
  'rbacMenuForm.path': 'Path',
  'rbacMenuForm.service': 'Service',
  'rbacMenuForm.method': 'Method',
};
