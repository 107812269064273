export default {
  'nmsMaintainWmt.title': 'Bit Info 配置',
  'nmsMaintainWmt.pageDescription': '根据飞机、装机设备及设备日常维护检查的逻辑关系，对需在 WMT上进行显示的内容进行初始化',

  'nmsMaintainWmt.basicInformation': '基本信息',
  'nmsMaintainWmt.versionName': '版本名称',
  'nmsMaintainWmt.modificationScheme': '改装方案',
  'nmsMaintainWmt.installationTime': '安装时间',
  'nmsMaintainWmt.networkType': '网络类型',
  'nmsMaintainWmt.installationDiagram': '装机示意图',

  'nmsMaintainSetting.lan': '局域网',
  'nmsMaintainSetting.satellite': '卫星',
  'nmsMaintainSetting.noNetwork': '无网络',

  'nmsMaintainWmt.equipmentAndInspectionItems': '设备及检查项',
  'nmsMaintainWmt.cancel': '取消',
  'nmsMaintainWmt.packing': '打包',
  'nmsMaintainWmt.areYouSure': '你确定离开此页面吗?',

};