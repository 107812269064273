export default {
  'logOplog.title': 'Op-log',

  'logOplog.operationTime': 'Operation Time',
  'logOplog.username': 'User Name',
  'logOplog.realName': 'Real Name',
  'logOplog.service': 'Service',
  'logOplog.params': 'Params',

  'logOplog.inputUser': 'User...',
  'logOplog.inputKeyword': 'Activity...',
};