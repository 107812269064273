export default {
  'nmsAlarmTroubleshoot.title': '数据排查',

  'nmsAlarmTroubleshoot.deviceBitInfoList': '设备Bit信息清单',
  'nmsAlarmTroubleshoot.bitInfoList': 'Bit信息清单',
  'nmsAlarmTroubleshoot.deviceStatusOfAll': '设备所有参数状态',
  'nmsAlarmTroubleshoot.backToTop': '回到顶部',
  'nmsAlarmTroubleshoot.statusOfAll': '所有参数状态',
  'nmsAlarmTroubleshoot.checkItem': '检查项目',
  'nmsAlarmTroubleshoot.curStatus': '当前状态',
  'nmsAlarmTroubleshoot.refresh': '刷新页面',

  'nmsAlarmTroubleshoot.deviceBitInfoListExp1': '无数据：黑灯，数据未上报或所定义的参数Key无效。',
  'nmsAlarmTroubleshoot.deviceBitInfoListExp2': '无效指标：黄灯，检测参数值设置有误，例如设的是Pass，结果上报的1。',
  'nmsAlarmTroubleshoot.deviceBitInfoListExp3': '运行异常：红灯，检测参数出现异常，一般参数值为1或Fail。',
  'nmsAlarmTroubleshoot.deviceBitInfoListExp4': '满载运行：黄灯，检测参数出现满载，例如参数值高过某个值。',
  'nmsAlarmTroubleshoot.deviceBitInfoListExp5': '运行正常：绿灯，检测参数正常，一般参数值为0或Pass。',
  'nmsAlarmTroubleshoot.statusOfAllExp': '所有参数状态',
};