export default {
  'dataPackage.title': '套餐统计',

  'dataPackage.tailNumber': '机尾号',
  'dataPackage.flightNumber': '航班号',
  'dataPackage.timeInterval': '时间区间',
  'dataPackage.query': '查询',
  'dataPackage.tailNumberSearch': '机尾号搜索...',
  'dataPackage.flightNumberSearch': '航班号搜索...',
  'dataPackage.commodityNameSearch': '套餐名称搜索...',
  'dataPackage.deptSearch': '出发城市、机场三字码搜索...',
  'dataPackage.destSearch': '到达城市、机场三字码搜索...',
  'dataPackage.exportData': '导出数据',

  'dataPackage.flightDate': '航班日期',
  'dataPackage.dept': '出发',
  'dataPackage.dest': '到达',
  'dataPackage.flightDuration': '飞行时长',

  'dataPackage.commodityName': '运营标题',
  'dataPackage.satcomName': '运营商流量包名称',
  'dataPackage.total': '订单数量',
  'dataPackage.inactive': '未激活数',
  'dataPackage.activated': '激活数',
  'dataPackage.failed': '激活失败',
  'dataPackage.orderPassenger': '订购人数',
  'dataPackage.activatedPassenger': '激活人数',

  'dataPackage.time': '时间',
  'dataPackage.totalFlight': '按航班统计',
  'dataPackage.totalDay': '按日期统计',
  'dataPackage.totalWeek': '按周统计',
  'dataPackage.totalMonth': '按月统计',
  'dataPackage.totalQuarter': '按季度统计',
  'dataPackage.totalYear': '按年统计',

  'dataPackage.last1d': '最近1天',
  'dataPackage.last2d': '最近2天',
  'dataPackage.last3d': '最近3天',
  'dataPackage.last5d': '最近5天',
  'dataPackage.last7d': '最近7天',
  'dataPackage.last30d': '最近30天',
  'dataPackage.custom': '自定义',
};
