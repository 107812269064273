export default {
  'iasOrderInfo.title': 'Order Details',

  'iasOrderInfo.passengerName': 'Passenger Name',
  'iasOrderInfo.orderNo': 'Order ID (Channel)',
  'iasOrderInfo.xopOrderNo': 'Order ID (XOP)',
  'iasOrderInfo.passengerMail': 'Passenger Mail',
  'iasOrderInfo.orderAddress': 'Order Address',
  'iasOrderInfo.idCard': 'ID Number',
  'iasOrderInfo.createTime': 'Order Time',
  'iasOrderInfo.value': 'Data Flow',
  'iasOrderInfo.seatNo': 'Seat Number',
  'iasOrderInfo.orderFrom': 'Channel',
  'iasOrderInfo.offerInstId': 'Offer Inst Id',
  'iasOrderInfo.endTime2': 'End Time',


  'iasOrderInfo.title2': 'Flight',

  'iasOrderInfo.flightNumber': 'Flight Number',
  'iasOrderInfo.departureTime': 'Departure Time',
  'iasOrderInfo.airportDepartureLanding': 'Airport departure and landing',
  'iasOrderInfo.tailNumber': 'Tail No',
  'iasOrderInfo.flight': '航段',
  'iasOrderInfo.flightTime': 'Flight Time',
  'iasOrderInfo.onNetTime': 'On Net Time',
  'iasOrderInfo.usedDataFlow': 'Used Data Flow',
  'iasOrderInfo.restDataFlow': 'Rest Data Flow',


  'iasOrderInfo.title3': 'Data Flow Details',

  'iasOrderInfo.recordTime': 'Record Time',
  'iasOrderInfo.MAC': 'MAC Address',
  'iasOrderInfo.uploadDataFlow': 'Upload Data Flow',
  'iasOrderInfo.downloadDataFlow': 'Download Data Flow',
  'iasOrderInfo.shouldCost': 'Expected Deduction',
  'iasOrderInfo.mustCost': 'Actual Deduction',

  'iasOrderInfo.operatorTrafficName': 'Carrier Data Package Name',
  'iasOrderInfo.effectiveTrafficDeadline': 'Data Package Expiration Date',
  'iasOrderInfo.trafficSnapshot': 'Traffic snapshot',
  'iasOrderInfo.trafficParam': 'Traffic parameter',
  'iasOrderInfo.operationInfo': 'Operation information',
  'iasOrderInfo.trafficSupplier': 'Traffic supplier',
  'iasOrderInfo.satcomOfferId': 'Operator Data Flow ID',
  'iasOrderInfo.trafficType': 'Traffic type',
  'iasOrderInfo.trafficSize': 'Traffic size',
  'iasOrderInfo.uplinkBroadband': 'Uplink broadband',
  'iasOrderInfo.downlinkBroadband': 'Downlink broadband',
  'iasOrderInfo.creationTime': 'Creation time',
  'iasOrderInfo.specifyURL': 'Specify access URL',
  'iasOrderInfo.specifyApp': 'Specify access app',
  'iasOrderInfo.notOpenedYet': '(not opened yet, please wait)',
  'iasOrderInfo.fixedTimeLong': 'Fixed time long',
  'iasOrderInfo.maxAvailableTrafficSize': 'Max available traffic size',
  'iasOrderInfo.activeTime': 'Begin',
  'iasOrderInfo.endTime': 'End',
  'iasOrderInfo.flightNoFrom': 'Flight Number',
  'iasOrderInfo.tailNoFrom': 'Tail No',

  'iasOrderInfo.packageName': 'Package name',
  'iasOrderInfo.salesPrice': 'Sales price',
  'iasOrderInfo.effectiveDeadline': 'Effective deadline',
  'iasOrderInfo.note': 'Note description',

  'iasOrderInfo.usedDataflow': 'Used Dataflow',
  'iasOrderInfo.restDataflow': 'Rest Dataflow',
  'iasOrderInfo.day': 'days',
};