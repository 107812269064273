export default {
  'nmsDeviceMonitor.title': '数据告警',
  'nmsDeviceMonitor.pageDescription': '设备状态基本信息',

  'nmsDeviceMonitor.realTimeData': '实时数据',
  'nmsDeviceMonitor.aircraftHealthAlarm': '设备健康告警',
  'nmsDeviceMonitor.tailNumberEg': '请输入机尾号搜索...',
  'nmsDeviceMonitor.airborneDeviceOperationStatus': '机载设备运行状态',
  'nmsDeviceMonitor.unknown': '未知',
  'nmsDeviceMonitor.lostConnection': '失联',
  'nmsDeviceMonitor.exception': '异常',
  'nmsDeviceMonitor.fullLoad': '满载',
  'nmsDeviceMonitor.normal': '正常',
  'nmsDeviceMonitor.notMatch': '无效指标',
  'nmsDeviceMonitor.noData': '无数据',
  'nmsDeviceMonitor.historyFaultDetectionRecord': '设备健康告警',
  'nmsDeviceMonitor.all': '全部',
  'nmsDeviceMonitor.tailNumber': '机尾号',
  'nmsDeviceMonitor.faultWarningTime': '数据告警时间',
  'nmsDeviceMonitor.deviceFaultName': '故障名称',
  'nmsDeviceMonitor.numberAlarms': '告警次数',
  'nmsDeviceMonitor.recommendedSolution': '建议解决方案',
  'nmsDeviceMonitor.recoveryTime': '恢复时间',
  'nmsDeviceMonitor.deviceName': '设备名称',
  'nmsDeviceMonitor.loadMore': '查看更多',
  'nmsDeviceMonitor.status': '状态',
  'nmsDeviceMonitor.service': '服务',
  'nmsDeviceMonitor.device': '设备',

  'nmsDeviceMonitor.tailNo': '机尾号',
  'nmsDeviceMonitor.eventLevelName': '事件名称',
  'nmsDeviceMonitor.eventPriority': '级别',
  'nmsDeviceMonitor.eventTime': '触发时间',

  'nmsDeviceMonitor.faultWarning': '数据告警',
  'nmsDeviceMonitor.realTimeWarningAircraft': '飞机设备故障实时预警',
  'nmsDeviceMonitor.faultCheck': '故障排查',
  'nmsDeviceMonitor.equipmentFailureChecklist': '设备故障排查清单',
  'nmsDeviceMonitor.troubleshooting': '故障处理',
  'nmsDeviceMonitor.lookUpHistorySimilarTroubleshooting': '查阅历史类似故障解决方案',
  'nmsDeviceMonitor.putIntoOperation': '投入运营',
  'nmsDeviceMonitor.realTimeRecordEquipmentOperation': '设备运行数据实时记录',
  'nmsDeviceMonitor.check': '排查',
  'nmsDeviceMonitor.devicesWarning': '告警历史'
};