export default {
  'passengerRegister.title': '注册记录',
  'passengerRegister.pageDescription': '普通旅客注册成为航司常旅客日志记录',

  'passengerRegister.keyword': '关键字',
  'passengerRegister.timeInterval': '注册时间',
  'passengerRegister.flightNumber': '航班号',
  'passengerRegister.certificateNo': '证件号',
  'passengerRegister.seatNumber': '座位号',
  'passengerRegister.tailNumber': '机尾号',
  'passengerRegister.phoneNumber': '手机号',
  'passengerRegister.status': '状态',
  'passengerRegister.creationTime': '创建时间',

  'passengerRegister.success': '成功',
  'passengerRegister.failed': '失败',
};