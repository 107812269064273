export default {
  'iasNetRealTimeCost.title': 'Real time cost',

  'iasNetRealTimeCost.search': 'Keywords search：',
  'iasNetRealTimeCost.pleaseInput': 'Please enter ID number or seat number',

  'iasNetRealTimeCost.idCard': 'Id card',
  'iasNetRealTimeCost.seatNo': 'Seat No',
  'iasNetRealTimeCost.accNum': 'Acc number',
  'iasNetRealTimeCost.fee': 'Fee（cent）',
}