export default {
  'rbacPage.title': '页面管理',

  'rbacPage.searchKey': '关键字搜索...',
  'rbacPage.add': '添加',
  'rbacPage.displayName': '显示名称',
  'rbacPage.sortNum': '排序号',
  'rbacPage.connectionAddress': '连接地址',
  'rbacPage.authorizationSign': '授权标示',
  'rbacPage.operation': '操作',
  'rbacPage.edit': '编辑',
  'rbacPage.delete': '删除',
};
