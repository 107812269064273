import React from 'react';
import {
  connect
} from "react-redux"
import * as auth from "apis/auth"
import style from "./style.less"
import {
  compose,
  withState,
  withHandlers,
  lifecycle
} from "recompose"
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import {Button, Input, message} from 'antd';
import {
  cls, logI, logE
} from 'shared/utils'
import {FormattedMessage, injectIntl} from 'react-intl';
import {getString} from "shared/langs";
import {
  setAuthInfo,
  setUserInfo,
  setMenus
} from "store/authorization/actions"
import {setLocale} from "store/config/actions"
import JSEncrypt from "jsencrypt";
import * as menusUtil from 'menus'

const PUBLICKEY =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDWUy3g9lI9XZAv0Fgs7j1Jmffc1eyyfA8XEZeNhrTmpZDuHDuB3v6PBGS0XYONGdfXQkuGW447AC/Ul0uJmlAc2ufWyOXsgcgtj25jhd3Xc9r5WM9QgU4SDK+jyubyc11hDkwNQqGEPephIEOxac1xSJn8rl2lGNEuMNzNZuQnMQIDAQAB";
const {
  create
} = Form;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 24
    },
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 24
    },
  },
};

class Login extends React.Component {
  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;
    const {intl} = this.props;
    const placeholderName = getString(intl, 'login.userName');
    const placeholderPwd = getString(intl, 'login.userPass');
    const version = process.env.REACT_APP_APP_VERSION;
    const usernameError = isFieldTouched('username') && getFieldError('username');
    const passwordError = isFieldTouched('password') && getFieldError('password');
    return (
      <div className={cls("page-content", style.login)}>
        <div className={cls("content-wrapper", style.container)}>
          <div className={style.card}>
            <div className={style.leftLayout}>
              <div className={style.log_title_img}>
                <img src={require('login_img.png')} alt=""/>
              </div>
            </div>
            <div className={style.rightLayout}>
              <div className={style.title_right_title}><FormattedMessage id="login.welcome"/></div>
              <Form onSubmit={this.handleSubmit} className={style.card_item_lay} {...formItemLayout}>
                <div className={style.title_right_txt}>
                  <FormattedMessage id="login.xopDetails"/>
                </div>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                  {getFieldDecorator('username', {
                    rules: [{required: true, message: getString(intl, "login.userNameErr")}],
                  })(
                    <Input
                      style={{width: '360px', height: '40px', marginBottom: '16px'}}
                      prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                      placeholder={placeholderName}
                    />,
                  )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: getString(intl, "login.userPassErr")}],
                  })(
                    <Input
                      style={{width: '360px', height: '40px'}}
                      prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                      type="password"
                      placeholder={placeholderPwd}
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit"
                          style={{width: '360px', height: '40px', marginTop: '36px'}}
                          disabled={this.props.hasErrors(getFieldsError())}
                          loading={this.props.loading}
                  >{this.props.loginState}</Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <div className={cls(style.copyright, 'version&' + (version || "v12.32.445-66"))}>Copyright&nbsp;&nbsp;
          <span className={style.blue}>@ 2019-2021</span> FTS ( {version || "v12.32.445-66"} )&nbsp;&nbsp;&nbsp;
          <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'>京ICP备18038583号-2</a>&nbsp;&nbsp;&nbsp;
          <a onClick={() => this.props.setLocale('zh')}>&nbsp;中文&nbsp;</a>
          <a onClick={() => this.props.setLocale('en')}>&nbsp;English&nbsp;</a>
        </div>
      </div>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!!err) {
        return
      }
      this.props.submitted(values.username, values.password)
    })
  }
}

const Comp = compose(
  connect(
    ({
       authorization,
       config
     }) => ({
      auth: authorization.auth,
      locale: config.locale
    }), {
      setLocale,
      setAuthInfo,
      setUserInfo,
      setMenus
    }
  ),
  injectIntl,
  create(),
  withState("loading", "setLoading", false),
  withState("loginState", "setLoginState", props => getString(props.intl, "login.login")),
  withHandlers({
      hasErrors: props => (fieldsError) => {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
      }
    }
  ),
  withHandlers({
    checkResources: props => async (menuList) => {
      const {search} = props.location;
      logI("--checkResources--", search);
      if (search && search.length > 3) {
        props.history.push(search.substring(1, search.length));
        return;
      }
      if (menuList && menuList.length > 0) {
        for (let item of menuList) {
          if (item.children) {
            for (let childrenItem of item.children) {
              if (childrenItem.path) {
                props.history.push(childrenItem.path);
                return;
              }
            }
          } else if (item.path) {
            props.history.push(item.path);
            return;
          }
        }
      } else {
        props.setLoginState(getString(props.intl, "login.login"));
        message.error(getString(props.intl, "login.adminPermission"));
      }
    }
  }),
  withHandlers({
    submitted: props => async (userName, password) => {
      try {
        props.setLoading(true);
        let encrypt = new JSEncrypt();
        encrypt.setPublicKey(PUBLICKEY);
        let encryptedPass = encrypt.encrypt(password);
        props.setLoginState(getString(props.intl, "login.loading"));
        let res = await auth.login(userName, encryptedPass)
        if (!!res.access_token) {
          let timestamp = Math.ceil(new Date().getTime() / 1000)
          await props.setAuthInfo({
            access_token: res.access_token,
            expires_in: (timestamp + res.expires_in - 200),
            refresh_token: res.refresh_token,
            scope: res.scope,
            token_type: res.token_type,
          });
          await props.setUserInfo(res.user);
          let menuList = menusUtil.data2Menus(res.user.menus);
          logI('--submitted--menuList=', menuList);
          await props.setMenus(menuList);
          props.checkResources(menuList);
        }
      } catch (e) {
        props.setLoginState(getString(props.intl, "login.login"));
        message.error(e.message)
        logE(e)
      } finally {
        props.setLoading(false);
      }
    }
  }),
  lifecycle({
    async componentDidMount() {
      this.forceUpdate()
      this.props.form.validateFields();
    }
  }),
)(Login)

export default Comp