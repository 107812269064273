export default {
  'aircraftType.title': '机型管理',

  'aircraftType.search': '搜索机型...',
  'aircraftType.newModel': '新增机型',
  'aircraftType.model': '机型',
  'aircraftType.ChineseName': '中文名称',
  'aircraftType.EnglishName': '英文名称',
  'aircraftType.productionCompany': '生产公司',
  'aircraftType.firstFlightDate': '首飞日期',
  'aircraftType.operation': '操作',
  'aircraftType.edit':'编辑',
  'aircraftType.editModel': '编辑机型',
  'aircraftType.numberOfPilots': '飞行员数量',
  'aircraftType.fuselageLength': '机身长度',
  'aircraftType.save': '保存',
  'aircraftType.cancel': '取消',
  'aircraftType.IATA3': 'IATA三字码',
  'aircraftType.CODE4': '四字码',
  'aircraftType.People': '人',
  'aircraftType.twoPlaces': '请输入小数点后两位',
  'aircraftType.m': '米',
};