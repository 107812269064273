export default {
  'passengerLogin.title': 'Auth Log',
  'passengerLogin.pageDescription': 'Passenger online record, view network access request status',

  'passengerLogin.keyword': 'Flight number',
  'passengerLogin.timeInterval': 'Login time',
  'passengerLogin.flightNumber': 'Flight number',
  'passengerLogin.certificateNo': 'Certificate No.',
  'passengerLogin.seatNumber': 'Seat number',
  'passengerLogin.tailNumber': 'Tail No',
  'passengerLogin.IpAddress': 'IP address',
  'passengerLogin.status': 'Status',
  'passengerLogin.creationTime': 'Creation time',
  'passengerLogin.accountDuplication': 'Account duplication',
  'passengerLogin.all': 'All',

  'passengerLogin.success': 'Success',
  'passengerLogin.failed': 'Failed',

  'passengerLogin.inputTailNo': 'Tail No...',
  'passengerLogin.inputFlightNumber': 'Flight Number...',

  'passengerLogin.internalIp': 'Internal Ip',
  'passengerLogin.externalIp': 'External Ip',
  'passengerLogin.accNumber': 'Acc number',
};