export default {
  'nmsResume.title': 'Device Resume',

  'nmsResume.deviceName': 'Device name',
  'nmsResume.serialNo': 'Serial No',
  'nmsResume.operation': 'Operation',
  'nmsResume.query': 'Search',
  'nmsResume.resume': 'Resume',
  'nmsResume.deviceNameExp': 'Device name...',
  'nmsResume.serialNoExp': 'Serial No...',

  'nmsResume.refresh': 'Refresh',
  'nmsResume.model': 'Model',
  'nmsResume.pn': 'PN',
  'nmsResume.modNo': 'MOD No',
};
