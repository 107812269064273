export default {
  'airlinesPreinstallForm.title': '改装预设',

  'airlinesPreinstallForm.step1': '选择改装方案',
  'airlinesPreinstallForm.step2': '确认设备构型',
  'airlinesPreinstallForm.step3': '填写小站信息',
  'airlinesPreinstallForm.step4': '选择机载软件',
  'airlinesPreinstallForm.step5': '授权机载系统',
  'airlinesPreinstallForm.step6': '完成预设',

  'airlinesPreinstallForm.printer': '打印',
  'airlinesPreinstallForm.lastStep': '上一步',
  'airlinesPreinstallForm.nextStep': '下一步',
  'airlinesPreinstallForm.nextSupplement': '下次补充',
  'airlinesPreinstallForm.ok': '完成',
  'airlinesPreinstallForm.cancel': '取消',

  'airlinesPreinstallForm.tailNo': '选择需要改装的飞机',
  'airlinesPreinstallForm.aircraftType':'飞机型号',
  'airlinesPreinstallForm.tailNo.': '机尾号',
  'airlinesPreinstallForm.refittingTime': '计划装机时间',
  'airlinesPreinstallForm.refittingPlan': '改装方案',
  'airlinesPreinstallForm.netType': '支持网络类型',
  'airlinesPreinstallForm.installDevice': '安装设备',
  'airlinesPreinstallForm.refittingDiagram': '装机示意图',
  'airlinesPreinstallForm.refittingDiagramBtn': '上传飞机示意图',
  'airlinesPreinstallForm.aircraftTypeExp': '选择机型...',
  'airlinesPreinstallForm.tailNoExp': '选择飞机...',
  'airlinesPreinstallForm.refittingDiagramExp': '(200kb以内png图片，建议最佳长宽比900:400)',
  'airlinesPreinstallForm.ISP': '网络运营商',
  'airlinesPreinstallForm.VSP': '增值服务商',
  'airlinesPreinstallForm.aaaType': 'AAA控制方式',

  'airlinesPreinstallForm.NONE': '无',
  'airlinesPreinstallForm.GROUND': '地面AAA',
  'airlinesPreinstallForm.ON_BOARD': '机上AAA',

  'airlinesPreinstallForm.inputProductNo': '请输入产品编码',
  'airlinesPreinstallForm.productNo': '产品编码',
  'airlinesPreinstallForm.manufactureSerialNo': '制造序号',
  'airlinesPreinstallForm.hardwareVersion': '硬件版本',

  'airlinesPreinstallForm.ip': '飞机IP /机载服务器IP',
  'airlinesPreinstallForm.modmanIp': 'MODMAN IP地址',
  'airlinesPreinstallForm.pointManagerGroup': '小站所属管理组编号',
  'airlinesPreinstallForm.pointVnoCode': 'VNO编码',
  'airlinesPreinstallForm.pointStationCode': '小站编码',

  'airlinesPreinstallForm.selectSoftware': '选择软件',
  'airlinesPreinstallForm.fillingSoftwareName': '软件名称',
  'airlinesPreinstallForm.supplier': '供应商',
  'airlinesPreinstallForm.project': '所属项目',
  'airlinesPreinstallForm.version': '版本',
  'airlinesPreinstallForm.name': '产品名称',
  'airlinesPreinstallForm.pn': '产品编号(PartNumber)',
  'airlinesPreinstallForm.subsVersion': '产品版本',
  'airlinesPreinstallForm.allZip': '全量包',

  'airlinesPreinstallForm.enabled': '可使用状态',
  'airlinesPreinstallForm.remark': '备注',
  'airlinesPreinstallForm.clickToGet':'点击获取',
  'airlinesPreinstallForm.inputNameKey':'请输入软件名称、供应商所属项目的关键字搜索...',
  'airlinesPreinstallForm.Enable':'启用',
  'airlinesPreinstallForm.Disable':'停用',
  'airlinesPreinstallForm.Recommended':'(推荐)',
};
