export default {
  'iasPassengerOnlineDetails.title': '上网旅客明细',

  'iasPassengerOnlineDetails.cardId': '证件号',
  'iasPassengerOnlineDetails.seatNumber': '座位号',
  'iasPassengerOnlineDetails.flightNumber': '航班号',
  'iasPassengerOnlineDetails.numberOfDevicesUsed': '使用设备数量',
  'iasPassengerOnlineDetails.passengerUsedTraffic': '旅客已用流量',
  'iasPassengerOnlineDetails.statusOfPassengerNetwork': '旅客网络可用状态',
  'iasPassengerOnlineDetails.AccountStatus': '账号状态',
  'iasPassengerOnlineDetails.temporaryOffline': '临时下线',
  'iasPassengerOnlineDetails.normalUse': '正常使用',
  'iasPassengerOnlineDetails.permanentBlackout': '永久拉黑',
  'iasPassengerOnlineDetails.flightIATA': '二字码航班',
  'iasPassengerOnlineDetails.flightICAO': '三字码航班',
  'iasPassengerOnlineDetails.deptCodeIATA': '出发机场三字码',
  'iasPassengerOnlineDetails.deptCodeICAO': '出发机场四字码',
  'iasPassengerOnlineDetails.destCodeIATA': '到达机场三字码',
  'iasPassengerOnlineDetails.destCodeICAO': '到达机场四字码',
  'iasPassengerOnlineDetails.destCity': '到达机场',
  'iasPassengerOnlineDetails.deptCity': '出发机场',

  'iasPassengerOnlineDetails.tailNo': '机尾号',
  'iasPassengerOnlineDetails.actualTakeOffTime': '实际起飞时间',
  'iasPassengerOnlineDetails.actualArriveTime': '实际到达时间',
  'iasPassengerOnlineDetails.arriveTime': '计划到达时间',
  'iasPassengerOnlineDetails.takeOffTime': '计划起飞时间',
  'iasPassengerOnlineDetails.predictArriveTime': '预计到达时间',
  'iasPassengerOnlineDetails.predictTakeOffTime': '预计起飞时间',
  'iasPassengerOnlineDetails.flightDate': '航班日期',
  'iasPassengerOnlineDetails.deptCityDestCity': '飞行航段',

  'iasPassengerOnlineDetails.trafficConsumption': '流量消耗',

  'iasPassengerOnlineDetails.internetDevice': '上网设备',
  'iasPassengerOnlineDetails.listOfEquipmentUsed': '当前航班使用过的设备列表',
  'iasPassengerOnlineDetails.IPAddress': 'GNS分配地址',
  'iasPassengerOnlineDetails.MACAddress': 'MAC地址',
  'iasPassengerOnlineDetails.externalIp': '运营商分配地址',
  'iasPassengerOnlineDetails.InternetOnlineStatus': '互联网在线状态',
  'iasPassengerOnlineDetails.just': '刚刚',
  'iasPassengerOnlineDetails.mAgo': '分钟前',
  'iasPassengerOnlineDetails.hAgo': '小时前',

  'iasPassengerOnlineDetails.availablePackages': '可用套餐',
  'iasPassengerOnlineDetails.listOfAvailablePackages': '当前航班可用套餐列表',
  'iasPassengerOnlineDetails.thirdPartyOrder': '渠道订单号',
  'iasPassengerOnlineDetails.order': '序号',
  'iasPassengerOnlineDetails.XOPOrderNumber': 'XOP订单号',
  'iasPassengerOnlineDetails.usedTraffic': '流量大小(MB)',
  'iasPassengerOnlineDetails.effectiveTime': '生效时间',
  'iasPassengerOnlineDetails.endTime': '完结时间',
  'iasPassengerOnlineDetails.inUse': '正在使用',
  'iasPassengerOnlineDetails.unlimited': '不限',
  'iasPassengerOnlineDetails.offset': '有效时长',
  'iasPassengerOnlineDetails.satcomOfferId': '运营商订单号',
  'iasPassengerOnlineDetails.status': '订单状态',
  'iasPassengerOnlineDetails.packageName': '流量包名称',
  'iasPassengerOnlineDetails.beginTime': '激活生效时间',
}