export default {
  'toolkit.titleSource': 'Data Source',
  'toolkit.titleInterface': 'Data Interface',
  'toolkit.titleChart': 'Chart',
  'toolkit.titlePreview': 'View Chart',
  'toolkit.titlePanel': 'Panel',

  'toolkit.newSource': 'Add',
  'toolkit.editSource': 'Edit',
  'toolkit.testSource': 'Test',
  'toolkit.add': 'Add',
  'toolkit.saveRelease': 'Save and Publish',
  'toolkit.addChart': 'Add',
  'toolkit.exportChart': 'Snapshot',
  'toolkit.exportData': 'Export',
  'toolkit.addPanel': 'Add',

  'toolkit.export': 'Export',
  'toolkit.filter': 'Filter',
  'toolkit.chart': 'Chart',
  'toolkit.style': 'Style',
  'toolkit.filterWidget': 'Filter Widget',
  'toolkit.chartWidget': 'Drag out the required data chart',
  'toolkit.setPanelStyle': 'Set dashboard style',
  'toolkit.timeWidget': 'Time Widget',
  'toolkit.date': 'Date',
  'toolkit.dateRange': 'Date Range',
  'toolkit.timeRange': 'Time Range',
  'toolkit.textWidget': 'Text Widget',
  'toolkit.oneSelect': 'Dropdown Single Select',
  'toolkit.multiSelect': 'Dropdown Multi-Select',

  'toolkit.nameWidget': 'Widget Name',
  'toolkit.subject': 'Subject',
  'toolkit.default value': 'Default Value',
  'toolkit.beginTime': 'Start Time',
  'toolkit.endTime': 'End Time',
  'toolkit.stow': 'Collapse',
  'toolkit.expand': 'Expand',
  'toolkit.option': 'Option',
  'toolkit.filterChar': 'Filter Field',

  'toolkit.last5minutes': 'Last 5 Minutes',
  'toolkit.last10minutes': 'Last 10 Minutes',
  'toolkit.last30minutes': 'Last 30 Minutes',
  'toolkit.last1day': 'Last 1 Day',
  'toolkit.last7days': 'Last 7 Days',
  'toolkit.last30days': 'Last 30 Days',
  'toolkit.currentDay': 'Current Day',

  'toolkit.px': 'Pixel',
  'toolkit.width': 'Width (Ignored or Multiple of 36)',

  'toolkit.interfaceKeySearch': 'API Name...',
  'toolkit.interfaceNameClick': 'Click to set interface function name...',
  'toolkit.tableKeySearch': 'Keywords...',
  'toolkit.pathInput': 'Interface address...',
  'toolkit.chartSearch': 'Chart Name/API Name...',
  'toolkit.panelSearch': 'Name...',
  'toolkit.selectApi': 'Select Data API',

  'toolkit.nameSource': 'Data Connection Name',
  'toolkit.driver': 'Driver',
  'toolkit.schema': 'Database',
  'toolkit.host': 'Host',
  'toolkit.port': 'Port',
  'toolkit.username': 'User',
  'toolkit.pwd': 'Password',
  'toolkit.charset': 'Catharses',
  'toolkit.urlSource': 'Source',

  'toolkit.method': 'Type',
  'toolkit.interfaceName': 'Name',
  'toolkit.interfaceDescribe': 'Interface Description',
  'toolkit.online': 'Online',
  'toolkit.offline': 'Offline',
  'toolkit.test': 'Test',
  'toolkit.onlineed': 'Published',
  'toolkit.notOnline': 'Not Published',
  'toolkit.path': 'Path',
  'toolkit.params': 'Request Parameters',
  'toolkit.required': 'Required',
  'toolkit.type': 'Parameter Type',
  'toolkit.key': 'Parameter Key',
  'toolkit.value': 'Parameter Value',
  'toolkit.describe': 'Parameter Description',
  'toolkit.script': 'Query Script',
  'toolkit.db': 'Database',
  'toolkit.result': 'Response Result',
  'toolkit.addLine': 'Add Parameter',
  'toolkit.Status': 'Status',

  'toolkit.CONNECTED': 'Connected',
  'toolkit.NOT_CONNECTED': 'Not Connected',

  'toolkit.STRING': 'String',
  'toolkit.INTEGER': 'Integer',
  'toolkit.DECIMAL': 'Decimal',

  'toolkit.chartName': 'Chart Name',
  'toolkit.chartType': 'Chart Type',
  'toolkit.apiName': 'DAPI Name',
  'toolkit.updateTime': 'Update Time',
  'toolkit.transferScript': 'Data Transformation',
  'toolkit.execute': 'Execute',
  'toolkit.preview': 'Preview',
  'toolkit.chartData': 'Chart Data',
  'toolkit.chartPreview': 'Chart Preview',

  'toolkit.bar': 'Bar Chart',
  'toolkit.line': 'Line Chart',
  'toolkit.pie': 'Pie Chart',
  'toolkit.scatter': 'Scatter Chart',
  'toolkit.effectScatter': 'Bubble Chart',
  'toolkit.radar': 'Radar Chart',
  'toolkit.tree': 'Tree Diagram',
  'toolkit.treemap': 'Treemap',
  'toolkit.sunburst': 'Sunburst Chart',
  'toolkit.boxplot': 'Box Plot',
  'toolkit.candlestick': 'Candlestick Chart',
  'toolkit.heatmap': 'Heatmap',
  'toolkit.map': 'Map',
  'toolkit.parallel': 'Parallel Coordinates Plot',
  'toolkit.lines': 'Path Diagram',
  'toolkit.graph': 'Graph',
  'toolkit.sankey': 'Sankey Diagram',
  'toolkit.funnel': 'Funnel Chart',
  'toolkit.gauge': 'Gauge',
  'toolkit.pictorialBar': 'Pictorial Bar Chart',
  'toolkit.themeRiver': 'Theme River Chart',
  'toolkit.table': 'Table',

  'toolkit.panelName': 'Name',
  'toolkit.inputPanelName': 'Panel name…',
  'toolkit.FullscreenBrowser': 'Fullscreen Browser',
  'toolkit.ExitFullscreen': 'Exit Fullscreen',
  'toolkit.Fullscreen': 'Fullscreen',
  'toolkit.back': 'Back',

  'toolkit.all': 'All',
  'toolkit.select': 'Select',
  'toolkit.refresh': 'Refresh',
  'toolkit.query': 'Search',
  'toolkit.operation': 'Operation',
  'toolkit.details': 'Details',
  'toolkit.check': 'Info',
  'toolkit.copy': 'Copy',
  'toolkit.clone': 'Clone',
  'toolkit.edit': 'Edit',
  'toolkit.delete': 'Delete',
  'toolkit.rename': 'Rename',
  'toolkit.package': 'Package',
  'toolkit.download': 'Download',
  'toolkit.cancel': 'Cancel',
  'toolkit.save': 'Save',
  'toolkit.ok': 'OK',
  'toolkit.copyUrl': 'Copy Download URL',
  'toolkit.FillfromClipboard': 'Fill from Clipboard',
  'toolkit.auto': 'Auto',
  'toolkit.testSucceeded': 'Test Succeeded',
  'toolkit.testFailed': 'Test Failed：',
};