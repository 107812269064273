export default {
  'rbacPageForm.pageFunctionDescription': '系统软件、目录、菜单配置和定义',
  'rbacPageForm.add': '新增',
  'rbacPageForm.edit': '修改',

  'rbacPageForm.type': '类型',
  'rbacPageForm.contents': '目录',
  'rbacPageForm.menus': '菜单页面',
  'rbacPageForm.displayName': '显示名称',
  'rbacPageForm.icon': '图标',
  'rbacPageForm.higherLevel': '上级',
  'rbacPageForm.sortNum': '排序号',
  'rbacPageForm.connectionAddress': '连接地址',
  'rbacPageForm.authorizationSign': '授权标示',
  'rbacPageForm.save': '保存',
  'rbacPageForm.cancel': '取消',
  'rbacPageForm.search': '搜索',

  'rbacPageForm.success': '操作成功',
  'rbacPageForm.pleaseEnterName': '请选择名称',
  'rbacPageForm.pleaseSelectParentDirectory': '请选择上级目录',

  'rbacPageForm.operation': '操作',
  'rbacPageForm.delete': '删除',
  'rbacPageForm.baseInfo': '基本信息',
  'rbacPageForm.btn': '按钮/功能',
  'rbacPageForm.searchKey': '输入关键字搜索...',
  'rbacPageForm.add2': '添加',
  'rbacPageForm.addFeatures': '添加功能',
  'rbacPageForm.name': '名称',
  'rbacPageForm.remark': '定义说明',
  'rbacPageForm.code': 'Code',
  'rbacPageForm.path': '地址路径',
  'rbacPageForm.service': '服务',
  'rbacPageForm.method': '方法',
};
