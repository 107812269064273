export default {
  'opsConfig.title': '运维配置',

  'opsConfig.add': '新增',
  'opsConfig.searchKey': '关键字搜索...',
  'opsConfig.delete': '删除',
  'opsConfig.operation': '操作',
  'opsConfig.edit': '编辑',

  'opsConfig.save': '保存',
  'opsConfig.cancel': '取消',

  'opsConfig.lableManage': '标签库管理',
  'opsConfig.name': '标签名称',
  'opsConfig.definitionDescription': '定义说明',
  'opsConfig.createTime': '添加时间',


}