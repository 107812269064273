export default {
  'passengerOrgConfig.title': 'Org Config',

  'passengerOrgConfig.openClient': 'Key config',
  'passengerOrgConfig.serverConfig': 'Airline service config',

  'passengerOrgConfig.add': 'Add',
  'passengerOrgConfig.searchKey': 'Keywords...',
  'passengerOrgConfig.edit': 'Edit',
  'passengerOrgConfig.delete': 'Delete',
  'passengerOrgConfig.enable': 'Enable',
  'passengerOrgConfig.disabled': 'Disabled',
  'passengerOrgConfig.isEnable': 'Enable',
  'passengerOrgConfig.copy':'Copy',

  'passengerOrgConfig.clientName': 'Client Name',
  'passengerOrgConfig.openClientId': 'Open Client Id',
  'passengerOrgConfig.publicKey': 'Public Key',
  'passengerOrgConfig.privateKey': 'Private Key',
  'passengerOrgConfig.remarks': 'Comments',
  'passengerOrgConfig.updateTime': 'Update Time',

  'passengerOrgConfig.airlineName': 'Airline Name',
  'passengerOrgConfig.airlineCode': 'Airline Code',
  'passengerOrgConfig.authenticationPath': 'Authentication Path',
  'passengerOrgConfig.registerPath': 'Register Path',
  'passengerOrgConfig.organizationIsUnauthorized': 'Organization is unauthorized',

  'passengerOrgConfig.save': 'Save',
  'passengerOrgConfig.cancel': 'Cancel',
  'passengerOrgConfig.oneTouch': 'One Touch',
}