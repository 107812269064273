export default {
  'commodityTable.title': '流量套餐',
  'commodityTable.pageDescription': '机载网络流量管理，包括流量包的定义及运营设置',

  'commodityTable.status': '状态:',
  'commodityTable.type': '类型:',
  'commodityTable.keyword': '关键字:',
  'commodityTable.all': '全部',
  'commodityTable.enterTitle': '请输入标题',
  'commodityTable.query': '查询',
  'commodityTable.addTrafficPacket': '添加流量包',
  'commodityTable.details': '详情',

  'commodityTable.satcomName': '运营商流量包名称',
  'commodityTable.operationTitle': '运营标题',
  'commodityTable.trafficType': '流量类型',
  'commodityTable.trafficSize': '流量大小',
  'commodityTable.price': '售价',
  'commodityTable.trafficEffectiveTime': '流量有效时间',
  'commodityTable.offShelfTime': '下架时间',
  'commodityTable.operation': '操作',
  'commodityTable.putOnShelf': '上架',
  'commodityTable.offShelf': '下架',
  'commodityTable.more': '更多',
  'commodityTable.edit': '编辑',
  'commodityTable.delete': '删除',
  'commodityTable.clone': '克隆',
  'commodityTable.prompt': '提示',
  'commodityTable.wantDeleteIt': '是否要删除该流量包，删除后将不能恢复！',
  'commodityTable.previousToast': '\'上一步\' 后已修改数据将丢失！',
  'commodityTable.state': '状态',

  'commodityTable.insales': '销售中',
  'commodityTable.toBePputOnShelf': '待上架',
  'commodityTable.offShelf1': '已下架',
  'commodityTable.fixedTraffic': '固定流量',
  'commodityTable.fixedLeg': '固定航段',
  'commodityTable.fixedDuration': '固定时长',
  'commodityTable.fixedPeriod': '固定时段',
  'commodityTable.other': '其他',
  'commodityTable.unlimited': '不限',
  'commodityTable.automaticOffShelfAfterExpiration': '超过有效期，自动下架中',
  'commodityTable.manualOff': '销售中(手动下架)',

  'commodityTable.ispId': '网络运营商',
  'commodityTable.vspId': '增值服务商',
  'commodityTable.ispIdExp': '(例如：中国卫通)',
  'commodityTable.vspIdExp': '(套餐所属机构名称)',
  'commodityTable.editTrafficPackage': '编辑流量包',
  'commodityTable.trafficDefinition': '流量定义',
  'commodityTable.operationSettings': '运营设置',
  'commodityTable.specifiesTheUsageTimeAirTraffic': '指定空中流量使用时长...',
  'commodityTable.specifyAirTrafficUsageTimePeriod': '指定空中流量使用时间段...',
  'commodityTable.airTrafficSpecifiedSize': '指定大小的空中流量...',
  'commodityTable.trafficValidInFlightSegment': '流量本航班航段内有效...',
  'commodityTable.effectiveDeadline': '有效截止时间',
  'commodityTable.bandwidthLimit': '带宽限制',
  'commodityTable.directedAccess': '定向访问',
  'commodityTable.unlimitedExperience': '不受限制体验',
  'commodityTable.specifyURL': '指定URL/IP',
  'commodityTable.specifyApplication': '指定应用',
  'commodityTable.pleaseEnterTitleTrafficPackag': '请输入流量包的标题',
  'commodityTable.operationMainDiagram': '运营主图',
  'commodityTable.lastTime': '上架下架时间',
  'commodityTable.trafficPackageDescription': '流量套餐说明',
  'commodityTable.descriptionTrafficPackage': '流量包图文说明，250字以内',
  'commodityTable.save': '保存',
  'commodityTable.saveAndReturn': '保存并返回',
  'commodityTable.areYouSure': '你确定离开此页面吗?',

  'commodityTable.operatorDataFlowID': '运营商流量ID',
  'commodityTable.credits': '所需积分',
  'commodityTable.operatorTrafficName': '运营商流量包名称',
  'commodityTable.trafficSupplier': '(流量供应商提供)',
  'commodityTable.fixedTrafficSize': '固定的流量大小',
  'commodityTable.untilFlowUsedUp': '(在有效期内，流量用完为止)',

  'commodityTable.fixedTimeLong': '固定的时间长',
  'commodityTable.cumulativeConsumptionOnlineTime': '(当前不支持上网时长累计消费)',
  'commodityTable.maxAvailableTrafficSize': '最多可用流量大小',
  'commodityTable.unlimitedEmpty': '(为空时不限制)',

  'commodityTable.addPackage': '新增套餐',
  'commodityTable.packageDetail': '套餐详情',
  'commodityTable.packageEdit': '修改套餐',
  'commodityTable.selectType': '选择类型',
  'commodityTable.fillOperationpolicy': '填写运营策略',
  'commodityTable.done': '完成',
  'commodityTable.next': '下一步',
  'commodityTable.previous': '上一步',
  'commodityTable.submit': '提 交',
  'commodityTable.uplinkRate': '上行速率',
  'commodityTable.downlinkRate': '下行速率',
  'commodityTable.effectiveTrafficDeadline': '流量套餐有效截止时间',
  'commodityTable.specifyAccessURL': '指定访问网址',
  'commodityTable.specifyAccessApp': '指定访问应用',
  'commodityTable.notOpenedYet': '(暂未开通，敬请期待)',
  'commodityTable.registerEffectiveID': '(到流量供应商登记生效ID)',
  'commodityTable.defaultEffectiveTime': '(目前仅支持当次航班有效)',
  'commodityTable.selectURL': '选择网址',
  'commodityTable.selectApp': '选择应用',
  'commodityTable.noPointsAreRequired': '(为空即无需积分)',
  'commodityTable.withinWords': '(150字以内)',
  'commodityTable.pngPicturesWithin': '(100kb以内jpg/png图片，最佳长宽比9:5)',
  'commodityTable.waitingForListing': '(为空即列为草稿，等待上架)',
  'commodityTable.salesPrice': '销售价格',
  'commodityTable.remarks': '备注说明',
  'commodityTable.purchaseUserType': '购买用户类型',
  'commodityTable.packageIntroduction': '套餐介绍',
  'commodityTable.numberGreaterThan0': '请输入大于0并且最多保留小数点后两位的数字',
  'commodityTable.Unlimited': '不限',
  'commodityTable.Member': '会员',
  'commodityTable.NonMember': '非会员',
  'commodityTable.viewList': '查看列表',
  'commodityTable.viewDetails': '查看详情',
  'commodityTable.cloneCopy': '克隆一份',
  'commodityTable.anotherCopy': '再来一份',

  'commodityTable.addImge': '添加图片',
  'commodityTable.BestSize': '公司Logo建议上传200*200以内的png图片',
  'commodityTable.uploadImage': '请上传图片文件',
  'commodityTable.replaceImage': '上传文件超过100Kb，请替换图片',
  'commodityTable.FileUploading': '文件正在上传中...请稍后再试',
  'commodityTable.Submitting': '正在提交...',
  'commodityTable.PNGAndJPEG': '仅支持PNG和JPEG格式图片!',
  'commodityTable.UpstreamBandwidth': '上行带宽',
  'commodityTable.DownstreamBandwidth': '下行带宽',
  'commodityTable.displayOnly': '仅用于展示，实际使用值以流量供应商的为准',

  'commodityTable.packageDescription': '流量包说明',
  'commodityTable.airFlowSpecifiedSize': '指定空中流量累计使用时长, 乘客上飞机时自愿激活,下飞机时流量自动断开,一次购买,多次消费, 在规定流量和有效期内，时间用完为止。',
  'commodityTable.refersFailureWarning': '指定空中流量使用时间段, 乘客上飞机时自愿激活,下飞机时流量自动断开,一次购买,多次消费, 在有效期内，该时间段内均有效。',
  'commodityTable.specifiesUseTimePeriodAirFlow': '指定大小的空中流量, 乘客上飞机时自愿激活,下飞机时流量自动断开,一次购买,多次消费, 在有效期内，流量用完为止。',
  'commodityTable.flowValidInFlightSegment': '激活航班航段内有效, 乘客上飞机时自愿激活本航段, 下飞机时流量自动关闭,固定航段流量包视为已使用,飞机从地点 A 到地点 B 降落, A-B 视为一个航段。',
};