export default {
  'nmsMaintainSetting.title': '改装详情',
  'nmsMaintainSetting.pageDescription': '将装机设备与XstreamOP系统建立关联关系',

  'nmsMaintainSetting.basicInformation': '基本信息',
  'nmsMaintainSetting.tailNumber': '机尾号',
  'nmsMaintainSetting.model': '机型',
  'nmsMaintainSetting.factoryDate': '出厂日期',
  'nmsMaintainSetting.machineAge': '机龄',
  'nmsMaintainSetting.installDevice': '安装设备',
  'nmsMaintainSetting.modificationScheme': '改装方案',
  'nmsMaintainSetting.installationTime': '装机时间',
  'nmsMaintainSetting.networkType': '网络类型',
  'nmsMaintainSetting.orgName': '所属公司',
  'nmsMaintainSetting.aaaType': '3A控制方式',

  'nmsMaintainSetting.lan': '局域网',
  'nmsMaintainSetting.satellite': '卫星',
  'nmsMaintainSetting.noNetwork': '无网络',

  'nmsMaintainSetting.editInstallation': '更新改装方案',
  'nmsMaintainSetting.installationDiagram': '装机示意图',
  'nmsMaintainSetting.newInstallation': '新装机',
  'nmsMaintainSetting.associateInstalledDevice': '将装机设备与XstreamOP系统建立关联关系',
  'nmsMaintainSetting.uploadAircraftSchematic': '上传飞机示意图',
  'nmsMaintainSetting.save': '保存',
  'nmsMaintainSetting.cancel': '取消',
  'nmsMaintainSetting.areYouSure': '你确定离开此页面吗?',
  'nmsMaintainSetting.print': '打印详情',

  'nmsMaintainSetting.aircraftTypeNameZh': '中文名称',
  'nmsMaintainSetting.aircraftTypeNameEn': '英文名称',
  'nmsMaintainSetting.iata3': 'IATA三字码',
  'nmsMaintainSetting.productionCompany': '生产公司',
  'nmsMaintainSetting.planeLength': '机身长度',
  'nmsMaintainSetting.pilotsNumber': '飞行员数量',
  'nmsMaintainSetting.firstFly': '首飞日期',
  'nmsMaintainSetting.seatsNumber': '标准座位数',
  'nmsMaintainSetting.icao4': '四字码',
  'nmsMaintainSetting.detailsIndexShow': '展开',
  'nmsMaintainSetting.detailsIndexHide': '收起',
  'nmsMaintainSetting.imageUploadTips1': '请上传宽高比为9:4的图片',
  'nmsMaintainSetting.imageUploadTips2': '仅支持PNG和JPEG格式图片!',
  'nmsMaintainSetting.imageUploadTips3': '图片大小必须小于10MB!',

  'nmsMaintainSetting.ip': '飞机IP',
  'nmsMaintainSetting.ipCheckWrong': 'IP格式错误, 请重新输入',

  'nmsMaintainSetting.tailNoCheckWrong': '请输入机尾号',
};