export default {
  'nmsResume.title': '设备履历',

  'nmsResume.deviceName': '设备名称',
  'nmsResume.serialNo': '设备序列号',
  'nmsResume.operation': '操作',
  'nmsResume.query': '查询',
  'nmsResume.resume': '履历',
  'nmsResume.deviceNameExp': '设备名称搜索...',
  'nmsResume.serialNoExp': '设备序列号搜索...',

  'nmsResume.refresh': '刷新',
  'nmsResume.model': '设备型号',
  'nmsResume.pn': '设备PN',
  'nmsResume.modNo': 'MOD号',
};
