export default {
  'nmsApplyConfig.title': 'Apply Config',

  'nmsApplyConfig.addConfig': 'Add',
  'nmsApplyConfig.editConfig': 'Edit Config',
  'nmsApplyConfig.cloneConfig': 'Clone Config',

  'nmsApplyConfig.keySearch': 'Name Search',
  'nmsApplyConfig.allTailNo': 'Tail No.',
  'nmsApplyConfig.allGroup': 'Group',
  'nmsApplyConfig.query': 'Search',
  'nmsApplyConfig.cloneBatch': 'Batch Cloning',
  'nmsApplyConfig.export': 'Export',
  'nmsApplyConfig.pleaseSelectAircraft': 'Please select an aircraft',
  'nmsApplyConfig.pleaseSelectAircraftAgain': 'Please select an aircraft number and try again!',

  'nmsApplyConfig.clonePlane': 'Source Aircraft',
  'nmsApplyConfig.targetPlane': 'Target Aircraft',

  'nmsApplyConfig.tailNo': 'Tail No.',
  'nmsApplyConfig.group': 'Group',
  'nmsApplyConfig.deviceName': 'Device',
  'nmsApplyConfig.paramName': 'Name',
  'nmsApplyConfig.paramAlias': 'Alias',
  'nmsApplyConfig.paramValue': 'Key/Value',
  'nmsApplyConfig.createTime': 'Creation Time',
  'nmsApplyConfig.createUser': 'Creator',
  'nmsApplyConfig.updateTime': 'Update Time',
  'nmsApplyConfig.operate': 'Operation',
  'nmsApplyConfig.paramAliasExp': 'It is recommended to set the same alias for the same applicable configuration of different aircraft.',

  'nmsApplyConfig.edit': 'Edit',
  'nmsApplyConfig.delete': 'Delete',
  'nmsApplyConfig.clone': 'Clone',
  'nmsApplyConfig.deleteConfirm': 'Once the configuration is deleted, it cannot be recovered. Are you sure you want to delete it?',
  'nmsApplyConfig.thinkAgain': 'Think Again',
  'nmsApplyConfig.confirm': 'Confirm',

  'nmsApplyConfig.cancel': 'Cancel',
  'nmsApplyConfig.submit': 'Submit',
  'nmsApplyConfig.save': 'Save',

  'nmsApplyConfig.deviceId': 'Device',
  'nmsApplyConfig.paramType': 'Key/Value',
  'nmsApplyConfig.paramValue1': ' ',
  'nmsApplyConfig.addItem': 'Add',
  'nmsApplyConfig.remark': 'Comments',
  'nmsApplyConfig.FIXED': 'Fixed Key',
  'nmsApplyConfig.DEVICE': 'Device Parameter',

  'nmsApplyConfig.inputName': 'Please input name',
  'nmsApplyConfig.inputRemark': 'Please input description...'
};
