export default {
  'dataAccessAnalysis.title': '入网统计',

  'dataAccessAnalysis.tailNumber': '机尾号',
  'dataAccessAnalysis.flightNumber': '航班号',
  'dataAccessAnalysis.timeInterval': '时间区间',
  'dataAccessAnalysis.query': '查询',
  'dataAccessAnalysis.tailNumberSearch': '机尾号搜索...',
  'dataAccessAnalysis.flightNumberSearch': '航班号搜索...',
  'dataAccessAnalysis.deptSearch': '出发城市、机场三字码搜索...',
  'dataAccessAnalysis.destSearch': '到达城市、机场三字码搜索...',
  'dataAccessAnalysis.exportData': '导出数据',

  'dataAccessAnalysis.flightDate': '航班日期',
  'dataAccessAnalysis.dept': '出发',
  'dataAccessAnalysis.dest': '到达',
  'dataAccessAnalysis.flightDuration': '飞行时长',
  'dataAccessAnalysis.seatNum': '座位数',
  'dataAccessAnalysis.boardingPassengerCount': '旅客人数',
  'dataAccessAnalysis.authPassengerCount': '认证人数',
  'dataAccessAnalysis.internetPassengerCount': '上网人数',
  'dataAccessAnalysis.wifiTerminalCount': 'WiFi终端',
  'dataAccessAnalysis.authTerminalCount': '认证终端',
  'dataAccessAnalysis.internetTerminalCount': '上网终端',
  'dataAccessAnalysis.joinTime': '入网时间',
  'dataAccessAnalysis.leaveTime': '断网时间',
  'dataAccessAnalysis.deptCode': '起飞机场三字码',
  'dataAccessAnalysis.destCode': '降落机场三字码',

  'dataAccessAnalysis.authPassengerCountExp': '完成实名认证的旅客人数',
  'dataAccessAnalysis.wifiTerminalCountExp': '连接Wi-Fi的终端数',
  'dataAccessAnalysis.authTerminalCountExp': '完成实名认证的旅客所用终端数',

  'dataAccessAnalysis.time': '时间',
  'dataAccessAnalysis.totalFlight': '按航班统计',
  'dataAccessAnalysis.totalDay': '按日期统计',
  'dataAccessAnalysis.totalWeek': '按周统计',
  'dataAccessAnalysis.totalMonth': '按月统计',
  'dataAccessAnalysis.totalQuarter': '按季度统计',
  'dataAccessAnalysis.totalYear': '按年统计',

  'dataAccessAnalysis.last1d': '最近1天',
  'dataAccessAnalysis.last2d': '最近2天',
  'dataAccessAnalysis.last3d': '最近3天',
  'dataAccessAnalysis.last5d': '最近5天',
  'dataAccessAnalysis.last7d': '最近7天',
  'dataAccessAnalysis.last30d': '最近30天',
  'dataAccessAnalysis.custom': '自定义',
};
