export default {
  'dataFlowAnalysis.title': 'Flow Analysis',

  'dataFlowAnalysis.tailNumber': 'Tail No',
  'dataFlowAnalysis.flightNumber': 'Flight number',
  'dataFlowAnalysis.timeInterval': 'Time interval',
  'dataFlowAnalysis.query': 'Search',
  'dataFlowAnalysis.tailNumberSearch': 'Tail No search...',
  'dataFlowAnalysis.flightNumberSearch': 'Flight number search...',
  'dataFlowAnalysis.deptSearch': 'Search dept...',
  'dataFlowAnalysis.destSearch': 'Search dest...',
  'dataFlowAnalysis.exportData': 'Export',

  'dataFlowAnalysis.flightDate': 'Flight date',
  'dataFlowAnalysis.dept': 'Dept',
  'dataFlowAnalysis.dest': 'Dest',
  'dataFlowAnalysis.flightDuration': 'Flight duration',

  'dataFlowAnalysis.orderTotal': 'Order total',
  'dataFlowAnalysis.orderPassengerCount': 'Order passenger count',
  'dataFlowAnalysis.internetPassengerCount': 'Internet passenger count',
  'dataFlowAnalysis.upTrafficTotal': 'Up traffic total(K)',
  'dataFlowAnalysis.downTrafficTotal': 'Down traffic total(K)',
  'dataFlowAnalysis.useTrafficTotal': 'Use traffic total(K)',
  'dataFlowAnalysis.usePer': 'Use per(K)',

  'dataFlowAnalysis.internetPassengerCountExp': 'Internet passenger count',
  'dataFlowAnalysis.usePerExp': 'Use per(K)',

  'dataFlowAnalysis.time': 'Time',
  'dataFlowAnalysis.totalFlight': 'Total Flight',
  'dataFlowAnalysis.totalDay': 'Total Day',
  'dataFlowAnalysis.totalWeek': 'Total Week',
  'dataFlowAnalysis.totalMonth': 'Total Month',
  'dataFlowAnalysis.totalQuarter': 'Total Quarter',
  'dataFlowAnalysis.totalYear': 'Total Year',

  'dataFlowAnalysis.last1d': 'Last day',
  'dataFlowAnalysis.last2d': 'Last 2 days',
  'dataFlowAnalysis.last3d': 'Last 3 days',
  'dataFlowAnalysis.last5d': 'Last 5 days',
  'dataFlowAnalysis.last7d': 'Last 7 days',
  'dataFlowAnalysis.last30d': 'Last 30 days',
  'dataFlowAnalysis.custom': 'Custom',
};
