export default {
  'airlinesFlightPlan.title': 'Flight Plan',

  'airlinesFlightPlan.tailNoType': 'Tail No/Type',
  'airlinesFlightPlan.xinqi1': 'Monday',
  'airlinesFlightPlan.xinqi2': 'Tuesday',
  'airlinesFlightPlan.xinqi3': 'Wednesday',
  'airlinesFlightPlan.xinqi4': 'Thursday',
  'airlinesFlightPlan.xinqi5': 'Friday',
  'airlinesFlightPlan.xinqi6': 'Saturday',
  'airlinesFlightPlan.xinqi7': 'Sunday',

  'airlinesFlightPlan.inputTailNo': 'Input Tail No, Flight number, Airport and City search',

  'airlinesFlightPlan.all': 'All',
  'airlinesFlightPlan.normal': 'Normal',
  'airlinesFlightPlan.delay': 'Delay',
  'airlinesFlightPlan.cancel': 'Cancel',
  'airlinesFlightPlan.other': 'Other',
};