export default {
  'rolesManage.title': 'Roles Manages',
  'rolesManage.addRole': 'Add',
  'rolesManage.roleName': 'Role Name',
  'rolesManage.menuAuth': 'Menu Auth',
  'rolesManage.pageAuth': 'Page Auth',
  'rolesManage.notificationAuth': 'Notification Auth',
  'rolesManage.description': 'Description',
  'rolesManage.operation': 'Operation',
  'rolesManage.delete': 'Delete',
  'rolesManage.roleNameMessage': 'Please input words less than 160',
  'rolesManage.roleAddPlaceholder1': 'Please input role name',
  'rolesManage.roleAddPlaceholder2': 'Please input description',
  'rolesManage.noSpace': 'Cant be null',
  'rolesManage.cancel': 'Cancel',
  'rolesManage.save': 'Save',
  'rolesManage.success': 'Success',
  'rolesManage.deleteConfirm': 'Are you sure to delete?',
  'rolesManage.deleteConfirm2': 'It`s unrecoverable',
  'rolesManage.yes': 'Yes',
  'rolesManage.no': 'No',
  'rolesManage.cantDelete': 'Can`t delete.',
  'rolesManage.add': 'Add',
  'rolesManage.edit': 'Edit',
};