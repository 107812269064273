import React from 'react';
import {
  Link
} from "react-router-dom"
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu } from "antd";
import {
  keySeparator,
} from "shared/const"

import "fonts/iconfont/iconfont.css"
import {FormattedMessage} from 'react-intl';
import {logI} from "shared/utils";

const SubMenu = Menu.SubMenu
const MenuItem = Menu.Item


const getSelectKeyFromRoute = (path) => {
  return [path.replace("/", keySeparator)]
};

const trans = (id, values) => {
  let valueMap = (values || []).reduce((p, c, index) => {
    return Object.assign(p, {[index]: c})
  }, {});
  return <FormattedMessage id={id} values={valueMap}/>
};

/**
 * 根据菜单数据生成菜单
 * @param menus
 * @param pKey
 * @param pRoute
 * */
const createMenus = (menus) => {
  return menus.map(item => {
    const _label = (
      <span className="menu-item">
        {getItemIcon(item.icon)}
        <FormattedMessage id={item.label || trans(item.key)}/>
      </span>
    );
    return item.children ?
      createSubMenu(item.children, item.key, _label) :
      createMenuItem(_label, item.path, item.key)
  })
}

const getItemIcon = (icon) => {
  if (!icon || icon === '') {
    return (
      <img style={{width: '20px', marginRight: '10px'}} src={null}/>
    );
  }
  if (icon.indexOf('http') !== -1) {
    return (
      <img style={{width: '20px', marginRight: '10px'}} src={icon}/>
    );
  } else {
    return <LegacyIcon type={icon} style={{fontSize: '14px', width: '20px',}} theme="outlined"/>;
  }
};


/**
 * 生成子菜单
 * @param items
 * @param key
 * @param route
 * @param label
 * */
const createSubMenu = (items, key, label) => {
  return (
    <SubMenu title={label} key={key}>
      {createMenus(items, key)}
    </SubMenu>
  )
}


/**
 * 生成菜单项
 * @param key
 * @param route
 * @param label
 * */
const createMenuItem = (label, path, key) => {
  return (
    <MenuItem key={`${key}`}>
      <Link to={`${path}`}>{label}</Link>
    </MenuItem>
  )
}

const menuSettings = {
  theme: "dark",
  style: {
    width: "100%"
  },
  mode: "inline",
  inlineCollapsed: true,
  inlineIndent: 20
};

export {
  createMenus,
  menuSettings,
  getSelectKeyFromRoute,
  keySeparator
}
