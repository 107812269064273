export default {
  'passengerAccess.title': 'Behavior log',
  'passengerAccess.pageDescription': 'History of online access URL of passengers',

  'passengerAccess.passengerName': 'Passenger name',
  'passengerAccess.tailNumber': 'Tail No',
  'passengerAccess.flightNumber': 'Flight Number',
  'passengerAccess.timeInterval': 'Time interval',
  'passengerAccess.certificateNo': 'Certificate No.',
  'passengerAccess.seatNumber': 'Seat number',
  'passengerAccess.URLAddress': 'URL address',
  'passengerAccess.search': 'Search',
  'passengerAccess.cancel': 'Cancel',

  'passengerAccess.inputSomething': 'Input something',
  'passengerAccess.enterTailNumber': 'Tail No...',
  'passengerAccess.enterFlightNumber': 'Flight Number...',
};