export default {
  'opsQuestion.title': '问题处理',

  'opsQuestion.add': '新增',
  'opsQuestion.addQuesion': '新增记录',
  'opsQuestion.searchKey': '关键字搜索...',
  'opsQuestion.delete': '删除',
  'opsQuestion.operation': '操作',
  'opsQuestion.edit': '编辑',
  'opsQuestion.query': '查询',
  'opsQuestion.details': '详情',

  'opsQuestion.save': '保存',
  'opsQuestion.cancel': '取消',

  'opsQuestion.lableManage': '标签库管理',
  'opsQuestion.name': '标签名称',
  'opsQuestion.definitionDescription': '定义说明',
  'opsQuestion.createTime': '添加时间',

  'opsQuestion.questionList': '问题列表',
  'opsQuestion.flightIATASearch': '航班号搜索...',
  'opsQuestion.problemKeySearch': '问题关键字搜索...',
  'opsQuestion.orgInChargeSearch': '问题定位搜索...',
  'opsQuestion.tailNoSearch': '机尾号搜索...',
  'opsQuestion.deptSearch': '出发城市或三字码搜索...',
  'opsQuestion.destSearch': '到达城市或三字码搜索...',
  'opsQuestion.exportData': '导出数据',
  'opsQuestion.customColumns': '自定义列',
  'opsQuestion.selectColumns': '选择列名称',
  'opsQuestion.allLabel': '全标签',
  'opsQuestion.allState': '全状态',
  'opsQuestion.tailNoExp': '机尾号...',
  'opsQuestion.allLevel': '全级别',

  'opsQuestion.FATAL': '致命',
  'opsQuestion.SERIOUS': '严重',
  'opsQuestion.WARN': '警戒',
  'opsQuestion.CRITICAL': '临界',
  'opsQuestion.ERROR': '错误',
  'opsQuestion.ALARM': '警告',
  'opsQuestion.ATTENTION': '注意',
  'opsQuestion.NOTIFICATION': '通知',
  'opsQuestion.DEBUG': '调试',

  'opsQuestion.PENDING': '待解决',
  'opsQuestion.PROCESSING': '处理中',
  'opsQuestion.UNVERIFIED': '待验证',
  'opsQuestion.SOLVED': '已解决',
  'opsQuestion.CLOSED': '已关闭',

  'opsQuestion.planeFlight': '飞机航班',

  'opsQuestion.serialNo': '问题编号',
  'opsQuestion.questionName': '问题名称',
  'opsQuestion.happenTime': '发生时间',
  'opsQuestion.orgInCharge': '问题定位',
  'opsQuestion.problemLevel': '问题级别',
  'opsQuestion.tailNo': '机尾号',
  'opsQuestion.flightIATA': '航班号',
  'opsQuestion.flightDate': '航班日期',
  'opsQuestion.deptCity': '出发城市',
  'opsQuestion.deptCode': '出发机场三字码',
  'opsQuestion.destCity': '到达城市',
  'opsQuestion.destCode': '到达机场三字码',
  'opsQuestion.description': '问题描述',
  'opsQuestion.reason': '问题原因',
  'opsQuestion.resolve': '解决方案',
  'opsQuestion.labels': '问题标签',
  'opsQuestion.state': '问题状态',
  'opsQuestion.personInCharge': '负责人',
  'opsQuestion.recordTime': '记录时间',
  'opsQuestion.updateTime': '更新时间',

  'opsQuestion.questionDetails': '问题详情',
  'opsQuestion.flight': '飞行航段',
  'opsQuestion.flightInfo': '事件航班信息',
  'opsQuestion.joinTime': '首次入网时间',
  'opsQuestion.leaveTime': '最后断网时间',
  'opsQuestion.takeOffTime': '计划起飞时间',
  'opsQuestion.arriveTime': '计划到达时间',
  'opsQuestion.actualTakeOffTime': '实际起飞时间',
  'opsQuestion.actualArriveTime': '实际到达时间',
  'opsQuestion.predictTakeOffTime': '预计起飞时间',
  'opsQuestion.predictArriveTime': '预计到达时间',
  'opsQuestion.updateUser': '问题修改人',

  'opsQuestion.exp1': '（同一架飞机的同一时间的问题名称不允许重复)',
  'opsQuestion.exp2': '（对当前问题负责的机构名称）',
  'opsQuestion.exp3': '（为空视为未指派）',
  'opsQuestion.exp4': '（一旦提交将无法修改）',
  'opsQuestion.exp5': '（从多角度和纬度评估，支持多选）',
}