export default {
  'dataAccessAnalysis.title': 'Access Analysis',

  'dataAccessAnalysis.tailNumber': 'Tail No',
  'dataAccessAnalysis.flightNumber': 'Flight number',
  'dataAccessAnalysis.timeInterval': 'Time interval',
  'dataAccessAnalysis.query': 'Search',
  'dataAccessAnalysis.tailNumberSearch': 'Tail No search...',
  'dataAccessAnalysis.flightNumberSearch': 'Flight number search...',
  'dataAccessAnalysis.deptSearch': 'Search dept...',
  'dataAccessAnalysis.destSearch': 'Search dest...',
  'dataAccessAnalysis.exportData': 'Export',

  'dataAccessAnalysis.flightDate': 'Flight date',
  'dataAccessAnalysis.dept': 'Dept',
  'dataAccessAnalysis.dest': 'Dest',
  'dataAccessAnalysis.flightDuration': 'Flight duration',
  'dataAccessAnalysis.seatNum': 'Seat num',
  'dataAccessAnalysis.boardingPassengerCount': 'Boarding PSG count',
  'dataAccessAnalysis.authPassengerCount': 'Auth PSG count',
  'dataAccessAnalysis.internetPassengerCount': 'Internet PSG count',
  'dataAccessAnalysis.wifiTerminalCount': 'WiFi TERM count',
  'dataAccessAnalysis.authTerminalCount': 'Auth TERM count',
  'dataAccessAnalysis.internetTerminalCount': 'Internet TERM count',
  'dataAccessAnalysis.joinTime': 'Join time',
  'dataAccessAnalysis.leaveTime': 'Leave time',

  'dataAccessAnalysis.authPassengerCountExp': 'Auth passenger count',
  'dataAccessAnalysis.wifiTerminalCountExp': 'WiFi terminal count',
  'dataAccessAnalysis.authTerminalCountExp': 'Auth terminal count',

  'dataAccessAnalysis.time': 'Time',
  'dataAccessAnalysis.totalFlight': 'Total Flight',
  'dataAccessAnalysis.totalDay': 'Total Day',
  'dataAccessAnalysis.totalWeek': 'Total Week',
  'dataAccessAnalysis.totalMonth': 'Total Month',
  'dataAccessAnalysis.totalQuarter': 'Total Quarter',
  'dataAccessAnalysis.totalYear': 'Total Year',

  'dataAccessAnalysis.last1d': 'Last day',
  'dataAccessAnalysis.last2d': 'Last 2 days',
  'dataAccessAnalysis.last3d': 'Last 3 days',
  'dataAccessAnalysis.last5d': 'Last 5 days',
  'dataAccessAnalysis.last7d': 'Last 7 days',
  'dataAccessAnalysis.last30d': 'Last 30 days',
  'dataAccessAnalysis.custom': 'Custom',
};
