export default {
  'passengerAccess.title': '行为日志',
  'passengerAccess.pageDescription': '旅客上网访问URL历史记录',

  'passengerAccess.passengerName': '乘客姓名',
  'passengerAccess.tailNumber': '机尾号',
  'passengerAccess.flightNumber': '航班号',
  'passengerAccess.timeInterval': '时间区间',
  'passengerAccess.certificateNo': '证件号',
  'passengerAccess.seatNumber': '座位号',
  'passengerAccess.URLAddress': 'URL地址',
  'passengerAccess.search': '搜索',
  'passengerAccess.cancel': '取消',

  'passengerAccess.inputSomething': '请输入内容',
  'passengerAccess.enterTailNumber': '请输入机尾号',
  'passengerAccess.enterFlightNumber': '请输入航班号',

};