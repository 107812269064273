export default {
  'nmsApplyConfig.title': '适用配置',

  'nmsApplyConfig.addConfig': '新增适配',
  'nmsApplyConfig.editConfig': '编辑适配',
  'nmsApplyConfig.cloneConfig': '克隆适配',

  'nmsApplyConfig.keySearch': '输入参数别名关键字搜索...',
  'nmsApplyConfig.allTailNo': '全部飞机',
  'nmsApplyConfig.allGroup': '全部分组',
  'nmsApplyConfig.query': '查询',
  'nmsApplyConfig.cloneBatch': '批量克隆',
  'nmsApplyConfig.export': '导出',
  'nmsApplyConfig.pleaseSelectAircraft': '请选择飞机',
  'nmsApplyConfig.pleaseSelectAircraftAgain': '请先选择飞机机号后重试!',

  'nmsApplyConfig.clonePlane': '克隆飞机',
  'nmsApplyConfig.targetPlane': '目标飞机',

  'nmsApplyConfig.tailNo': '所属飞机',
  'nmsApplyConfig.group': '所属分组',
  'nmsApplyConfig.deviceName': '所属设备',
  'nmsApplyConfig.paramName': '适用名称',
  'nmsApplyConfig.paramAlias': '参数别名',
  'nmsApplyConfig.paramValue': '映射参或值',
  'nmsApplyConfig.createTime': '创建时间',
  'nmsApplyConfig.createUser': '创建人',
  'nmsApplyConfig.updateTime': '更新时间',
  'nmsApplyConfig.operate': '操作',
  'nmsApplyConfig.paramAliasExp': '建议不同飞机同个适用配置设同一个别名',

  'nmsApplyConfig.edit': '编辑',
  'nmsApplyConfig.delete': '删除',
  'nmsApplyConfig.clone': '克隆',
  'nmsApplyConfig.deleteConfirm': '配置一旦被删除将不能恢复，确定要删除吗？',
  'nmsApplyConfig.thinkAgain': '再想一想',
  'nmsApplyConfig.confirm': '我意已决',

  'nmsApplyConfig.cancel': '取消',
  'nmsApplyConfig.submit': '提交',
  'nmsApplyConfig.save': '保存',

  'nmsApplyConfig.deviceId': '设备',
  'nmsApplyConfig.paramType': '映射参或值',
  'nmsApplyConfig.paramValue1': ' ',
  'nmsApplyConfig.addItem': '添加',
  'nmsApplyConfig.remark': '备注说明',
  'nmsApplyConfig.FIXED': '固定参数',
  'nmsApplyConfig.DEVICE': '设备参数',

  'nmsApplyConfig.inputName': '请输入名称',
  'nmsApplyConfig.inputRemark': '请输入描述...',
};
