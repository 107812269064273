export default {
  'nmsDictionary.title': 'Device Dictionary',
  'nmsDictionary.pageDescription': 'Airborne network equipment definition, equipment basic data management',

  'nmsDictionary.rename': 'Rename',
  'nmsDictionary.viewDetails': 'View Details',
  'nmsDictionary.valueConversion': 'Value Conversion',
  'nmsDictionary.deleteDevice': 'Delete Device',
  'nmsDictionary.parameterClass': 'Parameter class',
  'nmsDictionary.valueTransfer': 'Value transfer',
  'nmsDictionary.transferTable': 'Transfer Table',

  'nmsDictionary.deviceName': 'Device name',
  'nmsDictionary.ChineseName': 'Chinese name',
  'nmsDictionary.EnglishName': 'English name',
  'nmsDictionary.numberInstallableUnits': 'Installable units',
  'nmsDictionary.singleDevice': 'Single device',
  'nmsDictionary.multipleDevices': 'Multiple devices',
  'nmsDictionary.addDevice': 'Add device',
  'nmsDictionary.Front-enddisplay': 'Front-end display',

  'nmsDictionary.IFEChardwareDeviceDefinition': 'Device Dictionary: IFEC hardware device definition and data dictionary management',
  'nmsDictionary.dataClass': 'Data classification',
  'nmsDictionary.addDataClass': '+ Data classification',
  'nmsDictionary.keywordQuery': 'Keyword query',
  'nmsDictionary.enterKeyValue': 'Key value',
  'nmsDictionary.all': 'All',
  'nmsDictionary.query': 'Search',
  'nmsDictionary.new': 'Add',
  'nmsDictionary.delete': 'Delete',
  'nmsDictionary.edit': 'Edit',
  'nmsDictionary.manage': 'Manage',
  'nmsDictionary.multipleDelete': 'Multiple Delete',
  'nmsDictionary.newValue': 'Add',
  'nmsDictionary.pleaseSelectItem': 'Please select dictionary item to delete',
  'nmsDictionary.wantDeleteThem': 'Do you really want to delete them?',
  'nmsDictionary.wantDeleteIt': 'Do you really want to delete it?',
  'nmsDictionary.cannotBeRecovered': 'Cannot be recovered after deletion',
  'nmsDictionary.numberCode': 'Number code',
  'nmsDictionary.dataType': 'Data type',
  'nmsDictionary.displayName': 'Display name',
  'nmsDictionary.doesDeviceSupport': 'Dev. support',
  'nmsDictionary.noteDescription': 'Note description',
  'nmsDictionary.operation': 'Operation',
  'nmsDictionary.originalValue': 'Original value',
  'nmsDictionary.transferValue': 'Transfer value',
  'nmsDictionary.displayName2': 'Display name',
  'nmsDictionary.bitStatusSetting': 'Bit status setting',
  'nmsDictionary.TestValueConfig': 'Test Value Config',
  'nmsDictionary.originalValue2': 'Original Status Value (Raw Value Detected)',
  'nmsDictionary.transferValue2': 'Status Name (Preset Converted Value for Value Transformation)',
  'nmsDictionary.DeviceEncoding': 'Selection of Device Parameter Encoding',
  'nmsDictionary.inputNumberCode': 'Number code…',
  'nmsDictionary.BestSize': '(Best size 300 x 300px)',
  'nmsDictionary.uploadImage': 'Please upload an image file',
  'nmsDictionary.replaceImage': 'The uploaded file exceeds 100Kb. Please replace the image.',
  'nmsDictionary.FileUploading': 'File is uploading... Please try again later',
  'nmsDictionary.Submitting': 'Submitting...',
  'nmsDictionary.PNGAndJPEG': 'Only supports PNG and JPEG format images.',

  'nmsDictionary.statusValue': 'Status value',
  'nmsDictionary.statusName': 'Status name',
  'nmsDictionary.lightStatus': 'Light status',
  'nmsDictionary.statusCode': 'Status code',
  'nmsDictionary.priority': 'Priority',
  'nmsDictionary.handle': 'Handle',

  'nmsDictionary.text': 'Text',
  'nmsDictionary.numeric': 'Numeric',
  'nmsDictionary.percent': 'Percent',

  'nmsDictionary.NORMAL': 'Normal',
  'nmsDictionary.FAIL': 'Fail',
  'nmsDictionary.FULL_LOAD': 'Full Load',

  'nmsDictionary.addChildClass': 'Add class',
  'nmsDictionary.addClass': 'Add class',
  'nmsDictionary.editClass': 'Edit class',
  'nmsDictionary.className': 'Class name',
  'nmsDictionary.groupCode': 'Group code',
  'nmsDictionary.groupCodeExp': 'It cannot be modified after saving. If it is blank, it means system code',
  'nmsDictionary.classNameExp': 'For underlying business logic',
  'nmsDictionary.remark': 'Comments',
  'nmsDictionary.addDictionary': 'Add dictionary',
  'nmsDictionary.classification': 'Classification',
  'nmsDictionary.supported': 'Supported',
  'nmsDictionary.notSupported': 'Not supported',
  'nmsDictionary.yes': 'Yes',
  'nmsDictionary.no': 'No',

  'nmsDictionary.add': ' Add',
  'nmsDictionary.coding': 'Coding',
};