export default {
  'nmsPointStation.title': 'Station Config',
  'nmsPointStation.name': 'Name',
  'nmsPointStation.placeholder': 'Please input...',
  'nmsPointStation.modmanIp': 'MODMAN IP',
  'nmsPointStation.ip': 'Aircraft/GNS IP',
  'nmsPointStation.owner': 'Point Station Owner Code',
  'nmsPointStation.code': 'Point Station Code',
  'nmsPointStation.save': 'Update',
  'nmsPointStation.vno': 'VNO Code',
};
