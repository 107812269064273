import fetch from 'isomorphic-fetch'
import axios from 'axios';
import {BASE_API_URL} from 'shared/env'
import my_zh from 'shared/langs/zh_CN';
import my_en from 'shared/langs/en_US';
import io from 'socket.io-client';

const locales = {
  en: my_en,
  zh: my_zh,
};

let keyToString = (data) => {
  let acceptLang = localStorage.getItem('locale');
  if (!acceptLang) {
    acceptLang = 'zh'
  }
  if (data) {
    if (data.key) {
      return locales[acceptLang][data.key] || data.message;
    } else if (data.message) {
      return data.message;
    }
  }
  return locales[acceptLang]['codeShare.operationFailed'];
};

export async function fetchJSON(input, opts) {
  const res = await fetch(`${input}`, {
    ...opts,
  })
  if (!res.ok) {
    const err = new Error('network status error')
    err.status = res.status
    const json = await res.json()
    if (json && json.code) {
      err.code = json.code
    }
    throw err
  }
  const json = await res.json();
  if (json && json.status !== 'success') {
    const err = new Error()
    err.code = json.code
    throw err
  }
  return json.data || json
}

/**
 * RefreshToken.
 * */
window.isRefreshing = false;

let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
  refreshSubscribers.map(cb => cb(token));
}

function isRefreshTokenExpired() {
  const oDate = localStorage.getItem('nDate');
  const expires_in = localStorage.getItem('expires_in');
  const expiredDate = parseInt(oDate) + parseInt(expires_in);
  const nDate = parseInt(new Date().getTime() / 1000);
  return nDate > expiredDate;
}


function getRefreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  // return api(`${USER_REFRESH_PATH}?token=${refreshToken}`, 'post').then(res => res);
}

const service = axios.create({
  timeout: 120000,
  withCredentials: false,
});


service.interceptors.request.use(
  (config) => {
    if (typeof (config.data) === 'object') {
      config.data = JSON.stringify(config.data);
      config.headers = {
        Accept: 'application/json',
        'Content-type': 'application/json;charset=UTF-8',
      };
    } else {
      config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }

    const acceptLang = localStorage.getItem('locale');
    switch (acceptLang) {
      case 'en':
        config.headers = {
          'Accept-Language': 'en-US',
          ...config.headers
        };
        break;
      case 'zh':
        config.headers = {
          'Accept-Language': 'zh-CN',
          ...config.headers
        };
        break;
      default:
        config.headers = {
          'Accept-Language': 'zh-CN',
          ...config.headers
        };
        break;
    }

    const authinfo = sessionStorage.getItem('authinfo')
    if (authinfo) {
      let authJson = JSON.parse(authinfo)
      config.headers = {
        authorization: `Bearer ${authJson.access_token}`,
        ...config.headers
      };
    }
    return config;
  },
);

/**
 * @api {user:id} use
 * @apiName AAA
 * @apiGroup ABCD
 * @apiDescription --fdalsjfkdalsjfla
 */
service.interceptors.response.use(
  response => {
    if (response.request.responseURL.includes('api-test-k8s.fts.aero:999/mock')) {
      return {
        status: 1,
        code: 'OK',
        data: response.data
      }
    }
    if (response.data) {
      return response.data
    } else {
      const error = new Error();
      error.code = 500;
      return Promise.reject(error);
    }
  },
  (error) => {
    if (error instanceof Error) {
      if (!error.response) {
        return Promise.reject(new Error('timeout'));
      } else if (error.response.status === 401) {
        sessionStorage.clear('authinfo');
        return Promise.reject(new Error(keyToString({key: 'codeShare.NeedSignAgain'})));
      } else if (error.response.status === 403) {
        return Promise.reject(new Error(keyToString({key: 'codeShare.403Error'})));
      } else if (error.response.status === 404) {
        return Promise.reject(new Error(keyToString({key: 'codeShare.404Error'})));
      } else if (error.response.status === 500) {
        return Promise.reject(new Error(keyToString({key: 'codeShare.ServerInternalError'})));
      } else if (error.response.status === 502) {
        return Promise.reject(new Error(keyToString({key: 'codeShare.ServerInternalError'})));
      } else {
        let msg = error.response.data && error.response.data.data ? error.response.data.data : error.response;
        return Promise.reject(keyToString(msg));
      }
    }
  },
);

async function api(url, method = 'get', data, headers) {
  return service({
    url,
    method,
    data,
    headers,
  });
}

export async function getAxios(Path, Method, Param, Headers, needToken) {
  const res = await api(Path, Method, Param, Headers);
  const newRes = {};
  newRes.status = res.status;
  if (newRes.status === 1) {
    newRes.data = (res.data !== null && res.data !== undefined) ? res.data : res;
    return newRes.data;
  }
  //兼容CMS
  if (!!res.result && !!res.result.code && res.result.code === 'SUCCESS') {
    return res;
  }
  newRes.message = res.message;
  const error = new Error(await keyToString(res));
  error.status = newRes.status;
  throw error;
}

global.socket = io(BASE_API_URL, {
  path: '/socket.io',
  transports: ['websocket', 'polling']
});
//解决socket网络断开后，再连接失败问题
global.socket.on('connect', () => {
  let authinfo = sessionStorage.getItem('authinfo') ? JSON.parse(sessionStorage.getItem('authinfo')) : {};
  global.socket.emit('planeInfo', {xopToken: authinfo.access_token});
  global.socket.emit('track', {xopToken: authinfo.access_token});
});
global.socket.on('disconnect', () => {
  global.socket.connect()

});
global.socket.on('connect_error', () => {
  setTimeout(() => {
    global.socket.connect()
  }, 5000)
});
