import {
  withState,
  withHandlers,
  compose,
} from "recompose"
import {logI} from "shared/utils";

const menuCompose = compose(
  withState('selectedKeys', 'setSelectedKeys', ({context}) => {
    const {pathname, userInfo} = context;
    if ('/app/dashboard-ifec' === pathname) {
      context.setMenuCollapsed(true);
    }
    const {resources} = userInfo;
    for (let item of resources) {
      if (item.path === pathname) {
        return [item.id]
      }
    }
    return []
  }),
  withState('openKeys', 'setOpenKeys', ({context}) => {
    const {pathname, userInfo} = context;
    const {resources} = userInfo;
    let openKeys = [];
    for (let item of resources) {
      if (item.path === pathname) {
        openKeys.push(item.parentId);
        break;
      }
    }
    return openKeys
  }),
)

export default menuCompose