export const SET_AUTH_INFO = "AUTHORIZATION_SET_AUTH_INFO"
export const SET_USER_INFO = "AUTHORIZATION_SET_USER_INFO"
export const SET_MENUS = "AUTHORIZATION_SET_MENUS"

export function setAuthInfo(auth) {
  return async (dispatch) => {
    await sessionStorage.setItem("authinfo", JSON.stringify(auth))
    dispatch({
      type: SET_AUTH_INFO,
      auth,
    })
  }
}

export function setUserInfo(userData) {
  return async (dispatch) => {
    await sessionStorage.setItem("userinfo", JSON.stringify(userData))
    dispatch({
      type: SET_USER_INFO,
      userData,
    })
  }
}

export function setMenus(menus) {
  return async (dispatch) => {
    await sessionStorage.setItem("menus", JSON.stringify(menus))
    dispatch({
      type: SET_MENUS,
      menus,
    })
  }
}
