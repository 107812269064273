export default {
  'toolkit.titleSource': '数据连接',
  'toolkit.titleInterface': '数据接口',
  'toolkit.titleChart': '图表管理',
  'toolkit.titlePreview': '查看图表',
  'toolkit.titlePanel': '数据看板',

  'toolkit.newSource': '添加数据连接',
  'toolkit.editSource': '编辑数据连接',
  'toolkit.testSource': '测试连接',
  'toolkit.add': '新增',
  'toolkit.saveRelease': '保存并发布',
  'toolkit.addChart': '新增图表',
  'toolkit.exportChart': '导出图表',
  'toolkit.exportData': '导出数据',
  'toolkit.addPanel': '新增看板',

  'toolkit.export': '导出',
  'toolkit.filter': '条件',
  'toolkit.chart': '图表',
  'toolkit.style': '样式',
  'toolkit.filterWidget': '过滤条件组件',
  'toolkit.chartWidget': '拖出需要的数据图表',
  'toolkit.setPanelStyle': '设置看板样式',
  'toolkit.timeWidget': '时间组件',
  'toolkit.date': '日期',
  'toolkit.dateRange': '日期区间',
  'toolkit.timeRange': '时间区间',
  'toolkit.textWidget': '文本组件',
  'toolkit.oneSelect': '下拉单选',
  'toolkit.multiSelect': '下拉多选',

  'toolkit.nameWidget': '组件名称',
  'toolkit.subject': '主题',
  'toolkit.default value': '默认值',
  'toolkit.beginTime': '开始时间',
  'toolkit.endTime': '结束时间',
  'toolkit.stow': '收起',
  'toolkit.expand': '展开',
  'toolkit.option': '选择项',
  'toolkit.filterChar': '过滤字段',

  'toolkit.last5minutes': '最近5分钟',
  'toolkit.last10minutes': '最近10分钟',
  'toolkit.last30minutes': '最近30分钟',
  'toolkit.last1day': '最近1天',
  'toolkit.last7days': '最近7天',
  'toolkit.last30days': '最近30天',
  'toolkit.currentDay': '当天',

  'toolkit.px': '像素',
  'toolkit.width': '宽度 (忽略或者36的倍数)',

  'toolkit.interfaceKeySearch': '请输入接口名关键字搜索...',
  'toolkit.interfaceNameClick': '点击设置接口功能名称...',
  'toolkit.tableKeySearch': '输入表名称关键字搜索...',
  'toolkit.pathInput': '输入接口地址名...',
  'toolkit.chartSearch': '输入图表名称/接口名称搜索...',
  'toolkit.panelSearch': '输入看板名称搜索...',
  'toolkit.selectApi': '选择数据接口',

  'toolkit.nameSource': '数据连接名称',
  'toolkit.driver': '驱动',
  'toolkit.schema': '数据库名称',
  'toolkit.host': '主机',
  'toolkit.port': '端口',
  'toolkit.username': '用户名',
  'toolkit.pwd': '密码',
  'toolkit.charset': '编码',
  'toolkit.urlSource': '数据连接URL',

  'toolkit.method': '接口类型',
  'toolkit.interfaceName': '接口名称',
  'toolkit.interfaceDescribe': '接口说明',
  'toolkit.online': '发布',
  'toolkit.offline': '下线',
  'toolkit.test': '测试',
  'toolkit.onlineed': '已发布',
  'toolkit.notOnline': '未发布',
  'toolkit.path': '请求地址',
  'toolkit.params': '请求入参',
  'toolkit.required': '是否必传',
  'toolkit.type': '参数类型',
  'toolkit.key': '参数Key',
  'toolkit.value': '参数Value',
  'toolkit.describe': '参数说明',
  'toolkit.script': '查询脚本',
  'toolkit.db': '数据库',
  'toolkit.result': '响应结果',
  'toolkit.addLine': '添加参数',
  'toolkit.Status': '发布状态',

  'toolkit.CONNECTED': '已连接',
  'toolkit.NOT_CONNECTED': '未连接',

  'toolkit.STRING': '字符串',
  'toolkit.INTEGER': '整数',
  'toolkit.DECIMAL': '浮点数',

  'toolkit.chartName': '图表名称',
  'toolkit.chartType': '图表类型',
  'toolkit.apiName': '数据接口名称',
  'toolkit.updateTime': '更新时间',
  'toolkit.transferScript': '数据转换',
  'toolkit.execute': '执行',
  'toolkit.preview': '预览',
  'toolkit.chartData': '图表数据',
  'toolkit.chartPreview': '图表预览',

  'toolkit.bar': '柱状图',
  'toolkit.line': '折线图',
  'toolkit.pie': '饼状图',
  'toolkit.scatter': '散点图',
  'toolkit.effectScatter': '气泡图',
  'toolkit.radar': '雷达图',
  'toolkit.tree': '树图',
  'toolkit.treemap': '矩形树图',
  'toolkit.sunburst': '旭日图',
  'toolkit.boxplot': '盒须图',
  'toolkit.candlestick': 'K线图',
  'toolkit.heatmap': '热力图',
  'toolkit.map': '地图',
  'toolkit.parallel': '平行坐标图',
  'toolkit.lines': '路径图',
  'toolkit.graph': '关系图',
  'toolkit.sankey': '桑基图',
  'toolkit.funnel': '漏斗图',
  'toolkit.gauge': '仪表盘',
  'toolkit.pictorialBar': '象形柱图',
  'toolkit.themeRiver': '主题流图',
  'toolkit.table': '表格',

  'toolkit.panelName': '看板名称',
  'toolkit.inputPanelName': '请输入看板名称…',
  'toolkit.FullscreenBrowser': '浏览器全屏',
  'toolkit.ExitFullscreen': '退出全屏',
  'toolkit.Fullscreen': '全屏',
  'toolkit.back': '返回',

  'toolkit.all': '全部',
  'toolkit.select': '选择',
  'toolkit.refresh': '刷新',
  'toolkit.query': '查询',
  'toolkit.operation': '操作',
  'toolkit.details': '详情',
  'toolkit.check': '查看',
  'toolkit.copy': '复制',
  'toolkit.clone': '克隆',
  'toolkit.edit': '编辑',
  'toolkit.delete': '删除',
  'toolkit.rename': '重命名',
  'toolkit.package': '打包',
  'toolkit.download': '下载',
  'toolkit.cancel': '取消',
  'toolkit.save': '保存',
  'toolkit.ok': '确认',
  'toolkit.copyUrl': '复制下载URL',
  'toolkit.FillfromClipboard': '从粘贴板填充',
  'toolkit.auto': '自动',
  'toolkit.testSucceeded': '测试连接成功',
  'toolkit.testFailed': '测试失败，原因：',
};