import React from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom'
import {withRouter} from 'react-router';

@withRouter
class AuthorizedRoute extends React.Component {
  render() {
    const {
      component: Component,
      ...rest
    } = this.props;
    const auth = sessionStorage.getItem('authinfo') ? JSON.parse(sessionStorage.getItem('authinfo')) : {};
    let isExpires = true;
    if (auth) {
      let timestamp = Math.ceil(new Date().getTime() / 1000);
      if (auth.expires_in > timestamp) {
        isExpires = false;
      }
    }
    return <Route {...rest} render={props => {
      return !isExpires
        ? <Component {...props} />
        : <Redirect to={{pathname: "/login", search: this.props.location.pathname,}}/>
    }}/>
  }
}

export default AuthorizedRoute
