export default {
  'passengerCallRecord.title': 'Call Record',

  'passengerCallRecord.interfaceCall': 'Interface call statistics',
  'passengerCallRecord.responseNameAll': 'Response name statistics',
  'passengerCallRecord.detailList': 'Detail list',
  'passengerCallRecord.statusChart': 'Status chart',
  'passengerCallRecord.successFail': 'Percentage of success and failure',

  'passengerCallRecord.flightNo': 'Flight number',
  'passengerCallRecord.accNum': 'Acc number',
  'passengerCallRecord.idCard': 'Id card',
  'passengerCallRecord.seatNo': 'Seat no',
  'passengerCallRecord.apiName': 'Api name',
  'passengerCallRecord.status': 'Status',
  'passengerCallRecord.businessId': 'Business id',
  'passengerCallRecord.apiService': 'API service provider',
  'passengerCallRecord.requestSource': 'Request source',
  'passengerCallRecord.createTime': 'Create time',
  'passengerCallRecord.responseSource': 'Response source',

  'passengerCallRecord.total': 'Total',
  'passengerCallRecord.errorCount': 'Error Count',
  'passengerCallRecord.errorPercent': 'Error Percent',

  'passengerCallRecord.responseName': 'Response Name',
  'passengerCallRecord.count': 'Count',
  'passengerCallRecord.countPercent': 'Count Percent',

  'passengerCallRecord.responseNameExp': 'Response Name',

  'passengerCallRecord.last3h': 'Last 3h',
  'passengerCallRecord.last1d': 'Last day',
  'passengerCallRecord.last2d': 'Last 2 days',
  'passengerCallRecord.last3d': 'Last 3 days',
  'passengerCallRecord.last5d': 'Last 5 days',
  'passengerCallRecord.last7d': 'Last 7 days',
  'passengerCallRecord.last30d': 'Last 30 days',
  'passengerCallRecord.custom': 'Custom',

  'passengerCallRecord.searchKey': 'Search...',
  'passengerCallRecord.searchApiName': 'Api name...',
  'passengerCallRecord.allServiceProviders': 'All service providers',

  'passengerCallRecord.businessIdExp': 'A business request ID. when the request fails, the system will automatically try again. Multiple call records will be generated, but all of them belong to one business number',
};