export default {
  'nmsService.titleDefinition': '服务定义',
  'nmsService.titleSetting': '服务配置',
  'nmsService.titleTest': '服务检测',

  'nmsService.serviceAdd': '新增服务',
  'nmsService.serviceEdit': '编辑服务',
  'nmsService.settingAdd': '新增配置',
  'nmsService.settingEdit': '编辑配置',
  'nmsService.startTest': '开始检测',

  'nmsService.serviceName': '服务名称',
  'nmsService.serviceKey': '键值Key',
  'nmsService.description': '描述',
  'nmsService.updateTime': '更新时间',
  'nmsService.script': '检测脚本',
  'nmsService.tailNo': '机尾号',
  'nmsService.interval': '检测间隔',
  'nmsService.services': '检测服务',
  'nmsService.testResult': '检测结果',
  'nmsService.testTime': '检测时间',
  'nmsService.cmdType': '命令类型',
  'nmsService.param': '命令脚本',
  'nmsService.checkType': '结果检查类型',
  'nmsService.checkValue': '固定值',

  'nmsService.serviceKeyExp': '需要在虚拟设备中添加一个同名的参数代码',
  'nmsService.paramExp': '脚本中的变量可拷贝适用配置中的参数别名',

  'nmsService.contain': '是否包含',
  'nmsService.origin': '原始值',

  'nmsService.query': '查询',
  'nmsService.serviceKeySearch': '键值Key搜索...',
  'nmsService.serviceNameSearch': '服务名称搜索...',
  'nmsService.searchKey': '关键字搜索...',
  'nmsService.tailNoSearch': '机尾号搜索...',
  'nmsService.selectService': '选择服务',

  'nmsService.select': '选择',
  'nmsService.refresh': '刷新',
  'nmsService.operation': '操作',
  'nmsService.edit': '编辑',
  'nmsService.delete': '删除',
  'nmsService.save': '保存',
  'nmsService.cancel': '取消',
};
