export default {
  'airlinesScheme.title': '方案管理',

  'airlinesScheme.createScheme': '新增方案',
  'airlinesScheme.name': '方案名称',
  'airlinesScheme.netTypes': '网络类型',
  'airlinesScheme.schemeDevices': '安装设备',
  'airlinesScheme.description': '方案描述',
  'airlinesScheme.updateTime': '更新时间',
  'airlinesScheme.delete': '删除',

  'airlinesScheme.allowedRepeat': '(不允许重复)',
  'airlinesScheme.enterName': '请输入改装方案名称',
  'airlinesScheme.enterDescription': '请对该方案进行简单的描述',

  'airlinesScheme.LOCAL': '局域网',
  'airlinesScheme.SATELLITE': '卫星',
  'airlinesScheme.ok': '确认',
  'airlinesScheme.cancel': '取消',
};
