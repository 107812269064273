export default {
  'nmsFlightList.title': '数据报告',

  'nmsFlightList.tailNumber': '机尾号',
  'nmsFlightList.flightNumber': '航班号',
  'nmsFlightList.timeInterval': '时间区间',
  'nmsFlightList.query': '查询',
  'nmsFlightList.tailNumberSearch': '机尾号搜索...',
  'nmsFlightList.flightNumberSearch': '航班号搜索...',
  'nmsFlightList.deptSearch': '出发城市、机场三字码搜索...',
  'nmsFlightList.destSearch': '到达城市、机场三字码搜索...',
  'nmsFlightList.refresh': '更新数据',

  'nmsFlightList.time': '时间',
  'nmsFlightList.model': '机型',
  'nmsFlightList.departureCity': '起飞机场',
  'nmsFlightList.toCity': '降落机场',
  'nmsFlightList.takeOffArrive': '起飞 -- 到达',
  'nmsFlightList.actualLandingTime': '实际到达时间',
  'nmsFlightList.actualTakeoffTime': '实际起飞时间',
  'nmsFlightList.landingTime': '计划到达时间',
  'nmsFlightList.takeoffTime': '计划起飞时间',
  'nmsFlightList.flightTime': '实际飞行时长',
  'nmsFlightList.edit': '操作',
  'nmsFlightList.list': '清单',
  'nmsFlightList.fees': '费用',

  'nmsFlightList.flightReport': '硬件报告',
  'nmsFlightList.operationReport': '运营报告',
  'nmsFlightList.flightRun': '运行报告',
  'nmsFlightList.last5m': '最近5分钟',
  'nmsFlightList.last10m': '最近10分钟',
  'nmsFlightList.last30m': '最近30分钟',
  'nmsFlightList.last1h': '最近1小时',
  'nmsFlightList.last2h': '最近2小时',
  'nmsFlightList.last4h': '最近4小时',
  'nmsFlightList.last6h': '最近6小时',
  'nmsFlightList.last24h': '最近24小时',
  'nmsFlightList.last7d': '最近7天',
  'nmsFlightList.custom': '自定义',
};
