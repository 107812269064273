export default {
  'dataTraceBack.title': 'Trace Back',

  'dataTraceBack.tailNumber': 'Tail No',
  'dataTraceBack.flightNumber': 'Flight number',
  'dataTraceBack.timeInterval': 'Time interval',
  'dataTraceBack.query': 'Search',
  'dataTraceBack.tailNumberSearch': 'Tail No search...',
  'dataTraceBack.flightNumberSearch': 'Flight number search...',
  'dataTraceBack.searchKeySearch': 'SearchKey search...',
  'dataTraceBack.authenticatedSearch': 'Authenticated search...',
  'dataTraceBack.exportData': 'Export',

  'dataTraceBack.flightDate': 'Flight date',
  'dataTraceBack.dept': 'Dept',
  'dataTraceBack.dest': 'Dest',
  'dataTraceBack.idCard': 'Id Card',
  'dataTraceBack.seatNo': 'Seat No',
  'dataTraceBack.authenticated': 'Authenticated',
  'dataTraceBack.accNum': 'Acc num',
  'dataTraceBack.joinStatus': 'Join Status',
  'dataTraceBack.firstLoginTime': 'First Login Time',
  'dataTraceBack.traceBack': 'Trace Back',
  'dataTraceBack.operation': 'Operation',
  'dataTraceBack.details': 'Details',

  'dataTraceBack.authenticatedExp': 'Authenticated',
  'dataTraceBack.joinStatusExp': 'Success means that there is at least one online success. Failure means that there are activated traffic packets, but they have never been launched. If not, there are no activated traffic packets',

  'dataTraceBack.all': 'All',
  'dataTraceBack.success': 'Success',
  'dataTraceBack.fail': 'Fail',
  'dataTraceBack.allSuccess': 'Auth Status',
  'dataTraceBack.allActivated': 'Access Status',

  'dataTraceBack.activated': 'Activated',
  'dataTraceBack.failed': 'Failed',
  'dataTraceBack.inactive': 'Inactive',

  'dataTraceBack.last10m': 'Last 10 minutes',
  'dataTraceBack.last30m': 'Last 30 minutes',
  'dataTraceBack.last1h': 'Last hour',
  'dataTraceBack.last2h': 'Last 2 hours',
  'dataTraceBack.last3h': 'Last 3 hours',
  'dataTraceBack.last24h': 'Last 24 hours',
  'dataTraceBack.custom': 'Custom',
};
