export default {
  'mockTakeoffLanding.title': '模拟起飞降落',
  'mockTakeoffLanding.flightInfo': '模拟航班信息',

  'mockTakeoffLanding.on': '开',
  'mockTakeoffLanding.off': '关',
  'mockTakeoffLanding.edit': '编辑',
  'mockTakeoffLanding.onOffExp':'在还没降落前关闭总开关时，飞机会强制回到降落状态',

  'mockTakeoffLanding.planeTailNo': '机尾号',
  'mockTakeoffLanding.planeIP': '飞机Ip/机载服务器IP',
  'mockTakeoffLanding.takeOffDate': '起飞日期',
  'mockTakeoffLanding.flightNo': '航班号',
  'mockTakeoffLanding.actualTakeOffTime': '实际起飞时间',
  'mockTakeoffLanding.actualLandTime': '实际落地时间',
  'mockTakeoffLanding.takeOffAirportName': '起飞机场名',
  'mockTakeoffLanding.takeOffAirportTripleCode': '起飞机场代码',
  'mockTakeoffLanding.planningTakeOffTime': '计划起飞时间',
  'mockTakeoffLanding.landingAirportName': '降落机场名',
  'mockTakeoffLanding.landingAirportTripleCode': '降落机场代码',
  'mockTakeoffLanding.planningLandingTime': '计划到达时间',

  'mockTakeoffLanding.takeoff': '模拟起飞',
  'mockTakeoffLanding.landing': '模拟降落',
  'mockTakeoffLanding.takeoffExplain': '当模拟起飞时，调用卫通飞机起飞通知接口，开启入网',
  'mockTakeoffLanding.landingExplain': '当模拟降落时，调用卫通飞机降落通知接口，关闭入网',

  'mockTakeoffLanding.passengerList': 'XOP在线旅客状态列表',
  'mockTakeoffLanding.weiTongList': '卫通查在线接口返回结果',
  'mockTakeoffLanding.refresh': '刷新',
  'mockTakeoffLanding.seatNo': '座位号',
  'mockTakeoffLanding.idCard': '证件号',
  'mockTakeoffLanding.internalIp': 'GNS分配地址',
  'mockTakeoffLanding.gnsStatus': 'GNS放通状态',
  'mockTakeoffLanding.externalIp': '卫通分配地址',
  'mockTakeoffLanding.satcomStatus': '卫通放通状态',

  'mockTakeoffLanding.editTitle': '模拟起飞降落参数设置',
  'mockTakeoffLanding.save': '保存',

  'mockTakeoffLanding.syncing': '数据同步中',
  'mockTakeoffLanding.syncSuccess': '数据同步成功',
  'mockTakeoffLanding.syncFail': '数据同步失败',
};
