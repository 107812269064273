export default {
  'commodityTable.title': 'Data Package',
  'commodityTable.pageDescription': 'Airborne network traffic management, including the definition and operation settings of traffic packets',

  'commodityTable.status': 'Status:',
  'commodityTable.type': 'Type:',
  'commodityTable.keyword': 'Keywords:',
  'commodityTable.all': 'All',
  'commodityTable.enterTitle': 'Title',
  'commodityTable.query': 'Search',
  'commodityTable.addTrafficPacket': 'Add',
  'commodityTable.details': 'Details',

  'commodityTable.satcomName': 'Satcom name',
  'commodityTable.operationTitle': 'Operation title',
  'commodityTable.trafficType': 'Traffic type',
  'commodityTable.trafficSize': 'Traffic size',
  'commodityTable.price': 'Price',
  'commodityTable.trafficEffectiveTime': 'Traffic effective time',
  'commodityTable.offShelfTime': 'Off shelves time',
  'commodityTable.operation': 'Operation',
  'commodityTable.putOnShelf': 'Shelves',
  'commodityTable.offShelf': 'Off shelves',
  'commodityTable.more': 'More',
  'commodityTable.edit': 'Edit',
  'commodityTable.delete': 'Delete',
  'commodityTable.clone': 'Clone',
  'commodityTable.prompt': 'Prompt',
  'commodityTable.wantDeleteIt': 'Do you want to delete this traffic packet? It cannot be recovered after deletion! ',
  'commodityTable.previousToast': 'Data modified after the previous step will be lost！',
  'commodityTable.state': 'State',

  'commodityTable.insales': 'In sales',
  'commodityTable.toBePputOnShelf': 'To be put on sale',
  'commodityTable.offShelf1': 'Off shelves',
  'commodityTable.fixedTraffic': 'Fixed traffic',
  'commodityTable.fixedLeg': 'Fixed leg',
  'commodityTable.fixedDuration': 'Fixed duration',
  'commodityTable.fixedPeriod': 'Fixed period',
  'commodityTable.other': 'Other',
  'commodityTable.unlimited': 'Unlimited',
  'commodityTable.automaticOffShelfAfterExpiration': 'Automatic off shelf after expiration',
  'commodityTable.manualOff': 'Manual off',

  'commodityTable.ispId': 'ISP',
  'commodityTable.vspId': 'VSP',
  'commodityTable.ispIdExp': '(eg：中国卫通)',
  'commodityTable.vspIdExp': '(VSP)',
  'commodityTable.editTrafficPackage': 'Edit traffic package',
  'commodityTable.trafficDefinition': 'Traffic definition',
  'commodityTable.operationSettings': 'Operation settings',
  'commodityTable.specifiesTheUsageTimeAirTraffic': 'Specifies the usage time of air traffic...',
  'commodityTable.specifyAirTrafficUsageTimePeriod': 'Specify the air traffic usage time period...',
  'commodityTable.airTrafficSpecifiedSize': 'Air traffic of specified size...',
  'commodityTable.trafficValidInFlightSegment': 'Traffic valid in this flight segment...',
  'commodityTable.effectiveDeadline': 'Effective deadline',
  'commodityTable.bandwidthLimit': 'Bandwidth limit',
  'commodityTable.directedAccess': 'Directed access',
  'commodityTable.unlimitedExperience': 'Unlimited experience',
  'commodityTable.specifyURL': 'Specify URL/IP',
  'commodityTable.specifyApplication': 'Specify application',
  'commodityTable.pleaseEnterTitleTrafficPackag': 'Please enter the title of the traffic package',
  'commodityTable.operationMainDiagram': 'Operation main diagram',
  'commodityTable.lastTime': 'Last time',
  'commodityTable.trafficPackageDescription': 'Traffic package description',
  'commodityTable.descriptionTrafficPackage': 'Description of traffic package, within 250 words',
  'commodityTable.save': 'Save',
  'commodityTable.saveAndReturn': 'Save and return',
  'commodityTable.areYouSure': 'Are you sure you want to leave this page?',

  'commodityTable.operatorDataFlowID': 'Operator Data Flow ID',
  'commodityTable.credits': 'Credits',
  'commodityTable.operatorTrafficName': 'Operator traffic package name',
  'commodityTable.trafficSupplier': '(Provided by the traffic supplier)',
  'commodityTable.fixedTrafficSize': 'Fixed traffic size',
  'commodityTable.untilFlowUsedUp': '(Within the validity period, until the flow is used up)',

  'commodityTable.fixedTimeLong': 'Fixed time is long',
  'commodityTable.cumulativeConsumptionOnlineTime': '(Cumulative consumption of online time is not supported at present)',
  'commodityTable.maxAvailableTrafficSize': 'Maximum available traffic size',
  'commodityTable.unlimitedEmpty': '(Unlimited if empty)',

  'commodityTable.addPackage': 'Add package',
  'commodityTable.packageDetail': 'Package details',
  'commodityTable.packageEdit': 'Package Edit',
  'commodityTable.selectType': 'Select Type',
  'commodityTable.fillOperationpolicy': 'Fill in operation policy',
  'commodityTable.done': 'Done',
  'commodityTable.next': 'Next',
  'commodityTable.previous': 'Previous',
  'commodityTable.submit': 'Submit',
  'commodityTable.uplinkRate': 'Uplink rate',
  'commodityTable.downlinkRate': 'Downlink rate',
  'commodityTable.effectiveTrafficDeadline': 'Effective traffic deadline',
  'commodityTable.specifyAccessURL': 'Specify access URL',
  'commodityTable.specifyAccessApp': 'Specify access application',
  'commodityTable.notOpenedYet': '(Not opened yet, please wait)',
  'commodityTable.registerEffectiveID': '(Register effective ID to flow supplier)',
  'commodityTable.defaultEffectiveTime': '(If it is blank, the default effective time is 1 year)',
  'commodityTable.selectURL': 'Select URL',
  'commodityTable.selectApp': 'Select application',
  'commodityTable.noPointsAreRequired': '(if it is empty, no points are required)',
  'commodityTable.withinWords': '(within 150 words)',
  'commodityTable.pngPicturesWithin': '(jpg / PNG pictures within 100kb, the best aspect ratio is 9:5)',
  'commodityTable.waitingForListing': '(if it is empty, it will be listed as draft, waiting for listing)',
  'commodityTable.salesPrice': 'Sales price',
  'commodityTable.remarks': 'Comments',
  'commodityTable.purchaseUserType': 'Purchase user type',
  'commodityTable.packageIntroduction': 'Package Introduction',
  'commodityTable.numberGreaterThan0': 'Please enter a number greater than 0 and retain at most two decimal places',
  'commodityTable.Unlimited': 'Unlimited',
  'commodityTable.Member': 'Member',
  'commodityTable.NonMember': 'Non Member',
  'commodityTable.viewList': 'View list',
  'commodityTable.viewDetails': 'View details',
  'commodityTable.cloneCopy': 'Clone a copy',
  'commodityTable.anotherCopy': 'Another copy',

  'commodityTable.addImge': 'Add Imge',
  'commodityTable.BestSize': 'Best size 200 x 200px',
  'commodityTable.uploadImage': 'Please upload an image file',
  'commodityTable.replaceImage': 'The uploaded file exceeds 100Kb. Please replace the image.',
  'commodityTable.FileUploading': 'File is uploading... Please try again later',
  'commodityTable.Submitting': 'Submitting...',
  'commodityTable.PNGAndJPEG': 'Only supports PNG and JPEG format images.',
  'commodityTable.UpstreamBandwidth': 'Upstream Bandwidth',
  'commodityTable.DownstreamBandwidth': 'Downstream Bandwidth',
  'commodityTable.displayOnly': 'For display only, the actual value to be used is subject to the flow supplier.',

  'commodityTable.packageDescription': 'Traffic package description',
  'commodityTable.airFlowSpecifiedSize': 'The air flow of the specified size, which is activated voluntarily when passengers get on the plane, is automatically disconnected when they get off the plane, and is purchased once and consumed many times until the flow is exhausted. ',
  'commodityTable.refersFailureWarning': 'Refers to the failure warning report of aircraft modification scheme in unit time period. Specify the cumulative use time of air flow, which is activated voluntarily when passengers get on the aircraft, and automatically disconnect the flow when getting off the aircraft, purchase once and consume many times until the time is used up.',
  'commodityTable.specifiesUseTimePeriodAirFlow': 'Specifies the use time period of air flow, which is activated voluntarily when passengers get on the plane, and automatically disconnects when they get off the plane. It is valid for one purchase and multiple consumption.',
  'commodityTable.flowValidInFlightSegment': 'The flow is valid in the flight segment. When passengers get on the aircraft, they voluntarily activate the segment. When they get off the aircraft, the flow will be automatically closed. The flow package of this segment is deemed to have been used. The aircraft will fly from location a to location B, and A-B is deemed to be a segment. ',
};