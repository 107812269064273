export default {
  'dataEventStatistics.title': '事件统计',

  'dataEventStatistics.query': '查询',
  'dataEventStatistics.tailNumberSearch': '机尾号搜索...',
  'dataEventStatistics.flightNumberSearch': '航班号搜索...',
  'dataEventStatistics.eventNameSearch': '事件名称搜索...',
  'dataEventStatistics.eventLevelIdsSearch': '事件级别选择',
  'dataEventStatistics.deptSearch': '出发城市、机场三字码搜索...',
  'dataEventStatistics.destSearch': '到达城市、机场三字码搜索...',
  'dataEventStatistics.exportData': '导出数据',

  'dataEventStatistics.tailNo': '机尾号',
  'dataEventStatistics.flightNo': '航班号',
  'dataEventStatistics.flightDate': '时间',
  'dataEventStatistics.deptCity': '出发城市',
  'dataEventStatistics.destCity': '到达城市',
  'dataEventStatistics.body': '事件名称',
  'dataEventStatistics.eventLevelName': '事件级别',
  'dataEventStatistics.occurrenceCount': '出现次数',

  'dataEventStatistics.eventName': '看事件',
  'dataEventStatistics.eventLevel': '看级别',

  'dataEventStatistics.time': '时间',
  'dataEventStatistics.totalFlight': '按航班统计',
  'dataEventStatistics.totalDay': '按日期统计',
  'dataEventStatistics.totalWeek': '按周统计',
  'dataEventStatistics.totalMonth': '按月统计',
  'dataEventStatistics.totalQuarter': '按季度统计',
  'dataEventStatistics.totalYear': '按年统计',

  'dataEventStatistics.last1d': '最近1天',
  'dataEventStatistics.last2d': '最近2天',
  'dataEventStatistics.last3d': '最近3天',
  'dataEventStatistics.last5d': '最近5天',
  'dataEventStatistics.last7d': '最近7天',
  'dataEventStatistics.last30d': '最近30天',
  'dataEventStatistics.custom': '自定义',
};
