import rbacMenus from './rbac-menus'
import rbacMenuForm from './rbac-menu-form'
import rbacUsers from './rbac-users'
import rbacUsersForm from './rbac-users-form'
import FTPageHeader from './FTPageHeader'
import nmsRecord from './nms-record'
import nmsDeviceAircrafts from './nms-device-aircrafts'
import nmsDeviceMonitoring from './nms-device-monitoring'
import nmsDeviceMaintain from './nms-device-maintain'
import nmsMonitoringSettings from './nms-device-dictionary-monitoring-settings'
import nmsDeviceDictionary from './nms-device-dictionary'
import nmsException from './nms-exception-monitor'
import nmsDeviceMaintainSetting from './nms-device-maintain-setting'
import nmsDeviceMaintainWmt from './nms-device-maintain-wmt'
import nmsDeviceDataReport from './nms-device-data-report'
import menu from './menu'
import iasOrderLogs from './ias-order-logs'
import iasUserList from './ias-user-list'
import iasControl from './ias-control'
import commodityTable from './commodity-table'
import passengerAccess from './passenger-access-log'
import passengerLogin from './passenger-login-log'
import passengerRegister from './passenger-register-log'
import passengerVirtual from './passenger-virtual'
import aircraftManage from './aircraft-manage-table'
import aircraftType from './settings-aircraft-type'
import dashboardIfec from './dashboard-ifec'
import settingsClient from './settings-client'
import settingsFlight from './settings-flight'
import codeShare from './code-share'
import iasOrderInfo from './ias-order-info'
import commodityInfo from './commodity-info'
import personalCenter from './personal-center'
import messageDefinition from './settings-message-definition'
import rolesManage from './ams-roles-manage'
import login from './login'
import settingsNotification from './settings-notification'
import MockTakeoffLanding from './mock-takeoff-landing'
import iasFlightsList from './ias-flights-list'
import iasPassengerOnlineList from './ias-passenger-online-list'
import iasPassengerOnlineDetails from './ias-passenger-online-details'
import nmsPointStation from './nms-point-station'
import iasNetList from './ias-net-list'
import iasNetRealTimeCost from './ias-net-real-time-cost'
import nmsAlarmTroubleshooting from './nms-alarm-troubleshooting'
import dashboardMonitor from './dashboard-monitor'
import nmsDetailedLog from './nms-detailed-log'
import passengerCallRecord from './passenger-call-record'
import nmsFlightList from './nms-flight-list'
import nmsReportOperate from './nms-report-operate'
import nmsReportRun from './nms-report-run'
import dataAccessAnalysis from './data-access-analysis'
import dataFlowAnalysis from './data-flow-analysis'
import dataTraceBack from './data-trace-back'
import dataTraceBackDetails from './data-trace-back-details'
import satcomStationWarning from './satcom-station-warning'
import airlinesFlightPlan from './airlines-flight-plan'
import passengerNetList from './passenger-net-list'
import dataPackage from './data-package'
import nmsAlarmLevel from './nms-alarm-level'
import nmsMonitorEvent from './nms-monitor-event'
import nmsEventHistory from './nms-event-history'
import airlinesFilling from './airlines-filling'
import dataEventStatistics from './data-event-statistics'
import airlinesScheme from './airlines-scheme'
import airlinesPreinstallForm from './airlines-preinstall-form'
import nmsResume from './nms-resume'
import nmsJoin from './nms-join'
import rbacOrganization from './rbac-organization'
import passengerOrgConfig from './passenger-org-config'
import opsConfig from './ops-config'
import opsQuestion from './ops-question'
import opsDashboard from './ops-dashboard'
import rbacPage from './rbac-page'
import rbacPageForm from './rbac-page-form'
import logOplog from './log-oplog';
import iasAAA from './ias-aaa';
import toolkit from './toolkit'
import iasAirport from './ias-airport'
import nmsService from './nms-service'
import nmsApplyConfig from './nms-apply-config';

export default {
  ...rbacMenus,
  ...rbacMenuForm,
  ...rbacUsers,
  ...FTPageHeader,
  ...nmsRecord,
  ...nmsDeviceAircrafts,
  ...nmsDeviceMonitoring,
  ...nmsDeviceMaintain,
  ...nmsMonitoringSettings,
  ...nmsDeviceDictionary,
  ...menu,
  ...iasOrderLogs,
  ...iasUserList,
  ...iasControl,
  ...commodityTable,
  ...passengerAccess,
  ...passengerLogin,
  ...passengerRegister,
  ...aircraftManage,
  ...aircraftType,
  ...dashboardIfec,
  ...settingsClient,
  ...settingsFlight,
  ...nmsException,
  ...nmsDeviceMaintainSetting,
  ...nmsDeviceMaintainWmt,
  ...codeShare,
  ...nmsDeviceDataReport,
  ...passengerVirtual,
  ...iasOrderInfo,
  ...commodityInfo,
  ...personalCenter,
  ...messageDefinition,
  ...rolesManage,
  ...login,
  ...settingsNotification,
  ...MockTakeoffLanding,
  ...iasFlightsList,
  ...iasPassengerOnlineList,
  ...iasPassengerOnlineDetails,
  ...nmsPointStation,
  ...iasNetRealTimeCost,
  ...iasNetList,
  ...nmsAlarmTroubleshooting,
  ...dashboardMonitor,
  ...nmsDetailedLog,
  ...passengerCallRecord,
  ...nmsFlightList,
  ...nmsReportOperate,
  ...nmsReportRun,
  ...dataAccessAnalysis,
  ...dataFlowAnalysis,
  ...dataTraceBack,
  ...dataTraceBackDetails,
  ...rbacUsersForm,
  ...satcomStationWarning,
  ...airlinesFlightPlan,
  ...passengerNetList,
  ...dataPackage,
  ...nmsAlarmLevel,
  ...nmsMonitorEvent,
  ...nmsEventHistory,
  ...airlinesFilling,
  ...dataEventStatistics,
  ...airlinesScheme,
  ...airlinesPreinstallForm,
  ...nmsResume,
  ...nmsJoin,
  ...rbacOrganization,
  ...passengerOrgConfig,
  ...opsConfig,
  ...opsQuestion,
  ...opsDashboard,
  ...rbacPage,
  ...rbacPageForm,
  ...logOplog,
  ...iasAAA,
  ...toolkit,
  ...iasAirport,
  ...nmsService,
  ...nmsApplyConfig,
}
