export default {
  'dashboardIfec.basicFlightInfo': '航班基本信息',
  'dashboardIfec.tailNumber': '机尾号',
  'dashboardIfec.flight': '航班',
  'dashboardIfec.departure': '出发城市',
  'dashboardIfec.arrival': '到达城市',
  'dashboardIfec.planStart': '计划出发',
  'dashboardIfec.plannedArrival': '计划到达',
  'dashboardIfec.actualDeparture': '实际出发',
  'dashboardIfec.expectedArrival': '预计到达',
  'dashboardIfec.airline': '航空公司',
  'dashboardIfec.status': '飞行状态',
  'dashboardIfec.flying': '飞行中',
  'dashboardIfec.landed': '停飞中',
  'dashboardIfec.readyToTakeoff': '准备起飞',
  'dashboardIfec.cabinNetworkAccess': '客舱网络接入情况',
  'dashboardIfec.realTimeUplinkTraffic': '实时上行流量',
  'dashboardIfec.realTimeDownlinkTraffic': '实时下行流量',
  'dashboardIfec.numberPeopleOnline': '在线人数',
  'dashboardIfec.numberInternetDevices': '上网设备数',
  'dashboardIfec.airborneNetworkInformation': '机载网络设备状态',
  'dashboardIfec.currentNet': '当前网络：',
  'dashboardIfec.mobile': '移动',
  'dashboardIfec.Satellite': '卫星',
  'dashboardIfec.noSignal': '无信号',
  'dashboardIfec.flightFromAirline': '此航班基本信息来自航空公司',
  'dashboardIfec.map': '地图',
  'dashboardIfec.SATCOM': '卫通',
  'dashboardIfec.APSTAR': '亚太',

  'dashboardIfec.plan': '计划',
  'dashboardIfec.expect': '预计',
  'dashboardIfec.actual': '实际',
  'dashboardIfec.resource': '地面资源站',
  'dashboardIfec.EventAlert': '事件提醒',
  'dashboardIfec.InternetActivity': '旅客上网动态',
  'dashboardIfec.DeviceStatus': '机载网络设备状态',
  'dashboardIfec.Beam': '波束变化曲线',
  'dashboardIfec.Altitude': '海拔高度(*1000ft)',
  'dashboardIfec.AccessTerminals': '网络接入终端变化曲线',
  'dashboardIfec.UploadandDownloadSpeed': '上下行速率',
  'dashboardIfec.Seats': '旅客座位数',
  'dashboardIfec.People': '旅客人数',
  'dashboardIfec.Authentications': '认证人数',
  'dashboardIfec.InternetUsers': '上网人数',
  'dashboardIfec.Terminals': '上网终端数',
  'dashboardIfec.Orders': '流量订单数',

  'dashboardIfec.installedQuantity': '装机数量',
  'dashboardIfec.cumulativeNumberFlights': '累计飞行次数',
  'dashboardIfec.carryingPassengers': '承运旅客',
  'dashboardIfec.cumulativeTransactions': '累计机上交易',
  'dashboardIfec.timeFirstInstallation': '距首次装机时间',
  'dashboardIfec.trafficConsumption': '流量消耗',
  'dashboardIfec.proportionOnlinePassengers': '上网旅客占比',
  'dashboardIfec.onlineDurationCapita': '人均在线时长',
  'dashboardIfec.onlineFlightDurationCapita': '人均在线飞行时长比',
  'dashboardIfec.cabinWiFiUsage': '客舱Wi-Fi使用情况',
  'dashboardIfec.numberTrafficActivation': '流量激活数',
  'dashboardIfec.governmentIP': '政务IP',
  'dashboardIfec.businessIP': '商务IP',
  'dashboardIfec.entertainmentIP': '娱乐IP',
  'dashboardIfec.other': '其他',
  'dashboardIfec.allYearRound': '全年',
  'dashboardIfec.thisMonth': '本月',
  'dashboardIfec.thisWeek': '本周',
  'dashboardIfec.today': '今日',

  'dashboardIfec.detailedRecord': '详细记录',
  'dashboardIfec.trafficOrder': '流量订单',
  'dashboardIfec.flightPassengers': '航班旅客',
  'dashboardIfec.loginRecord': '登录记录',
  'dashboardIfec.flightHistory': '飞行历史',
  'dashboardIfec.modificationDetails': '改装详情',
  'dashboardIfec.details': '详情',
  'dashboardIfec.updateTime': '更新时间',
};