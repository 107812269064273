export default {
  'menu.dashboard': '仪表盘',
  'menu.dashboard.monitor': '数据监控',
  'menu.nms.manage': '设备管理',
  'menu.nms.aircrafts': '飞机列表',
  'menu.nms.monitoring': '数据告警',
  'menu.nms.maintain': '装机维护',
  'menu.nms.dataDownload': '设备数据',
  'menu.nms.dictionarySettings': '监控设置',
  'menu.nms.deviceDataReport': '数据报告',
  'menu.nms.dictionary': '设备字典',
  'menu.nms.warnings': '设备告警',
  'menu.ias.manage': '流量管理',
  'menu.ias.dataOrder': '流量订单',
  'menu.ias.userList': '上网旅客',
  'menu.ias.dataPackage': '流量套餐',
  'menu.ias.accessControl': '黑白名单',
  'menu.ias.addPackage': '新增套餐',
  'menu.ias.flightsList': '航班旅客',
  'menu.cms.manage': '内容管理',
  'menu.cms.video': '视频管理',
  'menu.cms.video.category': '视频分类',
  'menu.cms.video.version': '视频版本',
  'menu.cms.video.resource': '视频资源',
  'menu.cms.aircraftConfig': '飞机配置',
  'menu.cms.recommendedManage': '推荐管理',
  'menu.cms.advertisingManage': '广告管理',
  'menu.cms.e-commerceManage': '电商管理',
  'menu.cms.operationReport': '运营报表',
  'menu.cms.userManage': '用户管理',
  'menu.cms.download': '资源下载',
  'menu.passenger.manage': '乘客管理',
  'menu.passenger.accessLog': '行为日志',
  'menu.passenger.loginLog': '登录记录',
  'menu.passenger.regisiterLog': '注册记录',
  'menu.passenger.virtual': '虚拟旅客',
  'menu.passenger.callRecord': '接口分析',
  'menu.data.accessAnalysis': '入网统计',
  'menu.data.flowAnalysis': '流量统计',
  'menu.data.traceBack': '入网追溯',
  'menu.flights.manage': '机队管理',
  'menu.flights.flight': '飞行历史',
  'menu.flights.aircraftManage': '飞机管理',
  'menu.flights.aircraftType': '机型管理',
  'menu.data.analysis': '数据分析',
  'menu.data.dashboardIfec': '飞行地图',
  'menu.data.dashboardNms': 'NMS总览',
  'menu.system.config': '系统配置',
  'menu.system.authorization': '系统鉴权',
  'menu.system.dataSource': '数据源配置',
  'menu.system.notification': '通知服务',
  'menu.version': '软件版本',
  'menu.admin.permission': '权限管理',
  'menu.admin.account': '账号管理',
  'menu.admin.function': '功能管理',
  'menu.personalCenter': '个人中心',
  'menu.settings.messageDefinition': '消息设置',
  'menu.admin.role': '角色管理',
  'menu.softwareVersion': '软件版本',
  'menu.mockTest': '模拟测试',
  'menu.mockTest.takeoffLanding': '模拟起飞降落',
  'menu.satcom.stationWarning': '小站告警',
  'menu.airlines.flightPlan': '航班计划',
  'menu.passenger.netList': '上网清单',
  'menu.data.package': '套餐统计',
  'menu.nms.monitorEvent': '监控事件',
  'menu.airlines.filling': '机载软件',
  'menu.data.eventStatistics': '事件统计',
  'menu.airlines.scheme': '方案管理',
  'menu.airlines.preinstall': '改装预设',
  'menu.resume': '设备履历',
  'menu.nms.join': '接入明细',
  'menu.rbac.organization': '机构管理',
  'menu.passenger.orgConfig': '外部服务',
  'menu.ops.manage': '运维管理',
  'menu.ops.dashboard': '运维总览',
  'menu.ops.question': '问题处理',
  'menu.ops.config': '运维配置',
  'menu.nms.alarm.troubleshoot': '设备数据排查',
  'menu.data.trace-back-details': '入网追溯详情',
  'menu.nms.detailed.log': '飞行记录',
  'menu.nms.recordTime': '实时信息',
  'menu.nms.device.maintain.view': '改装详情',
  'menu.rbac.page': '页面管理',
  'menu.ops.question.details': '问题处理详情',
  'menu.ops.question.form': '问题处理新增编辑',
  'menu.ops.config.tag.form': '标签库新增编辑',
  'menu.airLineMap': '航行地图',
  'menu.passenger.orgConfig.key.form': '密钥配置新增编辑',
  'menu.passenger.orgConfig.server.form': '服务配置新增编辑',
  'menu.rbac.user.details': '账号信息',
  'menu.nms.resume.details': '设备履历详情',
  'menu.airlines.filling.version.info': '软件版本详情',
  'menu.airlines.filling.version.form': '软件版本新增编辑',
  'menu.nms.event.history': '历史事件',
  'menu.nms.event.monitor.event': '事件新增编辑',
  'menu.nms.alarm.level': '级别设置',//
  'menu.ias.online.passenger': '旅客列表',
  'menu.ias.online.passenger.details': '上网旅客明细',
  'menu.nms.point.station': '小站设置',
  'menu.nms.device.warnings': '告警历史',
  'menu.nms.report.hardware': '硬件报告',
  'menu.nms.report.operate': '运营报告',
  'menu.nms.report.run': '运行报告',
  'menu.nms.dictionary.info': '设备字典详情',
  'menu.nms.dictionary.bit': '设备字典bit',
  'menu.nms.value.transfer': '设备字典值转换',
  'menu.ias.packages.details': '套餐详情',///app/ias/data-packages/query/:id
  'menu.setting.add.client': '新增鉴权',///app/setting/add-client
  'menu.setting.aircraft.type': '机型新增编辑',// /app/setting/aircraft-type/:model
  'menu.nms.maintain.setting': '编辑改装方案',//app/nms/device/maintain/setting/:model/:id
  'menu.nms.maintain.wmt': 'Bit Info配置',///app/nms/device/maintain/wmt/:id
  'menu.ias.order.detail': '流量订单详情',///app/ias/order/detail/:id
  'menu.ias.package.clone': '流量套餐克隆',///app/ias/data-package/:model
  'menu.rbac.page.form': '页面管理新增编辑',///app/rbac/page-form/:model
  'menu.aircraft.manage.form': '飞机管理新增编辑',///app/flights/aircraft-manage-form/:model
  'menu.passenger.virtual.form': '虚拟旅客新增编辑',//app/passenger/virtual/form/:model
  'menu.rbac.users.form': '账号新增',///app/rbac/users/:model
  'menu.ams.roles.form': '角色新增编辑',//app/ams/roles-manage/:model
  'menu.rbac.organization.form': '机构新增编辑',//app/rbac/organization/:model
  'menu.rbac.menu.form': '功能新增编辑',//app/rbac/menu/:model
  'menu.log.manage': '日志管理',
  'menu.log.oplog': '操作日志',
  'menu.ias.aaa': 'AAA话单',
  'menu.toolkit.analysis': '可视化分析',
  'menu.toolkit.source': '数据连接',
  'menu.toolkit.interface': '数据接口',
  'menu.ias.airport': '机场数据',
  'menu.toolkit.chart': '图表管理',
  'menu.toolkit.panel': '数据看板',
  'menu.nms.service.definition': '服务定义',
  'menu.nms.service.setting': '服务配置',
  'menu.nms.service.test': '服务检测',
  'menu.nms.alarm.service': '服务排查',
  'menu.nms.apply.config': '适用配置',
  'menu.nms.apply.config.form': '适用配置新增编辑',
}
