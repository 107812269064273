export default {
  'iasAAA.title': 'AAA List',

  'iasAAA.query': 'Search',
  'iasAAA.exportData': 'Export',
  'iasAAA.tailNoSearch': 'Tail No…',
  'iasAAA.flightNoSearch': 'Flight Number…',
  'iasAAA.passengerInfoSearch': 'Passenger Info...',
  'iasAAA.deptSearch': 'Dept...',
  'iasAAA.destSearch': 'Dest...',
  'iasAAA.macSearch': 'MAC…',
  'iasAAA.packageNameSearch': 'Package Name…',

  'iasAAA.flightInfo': 'Flight Info',
  'iasAAA.deviceInfo': 'Device Info',
  'iasAAA.packageInfo': 'Package Info',
  'iasAAA.netList': 'Net List',

  'iasAAA.dept': 'Dept',
  'iasAAA.dest': 'Dest',
  'iasAAA.tailNo': 'Tail No',
  'iasAAA.flightNo': 'Flight Number',
  'iasAAA.flightDate': 'Flight Date',
  'iasAAA.idCardNo': 'Id Card No',
  'iasAAA.seatNo': 'Seat No',
  'iasAAA.startTime': 'Start Time',
  'iasAAA.useTime': 'Use Time(s)',
  'iasAAA.up': 'Up(KB)',
  'iasAAA.down': 'Down(KB)',
  'iasAAA.sumSize': 'Sum Size(KB)',
  'iasAAA.operation': 'Operation',
  'iasAAA.details': 'Details',
  'iasAAA.refresh': 'Refresh',

  'iasAAA.actualTakeOffTime': 'Actual Take Off Time',
  'iasAAA.actualArriveTime': 'Actual Arrive Time',
  'iasAAA.arriveTime': 'Arrive Time',
  'iasAAA.takeOffTime': 'Take Off Time',
  'iasAAA.predictArriveTime': 'Predict Arrive Time',
  'iasAAA.predictTakeOffTime': 'Predict Take Off Time',
  'iasAAA.deptCityDestCity': 'Dept City Dest City',

  'iasAAA.packageCount': 'Package Count',
  'iasAAA.deviceCount': 'Device Count',
  'iasAAA.usedTimeAll': 'Used Time All',
  'iasAAA.usedValueAll': 'Used Value All',

  'iasAAA.order': 'Order',
  'iasAAA.mac': 'MAC',
  'iasAAA.internalIp': 'Internal Ip',
  'iasAAA.externalIp': 'External Ip',
  'iasAAA.usedTime': 'Used Time',
  'iasAAA.usedTimeM': 'Used Time(m)',
  'iasAAA.usedValue': 'Used Value(MB)',
  'iasAAA.info': 'Info',

  'iasAAA.xopOrderNo': 'Xop Order No',
  'iasAAA.packageName': 'Package Name',
  'iasAAA.aTimeS': 'Time(s)',
  'iasAAA.aValueM': 'Value(MB)',
  'iasAAA.usedTimeS': 'Used Time(s)',
  'iasAAA.usedValueM': 'Used Value(MB)',
  'iasAAA.aTime': 'Time',
  'iasAAA.aValue': 'Value',

  'iasAAA.operatorOrderNo': 'Operator Order No',
  'iasAAA.channelOrderNo': 'Channel Order No',
  'iasAAA.accNum': 'Acc Num',
  'iasAAA.priority': 'Priority',
  'iasAAA.allowMaxDown': 'Allow Max Down',
  'iasAAA.allowMaxUp': 'Allow Max Up',
  'iasAAA.aValueBytes': 'Value(bytes)',
  'iasAAA.consumptionMode': 'Consumption Mode',
  'iasAAA.from': 'Use limits(from)',
  'iasAAA.to': 'Use limits(to)',
  'iasAAA.lastReportTime': 'Last Report Time',
  'iasAAA.surplusValue': 'Surplus Value(bytes)',
  'iasAAA.surplusTime': 'Surplus Time(s)',
  'iasAAA.orderTime': 'Order Time',
  'iasAAA.token': 'Token',
  'iasAAA.applied': 'Applied',
  'iasAAA.exceed30days': 'Time span selection cannot exceed 30 days',

  'iasAAA.remainTime': 'Remain Time(m)',
  'iasAAA.usedValueBytes': 'Used Value(bytes)',
  'iasAAA.remainValue': 'Remain Value(bytes)',
  'iasAAA.expireTime': 'Expire Time',
  'iasAAA.recordTime': 'Record Time',

  'iasAAA.last1d': 'Last 1 day',
  'iasAAA.last2d': 'Last 2 days',
  'iasAAA.last3d': 'Last 3 days',
  'iasAAA.last5d': 'Last 5 days',
  'iasAAA.last7d': 'Last 7 days',
  'iasAAA.last30d': 'Last 30 days',
  'iasAAA.custom': 'Custom',
};