export default {
  'nmsPointStation.title': '小站设置',
  'nmsPointStation.name': '小站名称',
  'nmsPointStation.placeholder': '请输入...',
  'nmsPointStation.modmanIp': 'MODMAN IP地址',
  'nmsPointStation.ip': '飞机IP/机载服务器IP',
  'nmsPointStation.owner': '小站所属管理组编号',
  'nmsPointStation.code': '小站编码',
  'nmsPointStation.save': '保存更新',
  'nmsPointStation.vno': 'VNO编码',
};
