export default {
  'nmsJoin.title': 'Join Detail',

  'nmsJoin.searchMac': 'MAC...',
  'nmsJoin.searchTailNo': 'Tail No...',
  'nmsJoin.searchFlightNo': 'Flight number...',
  'nmsJoin.searchGnsIp': 'GNS IP...',
  'nmsJoin.deptSearch': 'Dept...',
  'nmsJoin.destSearch': 'Dest...',
  'nmsJoin.onlyShowInternet': 'Only show internet',

  'nmsJoin.search': 'Search',
  'nmsJoin.exportData': 'Export',

  'nmsJoin.tailNo': 'Tail No',
  'nmsJoin.flightNo': 'Flight Number',
  'nmsJoin.flightDate': 'Flight Date',
  'nmsJoin.deptCity': 'Dept City',
  'nmsJoin.destCity': 'Dest City',
  'nmsJoin.mac': 'MAC',
  'nmsJoin.deviceName': 'Device Name',
  'nmsJoin.gnsIp': 'GNS IP',
  'nmsJoin.externalIp': 'External IP',
  'nmsJoin.timestamp': 'Timestamp',

  'nmsJoin.last5m': 'Last 5 minutes',
  'nmsJoin.last10m': 'Last 10 minutes',
  'nmsJoin.last30m': 'Last 30 minutes',
  'nmsJoin.last1h': 'Last hour',
  'nmsJoin.last24h': 'Last 24 hours',
  'nmsJoin.custom': 'Custom',
}