export default {
  'settingFlight.title': '飞行历史',
  'settingFlight.pageDescription': '航班执行历史记录',

  'settingFlight.tailNumber': '机尾号',
  'settingFlight.flightNumber': '航班号',
  'settingFlight.timeInterval': '时间区间',
  'settingFlight.query': '查询',
  'settingFlight.tailNumberSearch': '机尾号搜索...',
  'settingFlight.flightNumberSearch': '航班号搜索...',
  'settingFlight.deptSearch': '出发城市、机场三字码搜索...',
  'settingFlight.destSearch': '到达城市、机场三字码搜索...',
  'settingFlight.exportData': '导出数据',

  'settingFlight.tailNo': '飞机机号',
  'settingFlight.flightIATA': '航班编号',
  'settingFlight.flightDate': '航班日期',
  'settingFlight.flightStatus': '航班状态',
  'settingFlight.deptCode': '起飞机场三字码',
  'settingFlight.deptCity': '出发城市',
  'settingFlight.destCode': '降落机场三字码',
  'settingFlight.destCity': '到达城市',
  'settingFlight.actualTakeOffTime': '实际起飞时间',
  'settingFlight.actualArriveTime': '实际到达时间',
  'settingFlight.takeOffTime': '计划起飞时间',
  'settingFlight.predictTakeOffTime': '预计起飞时间',
  'settingFlight.arriveTime': '计划到达时间',
  'settingFlight.predictArriveTime': '预计到达时间',
  'settingFlight.passengerNum': '旅客人数',
  'settingFlight.operate': '操作',
  'settingFlight.correction': '修正',
  'settingFlight.correctionTitle': '修正航班信息',
  'settingFlight.save': '保存',

  'settingFlight.time': '时间',
  'settingFlight.model': '机型',
  'settingFlight.departureCity': '起飞机场',
  'settingFlight.toCity': '降落机场',
  'settingFlight.takeOffArrive': '起飞 -- 到达',
  'settingFlight.actualLandingTime': '计划降落时间',
  'settingFlight.actualTakeoffTime': '计划起飞时间',
  'settingFlight.flightTime': '计划飞行时长',
  'settingFlight.edit': '操作',
  'settingFlight.list': '清单',
  'settingFlight.fees': '费用',

  'settingFlight.all': '全部航班',
  'settingFlight.normal': '正常',
  'settingFlight.delay': '延误',
  'settingFlight.alternate': '备降',
  'settingFlight.forcedLanding': '迫降',
  'settingFlight.return': '返航',
  'settingFlight.cancel': '取消',
  'settingFlight.other': '其他',

  'settingFlight.last1d': '最近1天',
  'settingFlight.last2d': '最近2天',
  'settingFlight.last3d': '最近3天',
  'settingFlight.last5d': '最近5天',
  'settingFlight.last7d': '最近7天',
  'settingFlight.last30d': '最近30天',
  'settingFlight.custom': '自定义',

};
