import React from 'react';

import {
  Row,
  Col,
  Button,
  Card
} from 'antd';
import styles from "./style.less"

class ErrorMessage extends React.Component {

  render() {
    let {
      type,
      errorMessage,
      action,
    } = this.props
    return (
      <div className="content">
        <Card bordered={false} className={styles.errorFixedContianner}>
          <Row>
            <Col span={8} offset={4}>
              <div>
                <img src="/images/error.png"/>
              </div>
            </Col>
            <Col span={8} offset={4}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{fontSize: '48px'}}>
                  {type||"Error"}
                </span>
                <div className="mt-2"><span style={{fontSize: '16px'}}>{errorMessage}</span></div>
              </div>
              <Button type="primary" onClick={()=>action()}>返回</Button>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

export default ErrorMessage