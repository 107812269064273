export default {
  'nmsDeviceMonitor.title': 'Monitoring',
  'nmsDeviceMonitor.pageDescription': 'Device status information',
  'nmsDeviceMonitor.realTimeData': 'Real time data:',
  'nmsDeviceMonitor.aircraftHealthAlarm': 'Monitoring',
  'nmsDeviceMonitor.tailNumberEg': 'Tail No eg：B1241',
  'nmsDeviceMonitor.airborneDeviceOperationStatus': 'Airborne Device Status',
  'nmsDeviceMonitor.unknown': 'Unknown',
  'nmsDeviceMonitor.lostConnection': 'Unknown',
  'nmsDeviceMonitor.exception': 'Exception',
  'nmsDeviceMonitor.fullLoad': 'FullLoad',
  'nmsDeviceMonitor.normal': 'Normal',
  'nmsDeviceMonitor.notMatch': 'Not match',
  'nmsDeviceMonitor.noData': 'No data',
  'nmsDeviceMonitor.historyFaultDetectionRecord': 'Monitoring',
  'nmsDeviceMonitor.all': 'ALL',
  'nmsDeviceMonitor.tailNumber': 'Tail No',
  'nmsDeviceMonitor.faultWarningTime': 'Warning Time',
  'nmsDeviceMonitor.deviceFaultName': 'Device / Warning',
  'nmsDeviceMonitor.numberAlarms': 'Number of Warnings',
  'nmsDeviceMonitor.recommendedSolution': 'Recommended Operation',
  'nmsDeviceMonitor.recoveryTime': 'Recovery Time',
  'nmsDeviceMonitor.loadMore': 'More',
  'nmsDeviceMonitor.deviceName': 'Device Name',
  'nmsDeviceMonitor.status': 'Status',
  'nmsDeviceMonitor.service': 'Service',
  'nmsDeviceMonitor.device': 'Device',

  'nmsDeviceMonitor.tailNo': 'Tail No',
  'nmsDeviceMonitor.eventLevelName': 'Event Name',
  'nmsDeviceMonitor.eventPriority': 'Event Level ',
  'nmsDeviceMonitor.eventTime': 'Event Time',

  'nmsDeviceMonitor.faultWarning': 'Fault warning',
  'nmsDeviceMonitor.realTimeWarningAircraft': 'Real time warning of aircraft equipment failure',
  'nmsDeviceMonitor.faultCheck': 'Fault check',
  'nmsDeviceMonitor.equipmentFailureChecklist': 'Equipment failure checklist',
  'nmsDeviceMonitor.troubleshooting': 'Troubleshooting',
  'nmsDeviceMonitor.lookUpHistorySimilarTroubleshooting': 'Look up history similar to troubleshooting',
  'nmsDeviceMonitor.putIntoOperation': 'Put into operation',
  'nmsDeviceMonitor.realTimeRecordEquipmentOperation': 'Real time record of equipment operation data',
  'nmsDeviceMonitor.check': 'Check',
  'nmsDeviceMonitor.devicesWarnings': 'Device Warning'
};
