import {
  getAxios,
} from "shared/fetch"
import {
  BASE_API_URL,
} from "shared/env"
import * as menusUtil from 'menus'
import {obj2Str} from "shared/utils"

export const CHANGE_PASSWORD_URL = `${BASE_API_URL}/user/admin/changePassword`
export const MENU_CONFIG_URL = `${BASE_API_URL}/user/resource`;

export const USER_MENU_CONFIG_GET_URL = `${BASE_API_URL}/user/manage/resource/all`;//获取用户权限列表(全部,已有标记为选中)
export const USER_MENU_CONFIG_SET_URL = `${BASE_API_URL}/user/manage/resource`;//设置用户资源权限
export const USER_URL = `${BASE_API_URL}/user/manage`;
export const USER_ADD_URL = `${BASE_API_URL}/user/manage/add`;
export const USER_AIR_LIST_URL = `${BASE_API_URL}/user/manage/airlines`;//[所属机构]选择列表
export const ROLE_URL = `${BASE_API_URL}/user/role`;

export const USER_INFO = `${BASE_API_URL}/user/manage/profiles`;

export const USER_NOTICE_INFO = `${BASE_API_URL}/user/role/noticeTypes/all`;

export const ROLE_MENU_CONFIG_GET_URL = `${BASE_API_URL}/user/role/resource/all`;
export const ROLE_MENU_CONFIG_SET_URL = `${BASE_API_URL}/user/role`;

export const organizationUrl = `${BASE_API_URL}/user/organization`;
export const manageUrl = `${BASE_API_URL}/user/manage/list`;

export function getUserAllList() {
  return getAxios(`${manageUrl}`, "GET");
}

export function getOrganizationAllList(data) {
  let params = obj2Str(data);
  return getAxios(`${organizationUrl}/list?${params}`, "GET");
}

export function getOrganizationList(data) {
  let params = obj2Str(data);
  return getAxios(`${organizationUrl}/page?${params}`, "GET");
}

export function getOrganization(id) {
  return getAxios(`${organizationUrl}/${id}`, "GET");
}

export function editOrganization(id, data) {
  return getAxios(`${organizationUrl}/${id}`, "PUT", data);
}

export function addOrganization(data) {
  return getAxios(`${organizationUrl}`, "POST", data);
}

export function deleteOrganization(id) {
  return getAxios(`${organizationUrl}/${id}`, "DELETE");
}

export function changePassword(data) {
  return getAxios(`${CHANGE_PASSWORD_URL}`, "POST", data);
}

export function getMenuPageList() {
  let data = [menusUtil.TYPE_PAGE, menusUtil.TYPE_ACTION];
  return getAxios(`${MENU_CONFIG_URL}?types=${data.join(',')}`, "GET", data);
}

export function getMenuAllList() {
  return getAxios(`${MENU_CONFIG_URL}`, "GET",);
}

export function getMenuSelectList() {
  let data = [menusUtil.TYPE_ROOT, menusUtil.TYPE_RESOURCE_GROUP];
  return getAxios(`${MENU_CONFIG_URL}`, "GET", data);
}

export function getPermissionListByPath(path) {
  return getAxios(`${MENU_CONFIG_URL}/findResourceByPath?path=${path}`, "GET");
}

export function getPermissionList(data) {
  let params = obj2Str(data);
  return getAxios(`${MENU_CONFIG_URL}/permissions?${params}`, "GET", data);
}

export function getAirlines() {
  return getAxios(`${USER_AIR_LIST_URL}`, "GET");
}

export function editMenuConfig(id, data) {
  return getAxios(`${MENU_CONFIG_URL}/${id}`, "PUT", data);
}

export function addMenuConfig(data) {
  return getAxios(`${MENU_CONFIG_URL}`, "POST", data);
}

export function deleteMenuConfig(id) {
  return getAxios(`${MENU_CONFIG_URL}/${id}`, "DELETE");
}

export function getUserInfobyId(id) {
  return getAxios(`${USER_URL}/${id}`, "GET");
}

export function getUserList(pageSize, pageNum, data) {
  let params = obj2Str(data);
  return getAxios(`${USER_URL}/${pageSize}/${pageNum}?${params}`, "GET");
}

export function editUser(id, data) {
  return getAxios(`${USER_URL}/${id}`, "PUT", data);
}

export function addUser(data) {
  return getAxios(`${USER_ADD_URL}`, "POST", data);
}

export function deleteUser(id) {
  return getAxios(`${USER_URL}/${id}`, "DELETE");
}

export function getPermissionsList(userId) {
  return getAxios(`${USER_MENU_CONFIG_GET_URL}/${userId}`, "GET");
}

export function setPermissionsList(userId, data) {
  let str = obj2Str(data);
  return getAxios(`${USER_MENU_CONFIG_SET_URL}/${userId}`, "POST", str);
}

export function getUserInfo() {
  return getAxios(`${USER_INFO}`, "GET");
}

export function editUserInfo(data) {
  return getAxios(`${USER_INFO}`, "PUT", data);
}

export function getRoleList(pageSize, pageNum) {
  if (pageSize && pageNum) {
    return getAxios(`${ROLE_URL}/${pageSize}/${pageNum}`, "GET");
  } else {
    return getAxios(`${ROLE_URL}`, "GET");
  }
}

export function deleteRole(id) {
  return getAxios(`${ROLE_URL}/${id}`, "DELETE");
}

export function addRole(data) {
  return getAxios(`${ROLE_URL}`, "POST", data);
}

export function getRoleNoticeData(id) {
  return getAxios(`${USER_NOTICE_INFO}/${id}`, "GET");
}

export function setRoleNoticeData(id, data) {
  return getAxios(`${ROLE_URL}/${id}/notice`, "PUT", data);
}

export function getRoleMenuList(roleId) {
  return getAxios(`${ROLE_MENU_CONFIG_GET_URL}/${roleId}`, "GET");
}

export function setRoleMenuList(roleId, data) {
  return getAxios(`${ROLE_MENU_CONFIG_SET_URL}/${roleId}/menuResource`, "PUT", data);
}

export function setRolePageList(roleId, data) {
  return getAxios(`${ROLE_MENU_CONFIG_SET_URL}/${roleId}/pageResource`, "PUT", data);
}