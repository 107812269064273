export default {
  'iasFlightsList.title': 'Flights List',
  'iasFlightsList.tailNo': 'Tail No',
  'iasFlightsList.all': 'All',
  'iasFlightsList.airborne': 'Aerial',
  'iasFlightsList.landing': 'Ground',
  'iasFlightsList.statusTitle': 'Number of Online Passengers',
  'iasFlightsList.statusTitle2': 'Number of Intranet Device Connections',
  'iasFlightsList.statusTitle3': 'Number of Internet Device Connections',
  'iasFlightsList.statusTitle4': 'Number of Flight Passengers',
  'iasFlightsList.online': ' ',
  'iasFlightsList.devices': ' ',
  'iasFlightsList.depAirport': 'Departure Airport',
  'iasFlightsList.desAirport': 'Destination Airport',
  'iasFlightsList.flightNo': 'Flight Number',
  'iasFlightsList.notFlightNo': 'Flight number not obtained',

  'iasFlightsList.NONE': '',
  'iasFlightsList.GROUND': 'Ground AAA',
  'iasFlightsList.ON_BOARD': 'Onboard AAA'
}