import React, {
  PureComponent
} from "react"
import "./index.less"


class AppContainer extends PureComponent {

  render() {
    return (
      <div className="content-wrapper">
        { this.props.children }
      </div>
    )
  }
}

export default AppContainer