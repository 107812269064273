import React, {PureComponent} from "react";

import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {rootPath} from "shared/const";
import "./index.less";
import {AppContextEnhancer} from "shared/libs/app-context";

import {
  BellOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UpOutlined
} from "@ant-design/icons";

import "@fortawesome/fontawesome-free/css/all.min.css";
import {connect} from "react-redux";
import {setLocale, setTheme} from "store/config/actions";

import {logI} from "shared/utils";
import {chanageThemes, getThemes} from "styles/themes";

@AppContextEnhancer
@withRouter
@connect(
  ({authorization, config}) => ({
    userInfo: authorization.userData,
    locale: config.locale,
    theme: config.theme,
  }),
  {
    setLocale,
    setTheme,
  }
)
class AppHeader extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {context, theme} = this.props;
    const {menuCollapsed,} = context;
    let iconUrl;
    if (menuCollapsed) {
      iconUrl = require("ic_logo_small.png");
    } else {
      iconUrl = theme === 'dark' ? require("logo_white.png") : require("logo_black.png");
    }
    let className = theme !== 'dark' ? 'menu-border-bottom' : 'xop-border-bottom';
    return (
      <div className={"appHeader " + className}>
        <div className={"header-logo"}>
          <img src={iconUrl} alt=""/>
        </div>
      </div>
    );
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }
}

export default AppHeader;
