export default {
  'passengerNetList.title': '上网清单',

  'passengerNetList.searchTailNo': '机尾号搜索...',
  'passengerNetList.searchFlightNo': '航班号搜索...',
  'passengerNetList.searchKey': '旅客证件或座位号搜索...',
  'passengerNetList.deptSearch': '出发城市、机场三字码搜索...',
  'passengerNetList.destSearch': '到达城市、机场三字码搜索...',

  'passengerNetList.search': '查询',
  'passengerNetList.exportData': '导出数据',

  'passengerNetList.tailNo': '机尾号',
  'passengerNetList.flightNo': '航班号',
  'passengerNetList.flightDate': '航班日期',
  'passengerNetList.deptCity': '出发',
  'passengerNetList.destCity': '到达',
  'passengerNetList.duration': '飞行时长',
  'passengerNetList.idCard': '证件号',
  'passengerNetList.seatNo': '座位号',
  'passengerNetList.beginTime': '上网开始时间',
  'passengerNetList.useTime': '上网时长（秒）',
  'passengerNetList.upFlow': '上行流量（KB）',
  'passengerNetList.downFlow': '下行流量（KB）',
  'passengerNetList.sumFlow': '累计流量（KB）',

  'passengerNetList.last1d': '最近1天',
  'passengerNetList.last2d': '最近2天',
  'passengerNetList.last3d': '最近3天',
  'passengerNetList.last5d': '最近5天',
  'passengerNetList.last7d': '最近7天',
  'passengerNetList.last30d': '最近30天',
  'passengerNetList.custom': '自定义',
}