export default {
  'dataTraceBackDetails.title': '追溯',

  'dataTraceBackDetails.refresh': '刷新',
  'dataTraceBackDetails.callXOPInterface': '调用XOP接口',
  'dataTraceBackDetails.callThirdInterface': '调用第三方接口',

  'dataTraceBackDetails.operationSuccessful': '操作成功',
  'dataTraceBackDetails.operationFailed': '操作失败',
  'dataTraceBackDetails.requestInput': '请求入参',
  'dataTraceBackDetails.responseResult': '响应结果',
};
