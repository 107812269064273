export default {
  'nmsRecord.title': '设备数据',
  'nmsRecord.pageDescription': '机载设备运行日志的查看与下载',

  'nmsRecord.onboardDevice': '机载设备：',
  'nmsRecord.recordItem': '记录项目',
  'nmsRecord.all': '全选',
  'nmsRecord.stow': '收起',
  'nmsRecord.more': '更多',
  'nmsRecord.modifiedAircraft': '改装飞机：',
  'nmsRecord.modifiedTime': '装机时间：',
  'nmsRecord.enterTailNumber': '请输入机尾号查找...',
  'nmsRecord.runDate': '运行日期：',
  'nmsRecord.today': '今天',
  'nmsRecord.thisWeek': '最近1周',
  'nmsRecord.thisMonth': '最近1月',
  'nmsRecord.query': '查询',
  'nmsRecord.runLog': '运行日志',
  'nmsRecord.downloadLog': '导出日志',
  'nmsRecord.recordTime': '记录时间',
  'nmsRecord.recordValue': '记录值',
  'nmsRecord.recordIndicatorSelection': '记录指标选择',
  'nmsRecord.customColumns': '自定义列',
  'nmsRecord.indicatorIsChecked': '只有勾选记录指标，才能查询允许日志',
  'nmsRecord.selectAirborne': '请选择机载设备',
  'nmsRecord.selectStartTime': '请选择日志开始时间',
  'nmsRecord.selectEndTime ': '请选择日志结束时间',
  'nmsRecord.selectCustom': '请选择自定义列',

  'nmsRecord.parameterSwitchedTo': '以下参数状态已切换至',

  'nmsRecord.record': '实时信息',
  'nmsRecord.backLatest': '回到最新状态',
  'nmsRecord.realTimeData': '实时数据',
  'nmsRecord.seconds': '秒',
  'nmsRecord.minutes': '分钟',
  'nmsRecord.hours': '小时',
  'nmsRecord.timeInterval': '时间间隔',
  'nmsRecord.altitude': '海拔',
  'nmsRecord.cellular': '4g状态',
  'nmsRecord.satellite': '卫星状态',
  'nmsRecord.flightNo': '航班号',
  'nmsRecord.link': '网络可用',
  'nmsRecord.unlink': '网络不可用',
  'nmsRecord.unknow': '无网络',

  'nmsRecord.second': '近30秒',
  'nmsRecord.second2': '近60秒',
  'nmsRecord.hour': '近1小时',

  'nmsRecord.keywords': '检查项目',
  'nmsRecord.keywordsPlaceHolder': '关键字检索...',
};
