export default {
  'nmsDeviceDataReport.title': '数据报告',
  'nmsDeviceDataReport.pageDescription': '',
  'nmsDeviceDataReport.historyListTitle': '历史报告',
  'nmsDeviceDataReport.step1Select': '请选择机尾号',
  'nmsDeviceDataReport.tailNumber': '机尾号',
  'nmsDeviceDataReport.reportType': '报告类型',
  'nmsDeviceDataReport.step1SelectItem1': '运行报告',
  'nmsDeviceDataReport.step1SelectItem2': '预警报告（暂不可用）',
  'nmsDeviceDataReport.step1Button': '下一步',
  'nmsDeviceDataReport.time': '起止时间',
  'nmsDeviceDataReport.today': '今天',
  'nmsDeviceDataReport.thisMonth': '本月',
  'nmsDeviceDataReport.thisYear': '今年',
  'nmsDeviceDataReport.devicesTitle': '机载设备',
  'nmsDeviceDataReport.dataTypeTitle': '数据类型',
  'nmsDeviceDataReport.step2Button1': '上一步',
  'nmsDeviceDataReport.step2Button2': '提交',
  'nmsDeviceDataReport.submitSuccess': '提交成功',
  'nmsDeviceDataReport.step3Button1': '再来一份',
  'nmsDeviceDataReport.step3Button2': '下载',
  'nmsDeviceDataReport.step3Button3': '打印',
  'nmsDeviceDataReport.step3Button4': '取消',
  'nmsDeviceDataReport.step3tips': '点击报告名称预览',
  'nmsDeviceDataReport.reportTime': '申请时间',
  'nmsDeviceDataReport.reportName': '报告名称',
  'nmsDeviceDataReport.descriptionTitle': '说明',
  'nmsDeviceDataReport.description1': '1.运行报告，即单位时间段内机载硬件设备的总体运行情况，可分为运行日报、周报、月报及年报。',
  'nmsDeviceDataReport.description2': '2.预警报告，即单位时间段内飞机改装方案的数据告警报告。',
  'nmsDeviceDataReport.description3': '3.以上数据报告类型、格式、数据项均根据具体业务场景需要生成，具备报告预览、打印及下载功能。',
  'nmsDeviceDataReport.step1': '选择报告类型',
  'nmsDeviceDataReport.step2': '确认数据项',
  'nmsDeviceDataReport.step3': '报告预览/下载/打印',
  'nmsDeviceDataReport.to': "至",
  'nmsDeviceDataReport.step2radio1': "设备构型信息",
  'nmsDeviceDataReport.step2radio2': "飞行高度",
  'nmsDeviceDataReport.step2radio3': "在线旅客数",
  'nmsDeviceDataReport.step2radio4': "设备运行状态",
};