export default {
  'passengerCallRecord.title': '接口分析',

  'passengerCallRecord.interfaceCall': '接口调用统计',
  'passengerCallRecord.responseNameAll': '响应名称统计',
  'passengerCallRecord.detailList': '明细列表',
  'passengerCallRecord.statusChart': '状态统计图表',
  'passengerCallRecord.successFail': '成功失败占比',

  'passengerCallRecord.flightNo': '航班号',
  'passengerCallRecord.accNum': '上网账号',
  'passengerCallRecord.idCard': '证件号',
  'passengerCallRecord.seatNo': '座位号',
  'passengerCallRecord.apiName': '接口功能名称',
  'passengerCallRecord.status': '功能状态',
  'passengerCallRecord.businessId': '业务号',
  'passengerCallRecord.apiService': 'API供应商',
  'passengerCallRecord.requestSource': '请求入参',
  'passengerCallRecord.createTime': '调用时间',
  'passengerCallRecord.responseSource': '响应编码',

  'passengerCallRecord.total': '总调用次数',
  'passengerCallRecord.errorCount': '业务出错次数',
  'passengerCallRecord.errorPercent': '业务出错率',

  'passengerCallRecord.responseName': '响应名称',
  'passengerCallRecord.count': '出现次数',
  'passengerCallRecord.countPercent': '出现率',

  'passengerCallRecord.responseNameExp': '调用接口时，接口的响应结果名称',

  'passengerCallRecord.last3h': '最近3小时',
  'passengerCallRecord.last1d': '最近1天',
  'passengerCallRecord.last2d': '最近2天',
  'passengerCallRecord.last3d': '最近3天',
  'passengerCallRecord.last5d': '最近5天',
  'passengerCallRecord.last7d': '最近7天',
  'passengerCallRecord.last30d': '最近30天',
  'passengerCallRecord.custom': '自定义',

  'passengerCallRecord.searchKey': '入参和响应结果关键字搜索...',
  'passengerCallRecord.searchApiName': '接口名搜索...',
  'passengerCallRecord.allServiceProviders': '全部服务商',

  'passengerCallRecord.businessIdExp': '一次业务请求ID，当请求失败系统自动重试时会产生多条调用记录但都属于一个业务号',
};