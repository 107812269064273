export default {
  'settingsClient.title': '鉴权管理',
  'settingsClient.pageDescription': '机载网络设备或航司的系统授权码管理',

  'settingsClient.all': '全部',
  'settingsClient.serviceProvider': '服务商',
  'settingsClient.device': '设备',
  'settingsClient.addAuthorization': '添加鉴权',

  'settingsClient.tailNumber': '机尾号',
  'settingsClient.type': '类型',
  'settingsClient.lastUsedTime': '最近使用时间',
  'settingsClient.expirationTime': '到期时间',
  'settingsClient.usageStatus': '使用状态',
  'settingsClient.noteDescription': '备注说明',
  'settingsClient.enable': '启用',
  'settingsClient.disable': '停用',
  'settingsClient.warning': '警告',
  'settingsClient.authorizationCodeInUse': '该鉴权码正在使用，不允许删除！',
  'settingsClient.AreYouSureYouWantToDeleteIt': '该项可能在其他地方被引用，删除后将不能正常使用，请再次确认是否要删除？',
  'settingsClient.SuccessfullyEnabled': '启用成功',
  'settingsClient.SuccessfullyDisabled': '停用成功',

  'settingsClient.authorizationObject': '鉴权对象',
  'settingsClient.clickToGet': '点击获取',
  'settingsClient.save': '保存',
  'settingsClient.deviceAuthorization': '设备鉴权',
  'settingsClient.serviceProviderAuthorization': '服务商鉴权',
  'settingsClient.selectTailNumber': '请选择机尾号',
  'settingsClient.autoGenerate': '自动生成',
  'settingsClient.newAuthorization': '新增鉴权',
  'settingsClient.addAirborneNetworkDevice': '新增机载网络设备或服务商鉴权',
};