export default {
  "nmsDeviceMaintain.title": "Maintenance",
  "nmsDeviceMaintain.pageDescription":
    "IFEC hardware installation and daily maintenance, all configuration related operations are performed by configuration upgrade",

  "nmsDeviceMaintain.search": "Search",
  "nmsDeviceMaintain.newInstallation": "New Installation",
  "nmsDeviceMaintain.tailNumberEg": "Tail No eg：B573",
  "nmsDeviceMaintain.tailNumber": "Tail No",
  "nmsDeviceMaintain.modificationScheme": "Modification",
  "nmsDeviceMaintain.networkType": "Network",
  "nmsDeviceMaintain.installedTime": "Installation Date",
  "nmsDeviceMaintain.WMTcheck": "WMT CheckList Config",
  "nmsDeviceMaintain.edit": "Operation",

  "nmsDeviceMaintain.lan": "LAN",
  "nmsDeviceMaintain.satellite": "Satellite",
  "nmsDeviceMaintain.noNetwork": "NO Network"
};
