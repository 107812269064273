export const DEV = 'dev'
export const PROD = 'prod'
export const env = process.env.NODE_ENV === 'production' ? PROD : DEV

export const rootPath = 'app'
export const keySeparator = '$'

export const apiRoot = env === DEV ? 'http://localhost:8090/api' : '/api'

export const primaryColor = '#0073e1'

export const GROUP = {
  NET_ACCESS_TERMINAL: 'NET_ACCESS_TERMINAL',//网络接入终端变化
  CPU_USAGE: 'CPU_USAGE',//每台设备的CPU使用率变化曲线
  MEMORY_USAGE: 'MEMORY_USAGE',//每台设备的内存使用率变化曲线
  DISK_USAGE_INFO: 'DISK_USAGE_INFO',//设备的硬盘使用情况
}


