export default {
  'iasNetRealTimeCost.title': '实时费用',

  'iasNetRealTimeCost.search': '关键字搜索：',
  'iasNetRealTimeCost.pleaseInput': '请输入证件号或座位号',

  'iasNetRealTimeCost.idCard': '证件号',
  'iasNetRealTimeCost.seatNo': '座位号',
  'iasNetRealTimeCost.accNum': '上网账号',
  'iasNetRealTimeCost.fee': '费用（分）',
}