import {getString} from "shared/langs";

export const TYPE_RESOURCE_GROUP = 'RESOURCE_GROUP';//目录
export const TYPE_RESOURCE = 'RESOURCE';//
export const TYPE_MENU = 'MENU';//菜单
export const TYPE_ROOT = 'ROOT';//内置根目录
export const TYPE_ACTION = 'ACTION';//按钮
export const TYPE_PAGE = 'PAGE';//页面

function createTreeItem(netItem) {
  return {key: netItem.id, ...netItem}
}

function findTreeRootItem(list) {
  for (let item of list) {
    if (!item.parentId) {
      return createTreeItem(item);
    }
  }
}

function createTreeSelectItem(netItem) {
  return {
    key: netItem.id,
    value: netItem.id,
    title: netItem.name,
    resourceIndex: netItem.resourceIndex
  }
}

export function findTreeSelectRootItem(list) {
  for (let item of list) {
    if (!item.parentId) {
      return createTreeSelectItem(item);
    }
  }
}

function createMenuItem(netItem) {
  return {
    label: netItem.name,
    key: netItem.id,
    icon: netItem.icon,
    ...netItem
  }
}

export function data2Tree(data) {
  let root = findTreeRootItem(data);
  const group = {};
  for (let item of data) {
    if (item.parentId !== null && item.parentId !== undefined) {
      if (!group[item.parentId]) {
        group[item.parentId] = [];
      }
      group[item.parentId].push(createTreeItem(item));
    }
  }
  const queue = [];
  queue.push(root);
  while (queue.length) {
    const node = queue.shift();
    node.children = group[node.id] && group[node.id].length ? group[node.id] : null;
    if (node.children) {
      node.children.sort(listSort);
      queue.push(...node.children);
    }
  }
  queue.sort(listSort);
  return [root];
}

export function data2TreePage(data) {
  if (!data) {
    return [];
  }
  let menuList = {}
  const group = {}
  for (let item of data) {
    if (item.type === TYPE_PAGE) {
      menuList[item.path] = {key: item.id, value: item.id, title: item.name, ...item}
    }
    if (item.parentId !== null && item.parentId !== undefined
      && item.type === TYPE_ACTION) {
      if (!group[item.parentId]) {
        group[item.parentId] = [];
      }
      group[item.parentId].push({key: item.id, value: item.id, title: item.name, ...item});
    }
  }
  let list = []
  for (let key in menuList) {
    let item = menuList[key]
    item.children = group[item.id] && group[item.id].length ? group[item.id] : null;
    if (item.children) {
      item.children.sort(listSort);
    }
    list.push(item)
  }
  return list
}

export function data2TreePageSelect(data, selected) {
  if (!data) {
    return [];
  }
  let menuList = []
  const group = {}
  for (let item of data) {
    if (item.type === TYPE_PAGE) {
      menuList.push({key: item.id, value: item.id, title: item.name, ...item})
    }
    if (selected && item.checked && item.type === TYPE_ACTION) {
      selected.push(item.id)
    }
    if (item.parentId !== null && item.parentId !== undefined
      && item.type === TYPE_ACTION) {
      if (!group[item.parentId]) {
        group[item.parentId] = [];
      }
      group[item.parentId].push({key: item.id, value: item.id, title: item.name, ...item});
    }
  }
  for (let item of menuList) {
    item.children = group[item.id] && group[item.id].length ? group[item.id] : null;
    if (item.children) {
      item.children.sort(listSort);
    }
  }
  return menuList
}

export function data2TreeSelect(isAll, data, selected) {
  if (!data) {
    return [];
  }
  let root = findTreeSelectRootItem(data);
  const group = {};
  for (let item of data) {
    if (selected && item.checked && item.type === TYPE_MENU) {
      selected.push(item.id)
    }
    if (item.parentId !== null && item.parentId !== undefined
      && (isAll || (item.type !== TYPE_ACTION && item.type !== TYPE_MENU))) {
      if (!group[item.parentId]) {
        group[item.parentId] = [];
      }
      group[item.parentId].push(createTreeSelectItem(item));
    }
  }
  const queue = [];
  queue.push(root);
  while (queue.length) {
    const node = queue.shift();
    node.children = group[node.key] && group[node.key].length ? group[node.key] : null;
    if (node.children) {
      node.children.sort(listSort);
      queue.push(...node.children);
    }
  }
  queue.sort(listSort);
  return [root];
}

function listSort(a, b) {
  return a.resourceIndex - b.resourceIndex;
}

function getMenuType(list, id) {
  if (!id) {
    return null
  }
  for (let item of list) {
    if (id == item.id) {
      return item.type
    }
  }
  return null
}

export function data2Menus(data) {
  if (!data) {
    return [];
  }
  let root = {label: 'xop', key: 1, type: TYPE_ROOT};
  const group = {};
  for (let item of data) {
    let parentType = getMenuType(data, item.parentId)
    if (item.parentId !== null && item.parentId !== undefined
      && (parentType === TYPE_ROOT || parentType === TYPE_RESOURCE_GROUP)) {
      if (!group[item.parentId]) {
        group[item.parentId] = [];
      }
      group[item.parentId].push(createMenuItem(item));
    }
  }
  const queue = [];
  queue.push(root);
  while (queue.length) {
    const node = queue.shift();
    node.children = group[node.key] && group[node.key].length ? group[node.key] : null;
    if (node.children) {
      node.children.sort(listSort);
      queue.push(...node.children);
    }
  }
  queue.sort(listSort);
  return root.children;
}
