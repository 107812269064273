import update from "immutability-helper"
import {
  SET_AUTH_INFO,
  SET_USER_INFO,
  SET_MENUS
} from "store/authorization/actions"
let authinfo = sessionStorage.getItem('authinfo') ? JSON.parse(sessionStorage.getItem('authinfo')) : {};
let userinfo = sessionStorage.getItem('userinfo') ? JSON.parse(sessionStorage.getItem('userinfo')) : {};
let menus = sessionStorage.getItem('menus') ? JSON.parse(sessionStorage.getItem('menus')) : [];

let keys = new Set();
function menusToPermissions(menuTree) {
  if (!menuTree || menuTree.length === 0) {
    return [];
  }
  for (let item of menuTree) {
    if (item.path) {
      keys.add(item.path);
    }
    if (item.children && item.children.length > 0) {
      menusToPermissions(item.children);
    }
  }
  return [...keys];
}

const initialState = {
  auth: authinfo,
  userData: userinfo,
  menus,
  permissions: menus ? menusToPermissions(menus) : []
};

const handlers = {
  [SET_AUTH_INFO](state, {
                    auth
                  }) {
    return update(state, {
      auth: {
        $set: auth
      },
    })
  },
  [SET_USER_INFO](state, {
                    userData
                  }) {
    return update(state, {
      userData: {
        $set: userData
      },
    })
  },
  [SET_MENUS](state, {
                menus
              }) {
    let perList = menusToPermissions(menus);
    let tempState = update(state, {
      permissions: {
        $set: perList
      }
    });
    return update(tempState, {
      menus: {
        $set: menus
      },
    })
  }
}

export default function app(state = initialState, action) {
  const type = action.type
  if (!handlers[type]) return state
  return handlers[type](state, action)
}
