export default {
  'iasPassengerOnlineList.title': 'Passenger',

  'iasPassengerOnlineList.enterID': 'Card Id / Seat No. to search',

  'iasPassengerOnlineList.cardId': 'Card Id',
  'iasPassengerOnlineList.seatNumber': 'Seat No.',
  'iasPassengerOnlineList.numberDevices': 'Number of devices',
  'iasPassengerOnlineList.LAN': 'LAN',
  'iasPassengerOnlineList.satellite': 'Satellite',
  'iasPassengerOnlineList.edit': 'Operation',
  'iasPassengerOnlineList.details': 'Details',

  'iasPassengerOnlineList.all': 'All',
  'iasPassengerOnlineList.userStatus': 'Status',
  'iasPassengerOnlineList.refresh': 'Refresh',

  'iasPassengerOnlineList.soldSeat': 'Passengers',
  'iasPassengerOnlineList.authenticationCount': 'Authentication',
  'iasPassengerOnlineList.passengerNum': 'Online',
  'iasPassengerOnlineList.authenticationSeat': 'Authentication',
  'iasPassengerOnlineList.onlineSeat': 'Online',
  'iasPassengerOnlineList.takenSeat': 'Seated',
  'iasPassengerOnlineList.clickSeatDetails': 'Click seat details',
  'iasPassengerOnlineList.editSeatInformation': 'Please go to [Aircraft] to edit seat configuration information',
}