export default {
  'dashboardMonitor.title': '数据监控',

  'dashboardMonitor.tailNumberSearch': '机尾号搜索...',
  'dashboardMonitor.paramSelection': '监控参数选择',
  'dashboardMonitor.pleaseEnterKey': '请输入参数Key或名称搜索',
  'dashboardMonitor.last5m': '最近5分钟',
  'dashboardMonitor.last10m': '最近10分钟',
  'dashboardMonitor.last30m': '最近30分钟',
  'dashboardMonitor.last1h': '最近1小时',
  'dashboardMonitor.last2h': '最近2小时',
  'dashboardMonitor.last4h': '最近4小时',
  'dashboardMonitor.last6h': '最近6小时',
  'dashboardMonitor.last24h': '最近24小时',
  'dashboardMonitor.last7d': '最近7天',
  'dashboardMonitor.custom': '自定义',

  'dashboardMonitor.1column': '1列',
  'dashboardMonitor.2column': '2列',
  'dashboardMonitor.3column': '3列',
  'dashboardMonitor.4column': '4列',

  'dashboardMonitor.intervalCannotExceed24': '时间间隔不能超过24小时',
};