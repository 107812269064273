export default {
  'iasPassengerOnlineList.title': '在线旅客',

  'iasPassengerOnlineList.enterID': '请输入证件号/座位号搜索',

  'iasPassengerOnlineList.cardId': '证件号',
  'iasPassengerOnlineList.seatNumber': '座位号',
  'iasPassengerOnlineList.numberDevices': '设备数量',
  'iasPassengerOnlineList.LAN': '局域网',
  'iasPassengerOnlineList.satellite': '卫星',
  'iasPassengerOnlineList.edit': '操作',
  'iasPassengerOnlineList.details': '详情',

  'iasPassengerOnlineList.all': '全部',
  'iasPassengerOnlineList.userStatus': '用户状态',
  'iasPassengerOnlineList.refresh': '刷新',

  'iasPassengerOnlineList.soldSeat': '旅客人数',
  'iasPassengerOnlineList.authenticationCount': '认证人数',
  'iasPassengerOnlineList.passengerNum': '上网人数',
  'iasPassengerOnlineList.authenticationSeat': '认证旅客',
  'iasPassengerOnlineList.onlineSeat': '上网旅客',
  'iasPassengerOnlineList.takenSeat': '上坐旅客',
  'iasPassengerOnlineList.clickSeatDetails': '可点击座位查看详情',
  'iasPassengerOnlineList.editSeatInformation': '请到【飞机管理】编辑舱位信息',
}