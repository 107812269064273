export default {
  'nmsEventHistory.title': 'Event history',

  'nmsEventHistory.tailNumber': 'Tail No',
  'nmsEventHistory.flightNumber': 'Flight number',
  'nmsEventHistory.timeInterval': 'Time interval',
  'nmsEventHistory.query': 'Search',
  'nmsEventHistory.tailNumberSearch': 'Tail No...',
  'nmsEventHistory.flightNumberSearch': 'Flight number...',
  'nmsEventHistory.deptSearch': 'Dept search...',
  'nmsEventHistory.destSearch': 'Dest search...',
  'nmsEventHistory.eventNameSearch': 'Event name search...',
  'nmsEventHistory.eventLevelSelect': 'Event level select',
  'nmsEventHistory.exportData': 'Export',

  'nmsEventHistory.tailNo': 'Tail No',
  'nmsEventHistory.flightNo': 'Flight number',
  'nmsEventHistory.deptCity': 'Dept',
  'nmsEventHistory.destCity': 'Dest',
  'nmsEventHistory.eventLevelName': 'Event name',
  'nmsEventHistory.eventPriority': 'Event level',
  'nmsEventHistory.eventTime': 'Time',

  'nmsEventHistory.last5m': 'Last 5 minutes',
  'nmsEventHistory.last10m': 'Last 10 minutes',
  'nmsEventHistory.last30m': 'Last 30 minutes',
  'nmsEventHistory.last1h': 'Last hour',
  'nmsEventHistory.last1d': 'Last day',
  'nmsEventHistory.last2d': 'Last 2 days',
  'nmsEventHistory.last3d': 'Last 3 days',
  'nmsEventHistory.custom': 'Custom',

};
