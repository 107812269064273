export default {
  'nmsService.titleDefinition': 'Service Definition',
  'nmsService.titleSetting': 'Service Setting',
  'nmsService.titleTest': 'Service Test',

  'nmsService.serviceAdd': 'Add',
  'nmsService.serviceEdit': 'Edit',
  'nmsService.settingAdd': 'Add',
  'nmsService.settingEdit': 'Edit',
  'nmsService.startTest': 'Start',

  'nmsService.serviceName': 'Name',
  'nmsService.serviceKey': 'Key',
  'nmsService.description': 'Description',
  'nmsService.updateTime': 'Update Time',
  'nmsService.script': 'Test Script',
  'nmsService.tailNo': 'Tail No.',
  'nmsService.interval': 'Test Interval',
  'nmsService.services': 'Testing Services',
  'nmsService.testResult': 'Result',
  'nmsService.testTime': 'Time',
  'nmsService.cmdType': 'Command Type',
  'nmsService.param': 'Command Script',
  'nmsService.checkType': 'Result Check Type',
  'nmsService.checkValue': 'Fixed Value',

  'nmsService.serviceKeyExp': 'A parameter code with the same name needs to be added in the virtual device',
  'nmsService.paramExp': 'Variables in the script can be copied and applied to the parameter aliases in the configuration',

  'nmsService.contain': 'Contains',
  'nmsService.origin': 'Original Value',

  'nmsService.query': 'Search',
  'nmsService.serviceKeySearch': 'Key...',
  'nmsService.serviceNameSearch': 'Name...',
  'nmsService.searchKey': 'Keywords...',
  'nmsService.tailNoSearch': 'Tail No...',
  'nmsService.selectService': 'Select Service',

  'nmsService.select': 'Select',
  'nmsService.refresh': 'Refresh',
  'nmsService.operation': 'Operation',
  'nmsService.edit': 'Edit',
  'nmsService.delete': 'Delete',
  'nmsService.save': 'Save',
  'nmsService.cancel': 'Cancel'
};
