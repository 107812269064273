export default {
  'passengerVirtual.title': 'Virtual Passenger',

  'passengerVirtual.clickEnableVirtual': 'Click to enable virtual passenger account',
  'passengerVirtual.clickDisableVirtual': 'Click to disable the virtual passenger account',
  'passengerVirtual.newVirtualPassenger': 'New virtual passenger',
  'passengerVirtual.editVirtualPassenger': 'Edit virtual passenger',
  'passengerVirtual.passengerName': 'Passenger name',
  'passengerVirtual.gender': 'Gender',
  'passengerVirtual.certificateNo': 'Certificate No.',
  'passengerVirtual.seatNumber': 'Seat number',
  'passengerVirtual.availableStatus': 'Available status',
  'passengerVirtual.creationTime': 'Creation time',
  'passengerVirtual.remarks': 'Comments',
  'passengerVirtual.operation': 'Operation',

  'passengerVirtual.age': 'Age',
  'passengerVirtual.cabin': 'Cabin',
  'passengerVirtual.integral': 'Integral',
  'passengerVirtual.memberLevel': 'Mmember level',
  'passengerVirtual.nativePlace': 'Native place',
  'passengerVirtual.nonmember': 'Nonmember',
  'passengerVirtual.member': 'Member',
  'passengerVirtual.silver': 'Silver',
  'passengerVirtual.gold': 'Gold',
  'passengerVirtual.platinum': 'Platinum',

  'passengerVirtual.man': 'Male',
  'passengerVirtual.woman': 'Female',
  'passengerVirtual.other': 'Other',
  'passengerVirtual.enabled': 'Enabled',
  'passengerVirtual.disabled': 'Disabled',
  'passengerVirtual.enable': 'Enable',
  'passengerVirtual.disable': 'Disable',
  'passengerVirtual.removeWarning': 'Remove warning',
  'passengerVirtual.pleaseConfirmAgain': 'After deletion, it will not be recovered, and the data generated under this account will not be visible. Please confirm again if you want to delete it? ',
  'passengerVirtual.lastFourDigits': 'Please enter the last four digits of ID number',
  'passengerVirtual.numberEndingWithZ': 'Please enter the seat number ending with letter',
  'passengerVirtual.within32Words': 'Within 32 words',
  'passengerVirtual.save': 'Save',
};