export default {
  'dataFlowAnalysis.title': '流量统计',

  'dataFlowAnalysis.tailNumber': '机尾号',
  'dataFlowAnalysis.flightNumber': '航班号',
  'dataFlowAnalysis.timeInterval': '时间区间',
  'dataFlowAnalysis.query': '查询',
  'dataFlowAnalysis.tailNumberSearch': '机尾号搜索...',
  'dataFlowAnalysis.flightNumberSearch': '航班号搜索...',
  'dataFlowAnalysis.deptSearch': '出发城市、机场三字码搜索...',
  'dataFlowAnalysis.destSearch': '到达城市、机场三字码搜索...',
  'dataFlowAnalysis.exportData': '导出数据',

  'dataFlowAnalysis.flightDate': '航班日期',
  'dataFlowAnalysis.dept': '出发',
  'dataFlowAnalysis.dest': '到达',
  'dataFlowAnalysis.flightDuration': '飞行时长',

  'dataFlowAnalysis.orderTotal': '订单数量',
  'dataFlowAnalysis.orderPassengerCount': '订购人数',
  'dataFlowAnalysis.internetPassengerCount': '上网人数',
  'dataFlowAnalysis.upTrafficTotal': '上行流量(K)',
  'dataFlowAnalysis.downTrafficTotal': '下行流量(K)',
  'dataFlowAnalysis.useTrafficTotal': '累计流量(K)',
  'dataFlowAnalysis.usePer': '人均流量(K)',

  'dataFlowAnalysis.internetPassengerCountExp': '有流量消耗的上网旅客人数',
  'dataFlowAnalysis.usePerExp': '上网人均流量消耗',

  'dataFlowAnalysis.time': '时间',
  'dataFlowAnalysis.totalFlight': '按航班统计',
  'dataFlowAnalysis.totalDay': '按日期统计',
  'dataFlowAnalysis.totalWeek': '按周统计',
  'dataFlowAnalysis.totalMonth': '按月统计',
  'dataFlowAnalysis.totalQuarter': '按季度统计',
  'dataFlowAnalysis.totalYear': '按年统计',

  'dataFlowAnalysis.last1d': '最近1天',
  'dataFlowAnalysis.last2d': '最近2天',
  'dataFlowAnalysis.last3d': '最近3天',
  'dataFlowAnalysis.last5d': '最近5天',
  'dataFlowAnalysis.last7d': '最近7天',
  'dataFlowAnalysis.last30d': '最近30天',
  'dataFlowAnalysis.custom': '自定义',
};
