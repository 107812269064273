export default {
  'dataTraceBack.title': '入网追溯',

  'dataTraceBack.tailNumber': '机尾号',
  'dataTraceBack.flightNumber': '航班号',
  'dataTraceBack.timeInterval': '时间区间',
  'dataTraceBack.query': '查询',
  'dataTraceBack.tailNumberSearch': '机尾号搜索...',
  'dataTraceBack.flightNumberSearch': '航班号搜索...',
  'dataTraceBack.searchKeySearch': '证件或座位号搜索...',
  'dataTraceBack.authenticatedSearch': '实名认证搜索...',
  'dataTraceBack.exportData': '导出数据',

  'dataTraceBack.flightDate': '航班日期',
  'dataTraceBack.dept': '出发',
  'dataTraceBack.dest': '到达',
  'dataTraceBack.idCard': '证件号',
  'dataTraceBack.seatNo': '座位号',
  'dataTraceBack.authenticated': '实名认证',
  'dataTraceBack.accNum': '上网账号',
  'dataTraceBack.joinStatus': '上线状态',
  'dataTraceBack.firstLoginTime': '首次登录时间',
  'dataTraceBack.traceBack': '追溯',
  'dataTraceBack.operation': '操作',
  'dataTraceBack.details': '详情',

  'dataTraceBack.authenticatedExp': '当前航段上的旅客证件号和座位号的实名认证状态',
  'dataTraceBack.joinStatusExp': '成功即至少有一次上线成功，失败即有激活的流量包但从未上线成功过，未上线即没有激活的流量包',

  'dataTraceBack.all': '全部',
  'dataTraceBack.success': '认证成功',
  'dataTraceBack.fail': '认证失败',
  'dataTraceBack.allSuccess': '全部认证状态',
  'dataTraceBack.allActivated': '全部上线状态',

  'dataTraceBack.activated': '上线成功',
  'dataTraceBack.failed': '上线失败',
  'dataTraceBack.inactive': '未上线',

  'dataTraceBack.last10m': '最近10分钟',
  'dataTraceBack.last30m': '最近30分钟',
  'dataTraceBack.last1h': '最近1小时',
  'dataTraceBack.last2h': '最近2小时',
  'dataTraceBack.last3h': '最近3小时',
  'dataTraceBack.last24h': '最近24小时',
  'dataTraceBack.custom': '自定义',
};
