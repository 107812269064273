export default {
  'passengerVirtual.title': '虚拟旅客',

  'passengerVirtual.clickEnableVirtual': '点击启用虚拟旅客账号',
  'passengerVirtual.clickDisableVirtual': '点击禁用虚拟旅客账号',
  'passengerVirtual.newVirtualPassenger': '新增虚拟旅客',
  'passengerVirtual.editVirtualPassenger': '修改虚拟旅客',
  'passengerVirtual.passengerName': '旅客姓名',
  'passengerVirtual.gender': '性别',
  'passengerVirtual.certificateNo': '证件号',
  'passengerVirtual.seatNumber': '座位号',
  'passengerVirtual.availableStatus': '可用状态',
  'passengerVirtual.creationTime': '创建时间',
  'passengerVirtual.remarks': '备注',
  'passengerVirtual.operation': '操作',

  'passengerVirtual.age': '年龄',
  'passengerVirtual.cabin': '舱位',
  'passengerVirtual.integral': '持有积分',
  'passengerVirtual.memberLevel': '会员等级',
  'passengerVirtual.nativePlace': '籍贯',
  'passengerVirtual.nonmember': '非会员',
  'passengerVirtual.member': '普通卡',
  'passengerVirtual.silver': '银卡',
  'passengerVirtual.gold': '金卡',
  'passengerVirtual.platinum': '白金卡',

  'passengerVirtual.man': '男',
  'passengerVirtual.woman': '女',
  'passengerVirtual.other': '其他',
  'passengerVirtual.enabled': '已启用',
  'passengerVirtual.disabled': '已禁用',
  'passengerVirtual.enable': '启用',
  'passengerVirtual.disable': '禁用',
  'passengerVirtual.removeWarning': '删除警告',
  'passengerVirtual.pleaseConfirmAgain': '删除后将不能恢复，且该账号下所产生的数据将不可见，请再次确认是否要删除？',
  'passengerVirtual.lastFourDigits': '请输入身份证号的后四位',
  'passengerVirtual.numberEndingWithZ': '请输入以字母结尾的座位号',
  'passengerVirtual.within32Words': '32字以内',
  'passengerVirtual.save': '保存',



};