export default {
  'nmsAlarmLevel.title': 'Alarm Level',

  'nmsAlarmLevel.addLevel': 'Add Level',

  'nmsAlarmLevel.name': 'Name',
  'nmsAlarmLevel.code': 'Code',
  'nmsAlarmLevel.priority': 'Priority',
  'nmsAlarmLevel.remindType': 'Remind type',
  'nmsAlarmLevel.operate': 'Operation',
  'nmsAlarmLevel.delete': 'Delete',

  'nmsAlarmLevel.nameInput': 'Input name...',
  'nmsAlarmLevel.codeInput': 'Input code...',
  'nmsAlarmLevel.priorityInput': 'The higher the number, the lower the level...',

  'nmsAlarmLevel.system': 'System',
  'nmsAlarmLevel.email': 'Email',
  'nmsAlarmLevel.dingDing': 'Ding Ding',
  'nmsAlarmLevel.sms': 'SMS',

  'nmsAlarmLevel.deleteConfirm': '[Alarm] May be quoted in other places, and will be deleted',
  'nmsAlarmLevel.thinkAgain': 'Think again',
  'nmsAlarmLevel.confirm': 'Confirm',
  'nmsAlarmLevel.cancel': 'Cancel',
  'nmsAlarmLevel.save': 'Save',
  'nmsAlarmLevel.ok': 'Ok',
};
