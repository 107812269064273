export default {
  'rbacUsers.title': '账号管理',
  'rbacUsers.pageDescription': 'XstreamOP 系统登录账号配置',

  'rbacUsers.search': '请输入账号或姓名搜索...',
  'rbacUsers.searchOrganization': '请输入机构名称搜索...',
  'rbacUsers.add': '添加账号',
  'rbacUsers.account': '登录账号',
  'rbacUsers.name': '用户姓名',
  'rbacUsers.orgName': '所属机构',
  'rbacUsers.role': '用户角色',
  'rbacUsers.userPlanCount': '运营飞机',
  'rbacUsers.time': '添加时间',
  'rbacUsers.email': '常用邮箱',
  'rbacUsers.state': '账号状态',
  'rbacUsers.authoriza': '授权',
  'rbacUsers.remark': '备注说明',
  'rbacUsers.operation': '操作',
  'rbacUsers.totalOf': '共 {num} 条',

  'rbacUsers.delete': '删除',
  'rbacUsers.details': '详情',

  'rbacUsers.wantDelete': '删除后将不能恢复，是否确认删除？',

  'rbacUsers.authRole': '用户角色',
  'rbacUsers.unknownRole': '为空即未知角色',
  'rbacUsers.addAccount': '新增账号',
  'rbacUsers.editAccount': '修改账号',
  'rbacUsers.affiliate': '所属机构',
  'rbacUsers.phoneNo': '手机号',
  'rbacUsers.password': '登录密码',
  'rbacUsers.statusSetting': '状态设置',

  'rbacUsers.on': '启用',
  'rbacUsers.off': '停用',

  'rbacUsers.userInfo': '账号信息',
  'rbacUsers.searchTailNo': '请输入公司名称或机尾号搜索...',
  'rbacUsers.plane': '运营飞机',
  'rbacUsers.editInfo': '编辑信息',
  'rbacUsers.selectPlan': '选择运营飞机',
  'rbacUsers.tailNo': '机尾号',
  'rbacUsers.orgName2': '所属公司',
  'rbacUsers.aircraftType': '机型',
  'rbacUsers.seatCount': '座位数',
  'rbacUsers.createTime': '添加时间',
};
